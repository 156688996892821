import { createAction, props, union } from '@ngrx/store';

import {
  AirflowVerificationModel,
  DuctLeakageVerificationModel,
  DuctSystemInformationModel,
  EquipmentInfoModel,
  FanWattDrawModel,
  RefrigerantVerificationModel,
  SystemsModel,
  T24DataCollectionModel,
  UpdateMiniSplitsUnitsModel,
  UpdateT24DataCollectionFormModel
} from '@shared/models';

const fetchSystemsListStartAction = createAction(
  '[T24DataCollection] Fetch systems list',
  props<{ id: number }>()
);

const fetchSystemsListSuccessAction = createAction(
  '[T24DataCollection] Fetch systems list success',
  props<{ systems: SystemsModel[] }>()
);

const fetchSystemsListFailedAction = createAction('[T24DataCollection] Fetch systems list failed');

const removeSplitUnitSystemAction = createAction(
  '[T24DataCollection] Remove split unit system',
  props<{ id: number }>()
);

const removePackageUnitSystemAction = createAction(
  '[T24DataCollection] Remove package unit system',
  props<{ id: number }>()
);

const removeMiniSplitSystemAction = createAction(
  '[T24DataCollection] Remove mini split system',
  props<{ id: number }>()
);

const removeSystemSuccessAction = createAction(
  '[T24DataCollection] Remove system success',
  props<{ systems: SystemsModel[] }>()
);

const fetchSplitUnitsStartAction = createAction(
  '[T24DataCollection] Fetch split units',
  props<{ id: number }>()
);

const fetchSplitUnitsSuccessAction = createAction(
  '[T24DataCollection] Fetch split units success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const fetchSplitUnitsFailedAction = createAction('[T24DataCollection] Fetch split units failed');

const fetchPackageUnitsStartAction = createAction(
  '[T24DataCollection] Fetch package units',
  props<{ id: number }>()
);

const fetchPackageUnitsSuccessAction = createAction(
  '[T24DataCollection] Fetch package units success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const fetchPackageUnitsFailedAction = createAction(
  '[T24DataCollection] Fetch package units failed'
);

const fetchMiniSplitHeatPumpsStartAction = createAction(
  '[T24DataCollection] Fetch mini split heat pumps',
  props<{ id: number }>()
);

const fetchMiniSplitHeatPumpsSuccessAction = createAction(
  '[T24DataCollection] Fetch mini split heat pumps success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const fetchMiniSplitHeatPumpsFailedAction = createAction(
  '[T24DataCollection] Fetch mini split heat pumps failed'
);

const updateSplitUnitsAction = createAction(
  '[T24DataCollection] Update split units',
  props<{ id: number; update: UpdateT24DataCollectionFormModel }>()
);

const updateSplitUnitsSuccessAction = createAction(
  '[T24DataCollection] Update Update split units success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const updatePackageUnitsAction = createAction(
  '[T24DataCollection] Update package units',
  props<{ id: number; update: UpdateT24DataCollectionFormModel }>()
);

const updatePackageUnitsSuccessAction = createAction(
  '[T24DataCollection] Update Update package units success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const updateMiniSplitHeatPumpsAction = createAction(
  '[T24DataCollection] Update mini split heat pumps',
  props<{ id: number; update: UpdateT24DataCollectionFormModel }>()
);

const updateMiniSplitHeatPumpsSuccessAction = createAction(
  '[T24DataCollection] Update mini split heat pumps success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const createMiniSplitUnitsAction = createAction(
  '[T24DataCollection] Create mini split units',
  props<{ create: EquipmentInfoModel }>()
);

const createMiniSplitUnitsSuccessAction = createAction(
  '[T24DataCollection] Create mini split units success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateMiniSplitUnitsAction = createAction(
  '[T24DataCollection] Update mini split units',
  props<{ id: number; update: EquipmentInfoModel }>()
);

const updateMiniSplitUnitsSuccessAction = createAction(
  '[T24DataCollection] Update mini split units success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createUpdateMiniSplitUnitsAction = createAction(
  '[T24DataCollection] Create or update mini split units',
  props<{ update: UpdateMiniSplitsUnitsModel }>()
);

const createUpdateMiniSplitUnitsSuccessAction = createAction(
  '[T24DataCollection] Create or update mini split units success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const createHeatPumpPackageUnitsAction = createAction(
  '[T24DataCollection] Create heat pump package units',
  props<{ create: EquipmentInfoModel }>()
);

const createHeatPumpPackageUnitsSuccessAction = createAction(
  '[T24DataCollection] Create heat pump package units success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateHeatPumpPackageUnitsAction = createAction(
  '[T24DataCollection] Update heat pump package units',
  props<{ id: number; update: EquipmentInfoModel }>()
);

const updateHeatPumpPackageUnitsSuccessAction = createAction(
  '[T24DataCollection] Update heat pump package units success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createGasPackageUnitsAction = createAction(
  '[T24DataCollection] Create gas package units',
  props<{ create: EquipmentInfoModel }>()
);

const createGasPackageUnitsSuccessAction = createAction(
  '[T24DataCollection] Create gas package units success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateGasPackageUnitsAction = createAction(
  '[T24DataCollection] Update gas package units',
  props<{ id: number; update: EquipmentInfoModel }>()
);

const updateGasPackageUnitsSuccessAction = createAction(
  '[T24DataCollection] Update gas package units success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createFurnacesAction = createAction(
  '[T24DataCollection] Create furnaces',
  props<{ create: EquipmentInfoModel }>()
);

const createFurnacesSuccessAction = createAction(
  '[T24DataCollection] Create furnaces success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateFurnacesAction = createAction(
  '[T24DataCollection] Update furnaces',
  props<{ furnaceId: number; update: EquipmentInfoModel }>()
);

const updateFurnacesSuccessAction = createAction(
  '[T24DataCollection] Update furnaces success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createAcEvaporatorsAction = createAction(
  '[T24DataCollection] Create ac evaporators',
  props<{ create: EquipmentInfoModel }>()
);

const createAcEvaporatorsSuccessAction = createAction(
  '[T24DataCollection] Create ac evaporators success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateAcEvaporatorsAction = createAction(
  '[T24DataCollection] Update ac evaporators',
  props<{ acEvaporatorId: number; update: EquipmentInfoModel }>()
);

const updateAcEvaporatorsSuccessAction = createAction(
  '[T24DataCollection] Update ac evaporators success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createHeatPumpCondensersAction = createAction(
  '[T24DataCollection] Create heat pump condensers',
  props<{ create: EquipmentInfoModel }>()
);

const createHeatPumpCondensersSuccessAction = createAction(
  '[T24DataCollection] Create heat pump condensers success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateHeatPumpCondensersAction = createAction(
  '[T24DataCollection] Update heat pump condensers',
  props<{ heatPumpCondenserId: number; update: EquipmentInfoModel }>()
);

const updateHeatPumpCondensersSuccessAction = createAction(
  '[T24DataCollection] Update heat pump condensers success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createAirHandlersAction = createAction(
  '[T24DataCollection] Create air handlers',
  props<{ create: EquipmentInfoModel }>()
);

const createAirHandlersSuccessAction = createAction(
  '[T24DataCollection] Create air handlers success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateAirHandlersAction = createAction(
  '[T24DataCollection] Update air handlers',
  props<{ airHandlerId: number; update: EquipmentInfoModel }>()
);

const updateAirHandlersSuccessAction = createAction(
  '[T24DataCollection] Update air handlers success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createAcCondensersAction = createAction(
  '[T24DataCollection] Create ac condensers',
  props<{ create: EquipmentInfoModel }>()
);

const createAcCondensersSuccessAction = createAction(
  '[T24DataCollection] Create ac condensers success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const updateAcCondensersAction = createAction(
  '[T24DataCollection] Update ac condensers',
  props<{ acCondenserId: number; update: EquipmentInfoModel }>()
);

const updateAcCondensersSuccessAction = createAction(
  '[T24DataCollection] Update ac condensers success',
  props<{ t24Data: EquipmentInfoModel }>()
);

const createAirflowVerificationAction = createAction(
  '[T24DataCollection] Create airflow verification',
  props<{ create: AirflowVerificationModel }>()
);

const createAirflowVerificationSuccessAction = createAction(
  '[T24DataCollection] Create airflow verification success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const updateAirflowVerificationAction = createAction(
  '[T24DataCollection] Update airflow verification',
  props<{ id: number; update: AirflowVerificationModel }>()
);

const updateAirflowVerificationSuccessAction = createAction(
  '[T24DataCollection] Update airflow verification success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const createFanWattDrawAction = createAction(
  '[T24DataCollection] Create fan watt draw',
  props<{ create: FanWattDrawModel }>()
);

const createFanWattDrawSuccessAction = createAction(
  '[T24DataCollection] Create fan watt draw success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const updateFanWattDrawAction = createAction(
  '[T24DataCollection] Update fan watt draw',
  props<{ id: number; update: FanWattDrawModel }>()
);

const updateFanWattDrawSuccessAction = createAction(
  '[T24DataCollection] Update fan watt draw success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const createRefrigerantVerificationsAction = createAction(
  '[T24DataCollection] Create refrigerant verifications',
  props<{ create: RefrigerantVerificationModel }>()
);

const createRefrigerantVerificationsSuccessAction = createAction(
  '[T24DataCollection] Create refrigerant verifications success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const updateRefrigerantVerificationsAction = createAction(
  '[T24DataCollection] Update refrigerant verifications',
  props<{ id: number; update: RefrigerantVerificationModel }>()
);

const updateRefrigerantVerificationsSuccessAction = createAction(
  '[T24DataCollection] Update refrigerant verifications success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const createDuctLeakageVerificationAction = createAction(
  '[T24DataCollection] Create duct leakage verification',
  props<{ create: DuctLeakageVerificationModel }>()
);

const createDuctLeakageVerificationSuccessAction = createAction(
  '[T24DataCollection] Create duct leakage verification success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const updateDuctLeakageVerificationAction = createAction(
  '[T24DataCollection] Update duct leakage verification',
  props<{ id: number; update: DuctLeakageVerificationModel }>()
);

const updateDuctLeakageVerificationSuccessAction = createAction(
  '[T24DataCollection] Update duct leakage verification success',
  props<{ t24Data: T24DataCollectionModel }>()
);

const updateInstalledDuctSystemInfoAction = createAction(
  '[T24DataCollection] Update installed duct system info',
  props<{ id: number; update: DuctSystemInformationModel }>()
);

const updateInstalledDuctSystemInfoSuccessAction = createAction(
  '[T24DataCollection] Update installed duct system info success',
  props<{ t24Data: T24DataCollectionModel }>()
);

export const T24DataCollectionActions = {
  fetchSystemsListStartAction,
  fetchSystemsListSuccessAction,
  fetchSystemsListFailedAction,
  removeSplitUnitSystemAction,
  removePackageUnitSystemAction,
  removeMiniSplitSystemAction,
  removeSystemSuccessAction,
  fetchSplitUnitsStartAction,
  fetchSplitUnitsSuccessAction,
  fetchSplitUnitsFailedAction,
  fetchPackageUnitsStartAction,
  fetchPackageUnitsSuccessAction,
  fetchPackageUnitsFailedAction,
  fetchMiniSplitHeatPumpsStartAction,
  fetchMiniSplitHeatPumpsSuccessAction,
  fetchMiniSplitHeatPumpsFailedAction,
  updateMiniSplitHeatPumpsAction,
  updateMiniSplitHeatPumpsSuccessAction,
  updatePackageUnitsAction,
  updatePackageUnitsSuccessAction,
  updateSplitUnitsAction,
  updateSplitUnitsSuccessAction,
  createMiniSplitUnitsAction,
  createMiniSplitUnitsSuccessAction,
  updateMiniSplitUnitsAction,
  updateMiniSplitUnitsSuccessAction,
  createUpdateMiniSplitUnitsAction,
  createUpdateMiniSplitUnitsSuccessAction,
  updateHeatPumpPackageUnitsAction,
  updateHeatPumpPackageUnitsSuccessAction,
  updateGasPackageUnitsAction,
  updateGasPackageUnitsSuccessAction,
  updateFurnacesAction,
  updateFurnacesSuccessAction,
  updateAcEvaporatorsAction,
  updateAcEvaporatorsSuccessAction,
  updateHeatPumpCondensersAction,
  updateHeatPumpCondensersSuccessAction,
  updateAirHandlersAction,
  updateAirHandlersSuccessAction,
  createAcCondensersAction,
  createAcCondensersSuccessAction,
  updateAcCondensersAction,
  updateAcCondensersSuccessAction,
  createHeatPumpCondensersAction,
  createHeatPumpCondensersSuccessAction,
  createAirHandlersAction,
  createAirHandlersSuccessAction,
  createAcEvaporatorsAction,
  createAcEvaporatorsSuccessAction,
  createFurnacesAction,
  createFurnacesSuccessAction,
  createGasPackageUnitsAction,
  createGasPackageUnitsSuccessAction,
  createHeatPumpPackageUnitsAction,
  createHeatPumpPackageUnitsSuccessAction,
  createAirflowVerificationAction,
  createAirflowVerificationSuccessAction,
  updateAirflowVerificationAction,
  updateAirflowVerificationSuccessAction,
  createFanWattDrawAction,
  createFanWattDrawSuccessAction,
  updateFanWattDrawAction,
  updateFanWattDrawSuccessAction,
  createRefrigerantVerificationsAction,
  createRefrigerantVerificationsSuccessAction,
  updateRefrigerantVerificationsAction,
  updateRefrigerantVerificationsSuccessAction,
  createDuctLeakageVerificationAction,
  createDuctLeakageVerificationSuccessAction,
  updateDuctLeakageVerificationAction,
  updateDuctLeakageVerificationSuccessAction,
  updateInstalledDuctSystemInfoAction,
  updateInstalledDuctSystemInfoSuccessAction
};

const t24DataCollectionActionsUnion = union(T24DataCollectionActions);

export type T24DataCollectionActionsType = typeof t24DataCollectionActionsUnion;
