import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ArchonTranslateLoaderConfig } from './translate-loader.config';

@NgModule({
  imports: [CommonModule]
})
export class ArchonTranslateLoaderModule {
  public static forRoot(config: ArchonTranslateLoaderConfig): ModuleWithProviders {
    return {
      ngModule: ArchonTranslateLoaderModule,
      providers: [{ provide: ArchonTranslateLoaderConfig, useValue: config }]
    };
  }
}
