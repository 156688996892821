import { createReducer, on } from '@ngrx/store';

import { MessageActions, MessageActionsType } from './message.actions';
import { messageInitialState, MessageState } from './message.state';

const reducer = createReducer(
  messageInitialState,
  on(
    MessageActions.setErrorAction,
    (state: MessageState, { error }): MessageState => ({
      ...state,
      error
    })
  ),
  on(
    MessageActions.resetErrorAction,
    MessageActions.handleSuccessAction,
    (state: MessageState): MessageState => ({
      ...state,
      error: null
    })
  )
);

export function messageReducer(state: MessageState, action: MessageActionsType): MessageState {
  return reducer(state, action);
}
