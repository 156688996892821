import {
  CommScheduleStatusEnum,
  CommunicationStatusEnum,
  CommunicationTypeEnum
} from '@shared/enums';

export const CommTypeMap: { [key in CommunicationTypeEnum]: string } = {
  [CommunicationTypeEnum.Mail]: 'Sent Mail Attempt to Schedule',
  [CommunicationTypeEnum.PhoneCall]: 'Sent Phone Call Attempt to Schedule',
  [CommunicationTypeEnum.TextSMS]: 'Sent Text SMS Attempt to Schedule',
  [CommunicationTypeEnum.MarkUnresponsive]: ''
};

export const CommStatusMap: { [key in CommunicationStatusEnum]: string } = {
  [CommunicationStatusEnum.Unsuccessful]: 'Final attempt to schedule homeowner has been made',
  [CommunicationStatusEnum.SuccessfulComplete]: 'Communication successful',
  [CommunicationStatusEnum.UnscheduledMail]: '',
  [CommunicationStatusEnum.UnscheduledPhoneCall]: '',
  [CommunicationStatusEnum.UnscheduledTextSMS]: '',
  [CommunicationStatusEnum.ScheduledMail]: '',
  [CommunicationStatusEnum.ScheduledPhoneCall]: '',
  [CommunicationStatusEnum.ScheduledTextSMS]: '',
  [CommunicationStatusEnum.MarkUnresponsive]: ''
};

export const UnscheduledStatusMap: { [key in CommunicationTypeEnum]: CommunicationStatusEnum } = {
  [CommunicationTypeEnum.Mail]: CommunicationStatusEnum.UnscheduledMail,
  [CommunicationTypeEnum.PhoneCall]: CommunicationStatusEnum.UnscheduledPhoneCall,
  [CommunicationTypeEnum.TextSMS]: CommunicationStatusEnum.UnscheduledTextSMS,
  [CommunicationTypeEnum.MarkUnresponsive]: CommunicationStatusEnum.MarkUnresponsive
};

export const ScheduledStatusMap: { [key in CommunicationTypeEnum]: CommunicationStatusEnum } = {
  [CommunicationTypeEnum.Mail]: CommunicationStatusEnum.ScheduledMail,
  [CommunicationTypeEnum.PhoneCall]: CommunicationStatusEnum.ScheduledPhoneCall,
  [CommunicationTypeEnum.TextSMS]: CommunicationStatusEnum.ScheduledTextSMS,
  [CommunicationTypeEnum.MarkUnresponsive]: CommunicationStatusEnum.MarkUnresponsive
};

export const CommScheduleStatusNoteMap: {[key in CommScheduleStatusEnum]: string } = {
  [CommScheduleStatusEnum.Completed]: 'Communication successful',
  [CommScheduleStatusEnum.Unsuccessful]: 'Final attempt to schedule homeowner has been made',
  [CommScheduleStatusEnum.Scheduled]: '',
  [CommScheduleStatusEnum.Unscheduled]: '',
  [CommScheduleStatusEnum.NotRequested]: '',
  [CommScheduleStatusEnum.NotRequestedActive]: '',
  [CommScheduleStatusEnum.NotRequestedInactive]: 'Communication successful'
}
