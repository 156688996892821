import { createAction, props, union } from '@ngrx/store';

import { StatusTypeEnum } from '@shared/enums';
import {
  CompanyFormModel,
  CompanyModel,
  EntityStatusModel,
  FileModel,
  GeneralLicenseModel,
  GeneralSettingModel,
  PaginationModel,
  PaginationQueryModel,
  PriceListModel
} from '@shared/models';

const fetchCompaniesPaginationStartAction = createAction(
  '[Company] Fetch companies with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchCompaniesPaginationSuccessAction = createAction(
  '[Company] Fetch companies with pagination success',
  props<{ companies: CompanyModel[]; pagination: PaginationModel }>()
);

const changeCompanyStatusAction = createAction(
  '[Company] Change company status',
  props<EntityStatusModel>()
);

const changeCompanyStatusSuccessAction = createAction(
  '[Company] Change company status success',
  props<{ companies: CompanyModel[] }>()
);

const searchByNameAction = createAction(
  '[Company] Search company by name',
  props<{ name: string }>()
);

const searchByNameSuccessAction = createAction(
  '[Company] Search company by name success',
  props<{ companies: CompanyModel[] }>()
);

const fetchCompanyStartAction = createAction(
  '[Company] Fetch company started',
  props<{ id: number; allowOtherCalls?: boolean }>()
);

const fetchCompanySuccessAction = createAction(
  '[Company] Fetch company success',
  props<{ company: CompanyModel; allowOtherCalls?: boolean }>()
);

const fetchCompanyFailedAction = createAction('[Company] Fetch company failed');

const activateCompanyAction = createAction(
  '[Company] Activate company',
  props<EntityStatusModel>()
);

const activateCompanySuccessAction = createAction(
  '[Company] Activate company success',
  props<{ status: StatusTypeEnum }>()
);

const updateCompanyAction = createAction(
  '[Company] Update company',
  props<{ id: number; company: CompanyFormModel }>()
);

const updateCompanySuccessAction = createAction('[Company] Update company success');

const uploadCompanyLogoAction = createAction(
  '[Company] Upload company logo started',
  props<{ id: number; file: File }>()
);

const uploadCompanyLogoSuccessAction = createAction(
  '[Company] Upload company logo success',
  props<{ logo: FileModel }>()
);

const uploadCompanyDocumentSuccessAction = createAction(
  '[Company] Upload company document success',
  props<{ document: FileModel }>()
);

const removeCompanyDocumentAction = createAction(
  '[Company] Remove company document started',
  props<{ id: number; fileId: number }>()
);

const removeCompanyDocumentSuccessAction = createAction(
  '[Company] Remove company document success',
  props<{ documents: FileModel[] }>()
);

const changeBranchStatusAction = createAction(
  '[Company] Change branch status',
  props<EntityStatusModel>()
);

const changeBranchStatusSuccessAction = createAction(
  '[Company] Change branch status success',
  props<{ branches: CompanyModel[] }>()
);

const updateBranchStatusAction = createAction(
  '[Company] Update branch status',
  props<EntityStatusModel>()
);

const updateBranchStatusSuccessAction = createAction(
  '[Company] Update branch status success',
  props<{ status: StatusTypeEnum }>()
);

const deleteBranchAction = createAction('[Company] Delete branch', props<{ id: number }>());

const deleteBranchSuccessAction = createAction(
  '[Company] Delete branch success',
  props<{ branches: CompanyModel[] }>()
);

const createBranchAction = createAction(
  '[Company] Create company branch',
  props<{ company: CompanyFormModel }>()
);

const createBranchSuccessAction = createAction('[Company] Create company branch success');

const updateCompanyPriceListAction = createAction(
  '[Company] Update company price list',
  props<{ price_list: PriceListModel }>()
);

const fetchCompanyDropdownListStartAction = createAction('[Company] Fetch company dropdown list');

const fetchCompanyDropdownListSuccessAction = createAction(
  '[Company] Fetch company dropdown list success',
  props<{ companies: CompanyModel[] }>()
);

const fetchCompanyBranchesDropdownListStartAction = createAction(
  '[Company] Fetch company with branches dropdown list',
  props<{ id: number }>()
);

const fetchCompanyBranchesDropdownListSuccessAction = createAction(
  '[Company] Fetch company with branches dropdown list success',
  props<{ companies: CompanyModel[] }>()
);

const updateCompanyGeneralLicenseAction = createAction(
  '[Company] Update company general license',
  props<{ id: number; general_license: GeneralLicenseModel }>()
);

const updateCompanyGeneralLicenseSuccessAction = createAction(
  '[Company] Update company general license success'
);

const saveCompanyGeneralSettingAction = createAction(
  '[Company] Save company general setting',
  props<{ id: number; settings: GeneralSettingModel }>()
);

const saveCompanyGeneralSettingSuccessAction = createAction(
  '[Company] Save company general setting success'
);

export const CompanyActions = {
  fetchCompaniesPaginationStartAction,
  fetchCompaniesPaginationSuccessAction,
  changeCompanyStatusAction,
  changeCompanyStatusSuccessAction,
  searchByNameAction,
  searchByNameSuccessAction,
  fetchCompanyStartAction,
  fetchCompanySuccessAction,
  fetchCompanyFailedAction,
  activateCompanyAction,
  activateCompanySuccessAction,
  updateCompanyAction,
  updateCompanySuccessAction,
  uploadCompanyLogoAction,
  uploadCompanyLogoSuccessAction,
  uploadCompanyDocumentSuccessAction,
  removeCompanyDocumentAction,
  removeCompanyDocumentSuccessAction,
  changeBranchStatusAction,
  changeBranchStatusSuccessAction,
  updateBranchStatusAction,
  updateBranchStatusSuccessAction,
  deleteBranchAction,
  deleteBranchSuccessAction,
  createBranchAction,
  createBranchSuccessAction,
  updateCompanyPriceListAction,
  fetchCompanyDropdownListStartAction,
  fetchCompanyDropdownListSuccessAction,
  fetchCompanyBranchesDropdownListStartAction,
  fetchCompanyBranchesDropdownListSuccessAction,
  updateCompanyGeneralLicenseAction,
  updateCompanyGeneralLicenseSuccessAction,
  saveCompanyGeneralSettingAction,
  saveCompanyGeneralSettingSuccessAction
};

const companyActionsUnion = union(CompanyActions);

export type CompanyActionsType = typeof companyActionsUnion;
