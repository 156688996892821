import { createAction, props, union } from '@ngrx/store';

import {
  BuildingDepartmentModel,
  BusinessLicenseFormModel,
  BusinessLicenseModel,
  FileModel,
  PaginationModel,
  PaginationQueryModel,
  UpdateBuildingDepartmentModel
} from '@shared/models';

const fetchBuildingDepartmentListAction = createAction(
  '[BuildingDepartment] Fetch building department list'
);

const fetchBuildingDepartmentListSuccessAction = createAction(
  '[BuildingDepartment] Fetch building department list success',
  props<{ building_departments: BuildingDepartmentModel[] }>()
);

const searchByNameAction = createAction(
  '[BuildingDepartment] Search building department by name',
  props<{ name: string }>()
);

const fetchBuildingDepartmentAction = createAction(
  '[BuildingDepartment] Fetch building department',
  props<{ id: number }>()
);

const fetchBuildingDepartmentSuccessAction = createAction(
  '[BuildingDepartment] Fetch building department success',
  props<{ building_department: BuildingDepartmentModel }>()
);

const fetchBuildingDepartmentFailedAction = createAction(
  '[BuildingDepartment] Fetch building department failed'
);

const updateBuildingDepartmentAction = createAction(
  '[BuildingDepartment] Update building department',
  props<{ id: number; buildingDepartment: UpdateBuildingDepartmentModel }>()
);

const updateBuildingDepartmentSuccessAction = createAction(
  '[BuildingDepartment] Update building department success'
);

const uploadBuildingDepartmentDocumentSuccessAction = createAction(
  '[BuildingDepartment] Upload building department document success',
  props<{ document: FileModel }>()
);

const updateBuildingDepartmentDocumentAction = createAction(
  '[BuildingDepartment] Update building department document',
  props<{ id: number; fileId: number; fileName: string }>()
);

const updateBuildingDepartmentDocumentSuccessAction = createAction(
  '[BuildingDepartment] Update building department document success',
  props<{ documents: FileModel[] }>()
);

const removeBuildingDepartmentDocumentAction = createAction(
  '[BuildingDepartment] Remove building department document',
  props<{ id: number; fileId: number }>()
);

const removeBuildingDepartmentDocumentSuccessAction = createAction(
  '[BuildingDepartment] Remove building department document success',
  props<{ documents: FileModel[] }>()
);

const fetchBusinessLicensesPaginationStartAction = createAction(
  '[BuildingDepartment] Fetch business licenses with pagination',
  props<{ id: number; queryModel: PaginationQueryModel }>()
);

const fetchBusinessLicensesPaginationSuccessAction = createAction(
  '[BuildingDepartment] Fetch business licenses with pagination success',
  props<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }>()
);

const createBusinessLicenseAction = createAction(
  '[BuildingDepartment] Create business license',
  props<{ businessLicense: BusinessLicenseFormModel }>()
);

const createBusinessLicenseSuccessAction = createAction(
  '[BuildingDepartment] Create business license success',
  props<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }>()
);

const updateBusinessLicenseAction = createAction(
  '[BuildingDepartment] Update business license',
  props<{ id: number; businessLicense: BusinessLicenseFormModel }>()
);

const updateBusinessLicenseSuccessAction = createAction(
  '[BuildingDepartment] Update business license success',
  props<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }>()
);

const removeBusinessLicenseAction = createAction(
  '[BuildingDepartment] Remove business license',
  props<{ id: number }>()
);

const removeBusinessLicenseSuccessAction = createAction(
  '[BuildingDepartment] Remove business license success',
  props<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }>()
);

export const BuildingDepartmentActions = {
  fetchBuildingDepartmentListAction,
  fetchBuildingDepartmentListSuccessAction,
  searchByNameAction,
  fetchBuildingDepartmentAction,
  fetchBuildingDepartmentSuccessAction,
  fetchBuildingDepartmentFailedAction,
  updateBuildingDepartmentAction,
  updateBuildingDepartmentSuccessAction,
  uploadBuildingDepartmentDocumentSuccessAction,
  updateBuildingDepartmentDocumentAction,
  updateBuildingDepartmentDocumentSuccessAction,
  removeBuildingDepartmentDocumentAction,
  removeBuildingDepartmentDocumentSuccessAction,
  fetchBusinessLicensesPaginationStartAction,
  fetchBusinessLicensesPaginationSuccessAction,
  createBusinessLicenseAction,
  createBusinessLicenseSuccessAction,
  updateBusinessLicenseAction,
  updateBusinessLicenseSuccessAction,
  removeBusinessLicenseAction,
  removeBusinessLicenseSuccessAction
};

const buildingDepartmentActionsUnion = union(BuildingDepartmentActions);

export type BuildingDepartmentActionsType = typeof buildingDepartmentActionsUnion;
