import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { interval, Observable, of } from 'rxjs';
import { combineLatest, filter, switchMap, withLatestFrom } from 'rxjs/operators';

import {PermissionEnum, SessionStorageEnum, UserProfileTypeEnum} from '@shared/enums';
import { AuthActions } from '@store/auth/auth.actions';
import {
  getIsLoadingState,
  getTermsAndConditionAcceptedState,
  getUserPermissionsState,
  getUserProfileState,
  getUserProfileTypeState
} from '@store/auth/auth.selectors';
import { AuthState } from '@store/auth/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AuthState>, private jwtService: JwtHelperService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const allowedUserTypes: UserProfileTypeEnum[] = route.data.userTypes;
    const allowedPermissions: PermissionEnum[] = route.data.permissions;

    if (this.jwtService.isTokenExpired()) {
      if (window.location.pathname && window.location.pathname !== '') {
        localStorage.setItem(SessionStorageEnum.ReferralUrl, window.location.pathname);
      }
      /**
       * @dispatch [Auth] Logout
       */
      this.store.dispatch(AuthActions.logoutAction());

      return of(false);
    }

    return interval(500).pipe(
      withLatestFrom(this.store.select(getIsLoadingState)),
      filter(([, isLoading]) => !isLoading),
      combineLatest(
        this.store.select(getUserProfileTypeState),
        this.store.select(getTermsAndConditionAcceptedState),
        this.store.select(getUserProfileState),
        this.store.select(getUserPermissionsState),
      ),
      switchMap(([, userType, termsAccepted, userProfile, permissions]) => {
        let canAccess = true;

        if (
          (allowedUserTypes && !allowedUserTypes.includes(userType)) ||
          (allowedPermissions && !permissions.some( ai => allowedPermissions.includes(ai.name)))
        ) {
          canAccess = false;
        }

        if (!canAccess && Boolean(userType)) {
          /**
           * @dispatch [Auth] Navigate user
           */
          this.store.dispatch(AuthActions.navigateUserAction({ termsAccepted, userProfile }));
        }

        return of(canAccess);
      })
    );
  }
}
