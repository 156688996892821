import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  MessageResponseModel,
  PaginationModel,
  PaginationQueryModel,
  ZoneFormModel,
  ZoneListResponseModel,
  ZoneModel
} from '@shared/models';
import { flattenZonesWithPagination, getHttpHeaders, objToHttpParams } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ZoneHttpService {
  constructor(private http: HttpClient) {}

  public getPaginatedZones(
    queryParams: PaginationQueryModel
  ): Observable<{ zones: ZoneModel[]; pagination: PaginationModel }> {
    return this.http
      .get<ZoneListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.ZonesPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenZonesWithPagination));
  }

  public getZones(): Observable<{ operational_zones: ZoneModel[] }> {
    return this.http.get<{ operational_zones: ZoneModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.Zones),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getZone(id: number): Observable<{ operational_zone: ZoneModel }> {
    return this.http.get<{ operational_zone: ZoneModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.Zone, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateZone(id: number, zone: ZoneFormModel): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.Zone, id),
      zone,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    routeBinding: number
  ): string {
    return `${baseUrl}/${resource.replace(':id', routeBinding.toString())}`;
  }
}
