import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthRouteDataModel } from '@shared/models';

@Component({
  selector: 'auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthContainerComponent implements OnInit {
  public title: string;
  public subTitle: string;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.route.url.subscribe(() => {
      const { title, subTitle } = this.route.snapshot.firstChild.data as AuthRouteDataModel;
      this.title = title;
      this.subTitle = subTitle;
    });
  }
}
