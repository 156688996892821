import { createAction, props, union } from '@ngrx/store';

import { PaginationModel, PaginationQueryModel, ZoneFormModel, ZoneModel } from '@shared/models';

const fetchZonePaginationStartAction = createAction(
  '[Zone] Fetch zones with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchZonePaginationSuccessAction = createAction(
  '[Zone] Fetch zones with pagination success',
  props<{ zones: ZoneModel[]; pagination: PaginationModel }>()
);

const fetchZoneListStartAction = createAction('[Zone] Fetch zones');

const fetchZoneListSuccessAction = createAction(
  '[Zone] Fetch zones success',
  props<{ zones: ZoneModel[] }>()
);

const fetchZoneStartAction = createAction('[Zone] Fetch zone', props<{ id: number }>());

const fetchZoneSuccessAction = createAction(
  '[Zone] Fetch zone success',
  props<{ zone: ZoneModel }>()
);

const fetchZoneFailedAction = createAction('[Zone] Fetch zone failed');

const updateZoneAction = createAction(
  '[Zone] Update zone',
  props<{ id: number; zone: ZoneFormModel }>()
);

const updateZoneSuccessAction = createAction('[Zone] Update zone success');

export const ZoneActions = {
  fetchZonePaginationStartAction,
  fetchZonePaginationSuccessAction,
  fetchZoneListStartAction,
  fetchZoneListSuccessAction,
  fetchZoneStartAction,
  fetchZoneSuccessAction,
  fetchZoneFailedAction,
  updateZoneAction,
  updateZoneSuccessAction
};

const zoneActionsUnion = union(ZoneActions);

export type ZoneActionsType = typeof zoneActionsUnion;
