import { createAction, props, union } from '@ngrx/store';

import { EmailModel, LoginModel, PasswordChangeModel, RegisterModel } from '@app/+auth/models';
import { UserModel, UserProfileModel } from '@shared/models';

const initLoginFormAction = createAction('[Auth] Init login form');

const loginAction = createAction('[Auth] Login', props<{ login: LoginModel }>());

const loginSuccessAction = createAction('[Auth] Login success', props<{ user: UserModel }>());

const initRegisterFormAction = createAction('[Auth] Init register form');

const registerAction = createAction('[Auth] Register', props<{ register: RegisterModel }>());

const registerSuccessAction = createAction('[Auth] Register success');

const confirmEmailAction = createAction('[Auth] Confirm email', props<{ token: string }>());

const confirmEmailSuccessAction = createAction('[Auth] Confirm email success');

const acceptTermsAndConditionsAction = createAction('[Auth] Accept terms and conditions');

const initPasswordResetFormAction = createAction('[Auth] Init password reset form');

const passwordResetAction = createAction('[Auth] Password reset', props<{ email: EmailModel }>());

const passwordResetSuccessAction = createAction('[Auth] Password reset success');

const initResendVerificationEmailAction = createAction(
  '[Auth] Init resend verification email form'
);

const resendVerificationEmailAction = createAction(
  '[Auth] Resend verification email',
  props<{ email: EmailModel }>()
);

const resendVerificationEmailSuccessAction = createAction(
  '[Auth] Resend verification email success'
);

const initPasswordChangeFormAction = createAction(
  '[Auth] Init password change form',
  props<{ token: string; email: string }>()
);

const passwordChangeAction = createAction(
  '[Auth] Password change',
  props<{ passwordChange: PasswordChangeModel }>()
);

const passwordChangeSuccessAction = createAction('[Auth] Password change success');

const getUserAction = createAction('[Auth] Get user');

const getUserSuccessAction = createAction('[Auth] Get user success', props<{ user: UserModel }>());

const getUserFailedAction = createAction('[Auth] Get user failed');

const logoutAction = createAction('[Auth] Logout');

const navigateUserAction = createAction(
  '[Auth] Navigate user',
  props<{ termsAccepted: boolean; userProfile: UserProfileModel; forceReload?: boolean }>()
);

export const AuthActions = {
  initLoginFormAction,
  loginAction,
  loginSuccessAction,
  initRegisterFormAction,
  registerAction,
  registerSuccessAction,
  confirmEmailAction,
  confirmEmailSuccessAction,
  acceptTermsAndConditionsAction,
  initPasswordResetFormAction,
  passwordResetAction,
  passwordResetSuccessAction,
  initResendVerificationEmailAction,
  resendVerificationEmailAction,
  resendVerificationEmailSuccessAction,
  initPasswordChangeFormAction,
  passwordChangeAction,
  passwordChangeSuccessAction,
  getUserAction,
  getUserSuccessAction,
  getUserFailedAction,
  logoutAction,
  navigateUserAction
};

const authActionsUnion = union(AuthActions);

export type AuthActionsType = typeof authActionsUnion;
