import { InspectionAgreementModel } from '@shared/models';

export interface InspectionAgreementState {
  inspectionAgreement: InspectionAgreementModel;
  isLoading: boolean;
}

export const inspectionAgreementInitialState: InspectionAgreementState = {
  inspectionAgreement: null,
  isLoading: false
};

export const INSPECTION_AGREEMENT_FEATURE = 'inspectionAgreement';
