import { enableProdMode, InjectionToken } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

const DEFAULT_CURRENCY_CODE: InjectionToken<string> = new InjectionToken('DEFAULT_CURRENCY_CODE');

platformBrowserDynamic()
  .bootstrapModule(AppModule, { providers: [{ provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' }] })
  .catch(err => console.error(err));
