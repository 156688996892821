import { MainMenuRouteEnum } from '@shared/enums';
import { SidebarCategoryModel } from '@shared/models';

export const getTechniciansAppMenu = (): SidebarCategoryModel => ({
  category: 'navigation.sidebar.category.technicians-app',
  icon: 'icon-project-request.svg',
  group: [
    {
      name: 'navigation.sidebar.group.inspection-agreement',
      icon: 'icon-general-info.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.inspection-agreement',
          route: `${MainMenuRouteEnum.TechniciansApp}/inspection-agreement/`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.home-score-collection',
      icon: 'icon-general-info.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.general',
          route: `${MainMenuRouteEnum.TechniciansApp}/general/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.roof-attic',
          route: `${MainMenuRouteEnum.TechniciansApp}/roof-attic/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.foundation',
          route: `${MainMenuRouteEnum.TechniciansApp}/foundation/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.walls',
          route: `${MainMenuRouteEnum.TechniciansApp}/walls/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.windows',
          route: `${MainMenuRouteEnum.TechniciansApp}/windows/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.heating',
          route: `${MainMenuRouteEnum.TechniciansApp}/heating/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.cooling',
          route: `${MainMenuRouteEnum.TechniciansApp}/cooling/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.duct',
          route: `${MainMenuRouteEnum.TechniciansApp}/duct/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.hot-water',
          route: `${MainMenuRouteEnum.TechniciansApp}/hot-water/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.pv-system',
          route: `${MainMenuRouteEnum.TechniciansApp}/pv-system/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.appliances',
          route: `${MainMenuRouteEnum.TechniciansApp}/appliances/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.outdoor-features',
          route: `${MainMenuRouteEnum.TechniciansApp}/outdoor-features/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.assessor-electrification',
          route: `${MainMenuRouteEnum.TechniciansApp}/assessor-electrification/`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.bpi-data-collection',
      icon: 'icon-general-info.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.caz-testing',
          route: `${MainMenuRouteEnum.TechniciansApp}/caz-testing/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.infiltration-results',
          route: `${MainMenuRouteEnum.TechniciansApp}/infiltration-results/`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.t24-data-collection',
      icon: 'icon-general-info.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.install-verification',
          route: `${MainMenuRouteEnum.TechniciansApp}/systems/`,
          show: true
        }
      ]
    }
  ]
});
