import { createReducer, on } from '@ngrx/store';

import { ZoneActions, ZoneActionsType } from './zone.actions';
import { zoneInitialState, ZoneState } from './zone.state';

const reducer = createReducer(
  zoneInitialState,
  on(
    ZoneActions.fetchZonePaginationSuccessAction,
    (state: ZoneState, { zones, pagination }): ZoneState => ({
      ...state,
      zones,
      pagination
    })
  ),
  on(
    ZoneActions.fetchZoneListSuccessAction,
    (state: ZoneState, { zones }): ZoneState => ({
      ...state,
      zones
    })
  ),
  on(
    ZoneActions.fetchZoneStartAction,
    (state: ZoneState): ZoneState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    ZoneActions.fetchZoneSuccessAction,
    (state: ZoneState, { zone }): ZoneState => ({
      ...state,
      zone,
      isLoading: false
    })
  ),
  on(
    ZoneActions.fetchZoneFailedAction,
    (state: ZoneState): ZoneState => ({
      ...state,
      isLoading: false
    })
  )
);

export function zoneReducer(state: ZoneState, action: ZoneActionsType): ZoneState {
  return reducer(state, action);
}
