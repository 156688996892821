import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { interval, Observable, of } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';

import { AuthActions } from '@store/auth/auth.actions';
import { getIsLoadingState, getTermsAndConditionAcceptedState, getUserProfileState } from '@store/auth/auth.selectors';
import { AuthState } from '@store/auth/auth.state';

@Injectable({
  providedIn: 'root'
})
export class RestrictionGuard implements CanActivate {
  constructor(private store: Store<AuthState>, private jwtService: JwtHelperService) {}

  public canActivate(): Observable<boolean> {
    if (this.jwtService.isTokenExpired()) {
      return of(true);
    }

    return interval(500).pipe(
      withLatestFrom(
        this.store.select(getIsLoadingState),
        this.store.select(getTermsAndConditionAcceptedState),
        this.store.select(getUserProfileState)
      ),
      filter(([, isLoading]) => !isLoading),
      switchMap(([, , termsAccepted, userProfile]) => {
        /**
         * @dispatch [Auth] Navigate user
         */
        this.store.dispatch(AuthActions.navigateUserAction({ termsAccepted, userProfile }));

        return of(false);
      })
    );
  }
}
