import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { AppState } from './app.state';
import { authReducer } from './auth/auth.reducer';
import { billingReducer } from './billing/billing.reducer';
import { bpiDataCollectionReducer } from './bpi-data-collection/bpi-data-collection.reducer';
import { buildingDepartmentReducer } from './building-department/building-department.reducer';
import { cheersReducer } from './cheers/cheers.reducer';
import { companyReducer } from './company/company.reducer';
import { homeScoreReducer } from './home-score/home-score.reducer';
import { inspectionAgreementReducer } from './inspection-agreement/inspection-agreement.reducer';
import { layoutReducer } from './layout/layout.reducer';
import { messageReducer } from './message/message.reducer';
import { projectRequestReducer } from './project-request/project-request.reducer';
import { projectsReducer } from './projects/projects.reducer';
import { reportsReducer } from './reports/reports.reducer';
import { rolePermissionReducer } from './role-permission/role-permission.reducer';
import { t24DataCollectionReducer } from './t24-data-collection/t24-data-collection.reducer';
import { timeClockReducer } from './time-clock/time-clock.reducer';
import { userReducer } from './user/user.reducer';
import { zoneReducer } from './zone/zone.reducer';

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<AppState> = {
  authentication: authReducer,
  billing: billingReducer,
  bpiDataCollection: bpiDataCollectionReducer,
  buildingDepartment: buildingDepartmentReducer,
  cheers: cheersReducer,
  company: companyReducer,
  layout: layoutReducer,
  message: messageReducer,
  projectRequest: projectRequestReducer,
  projects: projectsReducer,
  reports: reportsReducer,
  rolePermission: rolePermissionReducer,
  router: routerReducer,
  homeScore: homeScoreReducer,
  inspectionAgreement: inspectionAgreementReducer,
  t24DataCollection: t24DataCollectionReducer,
  timeClock: timeClockReducer,
  user: userReducer,
  zone: zoneReducer
};
