import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { AuthFormService, AuthHttpService } from '@app/+auth/services';
import {
  getLoginFormData,
  getPasswordChangeFormData,
  getPasswordResetFormData,
  getRegisterFormData,
  getResendVerificationEmailFormData
} from '@app/+auth/utils';
import { AuthRouterPathEnum } from '@shared/enums';
import { MessageActions } from '@store/message/message.actions';
import { AuthActions } from './auth.actions';
import { getUserIdState, getUserProfileTypeState } from './auth.selectors';
import { AuthState } from './auth.state';

@Injectable()
export class AuthEffects {
  /**
   * @route auth/login
   * @listen [Auth] Init login form
   */
  public initLoginForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.initLoginFormAction),
        map(() => getLoginFormData(this.translate)),
        tap(form => this.formService.setFormData(form))
      ),
    { dispatch: false }
  );
  /**
   * @route auth/login
   * @listen [Auth] Login
   * @dispatch [Auth] Navigate user
   * @dispatch [Auth] Login success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public startLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginAction),
      switchMap(({ login }) =>
        this.authHttpService.login(login).pipe(
          mergeMap(user => [
            AuthActions.navigateUserAction({
              termsAccepted: user.terms_accepted,
              forceReload: true,
              userProfile: user.user_profile
            }),
            AuthActions.loginSuccessAction({ user }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route auth/accept-terms-and-conditions
   * @listen [Auth] Accept terms and conditions
   * @dispatch [Auth] Navigate user
   * @dispatch [Auth] Login success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public confirmTermsAndConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.acceptTermsAndConditionsAction),
      withLatestFrom(this.store.pipe(select(getUserIdState))),
      switchMap(([__, id]) =>
        this.authHttpService.confirmTerms(id).pipe(
          mergeMap(user => [
            AuthActions.navigateUserAction({
              termsAccepted: user.terms_accepted,
              forceReload: true,
              userProfile: user.user_profile
            }),
            AuthActions.loginSuccessAction({ user }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route auth/register
   * @listen [Auth] Init register form
   */
  public initRegisterForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.initRegisterFormAction),
        map(() => getRegisterFormData(this.translate)),
        tap(form => this.formService.setFormData(form))
      ),
    { dispatch: false }
  );
  /**
   * @route auth/register
   * @listen [Auth] Register
   * @dispatch [Auth] Register success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public startRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerAction),
      switchMap(({ register }) =>
        this.authHttpService.register(register).pipe(
          mergeMapTo([AuthActions.registerSuccessAction(), MessageActions.resetErrorAction()]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route auth/register
   * @listen [Auth] Register success
   */
  public successRegister$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.registerSuccessAction),
        tap(() => this.authHttpService.navigateOnSuccess(AuthRouterPathEnum.RegisterSuccess))
      ),
    { dispatch: false }
  );
  /**
   * @route auth/email/confirmation/:token
   * @listen [Auth] Confirm email
   * @dispatch [Auth] Confirm email success
   * @dispatch [Message] Handle error
   */
  public confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmEmailAction),
      switchMap(({ token }) =>
        this.authHttpService.confirmEmail(token).pipe(
          mapTo(AuthActions.confirmEmailSuccessAction()),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route auth/email/confirmation/:token
   * @listen [Auth] Confirm email success
   */
  public successVerificationEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.confirmEmailSuccessAction),
        tap(() =>
          this.authHttpService.navigateOnSuccess(AuthRouterPathEnum.Login)
        )
      ),
    { dispatch: false }
  );
  /**
   * @route auth/password/reset
   * @listen [Auth] Init password reset form
   */
  public initPasswordResetForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.initPasswordResetFormAction),
        map(() => getPasswordResetFormData(this.translate)),
        tap(form => this.formService.setFormData(form))
      ),
    { dispatch: false }
  );
  /**
   * @route auth/password/reset
   * @listen [Auth] Password reset
   * @dispatch [Auth] Password reset success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public startPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.passwordResetAction),
      switchMap(({ email }) =>
        this.authHttpService.passwordReset(email).pipe(
          mergeMapTo([AuthActions.passwordResetSuccessAction(), MessageActions.resetErrorAction()]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route auth/password/reset
   * @listen [Auth] Password reset success
   */
  public successPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.passwordResetSuccessAction),
        tap(() => this.authHttpService.navigateOnSuccess(AuthRouterPathEnum.PasswordResetSuccess))
      ),
    { dispatch: false }
  );
  /**
   * @route auth/email/resend
   * @listen [Auth] Init resend verification email form
   */
  public initResendVerificationEmailForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.initResendVerificationEmailAction),
        map(() => getResendVerificationEmailFormData(this.translate)),
        tap(form => this.formService.setFormData(form))
      ),
    { dispatch: false }
  );
  /**
   * @route auth/email/resend
   * @listen [Auth] Resend verification email
   * @dispatch [Auth] Resend verification email success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public startResendVerificationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resendVerificationEmailAction),
      switchMap(({ email }) =>
        this.authHttpService.resendVerificationEmail(email).pipe(
          mergeMapTo([
            AuthActions.resendVerificationEmailSuccessAction(),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route auth/email/resend
   * @listen [Auth] Resend verification email success
   */
  public successResendVerificationEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.resendVerificationEmailSuccessAction),
        tap(() =>
          this.authHttpService.navigateOnSuccess(AuthRouterPathEnum.ResendVerificationEmailSuccess)
        )
      ),
    { dispatch: false }
  );
  /**
   * @route auth/password/change/:token/:email
   * @listen [Auth] Init password change form
   */
  public initPasswordChangeForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.initPasswordChangeFormAction),
        map(({ token, email }) => getPasswordChangeFormData(this.translate, token, email)),
        tap(form => this.formService.setFormData(form))
      ),
    { dispatch: false }
  );
  /**
   * @route auth/password/change/:token/:email
   * @listen [Auth] Password change
   * @dispatch [Auth] Password change success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public startPasswordChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.passwordChangeAction),
      switchMap(({ passwordChange }) =>
        this.authHttpService.passwordChange(passwordChange).pipe(
          mergeMapTo([
            AuthActions.passwordChangeSuccessAction(),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route auth/password/change/:token/:email
   * @listen [Auth] Password change success
   */
  public successPasswordChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.passwordChangeSuccessAction),
        tap(() => this.authHttpService.navigateOnSuccess(AuthRouterPathEnum.PasswordChangeSuccess))
      ),
    { dispatch: false }
  );
  /**
   * @route /
   * @listen [Auth] Get user
   * @dispatch [Auth] Get user success
   * @dispatch [Message] Reset error
   * @dispatch [Auth] Get user failed
   */
  public getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserAction),
      withLatestFrom(this.store.pipe(select(getUserProfileTypeState))),
      filter(([__, userProfileType]) => !userProfileType && !this.jwtService.isTokenExpired()),
      switchMap(() =>
        this.authHttpService.getUser().pipe(
          mergeMap(user => [
            AuthActions.getUserSuccessAction({ user }),
            MessageActions.resetErrorAction()
          ]),
          catchError(__ => of(AuthActions.getUserFailedAction()))
        )
      )
    )
  );
  /**
   * @listen [Auth] Logout
   */
  public startLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutAction),
        tap(() => this.authHttpService.logout())
      ),
    { dispatch: false }
  );
  /**
   * @listen [Auth] Navigate user
   */
  public navigateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.navigateUserAction),
        tap(({ termsAccepted, userProfile, forceReload }) =>
          this.authHttpService.navigate(termsAccepted, userProfile, forceReload)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AuthState>,
    private actions$: Actions,
    private translate: TranslateService,
    private formService: AuthFormService,
    private authHttpService: AuthHttpService,
    private jwtService: JwtHelperService
  ) {}
}
