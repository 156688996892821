import { createAction, props, union } from '@ngrx/store';
import {
  ImportOldInspectionFormModel,
  ProjectInspectionsListModel,
  ProjectInspectionStepsModel,
  ProjectsListModel
} from '@shared/models';

const fetchProjectsListStartAction = createAction('[Projects] Fetch projects list');

const fetchProjectsListSuccessAction = createAction(
  '[Projects] Fetch projects list success',
  props<{ projects: ProjectsListModel }>()
);

const fetchProjectsListFailedAction = createAction('[Projects] Fetch projects list failed');

const fetchProjectInspectionsListStartAction = createAction(
  '[Projects] Fetch project inspections list',
  props<{ id: number }>()
);

const fetchProjectInspectionsListSuccessAction = createAction(
  '[Projects] Fetch project inspections list success',
  props<{ projectInspections: ProjectInspectionsListModel }>()
);

const fetchProjectInspectionsListFailedAction = createAction(
  '[Projects] Fetch project inspections list failed'
);

const fetchProjectInspectionStepsStartAction = createAction(
  '[Projects] Fetch project inspection steps',
  props<{ id: number }>()
);

const fetchProjectInspectionStepsSuccessAction = createAction(
  '[Projects] Fetch project inspection steps success',
  props<{ projectInspectionSteps: ProjectInspectionStepsModel }>()
);

const fetchProjectInspectionStepsFailedAction = createAction(
  '[Projects] Fetch project inspection steps failed'
);

const changeInspectionStatusStartAction = createAction(
  '[Projects] Change inspection status',
  props<{ id: number; completed: boolean }>()
);

const changeInspectionStatusSuccessAction = createAction(
  '[Projects] Change inspection status success',
  props<{ projectInspectionSteps: ProjectInspectionStepsModel }>()
);

const changeInspectionStatusFailedAction = createAction(
  '[Projects] Change inspection status failed'
);

const changeNoShowStatusStartAction = createAction(
  '[Projects] Change no show status',
  props<{ id: number }>()
);

const changeNoShowStatusSuccessAction = createAction(
  '[Projects] Change no show status success',
  props<{ projectInspectionSteps: ProjectInspectionStepsModel }>()
);

const changeNoShowStatusFailedAction = createAction('[Projects] Change no show status failed');

const importOldInspectionStartAction = createAction(
  '[Projects] Import old inspection',
  props<{ id: number; update: ImportOldInspectionFormModel }>()
);

const importOldInspectionSuccessAction = createAction(
  '[Projects] Import old inspection success',
  props<{ projectInspectionSteps: ProjectInspectionStepsModel }>()
);

const importOldInspectionFailedAction = createAction('[Projects] Import old inspection failed');

export const ProjectsActions = {
  fetchProjectsListStartAction,
  fetchProjectsListSuccessAction,
  fetchProjectsListFailedAction,
  fetchProjectInspectionsListStartAction,
  fetchProjectInspectionsListSuccessAction,
  fetchProjectInspectionsListFailedAction,
  fetchProjectInspectionStepsStartAction,
  fetchProjectInspectionStepsSuccessAction,
  fetchProjectInspectionStepsFailedAction,
  changeInspectionStatusStartAction,
  changeInspectionStatusSuccessAction,
  changeInspectionStatusFailedAction,
  changeNoShowStatusStartAction,
  changeNoShowStatusSuccessAction,
  changeNoShowStatusFailedAction,
  importOldInspectionStartAction,
  importOldInspectionSuccessAction,
  importOldInspectionFailedAction
};

const projectsActionsUnion = union(ProjectsActions);

export type ProjectsActionsType = typeof projectsActionsUnion;
