import { PaginationModel, PermissionModel, RoleModel } from '@shared/models';

export interface RolePermissionState {
  pagination: PaginationModel;
  permission: PermissionModel;
  permissions: PermissionModel[];
  role: RoleModel;
  roles: RoleModel[];
  isLoading: boolean;
}

export const rolePermissionInitialState: RolePermissionState = {
  pagination: null,
  permission: null,
  permissions: [],
  role: null,
  roles: [],
  isLoading: false
};

export const ROLE_PERMISSION_FEATURE = 'rolePermission';
