import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { MessageActionEnum } from '@shared/enums';
import { T24DataCollectionHttpService } from '@shared/services/t24-data-collection-http.service';
import { getSystemsWithoutRemoved } from '@shared/utils';
import { MessageActions } from '@store/message/message.actions';
import { T24DataCollectionActions } from '@store/t24-data-collection/t24-data-collection.actions';
import { getSystemsListState } from '@store/t24-data-collection/t24-data-collection.selectors';
import { T24DataCollectionState } from '@store/t24-data-collection/t24-data-collection.state';

@Injectable()
export class T24DataCollectionEffects {
  /**
   * @route technicians-app/systems/:id
   * @listen [T24DataCollection] Fetch systems list
   * @dispatch [T24DataCollection] Fetch systems list success
   * @dispatch [Message] Reset error
   * @dispatch [T24DataCollection] Fetch systems list failed
   * @dispatch [Message] Handle error
   */
  public fetchSystemsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.fetchSystemsListStartAction),
      switchMap(({ id }) =>
        this.t24DataCollectionHttpService.getSystemsList(id).pipe(
          mergeMap(({ systems }) => [
            T24DataCollectionActions.fetchSystemsListSuccessAction({
              systems
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              T24DataCollectionActions.fetchSystemsListFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/systems/:id
   * @listen [T24DataCollection] Remove split unit system
   * @dispatch [T24DataCollection] Remove split unit system success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeSplitUnitSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.removeSplitUnitSystemAction),
      withLatestFrom(this.store.pipe(select(getSystemsListState))),
      switchMap(([{ id }, systems]) =>
        this.t24DataCollectionHttpService.removeSplitUnitSystem(id).pipe(
          mergeMap(() => [
            T24DataCollectionActions.removeSystemSuccessAction({
              systems: getSystemsWithoutRemoved(id, systems)
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/systems/:id
   * @listen [T24DataCollection] Remove package unit system
   * @dispatch [T24DataCollection] Remove package unit system success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removePackageUnitSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.removePackageUnitSystemAction),
      withLatestFrom(this.store.pipe(select(getSystemsListState))),
      switchMap(([{ id }, systems]) =>
        this.t24DataCollectionHttpService.removePackageUnitSystem(id).pipe(
          mergeMap(() => [
            T24DataCollectionActions.removeSystemSuccessAction({
              systems: getSystemsWithoutRemoved(id, systems)
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/systems/:id
   * @listen [T24DataCollection] Remove mini split system
   * @dispatch [T24DataCollection] Remove mini split system success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeMiniSplitSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.removeMiniSplitSystemAction),
      withLatestFrom(this.store.pipe(select(getSystemsListState))),
      switchMap(([{ id }, systems]) =>
        this.t24DataCollectionHttpService.removeMiniSplitSystem(id).pipe(
          mergeMap(() => [
            T24DataCollectionActions.removeSystemSuccessAction({
              systems: getSystemsWithoutRemoved(id, systems)
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/install-verification/:id/SplitUnit
   * @listen [T24DataCollection] Fetch split units
   * @dispatch [T24DataCollection] Fetch split units success
   * @dispatch [Message] Reset error
   * @dispatch [T24DataCollection] Fetch split units failed
   * @dispatch [Message] Handle error
   */
  public fetchSplitUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.fetchSplitUnitsStartAction),
      switchMap(({ id }) =>
        this.t24DataCollectionHttpService.getSplitUnits(id).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.fetchSplitUnitsSuccessAction({
              t24Data
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              T24DataCollectionActions.fetchSplitUnitsFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/install-verification/:id/PackageUnit
   * @listen [T24DataCollection] Fetch package units
   * @dispatch [T24DataCollection] Fetch package units success
   * @dispatch [Message] Reset error
   * @dispatch [T24DataCollection] Fetch package units failed
   * @dispatch [Message] Handle error
   */
  public fetchPackageUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.fetchPackageUnitsStartAction),
      switchMap(({ id }) =>
        this.t24DataCollectionHttpService.getPackageUnits(id).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.fetchPackageUnitsSuccessAction({
              t24Data
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              T24DataCollectionActions.fetchPackageUnitsFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/install-verification/:id/MiniSplitHeatpump
   * @listen [T24DataCollection] Fetch mini split heat pumps
   * @dispatch [T24DataCollection] Fetch mini split heat pumps success
   * @dispatch [Message] Reset error
   * @dispatch [T24DataCollection] Fetch mini split heat pumps failed
   * @dispatch [Message] Handle error
   */
  public fetchMiniSplitHeatPump$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.fetchMiniSplitHeatPumpsStartAction),
      switchMap(({ id }) =>
        this.t24DataCollectionHttpService.getMiniSplitHeatPump(id).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.fetchMiniSplitHeatPumpsSuccessAction({
              t24Data
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              T24DataCollectionActions.fetchMiniSplitHeatPumpsFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/install-verification/:id/SplitUnit
   * @listen [T24DataCollection] Update split units
   * @dispatch [T24DataCollection] Update split units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateSplitUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateSplitUnitsAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateSplitUnits(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateSplitUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/install-verification/:id/PackageUnit
   * @listen [T24DataCollection] Update package units
   * @dispatch [T24DataCollection] Update package units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updatePackageUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updatePackageUnitsAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updatePackageUnits(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updatePackageUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/install-verification/:id/MiniSplitHeatpump
   * @listen [T24DataCollection] Update mini split heat pumps
   * @dispatch [T24DataCollection] Update mini split heat pumps success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateMiniSplitHeatPumps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateMiniSplitHeatPumpsAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateMiniSplitHeatPump(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateMiniSplitHeatPumpsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/MiniSplitHeatpump
   * @listen [T24DataCollection] Create mini split units
   * @dispatch [T24DataCollection] Create mini split units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createMiniSplitUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createMiniSplitUnitsAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createMiniSplitUnits(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createMiniSplitUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/MiniSplitHeatpump
   * @listen [T24DataCollection] Update mini split units
   * @dispatch [T24DataCollection] Update mini split units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateMiniSplitUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateMiniSplitUnitsAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateMiniSplitUnits(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateMiniSplitUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/MiniSplitHeatpump
   * @listen [T24DataCollection] Create or update mini split units
   * @dispatch [T24DataCollection] Create or update mini split units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createUpdateMiniSplitUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createUpdateMiniSplitUnitsAction),
      switchMap(({ update }) =>
        this.t24DataCollectionHttpService.createUpdateMiniSplitUnits(update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createUpdateMiniSplitUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/PackageUnit
   * @listen [T24DataCollection] Create heat pump package units
   * @dispatch [T24DataCollection] Create heat pump package units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createHeatPumpPackageUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createHeatPumpPackageUnitsAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createHeatPumpPackageUnits(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createHeatPumpPackageUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/PackageUnit
   * @listen [T24DataCollection] Update heat pump package units
   * @dispatch [T24DataCollection] Update heat pump package units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateHeatPumpPackageUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateHeatPumpPackageUnitsAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateHeatPumpPackageUnits(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateHeatPumpPackageUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/PackageUnit
   * @listen [T24DataCollection] Create gas package units
   * @dispatch [T24DataCollection] Create gas package units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createGasPackageUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createGasPackageUnitsAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createGasPackageUnits(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createGasPackageUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/PackageUnit
   * @listen [T24DataCollection] Update gas package units
   * @dispatch [T24DataCollection] Update gas package units success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateGasPackageUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateGasPackageUnitsAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateGasPackageUnits(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateGasPackageUnitsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Create furnaces
   * @dispatch [T24DataCollection] Create furnaces success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createFurnaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createFurnacesAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createFurnaces(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createFurnacesSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Update furnaces
   * @dispatch [T24DataCollection] Update furnaces success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateFurnaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateFurnacesAction),
      switchMap(({ furnaceId, update }) =>
        this.t24DataCollectionHttpService.updateFurnaces(furnaceId, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateFurnacesSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Create ac evaporators
   * @dispatch [T24DataCollection] Create ac evaporators success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createAcEvaporators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createAcEvaporatorsAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createAcEvaporators(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createAcEvaporatorsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Update ac evaporators
   * @dispatch [T24DataCollection] Update ac evaporators success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateAcEvaporators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateAcEvaporatorsAction),
      switchMap(({ acEvaporatorId, update }) =>
        this.t24DataCollectionHttpService.updateAcEvaporators(acEvaporatorId, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateAcEvaporatorsSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Create heat pump condensers
   * @dispatch [T24DataCollection] Create heat pump condensers success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createHeatPumpCondensers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createHeatPumpCondensersAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createHeatPumpCondensers(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createHeatPumpCondensersSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Update heat pump condensers
   * @dispatch [T24DataCollection] Update heat pump condensers success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateHeatPumpCondensers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateHeatPumpCondensersAction),
      switchMap(({ heatPumpCondenserId, update }) =>
        this.t24DataCollectionHttpService
          .updateHeatPumpCondensers(heatPumpCondenserId, update)
          .pipe(
            mergeMap(t24Data => [
              T24DataCollectionActions.updateHeatPumpCondensersSuccessAction({ t24Data }),
              MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
            ]),
            catchError((errorResponse: HttpErrorResponse) =>
              of(MessageActions.handleErrorAction({ errorResponse }))
            )
          )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Create air handlers
   * @dispatch [T24DataCollection] Create air handlers success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createAirHandlers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createAirHandlersAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createAirHandlers(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createAirHandlersSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Update air handlers
   * @dispatch [T24DataCollection] Update air handlers success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateAirHandlers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateAirHandlersAction),
      switchMap(({ airHandlerId, update }) =>
        this.t24DataCollectionHttpService.updateAirHandlers(airHandlerId, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateAirHandlersSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Create ac condensers
   * @dispatch [T24DataCollection] Create ac condensers success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createAcCondensers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createAcCondensersAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createAcCondensers(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createAcCondensersSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/equipment-info/:id/SplitUnit
   * @listen [T24DataCollection] Update ac condensers
   * @dispatch [T24DataCollection] Update ac condensers success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateAcCondensers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateAcCondensersAction),
      switchMap(({ acCondenserId, update }) =>
        this.t24DataCollectionHttpService.updateAcCondensers(acCondenserId, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateAcCondensersSuccessAction({ t24Data }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/install-verification/:id/:type
   * @listen [T24DataCollection] Create airflow verification
   * @dispatch [T24DataCollection] Create airflow verification success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createAirflowVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createAirflowVerificationAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createAirflowVerification(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createAirflowVerificationSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/install-verification/:id/:type
   * @listen [T24DataCollection] Update airflow verification
   * @dispatch [T24DataCollection] Update airflow verification success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateAirflowVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateAirflowVerificationAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateAirflowVerification(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateAirflowVerificationSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/fan-watt-draw/:id/:type
   * @listen [T24DataCollection] Create fan watt draw
   * @dispatch [T24DataCollection] Create fan watt draw success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createFanWattDraw$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createFanWattDrawAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createFanWattDraw(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createFanWattDrawSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/fan-watt-draw/:id/:type
   * @listen [T24DataCollection] Update fan watt draw
   * @dispatch [T24DataCollection] Update fan watt draw success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateFanWattDraw$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateFanWattDrawAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateFanWattDraw(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateFanWattDrawSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/refrigerant-verification/:id/:type
   * @listen [T24DataCollection] Create refrigerant verification
   * @dispatch [T24DataCollection] Create refrigerant verification success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createRefrigerantVerifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createRefrigerantVerificationsAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createRefrigerantVerifications(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createRefrigerantVerificationsSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/refrigerant-verification/:id/:type
   * @listen [T24DataCollection] Update refrigerant verification
   * @dispatch [T24DataCollection] Update refrigerant verification success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateRefrigerantVerifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateRefrigerantVerificationsAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateRefrigerantVerifications(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateRefrigerantVerificationsSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/duct-leakage-verification/:id/:type
   * @listen [T24DataCollection] Create duct leakage verification
   * @dispatch [T24DataCollection] Create duct leakage verification success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createDuctLeakageVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.createDuctLeakageVerificationAction),
      switchMap(({ create }) =>
        this.t24DataCollectionHttpService.createDuctLeakageVerification(create).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.createDuctLeakageVerificationSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/duct-leakage-verification/:id/:type
   * @listen [T24DataCollection] Update duct leakage verification
   * @dispatch [T24DataCollection] Update duct leakage verification success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateDuctLeakageVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateDuctLeakageVerificationAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateDuctLeakageVerification(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateDuctLeakageVerificationSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /** @route technicians-app/installed-duct-system-info/:id/:type
   * @listen [T24DataCollection] Update installed duct system info
   * @dispatch [T24DataCollection] Update installed duct system info success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateInstalledDuctSystemInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(T24DataCollectionActions.updateInstalledDuctSystemInfoAction),
      switchMap(({ id, update }) =>
        this.t24DataCollectionHttpService.updateInstalledDuctSystemInfo(id, update).pipe(
          mergeMap(t24Data => [
            T24DataCollectionActions.updateInstalledDuctSystemInfoSuccessAction({
              t24Data
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );

  constructor(
    private store: Store<T24DataCollectionState>,
    private actions$: Actions,
    private t24DataCollectionHttpService: T24DataCollectionHttpService
  ) {}
}
