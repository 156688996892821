import { createReducer, on } from '@ngrx/store';

import { TimeClockActions, TimeClockActionsType } from './time-clock.actions';
import { timeClockInitialState, TimeClockState } from './time-clock.state';

const reducer = createReducer(
  timeClockInitialState,
  on(
    TimeClockActions.createTaskSuccessAction,
    TimeClockActions.fetchTasksSuccessAction,
    TimeClockActions.removeTaskSuccessAction,
    TimeClockActions.updateTasksSuccessAction,
    (state: TimeClockState, { tasks, summary }): TimeClockState => ({
      ...state,
      tasks,
      summary,
      isLoading: false
    })
  ),
  on(
    TimeClockActions.fetchHistoryAction,
    TimeClockActions.fetchTasksForDateAction,
    TimeClockActions.fetchEmployeeEarningAction,
    (state: TimeClockState): TimeClockState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    TimeClockActions.fetchHistorySuccessAction,
    (state: TimeClockState, { history }): TimeClockState => ({
      ...state,
      history,
      isLoading: false
    })
  ),
  on(
    TimeClockActions.fetchEmployeeEarningSuccessAction,
    TimeClockActions.addCustomBonusSuccessAction,
    TimeClockActions.updateCustomBonusSuccessAction,
    (state: TimeClockState, { earnings }): TimeClockState => ({
      ...state,
      earnings,
      isLoading: false
    })
  ),
  on(
    TimeClockActions.fetchHistoryFailedAction,
    TimeClockActions.fetchTasksForDateFailedAction,
    TimeClockActions.fetchEmployeeEarningFailedAction,
    (state: TimeClockState): TimeClockState => ({
      ...state,
      isLoading: false
    })
  )
);

export function timeClockReducer(
  state: TimeClockState,
  action: TimeClockActionsType
): TimeClockState {
  return reducer(state, action);
}
