import Rmap from 'ramda/es/map';
import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';

import { StatusTypeEnum } from '@shared/enums';
import {
  AccountResponseModel,
  PaginationModel,
  UserModel,
  UserProfileModel,
  UserProfileResponseModel
} from '@shared/models';

export const getUserAccountsWithUpdatedStatus = (
  id: number,
  status: StatusTypeEnum,
  accounts: UserModel[]
): UserModel[] =>
  Rmap(
    (account: UserModel) => ({
      ...account,
      user_profile: {
        ...account.user_profile,
        status: account.id === id ? status : account.user_profile.status
      }
    }),
    accounts
  );

export const getUserProfilesWithUpdatedStatus = (
  id: number,
  status: StatusTypeEnum,
  userProfiles: UserProfileModel[]
): UserProfileModel[] =>
  Rmap(
    (userProfile: UserProfileModel) => ({
      ...userProfile,
      status: userProfile.id === id ? status : userProfile.status
    }),
    userProfiles
  );

export const flattenAccountsWithPagination = (
  data: AccountResponseModel
): { accounts: UserModel[]; pagination: PaginationModel } => ({
  accounts: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});

export const flattenUserProfilesWithPagination = (
  data: UserProfileResponseModel
): { user_profiles: UserProfileModel[]; pagination: PaginationModel } => ({
  user_profiles: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});
