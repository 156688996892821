import {
  ApplianceModel,
  AssessorElectrificationModel,
  CoolingModel,
  DuctModel,
  FoundationModel,
  GeneralModel,
  HeatingModel,
  HotWaterModel,
  OutdoorFeaturesModel,
  PvSystemModel,
  RoofAtticModel,
  WallModel,
  WindowsModel
} from '@shared/models';

export interface HomeScoreState {
  general: GeneralModel;
  insulation_zones: RoofAtticModel[];
  foundations: FoundationModel[];
  walls: WallModel[];
  windows: WindowsModel;
  heatings: HeatingModel[];
  coolings: CoolingModel[];
  ducts: DuctModel[];
  hotWater: HotWaterModel;
  pvSystem: PvSystemModel;
  appliances: ApplianceModel[];
  outdoorFeatures: OutdoorFeaturesModel;
  assessorElectrification: AssessorElectrificationModel;
  isLoading: boolean;
}

export const homeScoreInitialState: HomeScoreState = {
  general: null,
  insulation_zones: [],
  foundations: [],
  walls: [],
  windows: null,
  heatings: [],
  coolings: [],
  ducts: [],
  hotWater: null,
  pvSystem: null,
  appliances: [],
  outdoorFeatures: null,
  assessorElectrification: null,
  isLoading: false
};

export const HOME_SCORE_FEATURE = 'homeScore';
