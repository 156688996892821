import { createAction, props, union } from '@ngrx/store';

import { InspectionAgreementModel } from '@shared/models';

const fetchInspectionAgreementStartAction = createAction(
  '[InspectionAgreement] Fetch inspection agreement',
  props<{ id: number }>()
);

const fetchInspectionAgreementSuccessAction = createAction(
  '[InspectionAgreement] Fetch inspection agreement success',
  props<{ inspectionAgreement: InspectionAgreementModel }>()
);

const fetchInspectionAgreementFailedAction = createAction(
  '[InspectionAgreement] Fetch inspection agreement failed'
);

const updateInspectionAgreementStartAction = createAction(
  '[InspectionAgreement] Update inspection agreement',
  props<{ id: number; update: InspectionAgreementModel }>()
);

const updateInspectionAgreementSuccessAction = createAction(
  '[InspectionAgreement] Update inspection agreement success',
  props<{ inspectionAgreement: InspectionAgreementModel }>()
);

const updateInspectionAgreementFailedAction = createAction(
  '[InspectionAgreement] Update inspection agreement failed'
);

export const InspectionAgreementActions = {
  fetchInspectionAgreementStartAction,
  fetchInspectionAgreementSuccessAction,
  fetchInspectionAgreementFailedAction,
  updateInspectionAgreementStartAction,
  updateInspectionAgreementSuccessAction,
  updateInspectionAgreementFailedAction
};

const inspectionAgreementActionsUnion = union(InspectionAgreementActions);

export type InspectionAgreementActionsType = typeof inspectionAgreementActionsUnion;
