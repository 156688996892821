import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { HeroLoaderModule } from '@herodevs/hero-loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/primeng';

import { AuthLayoutModule } from '@app/layouts';
import { CommonLayoutModule } from '@app/layouts';
import { environment } from '@environments/environment';
import { ApiResourceEnum, SessionStorageEnum } from '@shared/enums';
import { PendingChangesGuard } from '@shared/guards/pending-changes.guard';
import { SessionExpirationModule } from '@shared/modules/+session-expiration';
import {
  ArchonTranslateLoader,
  ArchonTranslateLoaderModule
} from '@shared/modules/+translate-loader';
import { reducers } from '@store/app.reducer';
import { AuthEffects } from '@store/auth';
import { LayoutEffects } from '@store/layout';
import { MessageEffects } from '@store/message';
import { AppComponent } from './app.component';
import { AppRoutingModule, routes } from './app.routes';

export function tokenGetter(): string {
  return localStorage.getItem(SessionStorageEnum.AccessToken);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ArchonTranslateLoaderModule.forRoot({
      dictionaries: [
        'auth',
        'common',
        'field',
        'navigation',
        'session-expiration',
        'technicians-app',
        'time-clock'
      ],
      dictionaryPath: './assets/i18n'
    }),
    AuthLayoutModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'archon-compass' }),
    CommonLayoutModule,
    CommonModule,
    EffectsModule.forRoot([AuthEffects, LayoutEffects, MessageEffects]),
    FormlyModule.forRoot(),
    HeroLoaderModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [
          environment.API_DOMAIN,
          environment.API_TECHNICIANS_DOMAIN,
          environment.API_TIMECLOCK_DOMAIN
        ]
      }
    }),
    HttpClientModule,
    RouterModule.forRoot(routes, { useHash: false }),
    SessionExpirationModule.forRoot({
      refreshUrl: `${environment.API_URL}/${ApiResourceEnum.Refresh}`,
      localStorageName: SessionStorageEnum.AccessToken,
      trackingUrls: [ApiResourceEnum.Refresh],
      gracePeriod: 2
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false
      }
    }),
    StoreRouterConnectingModule.forRoot(),
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    AccordionModule,
    DialogModule,
    ConfirmDialogModule,
    StoreRouterConnectingModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: ArchonTranslateLoader
      }
    }),

  ],
  providers: [ConfirmationService, PendingChangesGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
  }
}
