export enum PositionTypeEnum {
  CEO = 'CEO',
  Inspector = 'Inspector',
  PermitTechnician = 'Permit Technician',
  OfficeStaff = 'Office Staff',
  OfficeManager = 'Office Manager',
  Accounting = 'Accounting',
  Owner = 'Owner',
  ProjectManager = 'Project Manager',
  Technician = 'Technician',
  SalesPerson = 'Sales Person',
  SalesManager = 'Sales Manager',
  LeadInstaller = 'Lead Installer',
  Apprentice = 'Apprentice'
}

export enum StatusTypeEnum {
  Active = 'active',
  Disabled = 'disabled',
  Pending = 'pending',
  Unverified = 'unverified',
  ReadyForApproval = 'ready_for_approval'
}

export enum UserProfileTypeEnum {
  Client = 'Client',
  Installer = 'Installer',
  SalesPerson = 'Sales Person',
  ArchonEmployee = 'Archon Employee',
  CompanyEmployee = 'Company Employee'
}

export enum PermissionTypeEnum {
  UserManagement = 'User Management',
  ScheduleSection = 'Schedule Section',
  ProjectCreation = 'Project Creation',
  Finance = 'Finance',
  ServicePrices = 'Service Prices',
  DocumentStorage = 'Document Storage',
  TechnicalSupport = 'Technical Support',
  TimeClock = 'Time Clock',
  Inspections = 'Inspections',
  Permits = 'Permits',
  BuildingDepartmentNotes = 'Building Department Notes',
  TaskManagement = 'Task Management',
  VehicleManagement = 'Vehicle Management',
  ReportsAnalytics = 'Reports / Analytics'
}

export enum RoleEnum {
  SuperUser = 'SuperUser'
}

export enum RoleTypeEnum {
  Archon = 'archon',
  Company = 'company',
  Branch = 'branch'
}

export enum PermissionEnum {
  CanSeeInspectionServicePrice = 'CanSeeInspectionServicePrice',
  CanCreateInvoice = 'CanCreateInvoice',
  CanApprovePr = 'CanApproveProjectRequest',
  CanEditPr = 'CanEditProjectRequest',
  CanCancelProject = 'CanCancelProject',
  CanSeeCommSchedule = 'CanSeeCommSchedule',
  CanScheduleInspection = 'CanScheduleInspection',
  CanSchedulePermit = 'CanSchedulePermit',
  CanScheduleTask = 'CanScheduleTask',
  CanSeeFinance = 'CanSeeFinance',
  CanSeeReports = 'CanSeeReports',
  CanSeeInventory = 'CanSeeInventory',
  CanInventoryManage = 'CanManageInventory',
  CanDeleteInventory = 'CanDeleteInventory',
  CanSeeAdmin = 'CanSeeAdmin',
  CanSeeCompanyProfile = 'CanSeeCompanyProfile',
  CanManageCompanyProfile = 'CanManageCompanyProfile',
  CanSeeCompanyDocuments = 'CanSeeCompanyDocuments',
  CanSeeBranches = 'CanSeeBranches',
  CanManageGeneralContacts = 'CanManageGeneralContacts',
  CanSeeBillingInfo = 'CanSeeBillingInfo',
  CanSeeGeneralSettings = 'CanSeeGeneralSettings',
  CanSeeHomeOwners = 'CanSeeHomeOwners',
  CanSeeSalesPerson = 'CanSeeSalesPerson',
  CanEditSalesPerson = 'CanEditSalesPerson',
  CanSeeInstallers = 'CanSeeInstallers',
  CanEditInstallers = 'CanEditInstallers',
  CanSeeTitle24Options = 'CanSeeTitle24Options',
  CanSeeRebateCompanies = 'CanSeeRebateCompanies',
  CanSeeHomeEnergyScore = 'CanSeeHomeEnergyScore',
  CanSeeAuthorizationLetters = 'CanSeeAuthorizationLetters',
  CanSeePermitContractors = 'CanSeePermitContractors',
  CanSeeBusinessLicenses = 'CanSeeBusinessLicenses',
  CanEditBusinessLicenses = 'CanEditBusinessLicenses',
  CanDeleteBusinessLicenses = 'CanDeleteBusinessLicenses',
  CanSeeBuildingDepartmentNote = 'CanSeeBuildingDepartmentNote',
  CanSeePermitServicePrice = 'CanSeePermitServicePrice',
  CanRequestCancelProject = 'CanRequestCancelProject',
  CanRequestAdminToPullPermit = 'CanRequestAdminToPullPermit',
  CanSeeCompanyEmployees = 'CanSeeCompanyEmployees',
  CanSeeCompanyAccounts = 'CanSeeCompanyAccounts',
  DeleteDocuments = 'CanDeleteDocuments',
  UploadDocuments = 'CanUploadDocuments',
  CanDelete = 'CanDeleteProjectFiles',
  CanDeleteAnyNote = 'CanDeleteAnyNote',
  CanDeleteOwnNote = 'CanDeleteOwnNote',
  CanSuperDeleteProject = 'CanSuperDeleteProject',
  CanDeleteCompany = 'CanDeleteCompany',
  CanForcePassed = 'CanForcePassed',
  CanReset2FA = 'CanReset2FA'
}
