import { CompanyModel, PaginationModel } from '@shared/models';

export interface CompanyState {
  companies: CompanyModel[];
  company: CompanyModel;
  pagination: PaginationModel;
  isLoading: boolean;
}

export const companyInitialState: CompanyState = {
  companies: [],
  company: null,
  pagination: null,
  isLoading: false
};

export const COMPANY_FEATURE = 'company';
