import { ConfigOption } from '@ngx-formly/core';

import { WrapperEnum } from './enums';
import { FormlyFieldPhoneInput } from './inputs';
import { GroupTitleWrapperComponent } from './wrappers';

export const FORMLY_CONTROLS_WRAPPERS = [GroupTitleWrapperComponent];
export const FORMLY_CONTROLS_INPUTS = [FormlyFieldPhoneInput];

export const FORMLY_CONTROLS_CONFIG: ConfigOption = {
  wrappers: [
    {
      name: WrapperEnum.GroupTitle,
      component: GroupTitleWrapperComponent
    }
  ],
  types: [{ name: 'phone-input', component: FormlyFieldPhoneInput }]
};
