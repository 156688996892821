import { MainMenuRouteEnum, PermissionEnum } from '@shared/enums';
import { PermissionModel, SidebarCategoryModel } from '@shared/models';

export const getAdminPanelMenu = (permissions: PermissionModel[]): SidebarCategoryModel => ({
  category: 'navigation.sidebar.category.admin-panel',
  icon: 'icon-project-request.svg',
  group: [
    {
      name: 'navigation.sidebar.group.contractors',
      icon: 'icon-general-info.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.contractor-list',
          route: `${MainMenuRouteEnum.AdminPanel}/contractors`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.accounts',
      icon: 'icon-permits.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.archon-employees',
          route: `${MainMenuRouteEnum.AdminPanel}/archon-employees`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeAdmin)
        },
        {
          name: 'navigation.sidebar.item.accounts',
          route: `${MainMenuRouteEnum.AdminPanel}/accounts`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeAdmin)
        },
        {
          name: 'navigation.sidebar.item.general-contacts',
          route: `${MainMenuRouteEnum.AdminPanel}/general-contacts`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.company-directory',
          route: `${MainMenuRouteEnum.AdminPanel}/company-directory`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.roles',
          route: `${MainMenuRouteEnum.AdminPanel}/roles`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeAdmin)
        },
        {
          name: 'navigation.sidebar.item.permissions',
          route: `${MainMenuRouteEnum.AdminPanel}/permissions`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeAdmin)
        },
        {
          name: 'navigation.sidebar.item.important-dates',
          route: `${MainMenuRouteEnum.AdminPanel}/dates`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.billing-info',
      icon: 'icon-billing.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.price-list',
          route: `${MainMenuRouteEnum.AdminPanel}/prices`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.company-pricing',
          route: `${MainMenuRouteEnum.AdminPanel}/company-prices`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.price-categories',
          route: `${MainMenuRouteEnum.AdminPanel}/price-categories`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.price-services',
          route: `${MainMenuRouteEnum.AdminPanel}/price-services`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.bonus-pricing',
          route: `${MainMenuRouteEnum.AdminPanel}/bonus-pricing`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.settings',
      icon: 'icon-inspections.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.building-dep-note',
          route: `${MainMenuRouteEnum.AdminPanel}/building-department-notes`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.zones',
          route: `${MainMenuRouteEnum.AdminPanel}/zones`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.unavailable-days',
          route: `${MainMenuRouteEnum.AdminPanel}/unavailable-days`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.rebates',
          route: `${MainMenuRouteEnum.AdminPanel}/rebates`,
          show: true
        }
      ]
    }
  ]
});
