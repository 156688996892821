import { createAction, props, union } from '@ngrx/store';

import {
  BankAccountModel,
  BonusPricingModel,
  CreditCardModel,
  PaginationModel,
  PaginationQueryModel,
  PriceCategoryModel,
  PriceGroupModel,
  PriceListFormModel,
  PriceListModel,
  PriceServiceModel
} from '@shared/models';

const fetchPriceListsStartAction = createAction('[Billing] Fetch price lists');

const fetchPriceListsSuccessAction = createAction(
  '[Billing] Fetch price lists success',
  props<{ prices: PriceListModel[]; empty_price_list: PriceListModel }>()
);

const fetchPriceListsPaginationStartAction = createAction(
  '[Billing] Fetch price lists with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchPriceListsPaginationSuccessAction = createAction(
  '[Billing] Fetch price lists with pagination success',
  props<{ prices: PriceListModel[]; pagination: PaginationModel }>()
);

const createCompanyPriceListAction = createAction(
  '[Billing] Create company price list',
  props<{ id: number; priceList: PriceListFormModel }>()
);

const createCompanyPriceListSuccessAction = createAction(
  '[Billing] Create company price list success',
  props<{ prices: PriceListModel[] }>()
);

const updateCompanyPriceListAction = createAction(
  '[Billing] Update company price list',
  props<{ id: number; priceList: PriceListFormModel }>()
);

const updateCompanyPriceListSuccessAction = createAction(
  '[Billing] Update company price list success',
  props<{ prices: PriceListModel[] }>()
);

const assignCompanyPriceListAction = createAction(
  '[Billing] Assign company price list',
  props<{ id: number; priceListId: number }>()
);

const assignCompanyPriceListSuccessAction = createAction(
  '[Billing] Assign company price list success',
  props<{ prices: PriceListModel[] }>()
);

const fetchPriceListStartAction = createAction(
  '[Billing] Fetch price list',
  props<{ id: number }>()
);

const fetchPriceListSuccessAction = createAction(
  '[Billing] Fetch price list success',
  props<{ price: PriceListModel }>()
);

const fetchPriceListFailedAction = createAction('[Billing] Fetch price list failed');

const updatePriceListAction = createAction(
  '[Billing] Update price list',
  props<{ id: number; priceList: PriceListFormModel }>()
);

const createPriceListAction = createAction(
  '[Billing] Create price list',
  props<{ priceList: PriceListFormModel }>()
);

const createPriceListSuccessAction = createAction('[Billing] Create price list success');

const fetchPaymentTypesAction = createAction(
  '[Billing] Fetch payment types',
  props<{ id: number }>()
);

const fetchPaymentTypesSuccessAction = createAction(
  '[Billing] Fetch payment types success',
  props<{ bank_accounts: BankAccountModel[]; credit_cards: CreditCardModel[] }>()
);

const removeAchPaymentAction = createAction(
  '[Billing] Remove ach payment',
  props<{ id: number; account_id: string }>()
);

const removeAchPaymentSuccessAction = createAction(
  '[Billing] Remove ach payment success',
  props<{ bank_accounts: BankAccountModel[] }>()
);

const removeCreditCardAction = createAction(
  '[Billing] Remove credit card',
  props<{ id: number; credit_card_id: string }>()
);

const removeCreditCardSuccessAction = createAction(
  '[Billing] Remove credit card success',
  props<{ credit_cards: CreditCardModel[] }>()
);

const setDefaultCreditCardAction = createAction(
  '[Billing] Set default credit card',
  props<{ id: number; credit_card_id: string }>()
);

const setDefaultCreditCardSuccessAction = createAction(
  '[Billing] Set default credit card success',
  props<{ credit_cards: CreditCardModel[] }>()
);

const createCreditCardAction = createAction(
  '[Billing] Create credit card',
  props<{ id: number; creditCard: CreditCardModel }>()
);

const createCreditCardSuccessAction = createAction(
  '[Billing] Create credit card success',
  props<{ credit_cards: CreditCardModel[] }>()
);

const createAchPaymentAction = createAction(
  '[Billing] Create ach payment',
  props<{ id: number; achPayment: BankAccountModel }>()
);

const createAchPaymentSuccessAction = createAction(
  '[Billing] Create ach payment success',
  props<{ bank_accounts: BankAccountModel[] }>()
);

const updateAchPaymentAction = createAction(
  '[Billing] Update ach payment',
  props<{ id: number; achPayment: BankAccountModel }>()
);

const updateAchPaymentSuccessAction = createAction(
  '[Billing] Update ach payment success',
  props<{ bank_accounts: BankAccountModel[] }>()
);

const updateCreditCardAction = createAction(
  '[Billing] Update credit card',
  props<{ id: number; creditCard: CreditCardModel }>()
);

const updateCreditCardSuccessAction = createAction(
  '[Billing] Update credit card success',
  props<{ credit_cards: CreditCardModel[] }>()
);

const fetchEmptyPriceListAction = createAction('[Billing] Fetch empty price list');

const fetchEmptyPriceListSuccessAction = createAction(
  '[Billing] Fetch empty price list success',
  props<{ empty_price_list: PriceListModel }>()
);

const fetchPriceCategoriesStartAction = createAction('[Billing] Fetch price categories');

const fetchPriceCategoriesSuccessAction = createAction(
  '[Billing] Fetch price categories success',
  props<{ price_categories: PriceCategoryModel[] }>()
);

const fetchPriceGroupsPaginationStartAction = createAction(
  '[Billing] Fetch price groups with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchPriceGroupsPaginationSuccessAction = createAction(
  '[Billing] Fetch price groups with pagination success',
  props<{ price_groups: PriceGroupModel[]; pagination: PaginationModel }>()
);

const createPriceGroupAction = createAction(
  '[Billing] Create price group',
  props<{ priceGroup: PriceGroupModel }>()
);

const createPriceGroupSuccessAction = createAction('[Billing] Create price group success');

const fetchPriceGroupStartAction = createAction(
  '[Billing] Fetch price group',
  props<{ id: number }>()
);

const fetchPriceGroupSuccessAction = createAction(
  '[Billing] Fetch price group success',
  props<{ price_group: PriceGroupModel }>()
);

const fetchPriceGroupFailedAction = createAction('[Billing] Fetch price group failed');

const updatePriceGroupAction = createAction(
  '[Billing] Update price group',
  props<{ id: number; priceGroup: PriceGroupModel }>()
);

const updatePriceGroupSuccessAction = createAction('[Billing] Update price group success');

const fetchPriceGroupsStartAction = createAction('[Billing] Fetch price groups');

const fetchPriceGroupsSuccessAction = createAction(
  '[Billing] Fetch price groups success',
  props<{ price_groups: PriceGroupModel[] }>()
);

const fetchPriceServicesPaginationStartAction = createAction(
  '[Billing] Fetch price services with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchPriceServicesPaginationSuccessAction = createAction(
  '[Billing] Fetch price services with pagination success',
  props<{ price_services: PriceServiceModel[]; pagination: PaginationModel }>()
);

const createPriceServiceAction = createAction(
  '[Billing] Create price service',
  props<{ priceService: PriceServiceModel }>()
);

const createPriceServiceSuccessAction = createAction('[Billing] Create price service success');

const fetchPriceServiceStartAction = createAction(
  '[Billing] Fetch price service',
  props<{ id: number }>()
);

const fetchPriceServiceSuccessAction = createAction(
  '[Billing] Fetch price service success',
  props<{ price_service: PriceServiceModel }>()
);

const fetchPriceServiceFailedAction = createAction('[Billing] Fetch price service failed');

const updatePriceServiceAction = createAction(
  '[Billing] Update price service',
  props<{ id: number; priceService: PriceServiceModel }>()
);

const updatePriceServiceSuccessAction = createAction('[Billing] Update price service success');

const fetchBonusPricingStartAction = createAction('[Billing] Fetch bonus pricing');

const fetchBonusPricingSuccessAction = createAction(
  '[Billing] Fetch bonus pricing success',
  props<{ bonus_pricing: BonusPricingModel }>()
);

const fetchBonusPricingFailedAction = createAction('[Billing] Fetch bonus pricing failed');

const saveBonusPricingStartAction = createAction(
  '[Billing] Save bonus pricing',
  props<{ bonus_pricing: BonusPricingModel }>()
);

const saveBonusPricingSuccessAction = createAction(
  '[Billing] Save bonus pricing success',
  props<{ bonus_pricing: BonusPricingModel }>()
);

const saveBonusPricingFailedAction = createAction('[Billing] Save bonus pricing failed');

const updateBonusPricingStartAction = createAction(
  '[Billing] Update bonus pricing',
  props<{ bonus_pricing: BonusPricingModel }>()
);

const updateBonusPricingSuccessAction = createAction(
  '[Billing] Update bonus pricing success',
  props<{ bonus_pricing: BonusPricingModel }>()
);

const updateBonusPricingFailedAction = createAction('[Billing] Update bonus pricing failed');

export const BillingActions = {
  fetchPriceListsStartAction,
  fetchPriceListsSuccessAction,
  fetchPriceListsPaginationStartAction,
  fetchPriceListsPaginationSuccessAction,
  createCompanyPriceListAction,
  createCompanyPriceListSuccessAction,
  updateCompanyPriceListAction,
  updateCompanyPriceListSuccessAction,
  assignCompanyPriceListAction,
  assignCompanyPriceListSuccessAction,
  fetchPriceListStartAction,
  fetchPriceListSuccessAction,
  fetchPriceListFailedAction,
  updatePriceListAction,
  createPriceListAction,
  createPriceListSuccessAction,
  fetchPaymentTypesAction,
  fetchPaymentTypesSuccessAction,
  removeAchPaymentAction,
  removeAchPaymentSuccessAction,
  removeCreditCardAction,
  removeCreditCardSuccessAction,
  setDefaultCreditCardAction,
  setDefaultCreditCardSuccessAction,
  createCreditCardAction,
  createCreditCardSuccessAction,
  createAchPaymentAction,
  createAchPaymentSuccessAction,
  updateAchPaymentAction,
  updateAchPaymentSuccessAction,
  updateCreditCardAction,
  updateCreditCardSuccessAction,
  fetchEmptyPriceListAction,
  fetchEmptyPriceListSuccessAction,
  fetchPriceCategoriesStartAction,
  fetchPriceCategoriesSuccessAction,
  fetchPriceGroupsPaginationStartAction,
  fetchPriceGroupsPaginationSuccessAction,
  createPriceGroupAction,
  createPriceGroupSuccessAction,
  fetchPriceGroupStartAction,
  fetchPriceGroupSuccessAction,
  fetchPriceGroupFailedAction,
  updatePriceGroupAction,
  updatePriceGroupSuccessAction,
  fetchPriceGroupsStartAction,
  fetchPriceGroupsSuccessAction,
  fetchPriceServicesPaginationStartAction,
  fetchPriceServicesPaginationSuccessAction,
  createPriceServiceAction,
  createPriceServiceSuccessAction,
  fetchPriceServiceStartAction,
  fetchPriceServiceSuccessAction,
  fetchPriceServiceFailedAction,
  updatePriceServiceAction,
  updatePriceServiceSuccessAction,
  fetchBonusPricingStartAction,
  fetchBonusPricingSuccessAction,
  fetchBonusPricingFailedAction,
  saveBonusPricingStartAction,
  saveBonusPricingSuccessAction,
  saveBonusPricingFailedAction,
  updateBonusPricingStartAction,
  updateBonusPricingSuccessAction,
  updateBonusPricingFailedAction
};

const billingActionsUnion = union(BillingActions);

export type BillingActionsType = typeof billingActionsUnion;
