import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  ApplianceModel,
  AssessorElectrificationModel,
  CoolingModel,
  DuctModel,
  FoundationModel,
  GeneralModel,
  HeatingModel,
  HotWaterModel,
  MessageResponseModel,
  OutdoorFeaturesModel,
  PvSystemModel,
  RoofAtticModel,
  UpdateAppliancesFormModel,
  UpdateCoolingFormModel,
  UpdateDuctFormModel,
  UpdateFoundationFormModel,
  UpdateHeatingFormModel,
  UpdateRoofAtticFormModel,
  UpdateWallsFormModel,
  WallModel,
  WindowsModel
} from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class HomeScoreHttpService {
  constructor(private http: HttpClient) {}

  public getGeneral(id: number): Observable<GeneralModel> {
    return this.http.get<GeneralModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ScoresInspectionById,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createGeneral(create: GeneralModel): Observable<GeneralModel> {
    return this.http.post<GeneralModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateScoresInspection),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateGeneral(id: number, update: GeneralModel): Observable<GeneralModel> {
    return this.http.post<GeneralModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ScoresInspection,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getHotWater(id: number): Observable<HotWaterModel> {
    return this.http.get<HotWaterModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.HotWaterByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateHotWater(id: number, update: HotWaterModel): Observable<HotWaterModel> {
    return this.http.post<HotWaterModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.HotWater,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createHotWater(create: HotWaterModel): Observable<HotWaterModel> {
    return this.http.post<HotWaterModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateHotWater),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPvSystem(id: number): Observable<PvSystemModel> {
    return this.http.get<PvSystemModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.PvSystemByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updatePvSystem(id: number, update: PvSystemModel): Observable<PvSystemModel> {
    return this.http.post<PvSystemModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.PvSystem,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createPvSystem(create: PvSystemModel): Observable<PvSystemModel> {
    return this.http.post<PvSystemModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreatePvSystem),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getOutdoorFeatures(id: number): Observable<OutdoorFeaturesModel> {
    return this.http.get<OutdoorFeaturesModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.OutdoorFeaturesByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateOutdoorFeatures(
    id: number,
    update: OutdoorFeaturesModel
  ): Observable<OutdoorFeaturesModel> {
    return this.http.post<OutdoorFeaturesModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.OutdoorFeatures,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createOutdoorFeatures(create: OutdoorFeaturesModel): Observable<OutdoorFeaturesModel> {
    return this.http.post<OutdoorFeaturesModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateOutdoorFeatures),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getRoofAttic(id: number): Observable<{ insulation_zones: RoofAtticModel[] }> {
    return this.http.get<{ insulation_zones: RoofAtticModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RoofAtticByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateRoofAttic(
    roofAttic: UpdateRoofAtticFormModel
  ): Observable<{ insulation_zones: RoofAtticModel[] }> {
    return this.http.post<{ insulation_zones: RoofAtticModel[] }>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.RoofAttic),
      roofAttic,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeRoofAttic(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveRoofAttic,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getFoundation(id: number): Observable<{ foundations: FoundationModel[] }> {
    return this.http.get<{ foundations: FoundationModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.FoundationByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateFoundation(
    foundations: UpdateFoundationFormModel
  ): Observable<{ foundations: FoundationModel[] }> {
    return this.http.post<{ foundations: FoundationModel[] }>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.Foundations),
      foundations,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeFoundation(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveFoundation,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getWalls(id: number): Observable<{ walls: WallModel[] }> {
    return this.http.get<{ walls: WallModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.WallsByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateWalls(walls: UpdateWallsFormModel): Observable<{ walls: WallModel[] }> {
    return this.http.post<{ walls: WallModel[] }>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.Walls),
      walls,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getHeating(id: number): Observable<{ heatings: HeatingModel[] }> {
    return this.http.get<{ heatings: HeatingModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.HeatingsByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateHeating(heatings: UpdateHeatingFormModel): Observable<{ heatings: HeatingModel[] }> {
    return this.http.post<{ heatings: HeatingModel[] }>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.Heatings),
      heatings,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeHeating(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveHeating,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getCooling(id: number): Observable<{ coolings: CoolingModel[] }> {
    return this.http.get<{ coolings: CoolingModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CoolingByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateCooling(coolings: UpdateCoolingFormModel): Observable<{ coolings: CoolingModel[] }> {
    return this.http.post<{ coolings: CoolingModel[] }>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.Coolings),
      coolings,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeCooling(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveCooling,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getDuct(id: number): Observable<{ ducts: DuctModel[] }> {
    return this.http.get<{ ducts: DuctModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.DuctByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateDuct(ducts: UpdateDuctFormModel): Observable<{ ducts: DuctModel[] }> {
    return this.http.post<{ ducts: DuctModel[] }>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.Ducts),
      ducts,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeDuct(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveDuct,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getAppliances(id: number): Observable<{ appliances: ApplianceModel[] }> {
    return this.http.get<{ appliances: ApplianceModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AppliancesByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAppliances(
    appliances: UpdateAppliancesFormModel
  ): Observable<{ appliances: ApplianceModel[] }> {
    return this.http.post<{ appliances: ApplianceModel[] }>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.Appliances),
      appliances,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeAppliances(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveAppliances,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getWindows(id: number): Observable<WindowsModel> {
    return this.http.get<WindowsModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.WindowsByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateWindows(windows: WindowsModel): Observable<WindowsModel> {
    return this.http.post<WindowsModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.Windows),
      windows,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getAssessorElectrification(id: number): Observable<AssessorElectrificationModel> {
    return this.http.get<AssessorElectrificationModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AssessorElectrificationByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAssessorElectrification(
    id: number,
    update: AssessorElectrificationModel
  ): Observable<AssessorElectrificationModel> {
    return this.http.post<AssessorElectrificationModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AssessorElectrification,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAssessorElectrification(
    create: AssessorElectrificationModel
  ): Observable<AssessorElectrificationModel> {
    return this.http.post<AssessorElectrificationModel>(
      this.getEndpoint(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateAssessorElectrification
      ),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    inspectionId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (inspectionId) {
      path = path.replace(':inspectionId', inspectionId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
