import { createReducer, on } from '@ngrx/store';

import { LayoutActions, LayoutActionsType } from './layout.actions';
import { layoutInitialState, LayoutState } from './layout.state';

const reducer = createReducer(
  layoutInitialState,
  on(
    LayoutActions.setMainMenuAction,
    (state: LayoutState, { main_menu }): LayoutState => ({
      ...state,
      main_menu,
      showUserDropdown: false
    })
  ),
  on(
    LayoutActions.setSidebarMenuAction,
    (state: LayoutState, { sidebar }): LayoutState => ({
      ...state,
      sidebar,
      showUserDropdown: false
    })
  ),
  on(
    LayoutActions.toggleUserDropdownAction,
    (state: LayoutState): LayoutState => ({
      ...state,
      showUserDropdown: !state.showUserDropdown
    })
  ),
  on(
    LayoutActions.toggleSideMenuAction,
    (state: LayoutState): LayoutState => ({
      ...state,
      showSideMenu: !state.showSideMenu
    })
  ),
  on(
    LayoutActions.toggleSidebarAction,
    (state: LayoutState): LayoutState => ({
      ...state,
      showSidebar: !state.showSidebar
    })
  ),
  on(
    LayoutActions.setSelectedCompanyIdAction,
    (state: LayoutState, { id }): LayoutState => ({
      ...state,
      selectedCompanyId: id
    })
  ),
  on(
    LayoutActions.setCompanyDropdownListAction,
    (state: LayoutState, { companies }): LayoutState => ({
      ...state,
      companyList: companies
    })
  ),
  on(
    LayoutActions.setSelectedEmployeeIdAction,
    (state: LayoutState, { id }): LayoutState => ({
      ...state,
      selectedEmployeeId: id
    })
  ),
  on(
    LayoutActions.setEmployeeDropdownListAction,
    (state: LayoutState, { user_profiles }): LayoutState => ({
      ...state,
      employeeList: user_profiles
    })
  )
);

export function layoutReducer(state: LayoutState, action: LayoutActionsType): LayoutState {
  return reducer(state, action);
}
