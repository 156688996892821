import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import { InspectionAgreementModel } from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class InspectionAgreementHttpService {
  constructor(private http: HttpClient) {}

  public getInspectionAgreement(id: number): Observable<InspectionAgreementModel> {
    return this.http.get<InspectionAgreementModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AgreementsInspectionById,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateInspectionAgreement(
    id: number,
    update: InspectionAgreementModel
  ): Observable<InspectionAgreementModel> {
    return this.http.post<InspectionAgreementModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AgreementsInspection,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    inspectionId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (inspectionId) {
      path = path.replace(':inspectionId', inspectionId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
