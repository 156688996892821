import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SESSION_EXPIRATION_CONFIG } from '../constants';
import { SessionExpirationConfigModel } from '../models';
import { SessionExpirationService } from '../services/session-expiration.service';

@Injectable()
export class SessionExpirationInterceptor implements HttpInterceptor {
  constructor(
    private sessionExpirationService: SessionExpirationService,
    @Inject(SESSION_EXPIRATION_CONFIG)
    private config: SessionExpirationConfigModel
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.config.trackingUrls.some(url => req.url.includes(url))) {
      this.sessionExpirationService.refreshTimer();
    }
    return next.handle(req);
  }
}
