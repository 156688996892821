import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import Rflatten from 'ramda/es/flatten';
import Rpipe from 'ramda/es/pipe';
import Rvalues from 'ramda/es/values';

import { ErrorResponseModel } from '@shared/models';

export const objToHttpParams = (obj: object): HttpParams =>
  Object.entries(obj).reduce((params, [key, value]) => params.set(key, value), new HttpParams());

export const getHttpHeaders = (): HttpHeaders =>
  new HttpHeaders({
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

export const getFlattenErrors = ({ error }: HttpErrorResponse): ErrorResponseModel => ({
  message: error.message || error.error || 'field.validation.error.not-available',
  ...(error.errors ? { errors: Rpipe(Rvalues, Rflatten)(error.errors) } : {})
});

export const fileToFormData = (file: File): FormData => {
  const formData = new FormData();
  formData.append('file', file, file.name);

  return formData;
};
