import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

import { CommonDirectiveModule } from '@shared/directives';
import {
  FORMLY_CONTROLS_CONFIG,
  FORMLY_CONTROLS_INPUTS,
  FORMLY_CONTROLS_WRAPPERS
} from './formly-controls.config';

@NgModule({
  imports: [
    CommonDirectiveModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule.forChild(FORMLY_CONTROLS_CONFIG)
  ],
  declarations: [...FORMLY_CONTROLS_WRAPPERS, ...FORMLY_CONTROLS_INPUTS]
})
export class FormlyControlsModule {}
