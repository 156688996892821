import { createReducer, on } from '@ngrx/store';

import { HomeScoreActions, HomeScoreActionsType } from './home-score.actions';
import { homeScoreInitialState, HomeScoreState } from './home-score.state';

const reducer = createReducer(
  homeScoreInitialState,
  on(
    HomeScoreActions.fetchGeneralStartAction,
    HomeScoreActions.fetchHotWaterStartAction,
    HomeScoreActions.fetchPvSystemStartAction,
    HomeScoreActions.fetchOutdoorFeaturesStartAction,
    HomeScoreActions.fetchRoofAtticStartAction,
    HomeScoreActions.fetchFoundationStartAction,
    HomeScoreActions.fetchWallsStartAction,
    HomeScoreActions.fetchHeatingStartAction,
    HomeScoreActions.fetchCoolingStartAction,
    HomeScoreActions.fetchDuctStartAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    HomeScoreActions.fetchAppliancesStartAction,
    HomeScoreActions.fetchWindowsStartAction,
    HomeScoreActions.fetchAssessorElectrificationStartAction,
    HomeScoreActions.updateOutdoorFeaturesStartAction,
    HomeScoreActions.createOutdoorFeaturesStartAction,
    HomeScoreActions.createHotWaterStartAction,
    HomeScoreActions.updateHotWaterStartAction,
    HomeScoreActions.createPvSystemStartAction,
    HomeScoreActions.updatePvSystemStartAction,
    HomeScoreActions.createAssessorElectrificationStartAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    HomeScoreActions.fetchGeneralSuccessAction,
    HomeScoreActions.createGeneralSuccessAction,
    HomeScoreActions.updateGeneralSuccessAction,
    (state: HomeScoreState, { general }): HomeScoreState => ({
      ...state,
      general,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchHotWaterSuccessAction,
    HomeScoreActions.createHotWaterSuccessAction,
    HomeScoreActions.updateHotWaterSuccessAction,
    (state: HomeScoreState, { hotWater }): HomeScoreState => ({
      ...state,
      hotWater,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchPvSystemSuccessAction,
    HomeScoreActions.createPvSystemSuccessAction,
    HomeScoreActions.updatePvSystemSuccessAction,
    (state: HomeScoreState, { pvSystem }): HomeScoreState => ({
      ...state,
      pvSystem,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchOutdoorFeaturesSuccessAction,
    HomeScoreActions.updateOutdoorFeaturesSuccessAction,
    HomeScoreActions.createOutdoorFeaturesSuccessAction,
    (state: HomeScoreState, { outdoorFeatures }): HomeScoreState => ({
      ...state,
      outdoorFeatures,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchRoofAtticSuccessAction,
    HomeScoreActions.updateRoofAtticSuccessAction,
    (state: HomeScoreState, { insulation_zones }): HomeScoreState => ({
      ...state,
      insulation_zones,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchFoundationSuccessAction,
    HomeScoreActions.updateFoundationSuccessAction,
    (state: HomeScoreState, { foundations }): HomeScoreState => ({
      ...state,
      foundations,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchWallsSuccessAction,
    HomeScoreActions.updateWallsSuccessAction,
    (state: HomeScoreState, { walls }): HomeScoreState => ({
      ...state,
      walls,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchHeatingSuccessAction,
    HomeScoreActions.updateHeatingSuccessAction,
    (state: HomeScoreState, { heatings }): HomeScoreState => ({
      ...state,
      heatings,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchCoolingSuccessAction,
    HomeScoreActions.updateCoolingSuccessAction,
    (state: HomeScoreState, { coolings }): HomeScoreState => ({
      ...state,
      coolings,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchDuctSuccessAction,
    HomeScoreActions.updateDuctSuccessAction,
    (state: HomeScoreState, { ducts }): HomeScoreState => ({
      ...state,
      ducts,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchAppliancesSuccessAction,
    HomeScoreActions.updateAppliancesSuccessAction,
    (state: HomeScoreState, { appliances }): HomeScoreState => ({
      ...state,
      appliances,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchWindowsSuccessAction,
    HomeScoreActions.updateWindowsSuccessAction,
    (state: HomeScoreState, { windows }): HomeScoreState => ({
      ...state,
      windows,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchAssessorElectrificationSuccessAction,
    HomeScoreActions.updateAssessorElectrificationSuccessAction,
    HomeScoreActions.createAssessorElectrificationSuccessAction,
    (state: HomeScoreState, { assessorElectrification }): HomeScoreState => ({
      ...state,
      assessorElectrification,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchGeneralFailedAction,
    HomeScoreActions.fetchHotWaterFailedAction,
    HomeScoreActions.fetchPvSystemFailedAction,
    HomeScoreActions.fetchOutdoorFeaturesFailedAction,
    HomeScoreActions.fetchRoofAtticFailedAction,
    HomeScoreActions.fetchFoundationFailedAction,
    HomeScoreActions.fetchWallsFailedAction,
    HomeScoreActions.fetchHeatingFailedAction,
    HomeScoreActions.fetchCoolingFailedAction,
    HomeScoreActions.fetchDuctFailedAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.fetchAppliancesFailedAction,
    HomeScoreActions.fetchWindowsFailedAction,
    HomeScoreActions.fetchAssessorElectrificationFailedAction,
    HomeScoreActions.updateOutdoorFeaturesFailedAction,
    HomeScoreActions.createOutdoorFeaturesFailedAction,
    HomeScoreActions.createHotWaterFailedAction,
    HomeScoreActions.updateHotWaterFailedAction,
    HomeScoreActions.createPvSystemFailedAction,
    HomeScoreActions.updatePvSystemFailedAction,
    HomeScoreActions.createAssessorElectrificationFailedAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    HomeScoreActions.resetRoofAtticAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      insulation_zones: []
    })
  ),
  on(
    HomeScoreActions.resetGeneralAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      general: null
    })
  ),
  on(
    HomeScoreActions.resetFoundationAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      foundations: []
    })
  ),
  on(
    HomeScoreActions.resetWallsAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      walls: []
    })
  ),
  on(
    HomeScoreActions.resetWindowsAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      windows: null
    })
  ),
  on(
    HomeScoreActions.resetHeatingAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      heatings: []
    })
  ),
  on(
    HomeScoreActions.resetCoolingAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      coolings: []
    })
  ),
  on(
    HomeScoreActions.resetDuctAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      ducts: []
    })
  ),
  on(
    HomeScoreActions.resetHotWaterAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      hotWater: null
    })
  ),
  on(
    HomeScoreActions.resetPvSystemAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      pvSystem: null
    })
  ),
  on(
    HomeScoreActions.resetAppliancesAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      appliances: []
    })
  ),
  on(
    HomeScoreActions.resetOutdoorFeaturesAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      outdoorFeatures: null
    })
  ),
  on(
    HomeScoreActions.resetAssessorElectrificationAction,
    (state: HomeScoreState): HomeScoreState => ({
      ...state,
      assessorElectrification: null
    })
  )
);

export function homeScoreReducer(
  state: HomeScoreState,
  action: HomeScoreActionsType
): HomeScoreState {
  return reducer(state, action);
}
