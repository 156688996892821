import { MainMenuRouteEnum } from '@shared/enums';
import { SidebarCategoryModel } from '@shared/models';

export const getTimeClockMenu = (): SidebarCategoryModel => ({
  category: 'navigation.sidebar.category.time-clock',
  icon: 'icon-project-request.svg',
  group: [
    {
      name: 'navigation.sidebar.group.time-clock',
      icon: 'icon-general-info.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.employee-list',
          route: `${MainMenuRouteEnum.TimeClock}/employee-list`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.current-day',
          route: `${MainMenuRouteEnum.TimeClock}/current-day`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.history',
          route: `${MainMenuRouteEnum.TimeClock}/history`,
          show: true
        }
      ]
    }
  ]
});
