import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  ImportOldInspectionFormModel,
  MessageResponseModel,
  ProjectInspectionsListModel,
  ProjectInspectionStepsModel,
  ProjectsListModel
} from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ProjectsHttpService {
  constructor(private http: HttpClient) {}

  public getProjects(): Observable<ProjectsListModel> {
    return this.http.get<ProjectsListModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.ProjectsGet),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getProjectInspections(id: number): Observable<ProjectInspectionsListModel> {
    return this.http.get<ProjectInspectionsListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ProjectInspectionsGet,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getProjectInspectionSteps(id: number): Observable<ProjectInspectionStepsModel> {
    return this.http.get<ProjectInspectionStepsModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ProjectInspectionStepsGet,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public changeInspectionStatus(id: number, completed: boolean): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ProjectInspectionChangeStatus,
        id
      ),
      completed,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public changeNoShowStatus(id: number): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.NoShowChangeStatus,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public importOldInspection(
    id: number,
    update: ImportOldInspectionFormModel
  ): Observable<ProjectInspectionStepsModel> {
    return this.http.post<ProjectInspectionStepsModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ImportOldInspection,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number
  ): string {
    return `${baseUrl}/${resource.replace(':id', id.toString())}`;
  }
}
