import { createAction, props, union } from '@ngrx/store';

import { CazTestingModel, InfiltrationResultsModel } from '@shared/models';

const fetchCazTestingStartAction = createAction(
  '[BpiDataCollection] Fetch caz testing',
  props<{ id: number }>()
);

const fetchCazTestingSuccessAction = createAction(
  '[BpiDataCollection] Fetch caz testing success',
  props<{ cazTesting: CazTestingModel }>()
);

const fetchCazTestingFailedAction = createAction('[BpiDataCollection] Fetch caz testing failed');

const resetCazTestingAction = createAction('[BpiDataCollection] Reset caz testing');

const updateCazTestingAction = createAction(
  '[BpiDataCollection] Update caz testing',
  props<{ update: CazTestingModel }>()
);

const updateCazTestingSuccessAction = createAction(
  '[BpiDataCollection] Update caz testing success',
  props<{ cazTesting: CazTestingModel }>()
);

const removeCazZoneAction = createAction(
  '[BpiDataCollection] Remove caz zone',
  props<{ id: number }>()
);

const removeCazZoneSuccessAction = createAction('[BpiDataCollection] Remove caz zone success');

const fetchInfiltrationResultsStartAction = createAction(
  '[BpiDataCollection] Fetch infiltration results',
  props<{ id: number }>()
);

const fetchInfiltrationResultsSuccessAction = createAction(
  '[BpiDataCollection] Fetch infiltration results success',
  props<{ infiltrationResults: InfiltrationResultsModel }>()
);

const fetchInfiltrationResultsFailedAction = createAction(
  '[BpiDataCollection] Fetch infiltration results failed'
);

const updateInfiltrationResultsAction = createAction(
  '[BpiDataCollection] Update infiltration results',
  props<{ id: number; update: InfiltrationResultsModel }>()
);

const updateInfiltrationResultsSuccessAction = createAction(
  '[BpiDataCollection] Update infiltration results success',
  props<{ infiltrationResults: InfiltrationResultsModel }>()
);

const createInfiltrationResultsStartAction = createAction(
  '[BpiDataCollection] Create infiltration results',
  props<{ create: InfiltrationResultsModel }>()
);

const createInfiltrationResultsSuccessAction = createAction(
  '[BpiDataCollection] Create infiltration results success',
  props<{ infiltrationResults: InfiltrationResultsModel }>()
);

const createInfiltrationResultsFailedAction = createAction(
  '[BpiDataCollection] Create infiltration results failed'
);

const resetInfiltrationResultsAction = createAction(
  '[BpiDataCollection] Reset infiltration results'
);

export const BpiDataCollectionActions = {
  fetchCazTestingStartAction,
  fetchCazTestingSuccessAction,
  fetchCazTestingFailedAction,
  resetCazTestingAction,
  updateCazTestingAction,
  updateCazTestingSuccessAction,
  removeCazZoneAction,
  removeCazZoneSuccessAction,
  fetchInfiltrationResultsStartAction,
  fetchInfiltrationResultsSuccessAction,
  fetchInfiltrationResultsFailedAction,
  updateInfiltrationResultsAction,
  updateInfiltrationResultsSuccessAction,
  createInfiltrationResultsStartAction,
  createInfiltrationResultsSuccessAction,
  createInfiltrationResultsFailedAction,
  resetInfiltrationResultsAction
};

const bpiDataCollectionActionsUnion = union(BpiDataCollectionActions);

export type BpiDataCollectionActionsType = typeof bpiDataCollectionActionsUnion;
