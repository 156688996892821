import Rreject from 'ramda/es/reject';
import RwhereEq from 'ramda/es/whereEq';

import { LineSizeDecimalsEnum, LineSizeFractionsEnum } from '@shared/enums';
import { SystemsModel } from '@shared/models';

/**
 * D20 formula:
 *
 * Floors = "Number Of Floors"(no_of_floors)
 *
 * If 1x Floors = 21, 5
 * If 1.5x Floors = 21, 5 * 0.89
 * If 2x Floors = 21, 5 * 0.81
 * If 2.5x Floors = 21, 5 * 0.76
 * If 3x Floors = 21, 5 * 0.72
 */
export const d20Formula = (noOfFloors: number = 0): number => {
  let d20;
  if (noOfFloors === 1) {
    d20 = 21.5;
  } else if (noOfFloors === 1.5) {
    d20 = 21.5 * 0.89;
  } else if (noOfFloors === 2) {
    d20 = 21.5 * 0.81;
  } else if (noOfFloors === 2.5) {
    d20 = 21.5 * 0.76;
  } else {
    d20 = 21.5 * 0.72;
  }
  return d20;
};

/**
 * 30% Reduction Goal:
 *
 * Formula: 4.9*C16/3.819)*0.7
 * (4.9 * ”Conditioned Floor Area” / 3.819) * 0.7
 */
export const reductionGoalFormula = (floorArea: number): string =>
  (4.9 * Number(floorArea / 3.819) * 0.7).toFixed(2);

/**
 * Final SLA:
 *
 * Formula: D25*3.819/C16
 * “Final Blower Door CFM50” * 3.819 / “Conditioned Floor Area”
 */
export const finalSlaFormula = (blowerDoorCfm50: number, floorArea: number): string =>
  (Number(blowerDoorCfm50 * 3.819) / Number(floorArea)).toFixed(2);

/**
 * % Reduction from Default SLA:
 *
 * Formula: (E23-D25)/E23)
 * ((4.9 * ”Conditioned Floor Area” /3.819) - ”Final Blower Door CFM50”) /
 * (4.9* ”Conditioned Floor Area” / 3.819)
 */
export const reductionDefaultSlaFormula = (floorArea: number, blowerDoorCfm50: number): string =>
  (
    (4.9 * Number(floorArea)) / 3.819 -
    Number(blowerDoorCfm50) / ((4.9 * Number(floorArea)) / 3.819)
  ).toFixed(2);

/**
 * Final ACHn:
 *
 * Formula: (D25*60)/(C16*C17)*D20
 * “Final Blower Door CFM50” * 60 / (“Conditioned Floor Area” * “Ceiling Height”) * D20
 */
export const finalAchnFormula = (
  blowerDoorCfm50: number,
  floorArea: number,
  ceilingHeight: number,
  noOfFloors: number
): string =>
  (
    (Number(blowerDoorCfm50) * 60) /
    (Number(floorArea) * Number(ceilingHeight) * d20Formula(Number(noOfFloors)))
  ).toFixed(2);

export const getSystemsWithoutRemoved = (id: number, systems: SystemsModel[]): SystemsModel[] =>
  Rreject(RwhereEq({ id }), systems);

export const btusCoolingFormula = (coolingTons: number = 0): number => coolingTons * 12000;

export const cfmCoolingFormula = (coolingTons: number = 0): number => coolingTons * 400;

export const cfmHeatingFormula = (outputBtu: number = 0): string =>
  ((Number(outputBtu) * 21.7) / 1000).toFixed(2);

export const linesetDiameterDecimalValue = (linesetDiameter: string): string => {
  let value = null;
  switch (linesetDiameter) {
    case LineSizeFractionsEnum.Half:
      value = LineSizeDecimalsEnum.Half;
      break;
    case LineSizeFractionsEnum.FiveEighths:
      value = LineSizeDecimalsEnum.FiveEighths;
      break;
    case LineSizeFractionsEnum.ThreeQuarters:
      value = LineSizeDecimalsEnum.ThreeQuarters;
      break;
    case LineSizeFractionsEnum.SevenEighths:
      value = LineSizeDecimalsEnum.SevenEighths;
      break;
    case LineSizeFractionsEnum.OneAndOneEighths:
      value = LineSizeDecimalsEnum.OneAndOneEighths;
      break;
  }
  return value;
};

export const linesetDiameterFractionValue = (linesetDiameter: string): string => {
  let value = null;
  switch (linesetDiameter) {
    case LineSizeDecimalsEnum.Half:
      value = LineSizeFractionsEnum.Half;
      break;
    case LineSizeDecimalsEnum.FiveEighths:
      value = LineSizeFractionsEnum.FiveEighths;
      break;
    case LineSizeDecimalsEnum.ThreeQuarters:
      value = LineSizeFractionsEnum.ThreeQuarters;
      break;
    case LineSizeDecimalsEnum.SevenEighths:
      value = LineSizeFractionsEnum.SevenEighths;
      break;
    case LineSizeDecimalsEnum.OneAndOneEighths:
      value = LineSizeFractionsEnum.OneAndOneEighths;
      break;
  }
  return value;
};
