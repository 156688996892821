import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { EquipmentInfoModel, SystemsModel, T24DataCollectionModel } from '@shared/models';

import { T24_DATA_COLLECTION_FEATURE, T24DataCollectionState } from './t24-data-collection.state';

export const getT24DataCollectionState = createFeatureSelector<T24DataCollectionState>(
  T24_DATA_COLLECTION_FEATURE
);

export const getIsLoadingState: MemoizedSelector<object, boolean> = createSelector(
  getT24DataCollectionState,
  ({ isLoading }: T24DataCollectionState): boolean => isLoading
);

export const getSystemsListState: MemoizedSelector<object, SystemsModel[]> = createSelector(
  getT24DataCollectionState,
  ({ systems }: T24DataCollectionState): SystemsModel[] => systems
);

export const getT24DataState: MemoizedSelector<
  object,
  T24DataCollectionModel | EquipmentInfoModel
> = createSelector(
  getT24DataCollectionState,
  ({ t24Data }: T24DataCollectionState): T24DataCollectionModel | EquipmentInfoModel => t24Data
);
