import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, Observer } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private confirmationService: ConfirmationService) {}

  public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ? true : this.showConfirmationDialog();
  }

  protected showConfirmationDialog(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        message:
          'If you leave this step before clicking the submit button, all entered information in this step will be deleted.',
        acceptLabel: 'OK',
        rejectLabel: 'Cancel',
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
}
