import { createReducer, on } from '@ngrx/store';

import { ProjectRequestActions, ProjectRequestActionsType } from './project-request.actions';
import { projectRequestInitialState, ProjectRequestState } from './project-request.state';

const reducer = createReducer(
  projectRequestInitialState,
  on(
    ProjectRequestActions.fetchCommSchedulePaginationSuccessAction,
    (state: ProjectRequestState, { comm_schedules, pagination }): ProjectRequestState => ({
      ...state,
      comm_schedules,
      pagination
    })
  ),
  on(
    ProjectRequestActions.searchByNameSuccessAction,
    ProjectRequestActions.createHistoryLogSuccessAction,
    (state: ProjectRequestState, { comm_schedules }): ProjectRequestState => ({
      ...state,
      comm_schedules
    })
  ),
  on(
    ProjectRequestActions.getPdfSuccessAction,
    (state: ProjectRequestState, { pdf }): ProjectRequestState => ({
      ...state,
      pdf
    })
  )
);

export function projectRequestReducer(
  state: ProjectRequestState,
  action: ProjectRequestActionsType
): ProjectRequestState {
  return reducer(state, action);
}
