import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { environment } from '@environments/environment';
import { SessionStorageEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class LaravelEchoService {
  public service: Echo;

  constructor() {
    try {
      const pusher = new Pusher(environment.PUSHER_APP_KEY, {
        wsHost: environment.PUSHER_HOST,
        wsPort: environment.PUSHER_PORT,
        httpHost: environment.PUSHER_HOST,
        forceTLS: environment.PUSHER_FORCE_TLS,
        authEndpoint: environment.PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(SessionStorageEnum.AccessToken)}`,
            Accept: 'application/json'
          }
        }
      });
      this.service = new Echo({
        broadcaster: 'pusher',
        client: pusher,
      });
    } catch (e) {
      console.log(e);
    }
  }
}
