import { createReducer, on } from '@ngrx/store';

import { CompanyActions, CompanyActionsType } from './company.actions';
import { companyInitialState, CompanyState } from './company.state';

const reducer = createReducer(
  companyInitialState,
  on(
    CompanyActions.fetchCompaniesPaginationSuccessAction,
    (state: CompanyState, { companies, pagination }): CompanyState => ({
      ...state,
      companies,
      pagination
    })
  ),
  on(
    CompanyActions.changeCompanyStatusSuccessAction,
    CompanyActions.searchByNameSuccessAction,
    CompanyActions.fetchCompanyDropdownListSuccessAction,
    CompanyActions.fetchCompanyBranchesDropdownListSuccessAction,
    (state: CompanyState, { companies }): CompanyState => ({
      ...state,
      companies
    })
  ),
  on(
    CompanyActions.fetchCompanyStartAction,
    (state: CompanyState): CompanyState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    CompanyActions.fetchCompanySuccessAction,
    (state: CompanyState, { company }): CompanyState => ({
      ...state,
      company,
      isLoading: false
    })
  ),
  on(
    CompanyActions.activateCompanySuccessAction,
    (state: CompanyState, { status }): CompanyState => ({
      ...state,
      company: {
        ...state.company,
        status
      }
    })
  ),
  on(
    CompanyActions.uploadCompanyLogoSuccessAction,
    (state: CompanyState, { logo }): CompanyState => ({
      ...state,
      company: {
        ...state.company,
        logo
      }
    })
  ),
  on(
    CompanyActions.uploadCompanyDocumentSuccessAction,
    (state: CompanyState, { document }): CompanyState => ({
      ...state,
      company: {
        ...state.company,
        documents: [...state.company.documents, document]
      }
    })
  ),
  on(
    CompanyActions.removeCompanyDocumentSuccessAction,
    (state: CompanyState, { documents }): CompanyState => ({
      ...state,
      company: {
        ...state.company,
        documents
      }
    })
  ),
  on(
    CompanyActions.fetchCompanyFailedAction,
    (state: CompanyState): CompanyState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    CompanyActions.changeBranchStatusSuccessAction,
    CompanyActions.deleteBranchSuccessAction,
    (state: CompanyState, { branches }): CompanyState => ({
      ...state,
      company: {
        ...state.company,
        branches
      }
    })
  ),
  on(
    CompanyActions.updateBranchStatusSuccessAction,
    (state: CompanyState, { status }): CompanyState => ({
      ...state,
      company: {
        ...state.company,
        status
      }
    })
  ),
  on(
    CompanyActions.updateCompanyPriceListAction,
    (state: CompanyState, { price_list }): CompanyState => ({
      ...state,
      company: {
        ...state.company,
        price_list
      }
    })
  )
);

export function companyReducer(state: CompanyState, action: CompanyActionsType): CompanyState {
  return reducer(state, action);
}
