import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SelectDropDownModule } from 'ngx-select-dropdown';

import { LoaderModule } from '@shared/modules/+loader';
import {
  CommonContainerComponent,
  CommonHeaderComponent,
  CommonRightSidebarComponent,
  CommonSectionHeaderComponent,
  CommonSidebarComponent
} from './components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoaderModule,
    RouterModule,
    SelectDropDownModule,
    TranslateModule
  ],
  declarations: [
    CommonContainerComponent,
    CommonHeaderComponent,
    CommonRightSidebarComponent,
    CommonSectionHeaderComponent,
    CommonSidebarComponent
  ]
})
export class CommonLayoutModule {}
