import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Observable, of } from 'rxjs';
import { combineLatest, filter, switchMap, withLatestFrom } from 'rxjs/operators';

import { StatusTypeEnum } from '@shared/enums';
import {
  AuthState,
  getIsLoadingState,
  getUserProfileState, getUserValidationsState
} from '@store/auth';

@Injectable({
  providedIn: 'root'
})
export class ActiveUserGuard implements CanActivate {
  constructor(private store: Store<AuthState>, private router: Router) {}

  public canActivate(): Observable<boolean> {
    return interval(500).pipe(
      withLatestFrom(this.store.select(getIsLoadingState)),
      filter(([, isLoading]) => !isLoading),
      combineLatest(
        this.store.select(getUserProfileState),
        this.store.select(getUserValidationsState)
      ),
      switchMap(([, userProfile, userValidations]) => {
        let canAccess = false;
        if (
          userProfile.status === StatusTypeEnum.Active &&
          userValidations.company_info &&
          userValidations.accounts &&
          userValidations.company_employees &&
          userValidations.general_contacts &&
          userValidations.payment_info
        ) {
          canAccess = true;
        }

        if (!canAccess) {
          const backRoute = '/settings/contractor/company-employees/view/' + userProfile.id;
          this.router.navigate([backRoute]);
        }

        return of(canAccess);
      })
    );
  }
}
