import { EmailSettingsEnum, EmailSettingsSectionEnum } from '@shared/enums';
import { EmailSettingsMapModel } from '@shared/models';

export const EmailSettingsMap: { [key in EmailSettingsSectionEnum]: EmailSettingsMapModel } = {
  [EmailSettingsSectionEnum.Payment]: {
    title: 'Payment notifications',
    sub_items: [{
      title: 'Payment options',
      options: [{
        title: 'Project Invoiced',
        name: EmailSettingsEnum.ProjectInvoiced,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }]
    }]
  },
  [EmailSettingsSectionEnum.Schedule]: {
    title: 'Schedule notifications',
    sub_items: [{
      title: 'Scheduling options',
      options: [{
        title: 'Notify me when a project has been scheduled for inspection service',
        name: EmailSettingsEnum.InspectionScheduled,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a inspection has been canceled',
        name: EmailSettingsEnum.InspectionCancelled,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a project has been scheduled for permit service',
        name: EmailSettingsEnum.BuildingInspectionScheduled,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }]
    }]
  },
  [EmailSettingsSectionEnum.Inspection]: {
    title: 'Inspection notifications',
    sub_items: [{
      title: 'Inspection options',
      options: [{
        title: 'Notify me when a project has failed on inspection service',
        name: EmailSettingsEnum.InspectionCompletedFailed,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a project has no show on inspection service',
        name: EmailSettingsEnum.InspectionNoShow,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }]
    }]
  },
  [EmailSettingsSectionEnum.Rebate]: {
    title: 'Rebate notifications',
    sub_items: [{
      title: 'Rebate options',
      options: [{
        title: 'Notify me when a project has been additional information needed on rebate service',
        name: EmailSettingsEnum.RebateStatusAdditionalInformationNeeded,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }]
    }]
  },
  [EmailSettingsSectionEnum.Project]: {
    title: 'Project notifications',
    sub_items: [{
      title: 'General project options',
      options: [{
        title: 'Notify me when a project has been approved',
        name: EmailSettingsEnum.ProjectApproved,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a project has been canceled',
        name: EmailSettingsEnum.ProjectCanceled,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a project has been reopened',
        name: EmailSettingsEnum.ProjectRestore,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a project has been requested to cancel',
        name: EmailSettingsEnum.ClientCancelledProjectMail,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }]
    }]
  },
  [EmailSettingsSectionEnum.Permit]: {
    title: 'Permit notifications',
    sub_items: [{
      title: 'Permit options',
      options: [{
        title: 'Notify me when a permit service has been abandoned',
        name: EmailSettingsEnum.PermitHasBeenAbandoned,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a permit service has been issued',
        name: EmailSettingsEnum.PermitIssued,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a certificate status has been completed',
        name: EmailSettingsEnum.CertificateStatusCompleted,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }]
    }, {
      title: 'Permit closure options',
      options: [{
        title: 'Notify me when a permit closure closure service has been abandoned',
        name: EmailSettingsEnum.PermitClosureAbandoned,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }, {
        title: 'Notify me when a permit closure closure service building inspection failed',
        name: EmailSettingsEnum.PermitClosureBuildingInspectionFailed,
        description: null,
        checked: false,
        id: 0,
        date: ''
      }]
    }]
  },
  [EmailSettingsSectionEnum.Expiration]: null
}
