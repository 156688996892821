import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { MessageActionEnum } from '@shared/enums';
import { HomeScoreHttpService } from '@shared/services';
import { HomeScoreActions } from '@store/home-score/home-score.actions';
import { MessageActions } from '@store/message/message.actions';

@Injectable()
export class HomeScoreEffects {
  /**
   * @route technicians-app/general/:id
   * @listen [HomeScore] Fetch general
   * @dispatch [HomeScore] Fetch general success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch general failed
   * @dispatch [Message] Handle error
   */
  public fetchGeneral$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchGeneralStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getGeneral(id).pipe(
          mergeMap(general => [
            HomeScoreActions.fetchGeneralSuccessAction({
              general
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchGeneralFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/general/:id
   * @listen [HomeScore] Create general
   * @dispatch [HomeScore] Create general success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createGeneral$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.createGeneralAction),
      switchMap(({ create }) =>
        this.homeScoreHttpService.createGeneral(create).pipe(
          mergeMap(general => [
            HomeScoreActions.createGeneralSuccessAction({
              general
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/general/:id
   * @listen [HomeScore] Update general
   * @dispatch [HomeScore] Update general success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateGeneral$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateGeneralAction),
      switchMap(({ id, update }) =>
        this.homeScoreHttpService.updateGeneral(id, update).pipe(
          mergeMap(general => [
            HomeScoreActions.updateGeneralSuccessAction({
              general
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/roof-attic/:id
   * @listen [HomeScore] Fetch roof / attic
   * @dispatch [HomeScore] Fetch roof / attic success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch roof / attic failed
   * @dispatch [Message] Handle error
   */
  public fetchRoofAttic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchRoofAtticStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getRoofAttic(id).pipe(
          mergeMap(({ insulation_zones }) => [
            HomeScoreActions.fetchRoofAtticSuccessAction({ insulation_zones }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchRoofAtticFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/roof-attic/:id
   * @listen [HomeScore] Update roof attic
   * @dispatch [HomeScore] Update roof attic success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateRoofAttic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateRoofAtticAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateRoofAttic(update).pipe(
          mergeMap(({ insulation_zones }) => [
            HomeScoreActions.updateRoofAtticSuccessAction({ insulation_zones }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/roof-attic/:id
   * @listen [HomeScore] Remove roof attic
   * @dispatch [HomeScore] Remove roof attic success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeRoofAttic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.removeRoofAtticAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.removeRoofAttic(id).pipe(
          mergeMap(() => [
            HomeScoreActions.removeRoofAtticSuccessAction(),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/foundation/:id
   * @listen [HomeScore] Fetch foundation
   * @dispatch [HomeScore] Fetch foundation success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch foundation failed
   * @dispatch [Message] Handle error
   */
  public fetchFoundation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchFoundationStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getFoundation(id).pipe(
          mergeMap(({ foundations }) => [
            HomeScoreActions.fetchFoundationSuccessAction({ foundations }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchFoundationFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/foundation/:id
   * @listen [HomeScore] Update foundation
   * @dispatch [HomeScore] Update foundation success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateFoundation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateFoundationAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateFoundation(update).pipe(
          mergeMap(({ foundations }) => [
            HomeScoreActions.updateFoundationSuccessAction({ foundations }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/foundation/:id
   * @listen [HomeScore] Remove foundation
   * @dispatch [HomeScore] Remove foundation success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeFoundation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.removeFoundationAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.removeFoundation(id).pipe(
          mergeMap(() => [
            HomeScoreActions.removeFoundationSuccessAction(),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/walls/:id
   * @listen [HomeScore] Fetch walls
   * @dispatch [HomeScore] Fetch walls success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch walls failed
   * @dispatch [Message] Handle error
   */
  public fetchWalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchWallsStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getWalls(id).pipe(
          mergeMap(({ walls }) => [
            HomeScoreActions.fetchWallsSuccessAction({ walls }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchWallsFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/walls/:id
   * @listen [HomeScore] Update walls
   * @dispatch [HomeScore] Update walls success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateWalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateWallsAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateWalls(update).pipe(
          mergeMap(({ walls }) => [
            HomeScoreActions.updateWallsSuccessAction({ walls }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/windows/:id
   * @listen [HomeScore] Fetch windows
   * @dispatch [HomeScore] Fetch windows success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch windows failed
   * @dispatch [Message] Handle error
   */
  public fetchWindows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchWindowsStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getWindows(id).pipe(
          mergeMap(windows => [
            HomeScoreActions.fetchWindowsSuccessAction({
              windows
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchWindowsFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/windows/:id
   * @listen [HomeScore] Update windows
   * @dispatch [HomeScore] Update windows success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateWindows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateWindowsAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateWindows(update).pipe(
          mergeMap(windows => [
            HomeScoreActions.updateWindowsSuccessAction({ windows }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/heating/:id
   * @listen [HomeScore] Fetch heating
   * @dispatch [HomeScore] Fetch heating success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch heating failed
   * @dispatch [Message] Handle error
   */
  public fetchHeating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchHeatingStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getHeating(id).pipe(
          mergeMap(({ heatings }) => [
            HomeScoreActions.fetchHeatingSuccessAction({ heatings }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchHeatingFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/heating/:id
   * @listen [HomeScore] Update heating
   * @dispatch [HomeScore] Update heating success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateHeating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateHeatingAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateHeating(update).pipe(
          mergeMap(({ heatings }) => [
            HomeScoreActions.updateHeatingSuccessAction({ heatings }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/heating/:id
   * @listen [HomeScore] Remove heating
   * @dispatch [HomeScore] Remove heating success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeHeating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.removeHeatingAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.removeHeating(id).pipe(
          mergeMap(() => [
            HomeScoreActions.removeHeatingSuccessAction(),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/cooling/:id
   * @listen [HomeScore] Fetch cooling
   * @dispatch [HomeScore] Fetch cooling success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch cooling failed
   * @dispatch [Message] Handle error
   */
  public fetchCooling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchCoolingStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getCooling(id).pipe(
          mergeMap(({ coolings }) => [
            HomeScoreActions.fetchCoolingSuccessAction({ coolings }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchCoolingFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/cooling/:id
   * @listen [HomeScore] Update cooling
   * @dispatch [HomeScore] Update cooling success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateCooling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateCoolingAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateCooling(update).pipe(
          mergeMap(({ coolings }) => [
            HomeScoreActions.updateCoolingSuccessAction({ coolings }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/cooling/:id
   * @listen [HomeScore] Remove cooling
   * @dispatch [HomeScore] Remove cooling success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeCooling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.removeCoolingAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.removeCooling(id).pipe(
          mergeMap(() => [
            HomeScoreActions.removeCoolingSuccessAction(),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/duct/:id
   * @listen [HomeScore] Fetch duct
   * @dispatch [HomeScore] Fetch duct success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch duct failed
   * @dispatch [Message] Handle error
   */
  public fetchDuct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchDuctStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getDuct(id).pipe(
          mergeMap(({ ducts }) => [
            HomeScoreActions.fetchDuctSuccessAction({ ducts }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchDuctFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/duct/:id
   * @listen [HomeScore] Update duct
   * @dispatch [HomeScore] Update duct success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateDuct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateDuctAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateDuct(update).pipe(
          mergeMap(({ ducts }) => [
            HomeScoreActions.updateDuctSuccessAction({ ducts }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/duct/:id
   * @listen [HomeScore] Remove duct
   * @dispatch [HomeScore] Remove duct success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeDuct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.removeDuctAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.removeDuct(id).pipe(
          mergeMap(() => [
            HomeScoreActions.removeDuctSuccessAction(),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/home-score/hot-water/:id
   * @listen [HomeScore] Fetch hot water
   * @dispatch [HomeScore] Fetch hot water success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch hot water failed
   * @dispatch [Message] Handle error
   */
  public fetchHotWater$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchHotWaterStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getHotWater(id).pipe(
          mergeMap(hotWater => [
            HomeScoreActions.fetchHotWaterSuccessAction({
              hotWater
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchHotWaterFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/home-score/hot-water/:id
   * @listen [HomeScore] Update hot water
   * @dispatch [HomeScore] Update hot water success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateHotWater$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateHotWaterStartAction),
      switchMap(({ id, update }) =>
        this.homeScoreHttpService.updateHotWater(id, update).pipe(
          mergeMap(hotWater => [
            HomeScoreActions.updateHotWaterSuccessAction({
              hotWater
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              HomeScoreActions.updateHotWaterFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/home-score/hot-water/:id
   * @listen [HomeScore] Create hot water
   * @dispatch [HomeScore] Create hot water success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createHotWater$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.createHotWaterStartAction),
      switchMap(({ create }) =>
        this.homeScoreHttpService.createHotWater(create).pipe(
          mergeMap(hotWater => [
            HomeScoreActions.createHotWaterSuccessAction({
              hotWater
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Create })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              HomeScoreActions.createHotWaterFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/pv-system/:id
   * @listen [HomeScore] Fetch pv system
   * @dispatch [HomeScore] Fetch pv system success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch pv system failed
   * @dispatch [Message] Handle error
   */
  public fetchPvSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchPvSystemStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getPvSystem(id).pipe(
          mergeMap(pvSystem => [
            HomeScoreActions.fetchPvSystemSuccessAction({
              pvSystem
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchPvSystemFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/pv-system/:id
   * @listen [HomeScore] Update pv system
   * @dispatch [HomeScore] Update pv system success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updatePvSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updatePvSystemStartAction),
      switchMap(({ id, update }) =>
        this.homeScoreHttpService.updatePvSystem(id, update).pipe(
          mergeMap(pvSystem => [
            HomeScoreActions.updatePvSystemSuccessAction({
              pvSystem
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              HomeScoreActions.updatePvSystemFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/pv-system/:id
   * @listen [HomeScore] Create pv system
   * @dispatch [HomeScore] Create pv system success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createPvSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.createPvSystemStartAction),
      switchMap(({ create }) =>
        this.homeScoreHttpService.createPvSystem(create).pipe(
          mergeMap(pvSystem => [
            HomeScoreActions.createPvSystemSuccessAction({
              pvSystem
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Create })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              HomeScoreActions.createPvSystemFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/appliances/:id
   * @listen [HomeScore] Fetch appliances
   * @dispatch [HomeScore] Fetch appliances success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch appliances failed
   * @dispatch [Message] Handle error
   */
  public fetchAppliances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchAppliancesStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getAppliances(id).pipe(
          mergeMap(({ appliances }) => [
            HomeScoreActions.fetchAppliancesSuccessAction({ appliances }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchAppliancesFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/appliances/:id
   * @listen [HomeScore] Update appliances
   * @dispatch [HomeScore] Update appliances success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateAppliances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateAppliancesAction),
      switchMap(({ update }) =>
        this.homeScoreHttpService.updateAppliances(update).pipe(
          mergeMap(({ appliances }) => [
            HomeScoreActions.updateAppliancesSuccessAction({ appliances }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/appliances/:id
   * @listen [HomeScore] Remove refrigerator
   * @dispatch [HomeScore] Remove refrigerator success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public removeAppliances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.removeAppliancesAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.removeAppliances(id).pipe(
          mergeMap(() => [
            HomeScoreActions.removeAppliancesSuccessAction(),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Delete })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/outdoor-features/:id
   * @listen [HomeScore] Fetch outdoor features
   * @dispatch [HomeScore] Fetch outdoor features success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch outdoor features failed
   * @dispatch [Message] Handle error
   */
  public fetchOutdoorFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchOutdoorFeaturesStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getOutdoorFeatures(id).pipe(
          mergeMap(outdoorFeatures => [
            HomeScoreActions.fetchOutdoorFeaturesSuccessAction({
              outdoorFeatures
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchOutdoorFeaturesFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/outdoor-features/:id
   * @listen [HomeScore] Update outdoor features
   * @dispatch [HomeScore] Update outdoor features success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public updateOutdoorFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateOutdoorFeaturesStartAction),
      switchMap(({ id, update }) =>
        this.homeScoreHttpService.updateOutdoorFeatures(id, update).pipe(
          mergeMap(outdoorFeatures => [
            HomeScoreActions.updateOutdoorFeaturesSuccessAction({
              outdoorFeatures
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              HomeScoreActions.updateOutdoorFeaturesFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/outdoor-features/:id
   * @listen [HomeScore] Create outdoor features
   * @dispatch [HomeScore] Create outdoor features success
   * @dispatch [Message] Handle success
   * @dispatch [Message] Handle error
   */
  public createOutdoorFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.createOutdoorFeaturesStartAction),
      switchMap(({ create }) =>
        this.homeScoreHttpService.createOutdoorFeatures(create).pipe(
          mergeMap(outdoorFeatures => [
            HomeScoreActions.createOutdoorFeaturesSuccessAction({
              outdoorFeatures
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Create })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              HomeScoreActions.createOutdoorFeaturesFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/assessor-electrification/:id
   * @listen [HomeScore] Fetch assessor electrification
   * @dispatch [HomeScore] Fetch assessor electrification success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch general failed
   * @dispatch [Message] Handle error
   */
  public fetchAssessorElectrification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.fetchAssessorElectrificationStartAction),
      switchMap(({ id }) =>
        this.homeScoreHttpService.getAssessorElectrification(id).pipe(
          mergeMap(assessorElectrification => [
            HomeScoreActions.fetchAssessorElectrificationSuccessAction({
              assessorElectrification
            }),
            MessageActions.resetErrorAction()
          ]),
          catchError(() => of(HomeScoreActions.fetchAssessorElectrificationFailedAction()))
        )
      )
    )
  );
  /**
   * @route technicians-app/assessor-electrification/:id
   * @listen [HomeScore] Update assessor electrification
   * @dispatch [HomeScore] Update assessor electrification success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch general failed
   * @dispatch [Message] Handle error
   */
  public updateAssessorElectrification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.updateAssessorElectrificationAction),
      switchMap(({ id, update }) =>
        this.homeScoreHttpService.updateAssessorElectrification(id, update).pipe(
          mergeMap(assessorElectrification => [
            HomeScoreActions.updateAssessorElectrificationSuccessAction({
              assessorElectrification
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/assessor-electrification/:id
   * @listen [HomeScore] Create assessor electrification
   * @dispatch [HomeScore] Create assessor electrification success
   * @dispatch [Message] Reset error
   * @dispatch [HomeScore] Fetch general failed
   * @dispatch [Message] Handle error
   */
  public createAssessorElectrification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeScoreActions.createAssessorElectrificationStartAction),
      switchMap(({ create }) =>
        this.homeScoreHttpService.createAssessorElectrification(create).pipe(
          mergeMap(assessorElectrification => [
            HomeScoreActions.createAssessorElectrificationSuccessAction({
              assessorElectrification
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Create })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              HomeScoreActions.createAssessorElectrificationFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private homeScoreHttpService: HomeScoreHttpService) {}
}
