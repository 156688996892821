import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  ProjectInspectionsListModel,
  ProjectInspectionStepsModel,
  ProjectsListModel
} from '@shared/models';

import { PROJECTS_FEATURE, ProjectsState } from './projects.state';

export const getProjectsState = createFeatureSelector<ProjectsState>(PROJECTS_FEATURE);

export const getIsLoadingState: MemoizedSelector<object, boolean> = createSelector(
  getProjectsState,
  ({ isLoading }: ProjectsState): boolean => isLoading
);

export const getProjectListState: MemoizedSelector<object, ProjectsListModel> = createSelector(
  getProjectsState,
  ({ projects }: ProjectsState): ProjectsListModel => projects
);

export const getProjectInspectionsListState: MemoizedSelector<
  object,
  ProjectInspectionsListModel
> = createSelector(
  getProjectsState,
  ({ projectInspections }: ProjectsState): ProjectInspectionsListModel => projectInspections
);

export const getProjectInspectionStepsState: MemoizedSelector<
  object,
  ProjectInspectionStepsModel
> = createSelector(
  getProjectsState,
  ({ projectInspectionSteps }: ProjectsState): ProjectInspectionStepsModel => projectInspectionSteps
);
