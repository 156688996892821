export enum MainMenuEnum {
  ManageProjects = 'manage-projects',
  ProjectInspections = 'project-inspections',
  ProjectInspectionSteps = 'project-inspection-steps',
  Dashboard = 'dashboard',
  Project = 'project',
  Schedule = 'schedule',
  Finance = 'finance',
  Document = 'document',
  TimeClock = 'time',
  Setting = 'setting',
  GeneralProject = 'general-project',
  PermiProject = 'permit-project',
  AdminPanel = 'admin-panel',
  CompanyProfile = 'company-profile',
  ProjectOption = 'project-option',
  CommSchedule = 'comm-schedule',
  CommScheduleInspection = 'comm-schedule-inspection',
  CommScheduleClosure = 'comm-schedule-closure',
  CommSchedulePermit = 'comm-schedule-permit',
  InspectionList = 'inspection-list',
  PermitClosureList = 'permit-closure-list',
  Inventory = 'inventory',
  Equipments = 'equipments',
  MiscParts = 'misc-parts',
  VehicleFleet = 'vehicles',
  Reports = 'reports',
  ReportsHomeScore = 'reports-home-score',
  ReportsT24 = 'reports-t24',
  ReportsBayren = 'reports-bayren',
  ReportsSmud = 'reports-smud',
  ReportsInspectionAgreement = 'inspection-agreement',
  ReportInspections = 'report-inspections',
  ReportPermit = 'report-permit',
  Cheers = 'cheers',
  SystemsList = 'systems',
  TechniciansApp = 'technicians-app',
  T24DataCollection = 't24-data-collection',
  CommScheduleRebate = 'comm-schedule-rebate'
}

export enum MainMenuRouteEnum {
  Dashboard = '/dashboard',
  Project = '/project-request',
  GeneralProject = '/project-request/general',
  PermiProject = '/project-request/permit',
  Schedule = '/scheduler',
  Finance = '/invoices',
  Document = '/documents/files',
  TimeClock = '/time-clock',
  Setting = '/settings',
  AdminPanel = '/settings/admin',
  CompanyProfile = '/settings/contractor',
  ProjectOption = '/settings/project-options',
  CommSchedule = '/comm-schedule',
  CommScheduleInspection = '/comm-schedule/inspection',
  CommScheduleClosure = '/comm-schedule/closure',
  CommSchedulePermit = '/communication-scheduling/permit',
  TechniciansApp = '/technicians-app',
  InspectionList = '/project-request/inspection-list',
  PermitClosureList = '/project-request/permit-closure-list',
  Inventory = '/inventory',
  Equipments = '/inventory/equipments',
  MiscParts = '/inventory/misc-parts',
  VehicleFleet = '/inventory/vehicles',
  Reports = '/report',
  ReportInspections = '/report/inspection',
  ReportPermit = '/report/permit',
  Cheers = '/cheers',
  T24DataCollection = '/technicians-app',
  CommScheduleRebate = '/communication-scheduling/rebate'
}
