import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  ApplianceModel,
  AssessorElectrificationModel,
  CoolingModel,
  DuctModel,
  FoundationModel,
  GeneralModel,
  HeatingModel,
  HotWaterModel,
  OutdoorFeaturesModel,
  PvSystemModel,
  RoofAtticModel,
  WallModel,
  WindowsModel
} from '@shared/models';

import { HOME_SCORE_FEATURE, HomeScoreState } from './home-score.state';

export const getHomeScoreState = createFeatureSelector<HomeScoreState>(HOME_SCORE_FEATURE);

export const getIsLoadingState: MemoizedSelector<object, boolean> = createSelector(
  getHomeScoreState,
  ({ isLoading }: HomeScoreState): boolean => isLoading
);

export const getGeneralState: MemoizedSelector<object, GeneralModel> = createSelector(
  getHomeScoreState,
  ({ general }: HomeScoreState): GeneralModel => general
);

export const getRoofAtticState: MemoizedSelector<object, RoofAtticModel[]> = createSelector(
  getHomeScoreState,
  ({ insulation_zones }: HomeScoreState): RoofAtticModel[] => insulation_zones
);

export const getFoundationState: MemoizedSelector<object, FoundationModel[]> = createSelector(
  getHomeScoreState,
  ({ foundations }: HomeScoreState): FoundationModel[] => foundations
);

export const getWallsState: MemoizedSelector<object, WallModel[]> = createSelector(
  getHomeScoreState,
  ({ walls }: HomeScoreState): WallModel[] => walls
);

export const getWindowsState: MemoizedSelector<object, WindowsModel> = createSelector(
  getHomeScoreState,
  ({ windows }: HomeScoreState): WindowsModel => windows
);

export const getHeatingState: MemoizedSelector<object, HeatingModel[]> = createSelector(
  getHomeScoreState,
  ({ heatings }: HomeScoreState): HeatingModel[] => heatings
);

export const getCoolingState: MemoizedSelector<object, CoolingModel[]> = createSelector(
  getHomeScoreState,
  ({ coolings }: HomeScoreState): CoolingModel[] => coolings
);

export const getDuctState: MemoizedSelector<object, DuctModel[]> = createSelector(
  getHomeScoreState,
  ({ ducts }: HomeScoreState): DuctModel[] => ducts
);

export const getHotWaterState: MemoizedSelector<object, HotWaterModel> = createSelector(
  getHomeScoreState,
  ({ hotWater }: HomeScoreState): HotWaterModel => hotWater
);

export const getPvSystemState: MemoizedSelector<object, PvSystemModel> = createSelector(
  getHomeScoreState,
  ({ pvSystem }: HomeScoreState): PvSystemModel => pvSystem
);

export const getAppliancesState: MemoizedSelector<object, ApplianceModel[]> = createSelector(
  getHomeScoreState,
  ({ appliances }: HomeScoreState): ApplianceModel[] => appliances
);

export const getOutdoorFeaturesState: MemoizedSelector<
  object,
  OutdoorFeaturesModel
> = createSelector(
  getHomeScoreState,
  ({ outdoorFeatures }: HomeScoreState): OutdoorFeaturesModel => outdoorFeatures
);

export const getAssessorElectrificationState: MemoizedSelector<
  object,
  AssessorElectrificationModel
> = createSelector(
  getHomeScoreState,
  ({ assessorElectrification }: HomeScoreState): AssessorElectrificationModel =>
    assessorElectrification
);
