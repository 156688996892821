import { createReducer, on } from '@ngrx/store';

import { UserActions, UserActionsType } from './user.actions';
import { userInitialState, UserState } from './user.state';

const reducer = createReducer(
  userInitialState,
  on(
    UserActions.fetchCompanyUserAccountListSuccessAction,
    UserActions.changeUserAccountStatusSuccessAction,
    (state: UserState, { accounts }): UserState => ({
      ...state,
      accounts
    })
  ),
  on(
    UserActions.fetchInspectorListSuccessAction,
    (state: UserState, { inspectors }): UserState => ({
      ...state,
      inspectors
    })
  ),
  on(
    UserActions.fetchTechnicianListSuccessAction,
    (state: UserState, { technicians }): UserState => ({
      ...state,
      technicians
    })
  ),
  on(
    UserActions.fetchAccountListPaginationSuccessAction,
    (state: UserState, { accounts, pagination }): UserState => ({
      ...state,
      accounts,
      pagination
    })
  ),
  on(
    UserActions.searchByNameSuccessAction,
    (state: UserState, { accounts }): UserState => ({
      ...state,
      accounts
    })
  ),
  on(
    UserActions.fetchUserProfileStartAction,
    (state: UserState): UserState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    UserActions.fetchUserProfileSuccessAction,
    UserActions.activateAccountSuccessAction,
    (state: UserState, { user_profile }): UserState => ({
      ...state,
      user_profile,
      isLoading: false
    })
  ),
  on(
    UserActions.fetchUserProfileFailedAction,
    (state: UserState): UserState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    UserActions.fetchCompanyProfilesWithoutAccountSuccessAction,
    UserActions.fetchCompanyUserProfilesSuccessAction,
    UserActions.fetchCompanyDirectoryProfilesSuccessAction,
    UserActions.changeUserProfileStatusSuccessAction,
    UserActions.searchByTypeAndNameSuccessAction,
    UserActions.fetchArchonEmployeesSuccessAction,
    (state: UserState, { user_profiles }): UserState => ({
      ...state,
      user_profiles
    })
  ),
  on(
    UserActions.fetchArchonEmployeesPaginationSuccessAction,
    UserActions.fetchCompanyEmployeesPaginationSuccessAction,
    UserActions.fetchCompanyEmployeesPerTypePaginationSuccessAction,
    (state: UserState, { user_profiles, pagination }): UserState => ({
      ...state,
      user_profiles,
      pagination
    })
  ),
  on(
    UserActions.uploadUserProfileAvatarSuccessAction,
    (state: UserState, { avatar }): UserState => ({
      ...state,
      user_profile: {
        ...state.user_profile,
        avatar
      }
    })
  ),
  on(
    UserActions.uploadUserProfileDocumentSuccessAction,
    (state: UserState, { document }): UserState => ({
      ...state,
      user_profile: {
        ...state.user_profile,
        documents: [...state.user_profile.documents, document]
      }
    })
  ),
  on(
    UserActions.removeUserProfileDocumentSuccessAction,
    (state: UserState, { documents }): UserState => ({
      ...state,
      user_profile: {
        ...state.user_profile,
        documents
      }
    })
  ),
  on(
    UserActions.fetchCompanyGeneralContactListSuccessAction,
    UserActions.createGeneralContactSuccessAction,
    UserActions.updateGeneralContactSuccessAction,
    UserActions.removeGeneralContactSuccessAction,
    (state: UserState, { general_contacts }): UserState => ({
      ...state,
      general_contacts
    })
  )
);

export function userReducer(state: UserState, action: UserActionsType): UserState {
  return reducer(state, action);
}
