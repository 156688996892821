import {
  BankAccountModel,
  BonusPricingModel,
  CreditCardModel,
  PaginationModel,
  PriceCategoryModel,
  PriceGroupModel,
  PriceListModel,
  PriceServiceModel
} from '@shared/models';

export interface BillingState {
  empty_price_list: PriceListModel;
  prices: PriceListModel[];
  pagination: PaginationModel;
  price: PriceListModel;
  bank_accounts: BankAccountModel[];
  credit_cards: CreditCardModel[];
  price_categories: PriceCategoryModel[];
  price_groups: PriceGroupModel[];
  price_group: PriceGroupModel;
  price_services: PriceServiceModel[];
  price_service: PriceServiceModel;
  bonus_pricing: BonusPricingModel;
  isLoading: boolean;
}

export const billingInitialState: BillingState = {
  empty_price_list: null,
  prices: [],
  pagination: null,
  price: null,
  bank_accounts: [],
  credit_cards: [],
  price_categories: [],
  price_groups: [],
  price_group: null,
  price_services: [],
  price_service: null,
  bonus_pricing: null,
  isLoading: false
};

export const BILLING_FEATURE = 'billing';
