import { createReducer, on } from '@ngrx/store';

import { RolePermissionActions, RolePermissionActionsType } from './role-permission.actions';
import { rolePermissionInitialState, RolePermissionState } from './role-permission.state';

const reducer = createReducer(
  rolePermissionInitialState,
  on(
    RolePermissionActions.fetchRolesPaginationSuccessAction,
    (state: RolePermissionState, { roles, pagination }): RolePermissionState => ({
      ...state,
      roles,
      pagination
    })
  ),
  on(
    RolePermissionActions.searchRoleByNameSuccessAction,
    RolePermissionActions.fetchRolesSuccessAction,
    (state: RolePermissionState, { roles }): RolePermissionState => ({
      ...state,
      roles
    })
  ),
  on(
    RolePermissionActions.fetchPermissionsPaginationSuccessAction,
    (state: RolePermissionState, { permissions, pagination }): RolePermissionState => ({
      ...state,
      permissions,
      pagination
    })
  ),
  on(
    RolePermissionActions.searchPermissionByNameSuccessAction,
    RolePermissionActions.fetchPermissionsSuccessAction,
    (state: RolePermissionState, { permissions }): RolePermissionState => ({
      ...state,
      permissions
    })
  ),
  on(
    RolePermissionActions.fetchPermissionStartAction,
    RolePermissionActions.fetchRoleStartAction,
    (state: RolePermissionState): RolePermissionState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    RolePermissionActions.fetchPermissionSuccessAction,
    (state: RolePermissionState, { permission }): RolePermissionState => ({
      ...state,
      permission,
      isLoading: false
    })
  ),
  on(
    RolePermissionActions.fetchPermissionFailedAction,
    RolePermissionActions.fetchRoleFailedAction,
    (state: RolePermissionState): RolePermissionState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    RolePermissionActions.fetchRoleSuccessAction,
    (state: RolePermissionState, { role }): RolePermissionState => ({
      ...state,
      role,
      isLoading: false
    })
  )
);

export function rolePermissionReducer(
  state: RolePermissionState,
  action: RolePermissionActionsType
): RolePermissionState {
  return reducer(state, action);
}
