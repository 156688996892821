import { CheersListModel } from '@shared/models';

export interface CheersState {
  cheersList: CheersListModel;
  url: string;
  isLoading: boolean;
}

export const cheersInitialState: CheersState = {
  cheersList: null,
  url: null,
  isLoading: false
};

export const CHEERS_FEATURE = 'cheers';
