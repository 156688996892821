import { createAction, props, union } from '@ngrx/store';

import {
  AdminRouterPathEnum,
  CompanyProfileRouterPathEnum,
  ProjectOptionsRouterPathEnum,
  UserProfileTypeEnum
} from '@shared/enums';
import {
  CreateAccountFormModel,
  EmployeeFormModel,
  EntityStatusModel,
  FileModel,
  GeneralContactModel,
  NewGeneralContactModel,
  PaginationModel,
  PaginationQueryModel,
  ResetPasswordFormModel,
  UpdateAccountFormModel,
  UpdateGeneralContactModel,
  UserModel,
  UserProfileModel
} from '@shared/models';

const fetchCompanyUserAccountListStartAction = createAction(
  '[User] Fetch company user account list',
  props<{ id: number }>()
);

const fetchCompanyUserAccountListSuccessAction = createAction(
  '[User] Fetch company user account list success',
  props<{ accounts: UserModel[] }>()
);

const changeUserAccountStatusAction = createAction(
  '[User] Change user account status',
  props<EntityStatusModel>()
);

const changeUserAccountStatusSuccessAction = createAction(
  '[User] Change user account status success',
  props<{ accounts: UserModel[] }>()
);

const fetchInspectorListStartAction = createAction('[User] Fetch inspector list started');

const fetchInspectorListSuccessAction = createAction(
  '[User] Fetch inspector list success',
  props<{ inspectors: UserProfileModel[] }>()
);

const fetchTechnicianListStartAction = createAction('[User] Fetch technician list');

const fetchTechnicianListSuccessAction = createAction(
  '[User] Fetch technician list success',
  props<{ technicians: UserProfileModel[] }>()
);

const fetchAccountListPaginationStartAction = createAction(
  '[User] Fetch accounts with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchAccountListPaginationSuccessAction = createAction(
  '[User] Fetch accounts with pagination success',
  props<{ accounts: UserModel[]; pagination: PaginationModel }>()
);

const searchByNameAction = createAction('[User] Search account by name', props<{ name: string }>());

const searchByNameSuccessAction = createAction(
  '[User] Search account by name success',
  props<{ accounts: UserModel[] }>()
);

const fetchUserProfileStartAction = createAction(
  '[User] Fetch user profile',
  props<{ id: number }>()
);

const fetchUserProfileSuccessAction = createAction(
  '[User] Fetch user profile success',
  props<{ user_profile: UserProfileModel }>()
);

const fetchUserProfileFailedAction = createAction('[User] Fetch user profile failed');

const activateAccountAction = createAction('[User] Activate account', props<EntityStatusModel>());

const activateAccountSuccessAction = createAction(
  '[User] Activate account success',
  props<{ user_profile: UserProfileModel }>()
);

const updateAccountAction = createAction(
  '[User] Update account',
  props<{ id: number; account: UpdateAccountFormModel }>()
);

const updateAccountSuccessAction = createAction('[User] Update account success');

const resetPasswordAction = createAction(
  '[User] Reset password',
  props<{ id: number; resetPassword: ResetPasswordFormModel }>()
);

const resetPasswordSuccessAction = createAction('[User] Reset password success');

const fetchCompanyUserProfilesAction = createAction(
  '[User] Fetch company user profiles',
  props<{ id: number }>()
);

const fetchCompanyUserProfilesSuccessAction = createAction(
  '[User] Fetch company user profiles without account success',
  props<{ user_profiles: UserProfileModel[] }>()
);

const fetchCompanyProfilesWithoutAccountAction = createAction(
  '[User] Fetch company user profiles without account',
  props<{ id: number }>()
);

const fetchCompanyProfilesWithoutAccountSuccessAction = createAction(
  '[User] Fetch company user profiles without account success',
  props<{ user_profiles: UserProfileModel[] }>()
);

const createAccountAction = createAction(
  '[User] Create account',
  props<{ id: number; account: CreateAccountFormModel }>()
);

const createAccountSuccessAction = createAction('[User] Create account success');

const fetchArchonEmployeesPaginationStartAction = createAction(
  '[User] Fetch archon employees with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchArchonEmployeesPaginationSuccessAction = createAction(
  '[User] Fetch archon employees with pagination success',
  props<{ user_profiles: UserProfileModel[]; pagination: PaginationModel }>()
);

const fetchArchonEmployeesStartAction = createAction('[User] Fetch archon employees');

const fetchArchonEmployeesSuccessAction = createAction(
  '[User] Fetch archon employees success',
  props<{ user_profiles: UserProfileModel[] }>()
);

const uploadUserProfileAvatarAction = createAction(
  '[User] Upload user profile avatar',
  props<{ id: number; file: File }>()
);

const uploadUserProfileAvatarSuccessAction = createAction(
  '[User] Upload user profile avatar success',
  props<{ avatar: FileModel }>()
);

const uploadUserProfileDocumentSuccessAction = createAction(
  '[User] Upload user profile document success',
  props<{ document: FileModel }>()
);

const removeUserProfileDocumentAction = createAction(
  '[User] Remove user profile document',
  props<{ id: number; fileId: number }>()
);

const removeUserProfileDocumentSuccessAction = createAction(
  '[User] Remove user profile document success',
  props<{ documents: FileModel[] }>()
);

const updateUserProfileAction = createAction(
  '[User] Update user profile',
  props<{ id: number; companyId: number; user_profile: EmployeeFormModel }>()
);

const updateArchonUserProfileAction = createAction(
  '[User] Update archon user profile',
  props<{ id: number; companyId: number; user_profile: EmployeeFormModel }>()
);

const updateUserProfileSuccessAction = createAction('[User] Update user profile success');

const createUserProfileAction = createAction(
  '[User] Create user profile',
  props<{
    id: number;
    user_profile: EmployeeFormModel;
    redirectUrl: AdminRouterPathEnum | CompanyProfileRouterPathEnum | ProjectOptionsRouterPathEnum;
  }>()
);

const createUserProfileSuccessAction = createAction(
  '[User] Create user profile success',
  props<{
    redirectUrl: AdminRouterPathEnum | CompanyProfileRouterPathEnum | ProjectOptionsRouterPathEnum;
  }>()
);

const fetchCompanyEmployeesPaginationStartAction = createAction(
  '[User] Fetch company employees with pagination',
  props<{ id: number; queryModel: PaginationQueryModel }>()
);

const fetchCompanyEmployeesPaginationSuccessAction = createAction(
  '[User] Fetch company employees with pagination success',
  props<{ user_profiles: UserProfileModel[]; pagination: PaginationModel }>()
);

const fetchCompanyGeneralContactListAction = createAction(
  '[User] Fetch company general contacts',
  props<{ id: number }>()
);

const fetchCompanyGeneralContactListSuccessAction = createAction(
  '[User] Fetch company general contacts success',
  props<{ general_contacts: GeneralContactModel[] }>()
);

const createGeneralContactAction = createAction(
  '[User] Create general contact',
  props<{ id: number; generalContact: NewGeneralContactModel }>()
);

const createGeneralContactSuccessAction = createAction(
  '[User] Create general contact success',
  props<{ general_contacts: GeneralContactModel[] }>()
);

const updateGeneralContactAction = createAction(
  '[User] Update general contact',
  props<{ id: number; generalContact: UpdateGeneralContactModel }>()
);

const updateGeneralContactSuccessAction = createAction(
  '[User] Update general contact success',
  props<{ general_contacts: GeneralContactModel[] }>()
);

const removeGeneralContactAction = createAction(
  '[User] Remove general contact',
  props<{ id: number }>()
);

const removeGeneralContactSuccessAction = createAction(
  '[User] Remove general contact success',
  props<{ general_contacts: GeneralContactModel[] }>()
);

const fetchCompanyDirectoryProfilesAction = createAction(
  '[User] Fetch company directory profiles',
  props<{ id: number }>()
);

const fetchCompanyDirectoryProfilesSuccessAction = createAction(
  '[User] Fetch company directory profiles success',
  props<{ user_profiles: UserProfileModel[] }>()
);

const fetchCompanyEmployeesPerTypePaginationStartAction = createAction(
  '[User] Fetch company employees per user profile type with pagination',
  props<{ id: number; queryModel: PaginationQueryModel }>()
);

const fetchCompanyEmployeesPerTypePaginationSuccessAction = createAction(
  '[User] Fetch company employees per user profile type with pagination success',
  props<{ user_profiles: UserProfileModel[]; pagination: PaginationModel }>()
);

const changeUserProfileStatusAction = createAction(
  '[User] Change user profile status',
  props<EntityStatusModel>()
);

const changeUserProfileStatusSuccessAction = createAction(
  '[User] Change user profile status success',
  props<{ user_profiles: UserProfileModel[] }>()
);

const searchByTypeAndNameAction = createAction(
  '[User] Search user profile by type and name',
  props<{ id: number; userType: UserProfileTypeEnum; name: string }>()
);

const searchByTypeAndNameSuccessAction = createAction(
  '[User] Search user profile by type and name success',
  props<{ user_profiles: UserProfileModel[] }>()
);

export const UserActions = {
  fetchCompanyUserAccountListStartAction,
  fetchCompanyUserAccountListSuccessAction,
  changeUserAccountStatusAction,
  changeUserAccountStatusSuccessAction,
  fetchInspectorListStartAction,
  fetchInspectorListSuccessAction,
  fetchTechnicianListStartAction,
  fetchTechnicianListSuccessAction,
  fetchAccountListPaginationStartAction,
  fetchAccountListPaginationSuccessAction,
  searchByNameAction,
  searchByNameSuccessAction,
  fetchUserProfileStartAction,
  fetchUserProfileSuccessAction,
  fetchUserProfileFailedAction,
  activateAccountAction,
  activateAccountSuccessAction,
  updateAccountAction,
  updateAccountSuccessAction,
  resetPasswordAction,
  resetPasswordSuccessAction,
  fetchCompanyUserProfilesAction,
  fetchCompanyUserProfilesSuccessAction,
  fetchCompanyProfilesWithoutAccountAction,
  fetchCompanyProfilesWithoutAccountSuccessAction,
  createAccountAction,
  createAccountSuccessAction,
  fetchArchonEmployeesPaginationStartAction,
  fetchArchonEmployeesPaginationSuccessAction,
  uploadUserProfileAvatarAction,
  uploadUserProfileAvatarSuccessAction,
  uploadUserProfileDocumentSuccessAction,
  removeUserProfileDocumentAction,
  removeUserProfileDocumentSuccessAction,
  updateUserProfileAction,
  updateUserProfileSuccessAction,
  createUserProfileAction,
  createUserProfileSuccessAction,
  fetchCompanyEmployeesPaginationStartAction,
  fetchCompanyEmployeesPaginationSuccessAction,
  fetchCompanyGeneralContactListAction,
  fetchCompanyGeneralContactListSuccessAction,
  createGeneralContactAction,
  createGeneralContactSuccessAction,
  updateGeneralContactAction,
  updateGeneralContactSuccessAction,
  removeGeneralContactAction,
  removeGeneralContactSuccessAction,
  fetchCompanyDirectoryProfilesAction,
  fetchCompanyDirectoryProfilesSuccessAction,
  fetchCompanyEmployeesPerTypePaginationStartAction,
  fetchCompanyEmployeesPerTypePaginationSuccessAction,
  changeUserProfileStatusAction,
  changeUserProfileStatusSuccessAction,
  searchByTypeAndNameAction,
  searchByTypeAndNameSuccessAction,
  fetchArchonEmployeesStartAction,
  fetchArchonEmployeesSuccessAction,
  updateArchonUserProfileAction
};

const userActionsUnion = union(UserActions);

export type UserActionsType = typeof userActionsUnion;
