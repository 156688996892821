import { createReducer, on } from '@ngrx/store';

import {
  BpiDataCollectionActions,
  BpiDataCollectionActionsType
} from './bpi-data-collection.actions';
import { bpiDataCollectionInitialState, BpiDataCollectionState } from './bpi-data-collection.state';

const reducer = createReducer(
  bpiDataCollectionInitialState,
  on(
    BpiDataCollectionActions.fetchCazTestingStartAction,
    BpiDataCollectionActions.fetchInfiltrationResultsStartAction,
    BpiDataCollectionActions.createInfiltrationResultsStartAction,
    (state: BpiDataCollectionState): BpiDataCollectionState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    BpiDataCollectionActions.fetchCazTestingSuccessAction,
    BpiDataCollectionActions.updateCazTestingSuccessAction,
    (state: BpiDataCollectionState, { cazTesting }): BpiDataCollectionState => ({
      ...state,
      cazTesting,
      isLoading: false
    })
  ),
  on(
    BpiDataCollectionActions.fetchInfiltrationResultsSuccessAction,
    BpiDataCollectionActions.updateInfiltrationResultsSuccessAction,
    BpiDataCollectionActions.createInfiltrationResultsSuccessAction,
    (state: BpiDataCollectionState, { infiltrationResults }): BpiDataCollectionState => ({
      ...state,
      infiltrationResults,
      isLoading: false
    })
  ),
  on(
    BpiDataCollectionActions.fetchCazTestingFailedAction,
    BpiDataCollectionActions.fetchInfiltrationResultsFailedAction,
    BpiDataCollectionActions.createInfiltrationResultsFailedAction,
    (state: BpiDataCollectionState): BpiDataCollectionState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    BpiDataCollectionActions.resetCazTestingAction,
    (state: BpiDataCollectionState): BpiDataCollectionState => ({
      ...state,
      cazTesting: null
    })
  ),
  on(
    BpiDataCollectionActions.resetInfiltrationResultsAction,
    (state: BpiDataCollectionState): BpiDataCollectionState => ({
      ...state,
      infiltrationResults: null
    })
  )
);

export function bpiDataCollectionReducer(
  state: BpiDataCollectionState,
  action: BpiDataCollectionActionsType
): BpiDataCollectionState {
  return reducer(state, action);
}
