import { createAction, props, union } from '@ngrx/store';

import {
  PaginationModel,
  PaginationQueryModel,
  PermissionFormModel,
  PermissionModel,
  RoleFormModel,
  RoleModel
} from '@shared/models';

const fetchRolesPaginationStartAction = createAction(
  '[RolePermission] Fetch roles with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchRolesPaginationSuccessAction = createAction(
  '[RolePermission] Fetch roles with pagination success',
  props<{ roles: RoleModel[]; pagination: PaginationModel }>()
);

const searchRoleByNameAction = createAction(
  '[RolePermission] Search role by name',
  props<{ name: string }>()
);

const searchRoleByNameSuccessAction = createAction(
  '[RolePermission] Search role by name success',
  props<{ roles: RoleModel[] }>()
);

const fetchPermissionsPaginationStartAction = createAction(
  '[RolePermission] Fetch permissions with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchPermissionsPaginationSuccessAction = createAction(
  '[RolePermission] Fetch permissions with pagination success',
  props<{ permissions: PermissionModel[]; pagination: PaginationModel }>()
);

const searchPermissionByNameAction = createAction(
  '[RolePermission] Search permission by name',
  props<{ name: string }>()
);

const searchPermissionByNameSuccessAction = createAction(
  '[RolePermission] Search permission by name success',
  props<{ permissions: PermissionModel[] }>()
);

const fetchPermissionStartAction = createAction(
  '[RolePermission] Fetch permission',
  props<{ id: number }>()
);

const fetchPermissionSuccessAction = createAction(
  '[RolePermission] Fetch permission success',
  props<{ permission: PermissionModel }>()
);

const fetchPermissionFailedAction = createAction('[RolePermission] Fetch permission failed');

const updatePermissionAction = createAction(
  '[RolePermission] Update permission',
  props<{ id: number; permission: PermissionFormModel }>()
);

const fetchRoleStartAction = createAction('[RolePermission] Fetch role', props<{ id: number }>());

const fetchRoleSuccessAction = createAction(
  '[RolePermission] Fetch role success',
  props<{ role: RoleModel }>()
);

const fetchRoleFailedAction = createAction('[RolePermission] Fetch role failed');

const updateRoleAction = createAction(
  '[RolePermission] Update role',
  props<{ id: number; role: RoleFormModel }>()
);

const fetchPermissionsStartAction = createAction('[RolePermission] Fetch permissions');

const fetchPermissionsSuccessAction = createAction(
  '[RolePermission] Fetch permissions success',
  props<{ permissions: PermissionModel[] }>()
);

const createRoleAction = createAction(
  '[RolePermission] Create role',
  props<{ role: RoleFormModel }>()
);

const createRoleSuccessAction = createAction('[RolePermission] Create role success');

const fetchRolesStartAction = createAction('[RolePermission] Fetch roles');

const fetchRolesSuccessAction = createAction(
  '[RolePermission] Fetch roles success',
  props<{ roles: RoleModel[] }>()
);

export const RolePermissionActions = {
  fetchRolesPaginationStartAction,
  fetchRolesPaginationSuccessAction,
  searchRoleByNameAction,
  searchRoleByNameSuccessAction,
  fetchPermissionsPaginationStartAction,
  fetchPermissionsPaginationSuccessAction,
  searchPermissionByNameAction,
  searchPermissionByNameSuccessAction,
  fetchPermissionStartAction,
  fetchPermissionSuccessAction,
  fetchPermissionFailedAction,
  updatePermissionAction,
  fetchRoleStartAction,
  fetchRoleSuccessAction,
  fetchRoleFailedAction,
  updateRoleAction,
  fetchPermissionsStartAction,
  fetchPermissionsSuccessAction,
  createRoleAction,
  createRoleSuccessAction,
  fetchRolesStartAction,
  fetchRolesSuccessAction
};

const rolePermissionActionsUnion = union(RolePermissionActions);

export type RolePermissionActionsType = typeof rolePermissionActionsUnion;
