import { createAction, props, union } from '@ngrx/store';

import {
  CustomBonusFormModel,
  EmployeeEarningModel,
  FileModel,
  TaskFormModel,
  TaskModel,
  TaskSummaryModel,
  TaskUpdateFormModel,
  TimeClockWeekModel
} from '@shared/models';

const createTaskAction = createAction('[TimeClock] Create task', props<{ task: TaskFormModel }>());

const createTaskSuccessAction = createAction(
  '[TimeClock] Create task success',
  props<{ tasks: TaskModel[]; summary: TaskSummaryModel }>()
);

const fetchTasksForTodayAction = createAction(
  '[TimeClock] Fetch tasks for today',
  props<{ id: number }>()
);

const fetchTasksSuccessAction = createAction(
  '[TimeClock] Fetch tasks success',
  props<{ tasks: TaskModel[]; summary: TaskSummaryModel }>()
);

const fetchHistoryAction = createAction(
  '[TimeClock] Fetch history',
  props<{ id: number; year: number }>()
);

const fetchHistorySuccessAction = createAction(
  '[TimeClock] Fetch history success',
  props<{ history: TimeClockWeekModel[] }>()
);
const fetchHistoryFailedAction = createAction('[TimeClock] Fetch history failed');

const fetchTasksForDateAction = createAction(
  '[TimeClock] Fetch tasks for date',
  props<{ id: number; date: string }>()
);

const fetchTasksForDateFailedAction = createAction('[TimeClock] Fetch tasks for date failed');

const removeTaskAction = createAction('[TimeClock] Remove task', props<{ id: number }>());

const removeTaskSuccessAction = createAction(
  '[TimeClock] Remove task success',
  props<{ tasks: TaskModel[]; summary: TaskSummaryModel }>()
);

const createPastTaskAction = createAction(
  '[TimeClock] Create past task',
  props<{ task: TaskFormModel }>()
);

const updateTasksAction = createAction(
  '[TimeClock] Update tasks',
  props<{ update: TaskUpdateFormModel[] }>()
);

const updateTasksSuccessAction = createAction(
  '[TimeClock] Update tasks success',
  props<{ tasks: TaskModel[]; summary: TaskSummaryModel }>()
);

const fetchEmployeeEarningAction = createAction(
  '[TimeClock] Fetch employee earning',
  props<{ date: string }>()
);

const fetchEmployeeEarningSuccessAction = createAction(
  '[TimeClock] Fetch employee earning success',
  props<{ earnings: EmployeeEarningModel[] }>()
);

const fetchEmployeeEarningFailedAction = createAction('[TimeClock] Fetch employee earning failed');

const generateReportAction = createAction('[TimeClock] Generate report', props<{ date: string }>());

const generateReportSuccessAction = createAction(
  '[TimeClock] Generate report success',
  props<{ report: FileModel }>()
);

const addCustomBonusAction = createAction(
  '[TimeClock] Add custom bonus',
  props<{ bonus: CustomBonusFormModel }>()
);

const addCustomBonusSuccessAction = createAction(
  '[TimeClock] Add custom bonus success',
  props<{ earnings: EmployeeEarningModel[] }>()
);

const updateCustomBonusAction = createAction(
  '[TimeClock] Update custom bonus',
  props<{ id: number; bonus: CustomBonusFormModel }>()
);

const updateCustomBonusSuccessAction = createAction(
  '[TimeClock] Update custom bonus success',
  props<{ earnings: EmployeeEarningModel[] }>()
);

export const TimeClockActions = {
  createTaskAction,
  createTaskSuccessAction,
  fetchTasksForTodayAction,
  fetchTasksSuccessAction,
  fetchHistoryAction,
  fetchHistorySuccessAction,
  fetchHistoryFailedAction,
  fetchTasksForDateAction,
  fetchTasksForDateFailedAction,
  removeTaskAction,
  removeTaskSuccessAction,
  createPastTaskAction,
  updateTasksAction,
  updateTasksSuccessAction,
  fetchEmployeeEarningAction,
  fetchEmployeeEarningSuccessAction,
  fetchEmployeeEarningFailedAction,
  generateReportAction,
  generateReportSuccessAction,
  addCustomBonusAction,
  addCustomBonusSuccessAction,
  updateCustomBonusAction,
  updateCustomBonusSuccessAction
};

const timeClockActionsUnion = union(TimeClockActions);

export type TimeClockActionsType = typeof timeClockActionsUnion;
