import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  CommScheduleHistoryLogFormModel,
  CommScheduleListResponseModel,
  CommScheduleModel,
  MessageResponseModel,
  PaginationModel,
  PaginationQueryModel
} from '@shared/models';
import { flattenCommSchedulesWithPagination, getHttpHeaders, objToHttpParams } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ProjectRequestHttpService {
  constructor(private http: HttpClient) {}

  public getPaginatedCommSchedules(
    queryParams: PaginationQueryModel
  ): Observable<{ comm_schedules: CommScheduleModel[]; pagination: PaginationModel }> {
    return this.http
      .get<CommScheduleListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.CommSchedulePaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenCommSchedulesWithPagination));
  }

  public getPaginatedCommSchedulesClosure(
    queryParams: PaginationQueryModel
  ): Observable<{ comm_schedules: CommScheduleModel[]; pagination: PaginationModel }> {
    return this.http
      .get<CommScheduleListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.CommScheduleClosurePaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenCommSchedulesWithPagination));
  }

  public searchByName(name: string): Observable<{ comm_schedules: CommScheduleModel[] }> {
    return this.http.get<{ comm_schedules: CommScheduleModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CommScheduleByName),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ name })
      }
    );
  }

  public searchByNameClosure(name: string): Observable<{ comm_schedules: CommScheduleModel[] }> {
    return this.http.get<{ comm_schedules: CommScheduleModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CommScheduleByNameClosure),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ name })
      }
    );
  }

  public createHistoryLog(
    id: number,
    log: CommScheduleHistoryLogFormModel
  ): Observable<{ comm_schedule: CommScheduleModel }> {
    return this.http.post<{ comm_schedule: CommScheduleModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CommScheduleHistoryLog,
        id
      ),
      log,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public sendSms(id: number, message: string): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CommScheduleSMS, id),
      { message },
      {
        headers: getHttpHeaders()
      }
    );
  }

  public downloadPdf(id: number): Observable<Blob> {
    return this.http.get<Blob>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CommSchedulePDF, id),
      {
        headers: getHttpHeaders(),
        responseType: 'blob' as 'json'
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    routeBinding: number
  ): string {
    return `${baseUrl}/${resource.replace(':id', routeBinding.toString())}`;
  }
}
