import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { groupBy, map, mergeMap, reduce, toArray } from 'rxjs/internal/operators';

import { NotificationGroupModel, NotificationModel } from '@shared/models/notification.model';


@Injectable({
  providedIn: 'root'
})
export class NotificationDataService {
  public notifications = new BehaviorSubject<NotificationGroupModel[]>([]);
  public respondedNotifications =  new BehaviorSubject<NotificationModel[]>([]);

  public setNotificationData(notifications: NotificationModel[]): void {
    this.respondedNotifications.next(notifications);
      of(...notifications).pipe(
        groupBy((p: any) =>
          moment(p.notification.created_at)
          .local()
          .format('MM/DD/YYYY')),
        mergeMap(group$ =>
          group$.pipe(reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))
        ),
        map(arr => ({ date: arr[0], notifications: arr.slice(1) })),
        toArray()
      ).subscribe(r => this.notifications.next(r));
  }

  public getNotificationData(): Observable<NotificationGroupModel[]> {
    return this.notifications.asObservable();
  }

  public updateNotifications(notifications: NotificationGroupModel[]): void {
    this.notifications.next(notifications);
    const allNotifications: NotificationModel[] = [];
    notifications.forEach(notificationGroup => {
      notificationGroup.notifications.forEach(notification => {
        allNotifications.push(notification);
      });
    });

    this.respondedNotifications.next(allNotifications);
  }

  public getRespondedNotifications(): Observable<NotificationModel[]> {
    return this.respondedNotifications.asObservable();
  }
}
