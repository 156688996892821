import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AdminRouterPathEnum, ApiResourceEnum } from '@shared/enums';
import {
  MessageResponseModel,
  PaginationModel,
  PaginationQueryModel,
  PermissionFormModel,
  PermissionModel,
  PermissionResponseModel,
  RoleFormModel,
  RoleModel,
  RoleResponseModel
} from '@shared/models';
import {
  flattenPermissionsWithPagination,
  flattenRolesWithPagination,
  getHttpHeaders,
  objToHttpParams
} from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionHttpService {
  constructor(private http: HttpClient, private router: Router) {}

  public getPaginatedRoles(
    queryParams: PaginationQueryModel
  ): Observable<{ roles: RoleModel[]; pagination: PaginationModel }> {
    return this.http
      .get<RoleResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.RolesPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenRolesWithPagination));
  }

  public getPaginatedPermissions(
    queryParams: PaginationQueryModel
  ): Observable<{ permissions: PermissionModel[]; pagination: PaginationModel }> {
    return this.http
      .get<PermissionResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.PermissionsPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenPermissionsWithPagination));
  }

  public searchRoleByName(name: string): Observable<{ roles: RoleModel[] }> {
    return this.http.get<{ roles: RoleModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.RoleByName),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ name })
      }
    );
  }

  public searchPermissionByName(name: string): Observable<{ permissions: PermissionModel[] }> {
    return this.http.get<{ permissions: PermissionModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.PermissionByName),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ name })
      }
    );
  }

  public getPermission(id: number): Observable<{ permission: PermissionModel }> {
    return this.http.get<{ permission: PermissionModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.Permission, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updatePermission(
    id: number,
    permission: PermissionFormModel
  ): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.Permission, id),
      permission,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getRole(id: number): Observable<{ role: RoleModel }> {
    return this.http.get<{ role: RoleModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.Role, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateRole(id: number, role: RoleFormModel): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.Role, id),
      role,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPermissions(): Observable<{ permissions: PermissionModel[] }> {
    return this.http.get<{ permissions: PermissionModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.Permissions),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createRole(role: RoleFormModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CreateRole),
      role,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getRoles(): Observable<{ roles: RoleModel[] }> {
    return this.http.get<{ roles: RoleModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.Roles),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigateToRoles(): void {
    this.router.navigate([AdminRouterPathEnum.Roles]);
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    routeBinding: number
  ): string {
    return `${baseUrl}/${resource.replace(':id', routeBinding.toString())}`;
  }
}
