import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  AirflowVerificationModel,
  DuctLeakageVerificationModel,
  DuctSystemInformationModel,
  EquipmentInfoModel,
  FanWattDrawModel,
  MessageResponseModel,
  RefrigerantVerificationModel,
  SystemsModel,
  T24DataCollectionModel,
  UpdateMiniSplitsUnitsModel,
  UpdateT24DataCollectionFormModel
} from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class T24DataCollectionHttpService {
  constructor(private http: HttpClient) {}

  public getSystemsList(id: number): Observable<{ systems: SystemsModel[] }> {
    return this.http.get<{ systems: SystemsModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.SystemsByInspectionId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeSplitUnitSystem(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveSplitUnitSystem,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removePackageUnitSystem(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemovePackageUnitSystem,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeMiniSplitSystem(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveMiniSplitSystem,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getSplitUnits(id: number): Observable<T24DataCollectionModel> {
    return this.http.get<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.SplitUnitsBySystemId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPackageUnits(id: number): Observable<T24DataCollectionModel> {
    return this.http.get<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.PackageUnitsBySystemId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getMiniSplitHeatPump(id: number): Observable<T24DataCollectionModel> {
    return this.http.get<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.MiniSplitHeatPumpBySystemId,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateSplitUnits(
    id: number,
    update: UpdateT24DataCollectionFormModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.SplitUnits,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updatePackageUnits(
    id: number,
    update: UpdateT24DataCollectionFormModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.PackageUnits,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateMiniSplitHeatPump(
    id: number,
    update: UpdateT24DataCollectionFormModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.MiniSplitHeatPump,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createMiniSplitUnits(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateMiniSplitUnits),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateMiniSplitUnits(
    id: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.MiniSplitUnits,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createUpdateMiniSplitUnits(
    update: UpdateMiniSplitsUnitsModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateUpdateMiniSplitUnits),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createHeatPumpPackageUnits(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateHeatPumpPackageUnits),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateHeatPumpPackageUnits(
    id: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.HeatPumpPackageUnits,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createGasPackageUnits(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateGasPackageUnits),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateGasPackageUnits(
    id: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.GasPackageUnits,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createFurnaces(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateFurnaces),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateFurnaces(
    furnaceId: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.Furnaces,
        furnaceId
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAcEvaporators(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateAcEvaporators),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAcEvaporators(
    acEvaporatorId: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AcEvaporators,
        acEvaporatorId
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createHeatPumpCondensers(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateHeatPumpCondensers),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateHeatPumpCondensers(
    heatPumpCondenserId: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.HeatPumpCondensers,
        heatPumpCondenserId
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAirHandlers(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateAirHandlers),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAirHandlers(
    airHandlerId: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AirHandlers,
        airHandlerId
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAcCondensers(create: EquipmentInfoModel): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateAcCondensers),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAcCondensers(
    acCondenserId: number,
    update: EquipmentInfoModel
  ): Observable<EquipmentInfoModel> {
    return this.http.post<EquipmentInfoModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AcCondensers,
        acCondenserId
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAirflowVerification(
    create: AirflowVerificationModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateAirflowVerifications),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAirflowVerification(
    id: number,
    update: AirflowVerificationModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.AirflowVerifications,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createFanWattDraw(create: FanWattDrawModel): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateFanWattDraw),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateFanWattDraw(
    id: number,
    update: FanWattDrawModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.FanWattDraw,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createRefrigerantVerifications(
    create: RefrigerantVerificationModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpoint(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateRefrigerantVerifications
      ),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateRefrigerantVerifications(
    id: number,
    update: RefrigerantVerificationModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RefrigerantVerifications,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createDuctLeakageVerification(
    create: DuctLeakageVerificationModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpoint(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateDuctLeakageVerifications
      ),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateDuctLeakageVerification(
    id: number,
    update: DuctLeakageVerificationModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.DuctLeakageVerifications,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateInstalledDuctSystemInfo(
    id: number,
    update: DuctSystemInformationModel
  ): Observable<T24DataCollectionModel> {
    return this.http.post<T24DataCollectionModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.UpdateInstalledDuctSystemInfo,
        id
      ),
      update,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    inspectionId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (inspectionId) {
      path = path.replace(':inspectionId', inspectionId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
