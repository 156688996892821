import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SESSION_EXPIRATION_CONFIG } from './constants';
import { SessionExpirationInterceptor } from './interceptors';
import { SessionExpirationConfigModel } from './models';

@NgModule()
export class SessionExpirationModule {
  public static forRoot(config: SessionExpirationConfigModel): ModuleWithProviders {
    return {
      ngModule: SessionExpirationModule,
      providers: [
        {
          provide: SESSION_EXPIRATION_CONFIG,
          useValue: config
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SessionExpirationInterceptor,
          multi: true
        }
      ]
    };
  }
}
