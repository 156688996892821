import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';

import { MainMenuEnum } from '@shared/enums';
import { MainMenuModel, UserProfileModel } from '@shared/models';
import {
  NotificationGroupModel,
  NotificationModel
} from '@shared/models/notification.model';
import { LaravelEchoService } from '@shared/services/laravel-echo.service';
import { NotificationDataService } from '@shared/services/notification-data.service';
import { NotificationHttpService } from '@shared/services/notification-http.service';

@Component({
  selector: 'common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonHeaderComponent implements OnInit {
  @Input() public userProfile: UserProfileModel;
  @Input() public mainMenu: MainMenuModel[];
  @Input() public showUserDropdown: boolean = false;
  @Input() public hasSidebar: boolean;
  @Input() public isArchonEmployee: boolean;
  @Input() public notifications: NotificationGroupModel[] = [];
  @Output() public subMenuOpened: EventEmitter<MainMenuEnum> = new EventEmitter();
  @Output() public menuItemClicked: EventEmitter<any> = new EventEmitter();
  @Output() public userDropdownToggle: EventEmitter<any> = new EventEmitter();
  @Output() public logoutClicked: EventEmitter<any> = new EventEmitter();
  @Output() public showSideMenu: EventEmitter<any> = new EventEmitter();
  @Output() public showSidebar: EventEmitter<any> = new EventEmitter();

  public sideMenuOpened: boolean = true;
  public showNotificationMenu: boolean = false;
  public respondedNotifications: NotificationModel[] = [];

  constructor(
    private notificationDataService: NotificationDataService,
    private notificationRequestService: NotificationHttpService,
    private router: Router,
    private echoService: LaravelEchoService,
    private cd: ChangeDetectorRef,
    ) {}

  public ngOnInit(): void {
    this.notificationDataService.getRespondedNotifications().subscribe(data => {
      this.respondedNotifications = data;
      this.cd.markForCheck();
    });

    this.echoService.service.private('notifications.' + this.userProfile.user_account_id).
    listen('NewNotification', data => {
      const notifications = this.respondedNotifications;
      notifications.unshift(data.notification);
      this.notificationDataService.setNotificationData(notifications);
    });
  }

  public onSubMenuOpen(event: Event, menuId: MainMenuEnum): void {
    this.subMenuOpened.emit(menuId);
    event.preventDefault();
    event.stopPropagation();
  }

  public onMenuItemClick(): void {
    this.menuItemClicked.emit();
  }

  public onUserDropDownToggle(): void {
    this.userDropdownToggle.emit();
  }

  public onLogoutClick(): void {
    this.logoutClicked.emit();
  }

  public onShowSideMenu(): void {
    this.sideMenuOpened = !this.sideMenuOpened;
    this.showSideMenu.emit();
  }

  public onShowSidebar(): void {
    this.showSidebar.emit();
  }

  public countUnreadNotifications(): number {
    let returnCount = 0;

    if (this.notifications.length > 0) {
      this.notifications.forEach(notificationGroup => {
        notificationGroup.notifications.forEach(notification => {
          if (!notification.seen_at) {
            returnCount ++;
          }
        });
      });
    }

    return returnCount;
  }

  public markAsRead(i: number, j: number): void {
    this.notifications[i].notifications[j].seen_at = 'true';
    this.notificationDataService.updateNotifications(this.notifications);
    this.notificationRequestService.markNotificationRead(this.notifications[i].notifications[j].id);
  }

  public markAllAsRead(): void {
    this.notifications.forEach(notificationGroup => {
      notificationGroup.notifications.forEach(notification => {
        notification.seen_at = 'true';
      });
    });

    this.notificationDataService.updateNotifications(this.notifications);
    this.notificationRequestService.markAllAsRead();
  }

  public openNotification(i: number, j: number): void {
    const url = this.notifications[i].notifications[j].notification.link.replace(/^.*\/\/[^\/]+/, '');
    this.markAsRead(i, j);

    this.router.navigate([url]);
  }
}
