export enum CommunicationTypeEnum {
  PhoneCall = 'Phone Call',
  TextSMS = 'Text SMS',
  Mail = 'Mail',
  MarkUnresponsive = 'Mark Unresponsive'
}

export enum CommunicationStatusEnum {
  UnscheduledPhoneCall = 'UnscheduledPhoneCall',
  UnscheduledTextSMS = 'UnscheduledTextSMS',
  UnscheduledMail = 'UnscheduledMail',
  SuccessfulComplete = 'SuccessfulComplete',
  ScheduledPhoneCall = 'ScheduledPhoneCall',
  ScheduledTextSMS = 'ScheduledTextSMS',
  ScheduledMail = 'ScheduledMail',
  Unsuccessful = 'Unsuccessful',
  MarkUnresponsive = 'MarkUnresponsive'
}

export enum CommScheduleStatusEnum {
  Unscheduled = 'Unscheduled',
  Scheduled = 'Scheduled',
  Completed = 'Completed',
  Unsuccessful = 'Unsuccessful',
  NotRequested = 'Not Requested',
  NotRequestedInactive = 'Not Requested Inactive',
  NotRequestedActive = 'Not Requested Active'
}

export enum CommScheduleTypeEnum {
  PhoneCall = 'PhoneCall',
  TextSMS = 'TextSMS',
  Mail = 'Mail',
  Unresponsive = 'Unresponsive',
  Verify = 'Verify'
}
