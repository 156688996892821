import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MainMenuModel } from '@shared/models';

@Component({
  selector: 'common-right-sidebar',
  templateUrl: './common-right-sidebar.component.html',
  styleUrls: ['./common-right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonRightSidebarComponent {
  @Input() public mainMenu: MainMenuModel[];
  @Input() public showSideMenu: boolean = true;
}
