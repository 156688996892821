import { MainMenuEnum, MainMenuRouteEnum } from '../enums/navigation.enum';
import { SidebarCategoryModel } from '../models/navigation.model';

export const SidebareMap: { [key in MainMenuEnum]: SidebarCategoryModel } = {
  [MainMenuEnum.Dashboard]: null,
  [MainMenuEnum.Project]: null,
  [MainMenuEnum.GeneralProject]: null,
  [MainMenuEnum.PermiProject]: null,
  [MainMenuEnum.Schedule]: null,
  [MainMenuEnum.Finance]: null,
  [MainMenuEnum.Document]: null,
  [MainMenuEnum.Inventory]: null,
  [MainMenuEnum.Equipments]: null,
  [MainMenuEnum.MiscParts]: null,
  [MainMenuEnum.VehicleFleet]: null,
  [MainMenuEnum.Reports]: null,
  [MainMenuEnum.ReportInspections]: null,
  [MainMenuEnum.ReportPermit]: null,
  [MainMenuEnum.InspectionList]: null,
  [MainMenuEnum.PermitClosureList]: null,
  [MainMenuEnum.TimeClock]: {
    category: 'navigation.sidebar.category.time-clock',
    icon: 'icon-project-request.svg',
    group: [
      {
        name: 'navigation.sidebar.group.time-clock',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.employee-list',
            route: `${MainMenuRouteEnum.TimeClock}/employee-list`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.current-day',
            route: `${MainMenuRouteEnum.TimeClock}/current-day`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.history',
            route: `${MainMenuRouteEnum.TimeClock}/history`,
            show: true
          }
        ]
      }
    ]
  },
  [MainMenuEnum.CommScheduleInspection]: null,
  [MainMenuEnum.CommSchedule]: null,
  [MainMenuEnum.CommScheduleClosure]: null,
  [MainMenuEnum.CommSchedulePermit]: null,
  [MainMenuEnum.CommScheduleRebate]: null,
  [MainMenuEnum.Setting]: null,
  [MainMenuEnum.AdminPanel]: {
    category: 'navigation.sidebar.category.admin-panel',
    icon: 'icon-project-request.svg',
    group: [
      {
        name: 'navigation.sidebar.group.contractors',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.contractor-list',
            route: `${MainMenuRouteEnum.AdminPanel}/contractors`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.accounts',
        icon: 'icon-permits.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.archon-employees',
            route: `${MainMenuRouteEnum.AdminPanel}/archon-employees`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.accounts',
            route: `${MainMenuRouteEnum.AdminPanel}/accounts`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.general-contacts',
            route: `${MainMenuRouteEnum.AdminPanel}/general-contacts`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.company-directory',
            route: `${MainMenuRouteEnum.AdminPanel}/company-directory`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.roles',
            route: `${MainMenuRouteEnum.AdminPanel}/roles`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.permissions',
            route: `${MainMenuRouteEnum.AdminPanel}/permissions`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.billing-info',
        icon: 'icon-billing.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.price-list',
            route: `${MainMenuRouteEnum.AdminPanel}/prices`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.company-pricing',
            route: `${MainMenuRouteEnum.AdminPanel}/company-prices`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.price-categories',
            route: `${MainMenuRouteEnum.AdminPanel}/price-categories`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.price-services',
            route: `${MainMenuRouteEnum.AdminPanel}/price-services`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.bonus-pricing',
            route: `${MainMenuRouteEnum.AdminPanel}/bonus-pricing`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.permit-info',
        icon: 'icon-inspections.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.building-dep-note',
            route: `${MainMenuRouteEnum.AdminPanel}/building-department-notes`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.zones',
            route: `${MainMenuRouteEnum.AdminPanel}/zones`,
            show: true
          }
        ]
      }
    ]
  },
  [MainMenuEnum.CompanyProfile]: {
    category: 'navigation.sidebar.category.business-profile',
    icon: 'icon-project-request.svg',
    group: [
      {
        name: 'navigation.sidebar.group.company-profile',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.company-info',
            route: `${MainMenuRouteEnum.CompanyProfile}/company-info`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.branches',
            route: `${MainMenuRouteEnum.CompanyProfile}/branches`,
            show: false
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.accounts-contacts',
        icon: 'icon-permits.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.company-employees',
            route: `${MainMenuRouteEnum.CompanyProfile}/company-employees`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.accounts',
            route: `${MainMenuRouteEnum.CompanyProfile}/accounts`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.general-contacts',
            route: `${MainMenuRouteEnum.CompanyProfile}/general-contacts`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.company-directory',
            route: `${MainMenuRouteEnum.CompanyProfile}/company-directory`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.billing-info',
        icon: 'icon-billing.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.payment-info',
            route: `${MainMenuRouteEnum.CompanyProfile}/payment-info`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.pricing-info',
            route: `${MainMenuRouteEnum.CompanyProfile}/pricing-info`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.settings',
        icon: 'icon-inspections.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.general-settings',
            route: `${MainMenuRouteEnum.CompanyProfile}/settings`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.notifications',
            route: `${MainMenuRouteEnum.CompanyProfile}/notifications`,
            show: true
          }
        ]
      }
    ]
  },
  [MainMenuEnum.ProjectOption]: {
    category: 'navigation.sidebar.category.project-options',
    icon: 'icon-project-request.svg',
    group: [
      {
        name: 'navigation.sidebar.group.company-associates',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.home-owners',
            route: `${MainMenuRouteEnum.ProjectOption}/home-owners`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.sales-persons',
            route: `${MainMenuRouteEnum.ProjectOption}/sales-persons`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.installers',
            route: `${MainMenuRouteEnum.ProjectOption}/installers`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.inspection-options',
        icon: 'icon-permits.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.title-24-options',
            route: `${MainMenuRouteEnum.ProjectOption}/title-24-options`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.rebate-companies',
            route: `${MainMenuRouteEnum.ProjectOption}/rebate-companies`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.home-energy-score',
            route: `${MainMenuRouteEnum.ProjectOption}/home-energy-score`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.permit-options',
        icon: 'icon-inspections.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.authorization-letters',
            route: `${MainMenuRouteEnum.ProjectOption}/authorization-letters`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.permit-contracts',
            route: `${MainMenuRouteEnum.ProjectOption}/permit-contracts`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.business-licenses',
            route: `${MainMenuRouteEnum.ProjectOption}/business-licenses`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.building-dep-note',
            route: `${MainMenuRouteEnum.ProjectOption}/building-department-notes`,
            show: true
          }
        ]
      }
    ]
  },
  [MainMenuEnum.TechniciansApp]: {
    category: 'navigation.sidebar.category.technicians-app',
    icon: 'icon-project-request.svg',
    group: [
      {
        name: 'navigation.sidebar.group.inspection-agreement',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.inspection-agreement',
            route: `${MainMenuRouteEnum.TechniciansApp}/inspection-agreement/`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.home-score-collection',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.general',
            route: `${MainMenuRouteEnum.TechniciansApp}/general/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.roof-attic',
            route: `${MainMenuRouteEnum.TechniciansApp}/roof-attic/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.foundation',
            route: `${MainMenuRouteEnum.TechniciansApp}/foundation/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.walls',
            route: `${MainMenuRouteEnum.TechniciansApp}/walls/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.windows',
            route: `${MainMenuRouteEnum.TechniciansApp}/windows/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.heating',
            route: `${MainMenuRouteEnum.TechniciansApp}/heating/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.cooling',
            route: `${MainMenuRouteEnum.TechniciansApp}/cooling/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.duct',
            route: `${MainMenuRouteEnum.TechniciansApp}/duct/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.hot-water',
            route: `${MainMenuRouteEnum.TechniciansApp}/hot-water/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.pv-system',
            route: `${MainMenuRouteEnum.TechniciansApp}/pv-system/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.appliances',
            route: `${MainMenuRouteEnum.TechniciansApp}/appliances/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.outdoor-features',
            route: `${MainMenuRouteEnum.TechniciansApp}/outdoor-features/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.assessor-electrification',
            route: `${MainMenuRouteEnum.TechniciansApp}/assessor-electrification/`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.bpi-data-collection',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.caz-testing',
            route: `${MainMenuRouteEnum.TechniciansApp}/caz-testing/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.infiltration-results',
            route: `${MainMenuRouteEnum.TechniciansApp}/infiltration-results/`,
            show: true
          }
        ]
      },
      {
        name: 'navigation.sidebar.group.t24-data-collection',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.install-verification',
            route: `${MainMenuRouteEnum.TechniciansApp}/systems/`,
            show: true
          }
        ]
      }
    ]
  },
  [MainMenuEnum.T24DataCollection]: {
    category: 'navigation.sidebar.category.technicians-app',
    icon: 'icon-project-request.svg',
    group: [
      {
        name: 'navigation.sidebar.group.t24-data-collection',
        icon: 'icon-general-info.svg',
        show: true,
        items: [
          {
            name: 'navigation.sidebar.item.install-verification',
            route: `${MainMenuRouteEnum.T24DataCollection}/install-verification/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.equipment-info',
            route: `${MainMenuRouteEnum.T24DataCollection}/equipment-info/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.installed-duct-system-info',
            route: `${MainMenuRouteEnum.T24DataCollection}/installed-duct-system-info/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.duct-leakage-verification',
            route: `${MainMenuRouteEnum.T24DataCollection}/duct-leakage-verification/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.airflow-verification',
            route: `${MainMenuRouteEnum.T24DataCollection}/airflow-verification/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.fan-watt-draw',
            route: `${MainMenuRouteEnum.T24DataCollection}/fan-watt-draw/`,
            show: true
          },
          {
            name: 'navigation.sidebar.item.refrigerant-verification',
            route: `${MainMenuRouteEnum.T24DataCollection}/refrigerant-verification/`,
            show: true
          }
        ]
      }
    ]
  },
  [MainMenuEnum.SystemsList]: null,
  [MainMenuEnum.Cheers]: null,
  [MainMenuEnum.ManageProjects]: null,
  [MainMenuEnum.ProjectInspections]: null,
  [MainMenuEnum.ProjectInspectionSteps]: null,
  [MainMenuEnum.Reports]: null,
  [MainMenuEnum.ReportsHomeScore]: null,
  [MainMenuEnum.ReportsT24]: null,
  [MainMenuEnum.ReportsBayren]: null,
  [MainMenuEnum.ReportsSmud]: null,
  [MainMenuEnum.ReportsInspectionAgreement]: null
};
