import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import Rmap from 'ramda/es/map';
import RmergeDeepLeft from 'ramda/es/mergeDeepLeft';
import Rreduce from 'ramda/es/reduce';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';

import { ArchonTranslateLoaderConfig } from '../translate-loader.config';

@Injectable({
  providedIn: 'root'
})
export class ArchonTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private config: ArchonTranslateLoaderConfig) {}

  public getTranslation(): Observable<any> {
    return forkJoin(
      Rmap(
        (dictionary: string): object =>
          this.http.get(`${this.config.dictionaryPath}/${dictionary}.json`).pipe(
            catchError(() => of({})),
            publishReplay(1),
            refCount()
          ),
        this.config.dictionaries
      )
    ).pipe(map(Rreduce(RmergeDeepLeft, {})));
  }
}
