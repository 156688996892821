import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import { FileModel, MessageResponseModel } from '@shared/models';
import { fileToFormData, getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class FileUploadHttpService {
  constructor(private http: HttpClient) {}

  public uploadLogo(id: number, file: File): Observable<{ logo: FileModel }> {
    return this.http.post<{ logo: FileModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.UploadLogo, id),
      fileToFormData(file)
    );
  }

  public uploadCompanyDocument(id: number, file: File): Observable<any> {
    return this.http.post<{ document: FileModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.UploadCompanyDocument,
        id
      ),
      fileToFormData(file),
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  public removeCompanyDocument(id: number, fileId: number): Observable<MessageResponseModel> {
    return this.http.delete<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.RemoveCompanyDocument,
        id,
        fileId
      )
    );
  }

  public uploadAvatar(id: number, file: File): Observable<{ avatar: FileModel }> {
    return this.http.post<{ avatar: FileModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.UploadAvatar, id),
      fileToFormData(file)
    );
  }

  public uploadUserProfileDocument(id: number, file: File): Observable<any> {
    return this.http.post<{ document: FileModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.UploadUserProfileDocument,
        id
      ),
      fileToFormData(file),
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  public removeUserProfileDocument(id: number, fileId: number): Observable<MessageResponseModel> {
    return this.http.delete<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.RemoveUserProfileDocument,
        id,
        fileId
      )
    );
  }

  public uploadBuildingDepartmentDocument(id: number, file: File): Observable<any> {
    return this.http.post<{ document: FileModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.UploadBuildingDepartmentDocument,
        id
      ),
      fileToFormData(file),
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  public updateBuildingDepartmentDocument(
    id: number,
    fileId: number,
    fileName: string
  ): Observable<MessageResponseModel> {
    return this.http.patch<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.RemoveBuildingDepartmentDocument,
        id,
        fileId
      ),
      { file_name: fileName },
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeBuildingDepartmentDocument(
    id: number,
    fileId: number
  ): Observable<MessageResponseModel> {
    return this.http.delete<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.RemoveBuildingDepartmentDocument,
        id,
        fileId
      )
    );
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    fileId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (fileId) {
      path = path.replace(':fileId', fileId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
