import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  BuildingDepartmentModel,
  BusinessLicenseFormModel,
  BusinessLicenseListResponseModel,
  BusinessLicenseModel,
  MessageResponseModel,
  PaginationModel,
  PaginationQueryModel,
  UpdateBuildingDepartmentModel
} from '@shared/models';
import {
  flattenBusinessLicensesWithPagination,
  getHttpHeaders,
  objToHttpParams
} from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class BuildingDepartmentHttpService {
  constructor(private http: HttpClient) {}

  public getBuildingDepartmentList(): Observable<{
    building_departments: BuildingDepartmentModel[];
  }> {
    return this.http.get<{ building_departments: BuildingDepartmentModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.BuildingDepartments),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getBuildingDepartment(
    id: number
  ): Observable<{
    building_department: BuildingDepartmentModel;
  }> {
    return this.http.get<{ building_department: BuildingDepartmentModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.BuildingDepartment, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateBuildingDepartment(
    id: number,
    buildingDepartment: UpdateBuildingDepartmentModel
  ): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.BuildingDepartmentNote,
        id
      ),
      buildingDepartment,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getBusinessLicenses(
    id: number,
    queryParams: PaginationQueryModel
  ): Observable<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }> {
    return this.http
      .get<BusinessLicenseListResponseModel>(
        this.getEndpointWithRouteBinding(
          environment.API_URL,
          ApiResourceEnum.BusinessLicensesPaginate,
          id
        ),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenBusinessLicensesWithPagination));
  }

  public createBusinessLicense(
    businessLicense: BusinessLicenseFormModel
  ): Observable<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }> {
    return this.http
      .post<BusinessLicenseListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.BusinessLicenseCreate),
        businessLicense,
        {
          headers: getHttpHeaders()
        }
      )
      .pipe(map(flattenBusinessLicensesWithPagination));
  }

  public updateBusinessLicense(
    id: number,
    businessLicense: BusinessLicenseFormModel
  ): Observable<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }> {
    return this.http
      .put<BusinessLicenseListResponseModel>(
        this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.BusinessLicense, id),
        businessLicense,
        {
          headers: getHttpHeaders()
        }
      )
      .pipe(map(flattenBusinessLicensesWithPagination));
  }

  public removeBusinessLicense(
    id: number
  ): Observable<{ business_licenses: BusinessLicenseModel[]; pagination: PaginationModel }> {
    return this.http
      .delete<BusinessLicenseListResponseModel>(
        this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.BusinessLicense, id),
        {
          headers: getHttpHeaders()
        }
      )
      .pipe(map(flattenBusinessLicensesWithPagination));
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    routeBinding: number
  ): string {
    return `${baseUrl}/${resource.replace(':id', routeBinding.toString())}`;
  }
}
