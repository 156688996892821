import { CompanyStatusEnum, MainMenuRouteEnum, PermissionEnum } from '@shared/enums';
import { PermissionModel, SidebarCategoryModel, UserProfileModel } from '@shared/models';

export const getCompanyProfileMenu = (
  permissions: PermissionModel[],
  userProfile: UserProfileModel
): SidebarCategoryModel => ({
  category: 'navigation.sidebar.category.business-profile',
  icon: 'icon-project-request.svg',
  group: [
    {
      name: 'navigation.sidebar.group.company-profile',
      icon: 'icon-general-info.svg',
      show: userProfile.company_status !== CompanyStatusEnum.Restricted,
      items: [
        {
          name: 'navigation.sidebar.item.company-info',
          route: `${MainMenuRouteEnum.CompanyProfile}/company-info`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.branches',
          route: `${MainMenuRouteEnum.CompanyProfile}/branches`,
          show: userProfile.company_status !== CompanyStatusEnum.Restricted &&
            permissions.some(({ name }) => name === PermissionEnum.CanSeeBranches)
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.accounts-contacts',
      icon: 'icon-permits.svg',
      show: userProfile.company_status !== CompanyStatusEnum.Restricted,
      items: [
        {
          name: 'navigation.sidebar.item.company-employees',
          route: `${MainMenuRouteEnum.CompanyProfile}/company-employees`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeCompanyEmployees)
        },
        {
          name: 'navigation.sidebar.item.my-profile',
          route: `${MainMenuRouteEnum.CompanyProfile}/company-employees/view/${userProfile.id}`,
          show: permissions.filter(item => item.name === PermissionEnum.CanSeeCompanyEmployees).length === 0
        },
        {
          name: 'navigation.sidebar.item.accounts',
          route: `${MainMenuRouteEnum.CompanyProfile}/accounts`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeCompanyAccounts)
        },
        {
          name: 'navigation.sidebar.item.my-account',
          route: `${MainMenuRouteEnum.CompanyProfile}/accounts/view/${userProfile.id}`,
          show: permissions.filter(item => item.name === PermissionEnum.CanSeeCompanyAccounts).length === 0
        },
        {
          name: 'navigation.sidebar.item.general-contacts',
          route: `${MainMenuRouteEnum.CompanyProfile}/general-contacts`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.company-directory',
          route: `${MainMenuRouteEnum.CompanyProfile}/company-directory`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.billing-info',
      icon: 'icon-billing.svg',
      show: permissions.some(({ name }) => name === PermissionEnum.CanSeeBillingInfo),
      items: [
        {
          name: 'navigation.sidebar.item.payment-info',
          route: `${MainMenuRouteEnum.CompanyProfile}/payment-info`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.pricing-info',
          route: `${MainMenuRouteEnum.CompanyProfile}/pricing-info`,
          show: true
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.settings',
      icon: 'icon-inspections.svg',
      show: userProfile.company_status !== CompanyStatusEnum.Restricted,
      items: [
        {
          name: 'navigation.sidebar.item.general-settings',
          route: `${MainMenuRouteEnum.CompanyProfile}/settings`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeGeneralSettings)
        },
        // {
        //   name: 'navigation.sidebar.item.notifications',
        //   route: `${MainMenuRouteEnum.CompanyProfile}/notifications`,
        //   show: true
        // }
      ]
    }
  ]
});
