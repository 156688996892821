import { createAction, props, union } from '@ngrx/store';

import {
  ApplianceModel,
  AssessorElectrificationModel,
  CoolingModel,
  DuctModel,
  FoundationModel,
  GeneralModel,
  HeatingModel,
  HotWaterModel,
  OutdoorFeaturesModel,
  PvSystemModel,
  RoofAtticModel,
  UpdateAppliancesFormModel,
  UpdateCoolingFormModel,
  UpdateDuctFormModel,
  UpdateFoundationFormModel,
  UpdateHeatingFormModel,
  UpdateRoofAtticFormModel,
  UpdateWallsFormModel,
  WallModel,
  WindowsModel
} from '@shared/models';

const fetchGeneralStartAction = createAction('[HomeScore] Fetch general', props<{ id: number }>());

const fetchGeneralSuccessAction = createAction(
  '[HomeScore] Fetch general success',
  props<{ general: GeneralModel }>()
);

const fetchGeneralFailedAction = createAction('[HomeScore] Fetch general failed');

const resetGeneralAction = createAction('[HomeScore] Reset general');

const createGeneralAction = createAction(
  '[HomeScore] Create general',
  props<{ create: GeneralModel }>()
);

const createGeneralSuccessAction = createAction(
  '[HomeScore] Create general success',
  props<{ general: GeneralModel }>()
);

const updateGeneralAction = createAction(
  '[HomeScore] Update general',
  props<{ id: number; update: GeneralModel }>()
);

const updateGeneralSuccessAction = createAction(
  '[HomeScore] Update general success',
  props<{ general: GeneralModel }>()
);

const fetchHotWaterStartAction = createAction(
  '[HomeScore] Fetch hot water',
  props<{ id: number }>()
);

const fetchHotWaterSuccessAction = createAction(
  '[HomeScore] Fetch hot water success',
  props<{ hotWater: HotWaterModel }>()
);

const fetchHotWaterFailedAction = createAction('[HomeScore] Fetch hot water failed');

const resetHotWaterAction = createAction('[HomeScore] Reset hot water');

const updateHotWaterStartAction = createAction(
  '[HomeScore] Update hot water',
  props<{ id: number; update: HotWaterModel }>()
);

const updateHotWaterSuccessAction = createAction(
  '[HomeScore] Update hot water success',
  props<{ hotWater: HotWaterModel }>()
);

const updateHotWaterFailedAction = createAction('[HomeScore] Update hot water failed');

const createHotWaterStartAction = createAction(
  '[HomeScore] Create hot water',
  props<{ create: HotWaterModel }>()
);

const createHotWaterSuccessAction = createAction(
  '[HomeScore] Create hot water success',
  props<{ hotWater: HotWaterModel }>()
);

const createHotWaterFailedAction = createAction('[HomeScore] Create hot water failed');

const fetchPvSystemStartAction = createAction(
  '[HomeScore] Fetch pv system',
  props<{ id: number }>()
);

const fetchPvSystemSuccessAction = createAction(
  '[HomeScore] Fetch pv system success',
  props<{ pvSystem: PvSystemModel }>()
);

const fetchPvSystemFailedAction = createAction('[HomeScore] Fetch pv system failed');

const resetPvSystemAction = createAction('[HomeScore] Reset pv system');

const updatePvSystemStartAction = createAction(
  '[HomeScore] Update pv system',
  props<{ id: number; update: PvSystemModel }>()
);

const updatePvSystemSuccessAction = createAction(
  '[HomeScore] Update pv system success',
  props<{ pvSystem: PvSystemModel }>()
);

const updatePvSystemFailedAction = createAction('[HomeScore] Update pv system failed');

const createPvSystemStartAction = createAction(
  '[HomeScore] Create pv system',
  props<{ create: PvSystemModel }>()
);

const createPvSystemSuccessAction = createAction(
  '[HomeScore] Create pv system success',
  props<{ pvSystem: PvSystemModel }>()
);

const createPvSystemFailedAction = createAction('[HomeScore] Create pv system failed');

const fetchOutdoorFeaturesStartAction = createAction(
  '[HomeScore] Fetch outdoor features',
  props<{ id: number }>()
);

const fetchOutdoorFeaturesSuccessAction = createAction(
  '[HomeScore] Fetch outdoor features success',
  props<{ outdoorFeatures: OutdoorFeaturesModel }>()
);

const fetchOutdoorFeaturesFailedAction = createAction('[HomeScore] Fetch outdoor features failed');

const resetOutdoorFeaturesAction = createAction('[HomeScore] Reset outdoor features');

const updateOutdoorFeaturesStartAction = createAction(
  '[HomeScore] Update outdoor features',
  props<{ id: number; update: OutdoorFeaturesModel }>()
);

const updateOutdoorFeaturesSuccessAction = createAction(
  '[HomeScore] Update outdoor features success',
  props<{ outdoorFeatures: OutdoorFeaturesModel }>()
);

const updateOutdoorFeaturesFailedAction = createAction(
  '[HomeScore] Update outdoor features failed'
);

const createOutdoorFeaturesStartAction = createAction(
  '[HomeScore] Create outdoor features',
  props<{ create: OutdoorFeaturesModel }>()
);

const createOutdoorFeaturesSuccessAction = createAction(
  '[HomeScore] Create outdoor features success',
  props<{ outdoorFeatures: OutdoorFeaturesModel }>()
);

const createOutdoorFeaturesFailedAction = createAction(
  '[HomeScore] Create outdoor features failed'
);

const fetchRoofAtticStartAction = createAction(
  '[HomeScore] Fetch roof / attic',
  props<{ id: number }>()
);

const fetchRoofAtticSuccessAction = createAction(
  '[HomeScore] Fetch roof attic success',
  props<{ insulation_zones: RoofAtticModel[] }>()
);

const fetchRoofAtticFailedAction = createAction('[HomeScore] Fetch roof / attic failed');

const resetRoofAtticAction = createAction('[HomeScore] Reset roof / attic');

const updateRoofAtticAction = createAction(
  '[HomeScore] Update roof attic',
  props<{ update: UpdateRoofAtticFormModel }>()
);

const updateRoofAtticSuccessAction = createAction(
  '[HomeScore] Update roof attic success',
  props<{ insulation_zones: RoofAtticModel[] }>()
);

const removeRoofAtticAction = createAction(
  '[HomeScore] Remove roof attic',
  props<{ id: number }>()
);

const removeRoofAtticSuccessAction = createAction('[HomeScore] Remove roof attic success');

const fetchFoundationStartAction = createAction(
  '[HomeScore] Fetch foundation',
  props<{ id: number }>()
);

const fetchFoundationSuccessAction = createAction(
  '[HomeScore] Fetch foundation success',
  props<{ foundations: FoundationModel[] }>()
);

const fetchFoundationFailedAction = createAction('[HomeScore] Fetch foundation failed');

const resetFoundationAction = createAction('[HomeScore] Reset foundation');

const updateFoundationAction = createAction(
  '[HomeScore] Update foundation',
  props<{ update: UpdateFoundationFormModel }>()
);

const updateFoundationSuccessAction = createAction(
  '[HomeScore] Update foundation success',
  props<{ foundations: FoundationModel[] }>()
);

const removeFoundationAction = createAction(
  '[HomeScore] Remove foundation',
  props<{ id: number }>()
);

const removeFoundationSuccessAction = createAction('[HomeScore] Remove foundation success');

const fetchWallsStartAction = createAction('[HomeScore] Fetch walls', props<{ id: number }>());

const fetchWallsSuccessAction = createAction(
  '[HomeScore] Fetch walls success',
  props<{ walls: WallModel[] }>()
);

const fetchWallsFailedAction = createAction('[HomeScore] Fetch walls failed');

const resetWallsAction = createAction('[HomeScore] Reset walls');

const updateWallsAction = createAction(
  '[HomeScore] Update walls',
  props<{ update: UpdateWallsFormModel }>()
);

const updateWallsSuccessAction = createAction(
  '[HomeScore] Update walls success',
  props<{ walls: WallModel[] }>()
);

const fetchHeatingStartAction = createAction('[HomeScore] Fetch heating', props<{ id: number }>());

const fetchHeatingSuccessAction = createAction(
  '[HomeScore] Fetch heating success',
  props<{ heatings: HeatingModel[] }>()
);

const fetchHeatingFailedAction = createAction('[HomeScore] Fetch heating failed');

const resetHeatingAction = createAction('[HomeScore] Reset heating');

const updateHeatingAction = createAction(
  '[HomeScore] Update heating',
  props<{ update: UpdateHeatingFormModel }>()
);

const updateHeatingSuccessAction = createAction(
  '[HomeScore] Update heating success',
  props<{ heatings: HeatingModel[] }>()
);

const removeHeatingAction = createAction('[HomeScore] Remove heating', props<{ id: number }>());

const removeHeatingSuccessAction = createAction('[HomeScore] Remove heating success');

const fetchCoolingStartAction = createAction('[HomeScore] Fetch cooling', props<{ id: number }>());

const fetchCoolingSuccessAction = createAction(
  '[HomeScore] Fetch cooling success',
  props<{ coolings: CoolingModel[] }>()
);

const fetchCoolingFailedAction = createAction('[HomeScore] Fetch cooling failed');

const resetCoolingAction = createAction('[HomeScore] Reset cooling');

const updateCoolingAction = createAction(
  '[HomeScore] Update cooling',
  props<{ update: UpdateCoolingFormModel }>()
);

const updateCoolingSuccessAction = createAction(
  '[HomeScore] Update cooling success',
  props<{ coolings: CoolingModel[] }>()
);

const removeCoolingAction = createAction('[HomeScore] Remove cooling', props<{ id: number }>());

const removeCoolingSuccessAction = createAction('[HomeScore] Remove cooling success');

const fetchDuctStartAction = createAction('[HomeScore] Fetch duct', props<{ id: number }>());

const fetchDuctSuccessAction = createAction(
  '[HomeScore] Fetch duct success',
  props<{ ducts: DuctModel[] }>()
);

const fetchDuctFailedAction = createAction('[HomeScore] Fetch duct failed');

const resetDuctAction = createAction('[HomeScore] Reset duct');

const updateDuctAction = createAction(
  '[HomeScore] Update duct',
  props<{ update: UpdateDuctFormModel }>()
);

const updateDuctSuccessAction = createAction(
  '[HomeScore] Update duct success',
  props<{ ducts: DuctModel[] }>()
);

const removeDuctAction = createAction('[HomeScore] Remove duct', props<{ id: number }>());

const removeDuctSuccessAction = createAction('[HomeScore] Remove duct success');

const fetchAppliancesStartAction = createAction(
  '[HomeScore] Fetch appliances',
  props<{ id: number }>()
);

const fetchAppliancesSuccessAction = createAction(
  '[HomeScore] Fetch appliances success',
  props<{ appliances: ApplianceModel[] }>()
);

const fetchAppliancesFailedAction = createAction('[HomeScore] Fetch appliances failed');

const resetAppliancesAction = createAction('[HomeScore] Reset appliances');

const updateAppliancesAction = createAction(
  '[HomeScore] Update appliances',
  props<{ update: UpdateAppliancesFormModel }>()
);

const updateAppliancesSuccessAction = createAction(
  '[HomeScore] Update appliances success',
  props<{ appliances: ApplianceModel[] }>()
);

const removeAppliancesAction = createAction(
  '[HomeScore] Remove appliances',
  props<{ id: number }>()
);

const removeAppliancesSuccessAction = createAction('[HomeScore] Remove appliances success');

const fetchWindowsStartAction = createAction('[HomeScore] Fetch windows', props<{ id: number }>());

const fetchWindowsSuccessAction = createAction(
  '[HomeScore] Fetch windows success',
  props<{ windows: WindowsModel }>()
);

const fetchWindowsFailedAction = createAction('[HomeScore] Fetch windows failed');

const resetWindowsAction = createAction('[HomeScore] Reset windows');

const updateWindowsAction = createAction(
  '[HomeScore] Update windows',
  props<{ update: WindowsModel }>()
);

const updateWindowsSuccessAction = createAction(
  '[HomeScore] Update windows success',
  props<{ windows: WindowsModel }>()
);

const fetchAssessorElectrificationStartAction = createAction(
  '[HomeScore] Fetch assessor electrification',
  props<{ id: number }>()
);

const fetchAssessorElectrificationSuccessAction = createAction(
  '[HomeScore] Fetch assessor electrification success',
  props<{ assessorElectrification: AssessorElectrificationModel }>()
);

const fetchAssessorElectrificationFailedAction = createAction(
  '[HomeScore] Fetch assessor electrification failed'
);

const resetAssessorElectrificationAction = createAction(
  '[HomeScore] Reset assessor electrification'
);

const updateAssessorElectrificationAction = createAction(
  '[HomeScore] Update assessor electrification',
  props<{ id: number; update: AssessorElectrificationModel }>()
);

const updateAssessorElectrificationSuccessAction = createAction(
  '[HomeScore] Update assessor electrification success',
  props<{ assessorElectrification: AssessorElectrificationModel }>()
);

const createAssessorElectrificationStartAction = createAction(
  '[HomeScore] Create assessor electrification',
  props<{ create: AssessorElectrificationModel }>()
);

const createAssessorElectrificationSuccessAction = createAction(
  '[HomeScore] Create assessor electrification success',
  props<{ assessorElectrification: AssessorElectrificationModel }>()
);

const createAssessorElectrificationFailedAction = createAction(
  '[HomeScore] Create assessor electrification failed'
);

export const HomeScoreActions = {
  fetchGeneralStartAction,
  fetchGeneralSuccessAction,
  fetchGeneralFailedAction,
  resetGeneralAction,
  createGeneralAction,
  createGeneralSuccessAction,
  updateGeneralAction,
  updateGeneralSuccessAction,
  fetchHotWaterStartAction,
  fetchHotWaterSuccessAction,
  fetchHotWaterFailedAction,
  resetHotWaterAction,
  updateHotWaterStartAction,
  updateHotWaterSuccessAction,
  updateHotWaterFailedAction,
  createHotWaterStartAction,
  createHotWaterSuccessAction,
  createHotWaterFailedAction,
  fetchPvSystemStartAction,
  fetchPvSystemSuccessAction,
  fetchPvSystemFailedAction,
  resetPvSystemAction,
  updatePvSystemStartAction,
  updatePvSystemSuccessAction,
  updatePvSystemFailedAction,
  createPvSystemStartAction,
  createPvSystemSuccessAction,
  createPvSystemFailedAction,
  fetchOutdoorFeaturesStartAction,
  fetchOutdoorFeaturesSuccessAction,
  fetchOutdoorFeaturesFailedAction,
  resetOutdoorFeaturesAction,
  updateOutdoorFeaturesStartAction,
  updateOutdoorFeaturesSuccessAction,
  updateOutdoorFeaturesFailedAction,
  createOutdoorFeaturesStartAction,
  createOutdoorFeaturesSuccessAction,
  createOutdoorFeaturesFailedAction,
  fetchRoofAtticStartAction,
  fetchRoofAtticSuccessAction,
  fetchRoofAtticFailedAction,
  resetRoofAtticAction,
  updateRoofAtticAction,
  updateRoofAtticSuccessAction,
  removeRoofAtticAction,
  removeRoofAtticSuccessAction,
  fetchFoundationStartAction,
  fetchFoundationSuccessAction,
  fetchFoundationFailedAction,
  resetFoundationAction,
  updateFoundationAction,
  updateFoundationSuccessAction,
  removeFoundationAction,
  removeFoundationSuccessAction,
  fetchWallsStartAction,
  fetchWallsSuccessAction,
  fetchWallsFailedAction,
  resetWallsAction,
  updateWallsAction,
  updateWallsSuccessAction,
  fetchHeatingStartAction,
  fetchHeatingSuccessAction,
  fetchHeatingFailedAction,
  resetHeatingAction,
  updateHeatingAction,
  updateHeatingSuccessAction,
  removeHeatingAction,
  removeHeatingSuccessAction,
  fetchCoolingStartAction,
  fetchCoolingSuccessAction,
  fetchCoolingFailedAction,
  resetCoolingAction,
  updateCoolingAction,
  updateCoolingSuccessAction,
  removeCoolingAction,
  removeCoolingSuccessAction,
  fetchDuctStartAction,
  fetchDuctSuccessAction,
  fetchDuctFailedAction,
  resetDuctAction,
  updateDuctAction,
  updateDuctSuccessAction,
  removeDuctAction,
  removeDuctSuccessAction,
  fetchAppliancesStartAction,
  fetchAppliancesSuccessAction,
  fetchAppliancesFailedAction,
  resetAppliancesAction,
  updateAppliancesAction,
  updateAppliancesSuccessAction,
  removeAppliancesAction,
  removeAppliancesSuccessAction,
  fetchWindowsStartAction,
  fetchWindowsSuccessAction,
  fetchWindowsFailedAction,
  resetWindowsAction,
  updateWindowsAction,
  updateWindowsSuccessAction,
  fetchAssessorElectrificationStartAction,
  fetchAssessorElectrificationSuccessAction,
  fetchAssessorElectrificationFailedAction,
  resetAssessorElectrificationAction,
  updateAssessorElectrificationAction,
  updateAssessorElectrificationSuccessAction,
  createAssessorElectrificationStartAction,
  createAssessorElectrificationSuccessAction,
  createAssessorElectrificationFailedAction
};

const homeScoreActionsUnion = union(HomeScoreActions);

export type HomeScoreActionsType = typeof homeScoreActionsUnion;
