export enum TaskEnum {
  EndDay = 'End Day',
  OnTheRoad = 'On The Road',
  Break = 'Break',
  Lunch = 'Lunch',
  OfficeTasks = 'Office Tasks',
  FieldTasks = 'Field Tasks',
  Note = 'Note'
}

export enum SubTaskEnum {
  Inspection = 'Inspection',
  PermitServices = 'Permit Services',
  VehicleMaintenance = 'Vehicle Maintenance',
  OfficeWork = 'Office Work',
  Meeting = 'Meeting',
  Other = 'Other'
}

export enum TaskIdEnum {
  EndDay = 1,
  OnTheRoad = 2,
  Break = 3,
  Lunch = 4,
  OfficeTasks = 5,
  FieldTasks = 6,
  Note = 7
}

export enum SubTaskIdEnum {
  Inspection = 1,
  PermitServices = 2,
  VehicleMaintenance = 3,
  OfficeWork = 4,
  Meeting = 5,
  Other = 6
}

export enum TaskSummaryEnum {
  Tasks = 'tasks',
  Breaks = 'breaks',
  Lunch = 'lunch',
  OnTheRoad = 'on_the_road'
}
