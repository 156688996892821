export enum EmailSettingsEnum {
  CertificateStatusCompleted = 'CertificateStatusCompleted',
  CompanyApproved = 'CompanyApproved',
  CompanyRegisteredNewAccount = 'CompanyRegisteredNewAccount',
  NewUserAddedToCompany = 'NewUserAddedToCompany',
  CustomerRequestInspectionCancel = 'CustomerRequestInspectionCancel',
  InspectionCancelled = 'InspectionCancelled',
  InspectionCompletedFailed = 'InspectionCompletedFailed',
  InspectionNoShow = 'InspectionNoShow',
  ClientEnterPermitNumberMail = 'ClientEnterPermitNumberMail',
  PermitAdditionalInfoNeeded = 'PermitAdditionalInfoNeeded',
  PermitClosureAbandoned = 'PermitClosureAbandoned',
  PermitClosureBuildingInspectionFailed = 'PermitClosureBuildingInspectionFailed',
  PermitHasBeenAbandoned = 'PermitHasBeenAbandoned',
  PermitIssued = 'PermitIssued',
  PermitNotEntered = 'PermitNotEntered',
  PermitReadyToBeMailed = 'PermitReadyToBeMailed',
  PermitServiceLeftUnpaidMail = 'PermitServiceLeftUnpaidMail',
  ClientCancelledProjectMail = 'ClientCancelledProjectMail',
  DraftProjectReminder = 'DraftProjectReminder',
  ProjectApproved = 'ProjectApproved',
  ProjectCanceled = 'ProjectCanceled',
  ProjectCancelRequest = 'ProjectCancelRequest',
  ProjectInvoiced = 'ProjectInvoiced',
  ProjectRestore = 'ProjectRestore',
  RebateStatusAdditionalInformationNeeded = 'RebateStatusAdditionalInformationNeeded',
  BuildingInspectionScheduled = 'BuildingInspectionScheduled',
  ClientScheduledInspection = 'ClientScheduledInspection',
  InspectionScheduled = 'InspectionScheduled',
  AccountApprovalConfirmationEmail = 'AccountApprovalConfirmationEmail',
  AccountResetPasswordEmail = 'AccountResetPasswordEmail',
  AccountVerifyEmail = 'AccountVerifyEmail',
  UserApproved = 'UserApproved'
}

export enum EmailSettingsSectionEnum {
  Payment,
  Schedule,
  Inspection,
  Rebate,
  Project,
  Expiration,
  Permit
}
