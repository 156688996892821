import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FormlyFieldsModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthFormService {
  private data$: Subject<FormlyFieldsModel> = new Subject();

  public getFormData(): Observable<FormlyFieldsModel> {
    return this.data$;
  }

  public setFormData(data: FormlyFieldsModel): void {
    this.data$.next(data);
  }
}
