import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ArrowTooltipComponent, ArrowTooltipDirective } from './arrow-tooltip';
import { PhoneMaskDirective } from './phone-mask';

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [ArrowTooltipComponent, ArrowTooltipDirective, PhoneMaskDirective],
  exports: [ArrowTooltipDirective, PhoneMaskDirective],
  entryComponents: [ArrowTooltipComponent]
})
export class CommonDirectiveModule {}
