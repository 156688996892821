export enum InputTypeEnum {
  Date = 'date',
  Datetime = 'datetime',
  DatetimeLocal = 'datetime-local',
  Email = 'email',
  Hidden = 'hidden',
  Month = 'month',
  Number = 'number',
  Password = 'password',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
  Url = 'url',
  Week = 'week'
}
