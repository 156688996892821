import { AbstractControl } from '@angular/forms';

export class ValidatePassword {
  public static MatchPassword(abstractControl: AbstractControl): boolean {
    const password = abstractControl.get('password').value;
    const confirmPassword = abstractControl.get('password_confirmation').value;
    if (password !== confirmPassword) {
      abstractControl.get('password_confirmation').setErrors({
        matchPassword: {
          field: 'Password'
        }
      });
    } else {
      return null;
    }
  }
}
