import { ProjectInspectionStepsModel } from '@shared/models';

export const getProjectInspectionWithUpdatedStatus = (
  completed: boolean,
  projectInspection: ProjectInspectionStepsModel
): ProjectInspectionStepsModel => ({
  ...projectInspection,
  completed
});

export const getProjectInspectionWithUpdatedNoShowStatus = (
  projectInspection: ProjectInspectionStepsModel
): ProjectInspectionStepsModel => ({
  ...projectInspection,
  no_show: true
});
