import { createAction, props, union } from '@ngrx/store';
import { CheersListModel, InspectionIdFormModel, ProjectRequestIdFormModel } from '@shared/models';

const fetchCheersListStartAction = createAction(
  '[Cheers] Fetch cheers list',
  props<{ id: number }>()
);

const fetchCheersListSuccessAction = createAction(
  '[Cheers] Fetch cheers list success',
  props<{ cheersList: CheersListModel }>()
);

const fetchCheersListFailedAction = createAction('[Cheers] Fetch cheers list failed');

const downloadDocumentUrStartAction = createAction(
  '[Cheers] Download document url started',
  props<{ id: number }>()
);

const downloadDocumentUrlSuccessAction = createAction(
  '[Cheers] Download document url success',
  props<{ url: string }>()
);

const downloadDocumentUrlFailedAction = createAction('[Cheers] Download document url failed');

const createCf1rCertificateStartAction = createAction(
  '[Cheers] Create cf1r certificate',
  props<{ create: ProjectRequestIdFormModel }>()
);

const createCf1rCertificateSuccessAction = createAction(
  '[Cheers] Create cf1r certificate success',
  props<{ cheersList: CheersListModel }>()
);

const createCf1rCertificateFailedAction = createAction('[Cheers] Create cf1r certificate failed');

const createCf2rCertificateStartAction = createAction(
  '[Cheers] Create cf2r certificate',
  props<{ create: InspectionIdFormModel }>()
);

const createCf2rCertificateSuccessAction = createAction(
  '[Cheers] Create cf2r certificate success',
  props<{ cheersList: CheersListModel }>()
);

const createCf2rCertificateFailedAction = createAction('[Cheers] Create cf2r certificate failed');

const createCf3rCertificateStartAction = createAction(
  '[Cheers] Create cf3r certificate',
  props<{ create: InspectionIdFormModel }>()
);

const createCf3rCertificateSuccessAction = createAction(
  '[Cheers] Create cf3r certificate success',
  props<{ cheersList: CheersListModel }>()
);

const createCf3rCertificateFailedAction = createAction('[Cheers] Create cf3r certificate failed');

const createAllCertificateStartAction = createAction(
  '[Cheers] Create all certificate',
  props<{ id: number }>()
);

const createAllCertificateSuccessAction = createAction(
  '[Cheers] Create all certificate success',
  props<{ url: string }>()
);

const createAllCertificateFailedAction = createAction('[Cheers] Create all certificate failed');

const deleteDocumentStartAction = createAction('[Cheers] Delete document', props<{ id: number }>());

const deleteDocumentSuccessAction = createAction(
  '[Cheers] Delete document success',
  props<{ cheersList: CheersListModel }>()
);

const deleteDocumentFailedAction = createAction('[Cheers] Delete document failed');

const resetUrlAction = createAction('[Cheers] Reset url');

export const CheersActions = {
  fetchCheersListStartAction,
  fetchCheersListSuccessAction,
  fetchCheersListFailedAction,
  downloadDocumentUrStartAction,
  downloadDocumentUrlSuccessAction,
  downloadDocumentUrlFailedAction,
  createCf1rCertificateStartAction,
  createCf1rCertificateSuccessAction,
  createCf1rCertificateFailedAction,
  createCf2rCertificateStartAction,
  createCf2rCertificateSuccessAction,
  createCf2rCertificateFailedAction,
  createCf3rCertificateStartAction,
  createCf3rCertificateSuccessAction,
  createCf3rCertificateFailedAction,
  createAllCertificateStartAction,
  createAllCertificateSuccessAction,
  createAllCertificateFailedAction,
  deleteDocumentStartAction,
  deleteDocumentSuccessAction,
  deleteDocumentFailedAction,
  resetUrlAction
};

const cheersActionsUnion = union(CheersActions);

export type CheersActionsType = typeof cheersActionsUnion;
