import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  combineLatest,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import { RoleEnum } from '@shared/enums';
import { CompanyHttpService, UserHttpService } from '@shared/services';
import { getMainMenu, getSidebarMenu, toggleSubmenu } from '@shared/utils';
import { AuthActions } from '@store/auth/auth.actions';
import {
  getIsRoleSuperAdminState,
  getUserPermissionsState,
  getUserProfileState,
  getUserProfileTypeState, getUserValidationsState
} from '@store/auth/auth.selectors';
import { MessageActions } from '@store/message/message.actions';
import { LayoutActions } from './layout.actions';
import { getMainMenuState } from './layout.selectors';
import { LayoutState } from './layout.state';

@Injectable()
export class LayoutEffects {
  /**
   * @route /
   * @listen [Layout] Init main menu
   * @dispatch [Layout] Set main menu
   */
  public initMainMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.initMainMenuAction),
      combineLatest(
        this.store.select(getUserProfileTypeState),
        this.store.select(getUserPermissionsState),
        this.store.select(getUserProfileState),
        this.store.select(getUserValidationsState),
        this.store.select(getIsRoleSuperAdminState)
      ),
      mergeMap(([{ id }, userType, permissions, userProfile, userValidations]) => [
        LayoutActions.setMainMenuAction({
          main_menu: getMainMenu(userType, permissions, userProfile, userValidations)
        }),
        LayoutActions.setSidebarMenuAction({ sidebar: getSidebarMenu(id, permissions, userProfile) })
      ])
    )
  );
  /**
   * @route /
   * @listen [Layout] Open submenu
   * @dispatch [Layout] Set main menu
   */
  public openSubmenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.openSubmenuAction),
      withLatestFrom(this.store.select(getMainMenuState)),
      map(([{ id }, menu]) =>
        LayoutActions.setMainMenuAction({ main_menu: toggleSubmenu(menu, id) })
      )
    )
  );
  /**
   * @route /
   * @listen [Auth] Get user success
   * @dispatch [Layout] Set company dropdown list
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public fetchDropdownCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserSuccessAction),
      switchMap(() =>
        this.companyHttpService.getCompanyDropdownList().pipe(
          mergeMap(({ companies }) => [
            LayoutActions.setCompanyDropdownListAction({ companies }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );

  /**
   * @route /
   * @listen [Auth] Get user success
   * @dispatch [Layout] Set employee dropdown list
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public fetchDropdownArchonEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserSuccessAction),
      filter(({ user }) => user.role && user.role.name === RoleEnum.SuperUser),
      switchMap(() =>
        this.userHttpService.getArchonEmployeeDropdownList().pipe(
          mergeMap(({ user_profiles }) => [
            LayoutActions.setEmployeeDropdownListAction({ user_profiles }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MessageActions.handleErrorAction({ errorResponse }))
          )
        )
      )
    )
  );

  constructor(
    private store: Store<LayoutState>,
    private actions$: Actions,
    private companyHttpService: CompanyHttpService,
    private userHttpService: UserHttpService
  ) {}
}
