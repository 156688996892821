import { ChartDataSets } from 'chart.js';

import { TaskEnum, TaskIdEnum, TaskSummaryEnum } from '../enums/task.enum';

export const TaskSummaryMap: { [key in TaskSummaryEnum]: ChartDataSets } = {
  [TaskSummaryEnum.Tasks]: {
    data: [],
    label: 'Tasks',
    backgroundColor: '#4d9ed5',
    hoverBackgroundColor: '#4d9ed5'
  },
  [TaskSummaryEnum.Breaks]: {
    data: [],
    label: 'Break',
    backgroundColor: '#dc3535',
    hoverBackgroundColor: '#dc3535'
  },
  [TaskSummaryEnum.Lunch]: {
    data: [],
    label: 'Lunch',
    backgroundColor: '#ff7403',
    hoverBackgroundColor: '#ff7403'
  },
  [TaskSummaryEnum.OnTheRoad]: {
    data: [],
    label: 'On the Road',
    backgroundColor: '#f1ac3c',
    hoverBackgroundColor: '#f1ac3c'
  }
};

export const TaskMap: { [key in TaskEnum]: TaskIdEnum } = {
  [TaskEnum.EndDay]: TaskIdEnum.EndDay,
  [TaskEnum.OnTheRoad]: TaskIdEnum.OnTheRoad,
  [TaskEnum.Break]: TaskIdEnum.Break,
  [TaskEnum.Lunch]: TaskIdEnum.Lunch,
  [TaskEnum.OfficeTasks]: TaskIdEnum.OfficeTasks,
  [TaskEnum.FieldTasks]: TaskIdEnum.FieldTasks,
  [TaskEnum.Note]: TaskIdEnum.Note
};
