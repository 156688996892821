import { createReducer, on } from '@ngrx/store';

import { AuthActions, AuthActionsType } from './auth.actions';
import { authInitialState, AuthState } from './auth.state';

const reducer = createReducer(
  authInitialState,
  on(
    AuthActions.loginSuccessAction,
    AuthActions.getUserSuccessAction,
    (state: AuthState, { user }): AuthState => ({
      ...state,
      ...user,
      isLoading: false
    })
  ),
  on(
    AuthActions.registerSuccessAction,
    AuthActions.passwordResetSuccessAction,
    AuthActions.passwordChangeSuccessAction,
    (state: AuthState): AuthState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    AuthActions.confirmEmailSuccessAction,
    (state: AuthState): AuthState => ({
      ...state,
      emailConfirmed: true
    })
  ),
  on(
    AuthActions.getUserAction,
    (state: AuthState): AuthState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    AuthActions.getUserFailedAction,
    (state: AuthState): AuthState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    AuthActions.logoutAction,
    (state: AuthState): AuthState => ({
      ...state,
      ...authInitialState
    })
  )
);

export function authReducer(state: AuthState, action: AuthActionsType): AuthState {
  return reducer(state, action);
}
