import { ReportsCountModel, ReportsListModel } from '@shared/models';

export interface ReportsState {
  reportsCount: ReportsCountModel;
  reportsList: ReportsListModel[];
  isLoading: boolean;
}

export const reportsInitialState: ReportsState = {
  reportsCount: null,
  reportsList: [],
  isLoading: false
};

export const REPORTS_FEATURE = 'reports';
