import { createReducer, on } from '@ngrx/store';

import {
  InspectionAgreementActions,
  InspectionAgreementActionsType
} from './inspection-agreement.actions';
import {
  inspectionAgreementInitialState,
  InspectionAgreementState
} from './inspection-agreement.state';

const reducer = createReducer(
  inspectionAgreementInitialState,
  on(
    InspectionAgreementActions.fetchInspectionAgreementStartAction,
    InspectionAgreementActions.updateInspectionAgreementStartAction,
    (state: InspectionAgreementState): InspectionAgreementState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    InspectionAgreementActions.fetchInspectionAgreementSuccessAction,
    InspectionAgreementActions.updateInspectionAgreementSuccessAction,
    (state: InspectionAgreementState, { inspectionAgreement }): InspectionAgreementState => ({
      ...state,
      inspectionAgreement,
      isLoading: false
    })
  ),
  on(
    InspectionAgreementActions.fetchInspectionAgreementFailedAction,
    InspectionAgreementActions.updateInspectionAgreementFailedAction,
    (state: InspectionAgreementState): InspectionAgreementState => ({
      ...state,
      isLoading: false
    })
  )
);

export function inspectionAgreementReducer(
  state: InspectionAgreementState,
  action: InspectionAgreementActionsType
): InspectionAgreementState {
  return reducer(state, action);
}
