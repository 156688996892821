import { createReducer, on } from '@ngrx/store';

import { ProjectsActions, ProjectsActionsType } from '@store/projects/projects.actions';
import { projectsInitialState, ProjectsState } from '@store/projects/projects.state';

const reducer = createReducer(
  projectsInitialState,
  on(
    ProjectsActions.fetchProjectsListStartAction,
    ProjectsActions.fetchProjectInspectionsListStartAction,
    ProjectsActions.fetchProjectInspectionStepsStartAction,
    ProjectsActions.changeInspectionStatusStartAction,
    ProjectsActions.changeNoShowStatusStartAction,
    ProjectsActions.importOldInspectionStartAction,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    ProjectsActions.fetchProjectsListSuccessAction,
    (state: ProjectsState, { projects }): ProjectsState => ({
      ...state,
      projects,
      isLoading: false
    })
  ),
  on(
    ProjectsActions.fetchProjectInspectionsListSuccessAction,
    (state: ProjectsState, { projectInspections }): ProjectsState => ({
      ...state,
      projectInspections,
      isLoading: false
    })
  ),
  on(
    ProjectsActions.fetchProjectInspectionStepsSuccessAction,
    ProjectsActions.changeInspectionStatusSuccessAction,
    ProjectsActions.changeNoShowStatusSuccessAction,
    ProjectsActions.importOldInspectionSuccessAction,
    (state: ProjectsState, { projectInspectionSteps }): ProjectsState => ({
      ...state,
      projectInspectionSteps,
      isLoading: false
    })
  ),
  on(
    ProjectsActions.fetchProjectsListFailedAction,
    ProjectsActions.fetchProjectInspectionsListFailedAction,
    ProjectsActions.fetchProjectInspectionStepsFailedAction,
    ProjectsActions.changeInspectionStatusFailedAction,
    ProjectsActions.changeNoShowStatusFailedAction,
    ProjectsActions.importOldInspectionFailedAction,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      isLoading: false
    })
  )
);

export function projectsReducer(state: ProjectsState, action: ProjectsActionsType): ProjectsState {
  return reducer(state, action);
}
