import { OrderByEnum } from '@shared/enums';
import { PaginationQueryModel } from '@shared/models';

export const PER_PAGE_LIST: number[] = [10, 25, 50, 100, 250, 500];

export const DEFAULT_PAGINATION_QUERY: PaginationQueryModel = {
  page: '1',
  per_page: '10',
  order: OrderByEnum.Asc
};

export const MIN_PER_PAGE: number = PER_PAGE_LIST[0];
