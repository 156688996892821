import { createAction, props, union } from '@ngrx/store';

import {
  CommScheduleHistoryLogFormModel,
  CommScheduleModel,
  PaginationModel,
  PaginationQueryModel
} from '@shared/models';

const fetchCommSchedulePaginationAction = createAction(
  '[ProjectRequest] Fetch comm schedules with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchCommScheduleClosurePaginationAction = createAction(
  '[ProjectRequest] Fetch comm schedules closure with pagination',
  props<{ queryModel: PaginationQueryModel }>()
);

const fetchCommSchedulePaginationSuccessAction = createAction(
  '[ProjectRequest] Fetch comm schedules with pagination success',
  props<{ comm_schedules: CommScheduleModel[]; pagination: PaginationModel }>()
);

const searchByNameAction = createAction(
  '[ProjectRequest] Search comm schedules by name',
  props<{ name: string }>()
);

const searchByNameClosureAction = createAction(
  '[ProjectRequest] Search comm schedules closure by name',
  props<{ name: string }>()
);

const searchByNameSuccessAction = createAction(
  '[ProjectRequest] Search comm schedules by name success',
  props<{ comm_schedules: CommScheduleModel[] }>()
);

const createHistoryLogAction = createAction(
  '[ProjectRequest] Create history log',
  props<{ id: number; log: CommScheduleHistoryLogFormModel }>()
);

const createHistoryLogSuccessAction = createAction(
  '[ProjectRequest] Create history log success',
  props<{ comm_schedules: CommScheduleModel[] }>()
);

const sendSmsAction = createAction(
  '[ProjectRequest] Send SMS',
  props<{ id: number; message: string }>()
);

const sendSmsSuccessAction = createAction('[ProjectRequest] Send SMS success');

const getPdfAction = createAction('[ProjectRequest] Get PDF', props<{ id: number }>());

const getPdfSuccessAction = createAction(
  '[ProjectRequest] Get PDF success',
  props<{ pdf: Blob }>()
);

export const ProjectRequestActions = {
  fetchCommSchedulePaginationAction,
  fetchCommScheduleClosurePaginationAction,
  fetchCommSchedulePaginationSuccessAction,
  searchByNameAction,
  searchByNameClosureAction,
  searchByNameSuccessAction,
  createHistoryLogAction,
  createHistoryLogSuccessAction,
  sendSmsAction,
  sendSmsSuccessAction,
  getPdfAction,
  getPdfSuccessAction
};

const projectRequestActionsUnion = union(ProjectRequestActions);

export type ProjectRequestActionsType = typeof projectRequestActionsUnion;
