import { CommScheduleModel, PaginationModel } from '@shared/models';

export interface ProjectRequestState {
  comm_schedules: CommScheduleModel[];
  pagination: PaginationModel;
  isLoading: boolean;
  pdf: Blob;
}

export const projectRequestInitialState: ProjectRequestState = {
  comm_schedules: [],
  pagination: null,
  isLoading: false,
  pdf: null
};

export const PROJECT_REQUEST_FEATURE = 'projectRequest';
