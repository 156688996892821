import { createReducer, on } from '@ngrx/store';

import {
  BuildingDepartmentActions,
  BuildingDepartmentActionsType
} from './building-department.actions';
import {
  buildingDepartmentInitialState,
  BuildingDepartmentState
} from './building-department.state';

const reducer = createReducer(
  buildingDepartmentInitialState,
  on(
    BuildingDepartmentActions.fetchBuildingDepartmentListSuccessAction,
    (state: BuildingDepartmentState, { building_departments }): BuildingDepartmentState => ({
      ...state,
      building_departments
    })
  ),
  on(
    BuildingDepartmentActions.searchByNameAction,
    (state: BuildingDepartmentState, { name }): BuildingDepartmentState => ({
      ...state,
      searchTerm: name
    })
  ),
  on(
    BuildingDepartmentActions.fetchBuildingDepartmentAction,
    (state: BuildingDepartmentState): BuildingDepartmentState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    BuildingDepartmentActions.fetchBuildingDepartmentSuccessAction,
    (state: BuildingDepartmentState, { building_department }): BuildingDepartmentState => ({
      ...state,
      building_department,
      isLoading: false
    })
  ),
  on(
    BuildingDepartmentActions.fetchBuildingDepartmentFailedAction,
    (state: BuildingDepartmentState): BuildingDepartmentState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    BuildingDepartmentActions.uploadBuildingDepartmentDocumentSuccessAction,
    (state: BuildingDepartmentState, { document }): BuildingDepartmentState => ({
      ...state,
      building_department: {
        ...state.building_department,
        documents: [...state.building_department.documents, document]
      }
    })
  ),
  on(
    BuildingDepartmentActions.removeBuildingDepartmentDocumentSuccessAction,
    BuildingDepartmentActions.updateBuildingDepartmentDocumentSuccessAction,
    (state: BuildingDepartmentState, { documents }): BuildingDepartmentState => ({
      ...state,
      building_department: {
        ...state.building_department,
        documents
      }
    })
  ),
  on(
    BuildingDepartmentActions.fetchBusinessLicensesPaginationSuccessAction,
    BuildingDepartmentActions.createBusinessLicenseSuccessAction,
    BuildingDepartmentActions.updateBusinessLicenseSuccessAction,
    BuildingDepartmentActions.removeBusinessLicenseSuccessAction,
    (
      state: BuildingDepartmentState,
      { business_licenses, pagination }
    ): BuildingDepartmentState => ({
      ...state,
      business_licenses,
      pagination
    })
  )
);

export function buildingDepartmentReducer(
  state: BuildingDepartmentState,
  action: BuildingDepartmentActionsType
): BuildingDepartmentState {
  return reducer(state, action);
}
