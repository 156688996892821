import { createAction, props, union } from '@ngrx/store';

import { MainMenuEnum } from '@shared/enums';
import {
  CompanyModel,
  MainMenuModel,
  SidebarCategoryModel,
  UserProfileModel
} from '@shared/models';

const initMainMenuAction = createAction('[Layout] Init main menu', props<{ id: MainMenuEnum }>());

const setMainMenuAction = createAction(
  '[Layout] Set main menu',
  props<{ main_menu: MainMenuModel[] }>()
);

const openSubmenuAction = createAction('[Layout] Open submenu', props<{ id: MainMenuEnum }>());

const setSidebarMenuAction = createAction(
  '[Layout] Set sidebar menu',
  props<{ sidebar: SidebarCategoryModel }>()
);

const toggleUserDropdownAction = createAction('[Layout] Toggle user dropdown');

const toggleSideMenuAction = createAction('[Layout] Toggle side menu');

const toggleSidebarAction = createAction('[Layout] Toggle sidebar');

const setSelectedCompanyIdAction = createAction(
  '[Layout] Set selected company id',
  props<{ id: number }>()
);

const setSelectedEmployeeIdAction = createAction(
  '[Layout] Set selected employee id',
  props<{ id: number }>()
);

const setCompanyDropdownListAction = createAction(
  '[Layout] Set company dropdown list',
  props<{ companies: CompanyModel[] }>()
);

const setEmployeeDropdownListAction = createAction(
  '[Layout] Set employee dropdown list',
  props<{ user_profiles: UserProfileModel[] }>()
);

export const LayoutActions = {
  initMainMenuAction,
  setMainMenuAction,
  openSubmenuAction,
  setSidebarMenuAction,
  toggleUserDropdownAction,
  toggleSideMenuAction,
  toggleSidebarAction,
  setSelectedCompanyIdAction,
  setSelectedEmployeeIdAction,
  setCompanyDropdownListAction,
  setEmployeeDropdownListAction
};

const layoutActionsUnion = union(LayoutActions);

export type LayoutActionsType = typeof layoutActionsUnion;
