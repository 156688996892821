import { MainMenuRouteEnum } from '@shared/enums';
import { SidebarCategoryModel } from '@shared/models';

export const getT24DataCollectionMenu = (): SidebarCategoryModel => ({
  category: 'navigation.sidebar.category.technicians-app',
  icon: 'icon-project-request.svg',
  group: [
    {
      name: 'navigation.sidebar.group.t24-data-collection',
      icon: 'icon-general-info.svg',
      show: true,
      items: [
        {
          name: 'navigation.sidebar.item.install-verification',
          route: `${MainMenuRouteEnum.T24DataCollection}/install-verification/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.equipment-info',
          route: `${MainMenuRouteEnum.T24DataCollection}/equipment-info/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.installed-duct-system-info',
          route: `${MainMenuRouteEnum.T24DataCollection}/installed-duct-system-info/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.duct-leakage-verification',
          route: `${MainMenuRouteEnum.T24DataCollection}/duct-leakage-verification/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.airflow-verification',
          route: `${MainMenuRouteEnum.T24DataCollection}/airflow-verification/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.fan-watt-draw',
          route: `${MainMenuRouteEnum.T24DataCollection}/fan-watt-draw/`,
          show: true
        },
        {
          name: 'navigation.sidebar.item.refrigerant-verification',
          route: `${MainMenuRouteEnum.T24DataCollection}/refrigerant-verification/`,
          show: true
        }
      ]
    }
  ]
});
