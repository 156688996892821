import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props, union } from '@ngrx/store';

import { MessageActionEnum } from '@shared/enums';
import { ErrorResponseModel } from '@shared/models';

const handleErrorAction = createAction(
  '[Message] Handle error',
  props<{ errorResponse: HttpErrorResponse }>()
);

const handleSuccessAction = createAction(
  '[Message] Handle success',
  props<{ success: MessageActionEnum }>()
);

const setErrorAction = createAction('[Message] Set error', props<{ error: ErrorResponseModel }>());

const resetErrorAction = createAction('[Message] Reset error');

export const MessageActions = {
  handleErrorAction,
  setErrorAction,
  resetErrorAction,
  handleSuccessAction
};

const messageActionsUnion = union(MessageActions);

export type MessageActionsType = typeof messageActionsUnion;
