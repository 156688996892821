import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MainMenuEnum,
  ProjectRequestRouterPathEnum,
  SessionStorageEnum,
  StatusTypeEnum,
  TechniciansAppRouterPathEnum
} from '@shared/enums';
import { ProjectInspectionStepsModel, UserProfileModel } from '@shared/models';

@Component({
  selector: 'common-section-header',
  templateUrl: './common-section-header.component.html',
  styleUrls: ['./common-section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonSectionHeaderComponent {
  @Input() public userProfile: UserProfileModel;
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public employeeFullName: string;
  @Input('projectInspectionSteps')
  set projectInspectionSteps(value: ProjectInspectionStepsModel) {
    if (value) {
      this.projectInspection = value;
    } else {
      this.projectInspection = JSON.parse(
        localStorage.getItem(SessionStorageEnum.ProjectInspectionData)
      );
    }
  }
  @Input() public showBackToProject: boolean;
  @Input() public menuId: MainMenuEnum;

  public userStatuses = StatusTypeEnum;
  public mainMenuEnum = MainMenuEnum;
  public projectRequestRouterPathEnum = ProjectRequestRouterPathEnum;
  public techniciansAppRouterPathEnum = TechniciansAppRouterPathEnum;
  public projectInspection: ProjectInspectionStepsModel;
}
