import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  MessageResponseModel,
  ReportsBayernFormModel,
  ReportsCountModel,
  ReportsListModel
} from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ReportsHttpService {
  constructor(private http: HttpClient) {}

  public getReportsCount(id: number): Observable<ReportsCountModel> {
    return this.http.get<ReportsCountModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ReportsCountById,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getReportsHomeScore(id: number): Observable<ReportsListModel[]> {
    return this.http.get<ReportsListModel[]>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ReportsHomeScore,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createReportHomeScore(id: number): Observable<ReportsListModel> {
    return this.http.post<ReportsListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateReportHomeScore,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeReport(id: number): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.RemoveReport,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getReportsT24(id: number): Observable<ReportsListModel[]> {
    return this.http.get<ReportsListModel[]>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ReportsT24,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createReportT24(id: number): Observable<ReportsListModel> {
    return this.http.post<ReportsListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateReportT24,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getReportsSmud(id: number): Observable<ReportsListModel[]> {
    return this.http.get<ReportsListModel[]>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ReportsSmud,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createReportSmud(id: number): Observable<ReportsListModel> {
    return this.http.post<ReportsListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateReportSmud,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getReportsInspectionAgreement(id: number): Observable<ReportsListModel[]> {
    return this.http.get<ReportsListModel[]>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ReportsInspectionAgreement,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createReportInspectionAgreement(id: number): Observable<ReportsListModel> {
    return this.http.post<ReportsListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateReportInspectionAgreement,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createReportHomeScoreRebate(id: number): Observable<ReportsListModel> {
    return this.http.post<ReportsListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateReportHomeScoreRebate,
        id
      ),
      {},
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getReportsBayren(id: number): Observable<ReportsListModel[]> {
    return this.http.get<ReportsListModel[]>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.ReportsBayren,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createReportBayren(
    id: number,
    create: ReportsBayernFormModel
  ): Observable<ReportsListModel> {
    return this.http.post<ReportsListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateReportBayren,
        id
      ),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    inspectionId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (inspectionId) {
      path = path.replace(':inspectionId', inspectionId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
