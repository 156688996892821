import {CompanyStatusEnum, StatusTypeEnum} from '@shared/enums';

export const STATUS_LIST: StatusTypeEnum[] = [
  StatusTypeEnum.Active,
  StatusTypeEnum.Disabled,
  StatusTypeEnum.Pending,
  StatusTypeEnum.ReadyForApproval,
  StatusTypeEnum.Unverified
];

export const STATUS_COMPANY_LIST: CompanyStatusEnum[] = [
  CompanyStatusEnum.Active,
  CompanyStatusEnum.Disabled,
  CompanyStatusEnum.Pending,
  CompanyStatusEnum.Restricted
];
