import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs/operators';

import { MessageActionMap } from '@shared/mappings';
import { getFlattenErrors } from '@shared/utils';
import { MessageActions } from './message.actions';

@Injectable()
export class MessageEffects {
  /**
   * @route /
   * @listen [Message] Handle error
   * @dispatch [Message] Set error
   */
  public handleError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageActions.handleErrorAction),
      map(({ errorResponse }) =>
        MessageActions.setErrorAction({ error: getFlattenErrors(errorResponse) })
      )
    )
  );
  /**
   * @route /
   * @listen [Message] Set error
   */
  public showErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessageActions.setErrorAction),
        tap(({ error }) => {
          if (typeof error.message === 'string') {
            this.toastService.error(
              this.translate.instant(error.message) || error.message,
              this.translate.instant('field.validation.error.title')
            );
          } else {
            this.toastService.error(
              this.translate.instant('field.validation.error.data-invalid'),
              this.translate.instant('field.validation.error.title')
            );
          }

          if (error.errors) {
            (error.errors as string[]).forEach((message: string): void => {
              this.toastService.error(
                message,
                this.translate.instant('field.validation.error.title')
              );
            });
          }
          if (typeof error.message === 'object') {
            Object.keys(error.message).forEach((key: string): void => {
              this.toastService.error(
                error.message[key][0],
                this.translate.instant('field.validation.error.title')
              );
            });
            // ((error.message as unknown) as string[]).forEach((message: string): void => {
            //   this.toastService.error(
            //     message,
            //     this.translate.instant('field.validation.error.title')
            //   );
            // });
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * @route /
   * @listen [Message] Handle success
   */
  public showSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessageActions.handleSuccessAction),
        tap(({ success }) => {
          this.toastService.success(
            this.translate.instant(MessageActionMap[success]),
            this.translate.instant('field.validation.success.title')
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private toastService: ToastrService
  ) {}
}
