import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, mergeMapTo, switchMap, withLatestFrom } from 'rxjs/operators';

import { MessageActionEnum } from '@shared/enums';
import { ProjectsHttpService } from '@shared/services/projects-http.service';
import {
  getProjectInspectionWithUpdatedNoShowStatus,
  getProjectInspectionWithUpdatedStatus
} from '@shared/utils';
import { MessageActions } from '@store/message/message.actions';
import { ProjectsActions } from '@store/projects/projects.actions';
import { getProjectInspectionStepsState } from '@store/projects/projects.selectors';
import { ProjectsState } from '@store/projects/projects.state';

@Injectable()
export class ProjectsEffects {
  /**
   * @route technicians-app/manage-projects
   * @listen [Projects] Fetch projects list
   * @dispatch [Projects] Fetch projects list success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public fetchProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.fetchProjectsListStartAction),
      switchMap(() =>
        this.projectsHttpService.getProjects().pipe(
          mergeMap(projects => [
            ProjectsActions.fetchProjectsListSuccessAction({ projects }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              ProjectsActions.fetchProjectsListFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/project-inspections/:id
   * @listen [Projects] Fetch project inspections list
   * @dispatch [Projects] Fetch project inspections list success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public fetchProjectInspections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.fetchProjectInspectionsListStartAction),
      switchMap(({ id }) =>
        this.projectsHttpService.getProjectInspections(id).pipe(
          mergeMap(projectInspections => [
            ProjectsActions.fetchProjectInspectionsListSuccessAction({ projectInspections }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              ProjectsActions.fetchProjectInspectionsListFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/project-inspection-steps/:id
   * @listen [Projects] Fetch project inspection steps
   * @dispatch [Projects] Fetch project inspection steps success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public fetchProjectInspectionSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.fetchProjectInspectionStepsStartAction),
      switchMap(({ id }) =>
        this.projectsHttpService.getProjectInspectionSteps(id).pipe(
          mergeMap(projectInspectionSteps => [
            ProjectsActions.fetchProjectInspectionStepsSuccessAction({ projectInspectionSteps }),
            MessageActions.resetErrorAction()
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              ProjectsActions.fetchProjectInspectionStepsFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/project-inspection-steps/:id
   * @listen [Projects] Change inspection status
   * @dispatch [Projects] Change inspection status success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public changeInspectionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.changeInspectionStatusStartAction),
      withLatestFrom(this.store.pipe(select(getProjectInspectionStepsState))),
      switchMap(([{ id, completed }, projectInspection]) =>
        this.projectsHttpService.changeInspectionStatus(id, completed).pipe(
          mergeMapTo([
            ProjectsActions.changeInspectionStatusSuccessAction({
              projectInspectionSteps: getProjectInspectionWithUpdatedStatus(
                completed,
                projectInspection
              )
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              ProjectsActions.changeInspectionStatusFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/project-inspection-steps/:id
   * @listen [Projects] Change no show status
   * @dispatch [Projects] Change no show status success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public changeNoShowStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.changeNoShowStatusStartAction),
      withLatestFrom(this.store.pipe(select(getProjectInspectionStepsState))),
      switchMap(([{ id }, projectInspection]) =>
        this.projectsHttpService.changeNoShowStatus(id).pipe(
          mergeMapTo([
            ProjectsActions.changeNoShowStatusSuccessAction({
              projectInspectionSteps: getProjectInspectionWithUpdatedNoShowStatus(projectInspection)
            }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              ProjectsActions.changeNoShowStatusFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );
  /**
   * @route technicians-app/project-inspection-steps/:id
   * @listen [Projects] Import old inspection
   * @dispatch [Projects] Import old inspection success
   * @dispatch [Message] Reset error
   * @dispatch [Message] Handle error
   */
  public importOldInspection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.importOldInspectionStartAction),
      switchMap(({ id, update }) =>
        this.projectsHttpService.importOldInspection(id, update).pipe(
          mergeMap(projectInspectionSteps => [
            ProjectsActions.importOldInspectionSuccessAction({ projectInspectionSteps }),
            MessageActions.handleSuccessAction({ success: MessageActionEnum.Save })
          ]),
          catchError((errorResponse: HttpErrorResponse) =>
            of(
              ProjectsActions.importOldInspectionFailedAction(),
              MessageActions.handleErrorAction({ errorResponse })
            )
          )
        )
      )
    )
  );

  constructor(
    private store: Store<ProjectsState>,
    private actions$: Actions,
    private projectsHttpService: ProjectsHttpService
  ) {}
}
