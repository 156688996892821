import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef
} from '@angular/core';

import { ArrowTooltipComponent } from './arrow-tooltip.component';

@Directive({ selector: '[arrowTooltip]' })
export class ArrowTooltipDirective implements OnInit {
  @Input('arrowTooltip') public content: TemplateRef<any>;
  private overlayRef: OverlayRef;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  public ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  public show(): void {
    const tooltipPortal = new ComponentPortal(ArrowTooltipComponent);
    const tooltipRef: ComponentRef<ArrowTooltipComponent> = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.content = this.content;
  }

  @HostListener('mouseout')
  public hide(): void {
    this.overlayRef.detach();
  }
}
