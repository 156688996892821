import {
  EquipmentInfoModel,
  SystemsModel,
  T24DataCollectionModel
} from '@shared/models/t24-data-collection.model';

export interface T24DataCollectionState {
  systems: SystemsModel[];
  t24Data: T24DataCollectionModel | EquipmentInfoModel;
  isLoading: boolean;
}

export const t24DataCollectionInitialState: T24DataCollectionState = {
  systems: [],
  t24Data: null,
  isLoading: false
};

export const T24_DATA_COLLECTION_FEATURE = 't24DataCollection';
