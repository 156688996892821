import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import { NotificationResponseModel } from '@shared/models/notification.model';
import { NotificationDataService } from '@shared/services/notification-data.service';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {
  constructor(
    private http: HttpClient,
    private notificationDataService: NotificationDataService
  ) {}

  public getNotifications(): void {
    this.http
      .get<NotificationResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.GetNotifications),
        {
          headers: getHttpHeaders()
        }
      ).subscribe(data => {
        this.notificationDataService.setNotificationData(data.notifications);
      });
  }

  public markNotificationRead(userNotificationId: number): void {
    const url = this.getEndpoint(environment.API_URL, ApiResourceEnum.GetNotifications) + '/' + userNotificationId + '/mark-as-read';
    this.http.put<any>(url, {headers: getHttpHeaders()}).subscribe();
  }

  public markAllAsRead(): void {
    const url = this.getEndpoint(environment.API_URL, ApiResourceEnum.GetNotifications) + '/mark-all-as-read';
    this.http.put<any>(url, {headers: getHttpHeaders()}).subscribe();
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }
}
