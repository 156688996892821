import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AdminRouterPathEnum, ApiResourceEnum } from '@shared/enums';
import {
  BankAccountModel,
  BonusPricingModel,
  CreatedPriceListResponseModel,
  CreditCardModel,
  MessageResponseModel,
  PaginationModel,
  PaginationQueryModel,
  PriceCategoryModel,
  PriceGroupListResponseModel,
  PriceGroupModel,
  PriceListFormModel,
  PriceListModel,
  PriceListResponseModel,
  PriceServiceListResponseModel,
  PriceServiceModel,
  UserModel
} from '@shared/models';
import {
  flattenPriceGroupsWithPagination,
  flattenPriceServicesWithPagination,
  flattenPricesWithPagination,
  getHttpHeaders,
  objToHttpParams
} from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class BillingHttpService {
  constructor(private http: HttpClient, private router: Router) {}

  public getAllPrices(): Observable<{
    price_lists: PriceListModel[];
    empty_price_list: PriceListModel;
  }> {
    return this.http.get<{ price_lists: PriceListModel[]; empty_price_list: PriceListModel }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.PriceLists),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPaginatedPrices(
    queryParams: PaginationQueryModel
  ): Observable<{ prices: PriceListModel[]; pagination: PaginationModel }> {
    return this.http
      .get<PriceListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.PriceListsPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenPricesWithPagination));
  }

  public createCompanyPriceList(
    id: number,
    priceList: PriceListFormModel
  ): Observable<CreatedPriceListResponseModel> {
    return this.http.post<CreatedPriceListResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CreateCompanyPriceList,
        id
      ),
      priceList,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public assignCompanyPriceList(id: number, priceListId: number): Observable<MessageResponseModel> {
    return this.http.patch<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.AssignCompanyPriceList,
        id,
        priceListId
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPriceList(id: number): Observable<{ price_list: PriceListModel }> {
    return this.http.get<{ price_list: PriceListModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.PriceList, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getEmptyPriceList(): Observable<{
    empty_price_list: PriceListModel;
  }> {
    return this.http.get<{ empty_price_list: PriceListModel }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CreatePriceList),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updatePriceList(
    id: number,
    priceList: PriceListFormModel
  ): Observable<CreatedPriceListResponseModel> {
    return this.http.put<CreatedPriceListResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.PriceList, id),
      priceList,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createPriceList(priceList: PriceListFormModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CreatePriceList),
      priceList,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigateToPriceLists(): void {
    this.router.navigate([AdminRouterPathEnum.Prices]);
  }

  public getPaymentTypes(
    id: number
  ): Observable<{ bank_accounts: BankAccountModel[]; credit_cards: CreditCardModel[] }> {
    return this.http.get<{
      bank_accounts: BankAccountModel[];
      credit_cards: CreditCardModel[];
    }>(this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.PaymentTypes, id), {
      headers: getHttpHeaders()
    });
  }

  public removeAchPayment(
    id: number,
    accountId: string
  ): Observable<{ bank_accounts: BankAccountModel[] }> {
    return this.http.delete<{ bank_accounts: BankAccountModel[] }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.BankAccount, id),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ id: accountId })
      }
    );
  }

  public removeCreditCard(
    id: number,
    creditCardId: string
  ): Observable<{ credit_cards: CreditCardModel[] }> {
    return this.http.delete<{ credit_cards: CreditCardModel[] }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CreditCard, id),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ id: creditCardId })
      }
    );
  }

  public setDefaultCreditCard(
    id: number,
    creditCardId: string
  ): Observable<{ credit_cards: CreditCardModel[] }> {
    return this.http.post<{ credit_cards: CreditCardModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.SetDefaultCreditCard,
        id
      ),
      { id: creditCardId },
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createCreditCard(
    id: number,
    creditCard: CreditCardModel
  ): Observable<{ credit_cards: CreditCardModel[]; user: UserModel }> {
    return this.http.post<{ credit_cards: CreditCardModel[]; user: UserModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CreditCard, id),
      creditCard,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAchPayment(
    id: number,
    achPayment: BankAccountModel
  ): Observable<{ bank_accounts: BankAccountModel[]; user: UserModel }> {
    return this.http.post<{ bank_accounts: BankAccountModel[]; user: UserModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.BankAccount, id),
      achPayment,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAchPayment(
    id: number,
    achPayment: BankAccountModel
  ): Observable<{ bank_accounts: BankAccountModel[] }> {
    return this.http.put<{ bank_accounts: BankAccountModel[] }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.BankAccount, id),
      achPayment,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateCreditCard(
    id: number,
    creditCard: CreditCardModel
  ): Observable<{ credit_cards: CreditCardModel[] }> {
    return this.http.put<{ credit_cards: CreditCardModel[] }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CreditCard, id),
      creditCard,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPriceCategories(): Observable<{ price_categories: PriceCategoryModel[] }> {
    return this.http.get<{
      price_categories: PriceCategoryModel[];
    }>(this.getEndpoint(environment.API_URL, ApiResourceEnum.PriceCategories), {
      headers: getHttpHeaders()
    });
  }

  public getPaginatedPriceGroups(
    queryParams: PaginationQueryModel
  ): Observable<{ price_groups: PriceGroupModel[]; pagination: PaginationModel }> {
    return this.http
      .get<PriceGroupListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.PriceGroupsPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenPriceGroupsWithPagination));
  }

  public createPriceGroup(priceGroup: PriceGroupModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CreatePriceGroup),
      priceGroup,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigateToPriceGroups(): void {
    this.router.navigate([AdminRouterPathEnum.PriceCategories]);
  }

  public getPriceGroup(id: number): Observable<{ price_group: PriceGroupModel }> {
    return this.http.get<{ price_group: PriceGroupModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.PriceGroup, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updatePriceGroup(
    id: number,
    priceGroup: PriceGroupModel
  ): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.PriceGroup, id),
      priceGroup,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPriceGroups(): Observable<{ price_groups: PriceGroupModel[] }> {
    return this.http.get<{
      price_groups: PriceGroupModel[];
    }>(this.getEndpoint(environment.API_URL, ApiResourceEnum.PriceGroups), {
      headers: getHttpHeaders()
    });
  }

  public getPaginatedPriceServices(
    queryParams: PaginationQueryModel
  ): Observable<{ price_services: PriceServiceModel[]; pagination: PaginationModel }> {
    return this.http
      .get<PriceServiceListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.PriceServicesPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenPriceServicesWithPagination));
  }

  public createPriceService(priceService: PriceServiceModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CreatePriceService),
      priceService,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigateToPriceServices(): void {
    this.router.navigate([AdminRouterPathEnum.PriceServices]);
  }

  public getPriceService(id: number): Observable<{ price_service: PriceServiceModel }> {
    return this.http.get<{ price_service: PriceServiceModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.PriceService, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updatePriceService(
    id: number,
    priceService: PriceServiceModel
  ): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.PriceService, id),
      priceService,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getBonusPricing(): Observable<{ bonus_pricing: BonusPricingModel }> {
    return this.http.get<{ bonus_pricing: BonusPricingModel }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.BonusPricing),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public saveBonusPricing(
    bonusPricing: BonusPricingModel
  ): Observable<{ bonus_pricing: BonusPricingModel }> {
    return this.http.post<{ bonus_pricing: BonusPricingModel }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.BonusPricing),
      bonusPricing,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateBonusPricing(
    bonusPricing: BonusPricingModel
  ): Observable<{ bonus_pricing: BonusPricingModel }> {
    console.log(bonusPricing);
    return this.http.put<{ bonus_pricing: BonusPricingModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.UpdateBonusPricing,
        bonusPricing.id
      ),
      bonusPricing,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    priceListId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (priceListId) {
      path = path.replace(':priceListId', priceListId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
