import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import { CheersListModel, InspectionIdFormModel, ProjectRequestIdFormModel } from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class CheersHttpService {
  constructor(private http: HttpClient) {}

  public getCheersList(id: number): Observable<CheersListModel> {
    return this.http.get<CheersListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CheersListById,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public downloadDocumentUrl(id: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CheersDownloadDocument,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createCf1rCertificate(create: ProjectRequestIdFormModel): Observable<CheersListModel> {
    return this.http.post<CheersListModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateCf1rCertificate),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createCf2rCertificate(create: InspectionIdFormModel): Observable<CheersListModel> {
    return this.http.post<CheersListModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateCf2rCertificate),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createCf3rCertificate(create: InspectionIdFormModel): Observable<CheersListModel> {
    return this.http.post<CheersListModel>(
      this.getEndpoint(environment.API_TECHNICIANS_URL, ApiResourceEnum.CreateCf3rCertificate),
      create,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAllCertificate(id: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CreateAllCertificate,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public deleteDocument(id: number): Observable<CheersListModel> {
    return this.http.delete<CheersListModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TECHNICIANS_URL,
        ApiResourceEnum.CheersDeleteDocument,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    inspectionId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (inspectionId) {
      path = path.replace(':inspectionId', inspectionId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
