import { EmployeePositionsEnum } from '@shared/enums';

export const EmployeePositionMap: {[key in EmployeePositionsEnum]: string} = {
  [EmployeePositionsEnum.CEO]: EmployeePositionsEnum.CEO,
  [EmployeePositionsEnum.Inspector]: EmployeePositionsEnum.Inspector,
  [EmployeePositionsEnum.PermitTechnician]: 'PermitTechnician',
  [EmployeePositionsEnum.OfficeStaff]: 'OfficeStaff',
  [EmployeePositionsEnum.OfficeManager]: 'OfficeManager',
  [EmployeePositionsEnum.Accounting]: EmployeePositionsEnum.Accounting,
  [EmployeePositionsEnum.SalesManager]: 'SalesManager'
}
