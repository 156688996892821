import { BuildingDepartmentModel, BusinessLicenseModel, PaginationModel } from '@shared/models';

export interface BuildingDepartmentState {
  building_departments: BuildingDepartmentModel[];
  building_department: BuildingDepartmentModel;
  business_licenses: BusinessLicenseModel[];
  pagination: PaginationModel;
  searchTerm: string;
  isLoading: boolean;
}

export const buildingDepartmentInitialState: BuildingDepartmentState = {
  building_departments: [],
  building_department: null,
  business_licenses: [],
  pagination: null,
  searchTerm: '',
  isLoading: false
};

export const BUILDING_DEPARTMENT_FEATURE = 'buildingDepartment';
