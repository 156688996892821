import Rfind from 'ramda/es/find';
import Rmap from 'ramda/es/map';
import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';
import RwhereEq from 'ramda/es/whereEq';

import { PriceListTypeEnum } from '@shared/enums';
import {
  PaginationModel,
  PriceGroupListResponseModel,
  PriceGroupModel,
  PriceListModel,
  PriceListResponseModel,
  PriceServiceListResponseModel,
  PriceServiceModel
} from '@shared/models';

const hasCustomPriceList = (prices: PriceListModel[]): boolean =>
  Boolean(Rfind(({ type }: PriceListModel) => type === PriceListTypeEnum.Custom, prices));

export const flattenPricesWithPagination = (
  data: PriceListResponseModel
): { prices: PriceListModel[]; pagination: PaginationModel } => ({
  prices: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});

export const flattenPriceGroupsWithPagination = (
  data: PriceGroupListResponseModel
): { price_groups: PriceGroupModel[]; pagination: PaginationModel } => ({
  price_groups: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});

export const flattenPriceServicesWithPagination = (
  data: PriceServiceListResponseModel
): { price_services: PriceServiceModel[]; pagination: PaginationModel } => ({
  price_services: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});

export const setPriceLists = (
  priceList: PriceListModel,
  prices: PriceListModel[]
): PriceListModel[] => {
  if (hasCustomPriceList(prices)) {
    return Rmap(
      (price: PriceListModel) => ({
        ...price,
        ...(price.type === PriceListTypeEnum.Custom ? priceList : {})
      }),
      prices
    );
  }
  return [priceList, ...prices];
};

export const getPriceListWithId = (id: number, prices: PriceListModel[]): PriceListModel =>
  Rfind(RwhereEq({ id }), prices);
