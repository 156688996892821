import { PaginationModel, ZoneModel } from '@shared/models';

export interface ZoneState {
  pagination: PaginationModel;
  zone: ZoneModel;
  zones: ZoneModel[];
  isLoading: boolean;
}

export const zoneInitialState: ZoneState = {
  pagination: null,
  zone: null,
  zones: [],
  isLoading: false
};

export const ZONE_FEATURE = 'zone';
