import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';

import { PaginationModel, ZoneListResponseModel, ZoneModel } from '@shared/models';

export const flattenZonesWithPagination = (
  data: ZoneListResponseModel
): { zones: ZoneModel[]; pagination: PaginationModel } => ({
  zones: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});
