import { createReducer, on } from '@ngrx/store';

import {
  T24DataCollectionActions,
  T24DataCollectionActionsType
} from './t24-data-collection.actions';
import { t24DataCollectionInitialState, T24DataCollectionState } from './t24-data-collection.state';

const reducer = createReducer(
  t24DataCollectionInitialState,
  on(
    T24DataCollectionActions.fetchSystemsListStartAction,
    T24DataCollectionActions.fetchSplitUnitsStartAction,
    T24DataCollectionActions.fetchPackageUnitsStartAction,
    T24DataCollectionActions.fetchMiniSplitHeatPumpsStartAction,
    (state: T24DataCollectionState): T24DataCollectionState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    T24DataCollectionActions.fetchSystemsListSuccessAction,
    (state: T24DataCollectionState, { systems }): T24DataCollectionState => ({
      ...state,
      systems,
      isLoading: false
    })
  ),
  on(
    T24DataCollectionActions.fetchSplitUnitsSuccessAction,
    T24DataCollectionActions.fetchPackageUnitsSuccessAction,
    T24DataCollectionActions.fetchMiniSplitHeatPumpsSuccessAction,
    T24DataCollectionActions.createAirflowVerificationSuccessAction,
    T24DataCollectionActions.updateAirflowVerificationSuccessAction,
    T24DataCollectionActions.createFanWattDrawSuccessAction,
    T24DataCollectionActions.updateFanWattDrawSuccessAction,
    T24DataCollectionActions.createRefrigerantVerificationsSuccessAction,
    T24DataCollectionActions.updateRefrigerantVerificationsSuccessAction,
    (state: T24DataCollectionState, { t24Data }): T24DataCollectionState => ({
      ...state,
      t24Data,
      isLoading: false
    })
  ),
  on(
    T24DataCollectionActions.createDuctLeakageVerificationSuccessAction,
    T24DataCollectionActions.updateDuctLeakageVerificationSuccessAction,
    T24DataCollectionActions.updateMiniSplitHeatPumpsSuccessAction,
    T24DataCollectionActions.updatePackageUnitsSuccessAction,
    T24DataCollectionActions.updateSplitUnitsSuccessAction,
    T24DataCollectionActions.createUpdateMiniSplitUnitsSuccessAction,
    T24DataCollectionActions.updateInstalledDuctSystemInfoSuccessAction,
    (state: T24DataCollectionState, { t24Data }): T24DataCollectionState => ({
      ...state,
      t24Data,
      isLoading: false
    })
  ),
  on(
    T24DataCollectionActions.fetchSystemsListFailedAction,
    T24DataCollectionActions.fetchSplitUnitsFailedAction,
    T24DataCollectionActions.fetchPackageUnitsFailedAction,
    T24DataCollectionActions.fetchMiniSplitHeatPumpsFailedAction,
    (state: T24DataCollectionState): T24DataCollectionState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    T24DataCollectionActions.removeSystemSuccessAction,
    (state: T24DataCollectionState, { systems }): T24DataCollectionState => ({
      ...state,
      systems
    })
  )
);

export function t24DataCollectionReducer(
  state: T24DataCollectionState,
  action: T24DataCollectionActionsType
): T24DataCollectionState {
  return reducer(state, action);
}
