import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SessionStorageEnum } from '@shared/enums';
import {
  CompanyModel,
  EquipmentInfoModel,
  GeneralModel,
  ProjectInspectionStepsModel,
  RouteParamsModel,
  SidebarCategoryModel,
  T24DataCollectionModel,
  UserProfileModel,
  UserValidationsModel
} from '@shared/models';

@Component({
  selector: 'common-sidebar',
  templateUrl: './common-sidebar.component.html',
  styleUrls: ['./common-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonSidebarComponent {
  @Input() public userValidations: UserValidationsModel;
  @Input() public t24Data: T24DataCollectionModel | EquipmentInfoModel;
  @Input() public sidebar: SidebarCategoryModel;
  @Input() public params: RouteParamsModel;
  @Input() public showSidebar: boolean = false;
  @Input() public showCompanySearch: boolean = false;
  @Input() public showEmployeeSearch: boolean = false;
  @Input() public isArchonEmployee: boolean = false;
  @Input() public canSeeBranches: boolean = false;
  @Input('companyId')
  set companyId(value: number) {
    this.selectedCompanyId = value;
    this.selectedCompany = (this.companies || []).filter(({ id }: CompanyModel) => id === value);
  }
  @Input('companyList')
  set companyList(value: CompanyModel[]) {
    this.companies = [...value];
    this.selectedCompany = (this.companies || []).filter(
      ({ id }: CompanyModel) => id === this.selectedCompanyId
    );
  }
  @Input('employeeId')
  set employeeId(value: number) {
    this.selectedEmployeeId = value;
    this.selectedEmployee = (this.employees || []).filter(
      ({ user_account_id }: UserProfileModel) => user_account_id === value
    );
  }
  @Input('employeeList')
  set employeeList(value: UserProfileModel[]) {
    this.employees = [...value];
    this.selectedEmployee = (this.employees || []).filter(
      ({ user_account_id }: UserProfileModel) => user_account_id === this.selectedEmployeeId
    );
  }
  @Input('general')
  set general(value: GeneralModel) {
    if (value) {
      this.generalData = value;
    } else {
      this.generalData = JSON.parse(localStorage.getItem(SessionStorageEnum.GeneralData));
    }
  }
  @Output() public companySelect: EventEmitter<number> = new EventEmitter();
  @Output() public employeeSelect: EventEmitter<number> = new EventEmitter();

  public selectedCompanyId: number;
  public companies: CompanyModel[] = [];
  public selectedCompany: CompanyModel[] = [];
  public companySelectConfig: object = {
    displayKey: 'company_name',
    search: true,
    height: '400px',
    placeholder: 'Company / Branch'
  };
  public selectedEmployeeId: number;
  public employees: UserProfileModel[] = [];
  public selectedEmployee: UserProfileModel[] = [];
  public employeeSelectConfig: object = {
    displayKey: 'full_name',
    search: true,
    height: '400px',
    placeholder: 'Employee'
  };
  public generalData: GeneralModel;
  public sideMenuOpened: boolean = false;
  public projectInspection: ProjectInspectionStepsModel = JSON.parse(
    localStorage.getItem(SessionStorageEnum.ProjectInspectionData)
  );

  public selectCompany({ value }: { value: CompanyModel }): void {
    const id = (value && value.id) || null;
    localStorage.setItem(SessionStorageEnum.SelectedCompany, id ? id.toString() : '');
    this.companySelect.emit(id);
  }

  public selectEmployee({ value }: { value: UserProfileModel }): void {
    const id = (value && value.user_account_id) || null;
    localStorage.setItem(SessionStorageEnum.SelectedEmployee, id ? id.toString() : '');
    this.employeeSelect.emit(id);
  }

  public routerLink(route: string): string {
    if (!this.params.id) {
      return route;
    }
    if (this.params.id && this.params.type) {
      return route + this.params.id + '/' + this.params.type;
    }
    return route + this.params.id;
  }

  public onShowSideMenu(): void {
    this.sideMenuOpened = !this.sideMenuOpened;
    this.showSidebar = !this.showSidebar;
  }
}
