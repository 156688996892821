import {
  EmployeeEarningModel,
  TaskModel,
  TaskSummaryModel,
  TimeClockWeekModel
} from '@shared/models';

export interface TimeClockState {
  tasks: TaskModel[];
  summary: TaskSummaryModel;
  history: TimeClockWeekModel[];
  earnings: EmployeeEarningModel[];
  isLoading: boolean;
}

export const timeClockInitialState: TimeClockState = {
  tasks: [],
  summary: null,
  history: [],
  earnings: [],
  isLoading: false
};

export const TIME_CLOCK_FEATURE = 'timeClock';
