import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PermitListStatsModel } from '@app/modules/ProjectRequest/models/project-lists/permit-list-stats.model';
import {
  GeneralListSearch,
  InspectionListSearch,
  InvoiceListSearch,
  PermitClosureListSearch,
  PermitListSearch
} from '@app/modules/ProjectRequest/models/searchLists.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  protected generalProjectList = new BehaviorSubject<GeneralListSearch>({
    status: 0,
    t24_status: 0,
    permit_status: 0,
    rebate_status: 0,
    hes_status: 0,
    mailing_status: 0,
    t24_invoice_status: 0,
    ins_request_type: '',
    contractor_id: 0,
    search: null,
    sort_by: null,
    sort_order: null,
    permit_number: null,
    hes_label: null,
    mailing_status_type: null,
    issued_date_range: null,
    scheduled_date_range: null,
    rebates: []
  });

  protected permitList = new BehaviorSubject<PermitListSearch>({
    permit_status: 30,
    contractor_id: 0,
    building_department_id: 0,
    requested_service: null,
    requested_date_range: null,
    issued_date_range: null,
    search: null,
    emg_perm_svc: null
  });

  protected inspectionList = new BehaviorSubject<InspectionListSearch>({
    ins_request_type: '',
    t24_status: 0,
    inspection_status: 0,
    rebate_status: 0,
    contractor_id: 0,
    certificate_status: 0,
    permit_status: 0,
    mailing_status: 0,
    mailing_status_type: null,
    inspector: 0,
    schedule_date_range: null,
    search: null,
    sort_by: null,
    sort_order: null,
    rebates: []
  });

  protected permitClosureList = new BehaviorSubject<PermitClosureListSearch>({
    permit_status: 0,
    mailing_status: 0,
    contractor_id: 0,
    building_department_id: 0,
    last_event_status: 0,
    schedule_date_range: null,
    paperwork_mailed_date_range: null,
    permit_expires_date_range: null,
    search: null,
    sort_by: null,
    sort_order: null,
    permit_number: null,
    service_type: null
  });

  protected invoiceList = new BehaviorSubject<InvoiceListSearch>({
    year: 0,
    company: 0,
    status: 0,
    search: null
  });

  protected permitListStats = new BehaviorSubject<PermitListStatsModel[]>([]);

  public setGeneralProjectList(filters: GeneralListSearch): void {
    this.generalProjectList.next(filters);
  }

  public setPermitList(filters: PermitListSearch): void {
    this.permitList.next(filters);
  }

  public setInspectionList(filters: InspectionListSearch): void {
    this.inspectionList.next(filters);
  }

  public setPermitClosureList(filters: PermitClosureListSearch): void {
    this.permitClosureList.next(filters);
  }

  public setPermitListStats(statistics: PermitListStatsModel[]): void {
    this.permitListStats.next(statistics);
  }

  public setInvoiceList(filters: InvoiceListSearch): void {
    this.invoiceList.next(filters);
  }

  public getGeneralProjectList(): GeneralListSearch {
    return this.generalProjectList.getValue();
  }

  public getPermitList(): PermitListSearch {
    return this.permitList.getValue();
  }

  public getInspectionList(): InspectionListSearch {
    return this.inspectionList.getValue();
  }

  public getPermitClosureList(): PermitClosureListSearch {
    return this.permitClosureList.getValue();
  }

  public getPermitListStats(): Observable<PermitListStatsModel[]> {
    return this.permitListStats.asObservable();
  }

  public getInvoiceList(): InvoiceListSearch {
    return this.invoiceList.getValue();
  }
}
