import {
  ProjectInspectionsListModel,
  ProjectInspectionStepsModel,
  ProjectsListModel
} from '@shared/models';

export interface ProjectsState {
  projects: ProjectsListModel;
  projectInspections: ProjectInspectionsListModel;
  projectInspectionSteps: ProjectInspectionStepsModel;
  isLoading: boolean;
}

export const projectsInitialState: ProjectsState = {
  projects: null,
  projectInspections: null,
  projectInspectionSteps: null,
  isLoading: false
};

export const PROJECTS_FEATURE = 'projects';
