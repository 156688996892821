import { ErrorResponseModel } from '@shared/models';

export interface MessageState {
  error: ErrorResponseModel;
}

export const messageInitialState: MessageState = {
  error: null
};

export const MESSAGE_FEATURE = 'message';
