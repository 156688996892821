import { createAction, props, union } from '@ngrx/store';
import { ReportsBayernFormModel, ReportsCountModel, ReportsListModel } from '@shared/models';

const fetchReportsCountStartAction = createAction(
  '[Reports] Fetch reports count',
  props<{ id: number }>()
);

const fetchReportsCountSuccessAction = createAction(
  '[Reports] Fetch reports count success',
  props<{ reportsCount: ReportsCountModel }>()
);

const fetchReportsCountFailedAction = createAction('[Reports] Fetch reports count failed');

const fetchReportsHomeScoreStartAction = createAction(
  '[Reports] Fetch reports home score',
  props<{ id: number }>()
);

const fetchReportsHomeScoreSuccessAction = createAction(
  '[Reports] Fetch reports home score success',
  props<{ reportsList: ReportsListModel[] }>()
);

const fetchReportsHomeScoreFailedAction = createAction('[Reports] Fetch reports home score failed');

const createReportHomeScoreStartAction = createAction(
  '[Reports] Create report home score',
  props<{ id: number }>()
);

const createReportHomeScoreSuccessAction = createAction(
  '[Reports] Create report home score success',
  props<{ reportsList: ReportsListModel[] }>()
);

const createReportHomeScoreFailedAction = createAction('[Reports] Create report home score failed');

const removeReportStartAction = createAction('[Reports] Remove report', props<{ id: number }>());

const removeReportSuccessAction = createAction(
  '[Reports] Remove report success',
  props<{ reportsList: ReportsListModel[] }>()
);

const fetchReportsT24StartAction = createAction(
  '[Reports] Fetch reports t24',
  props<{ id: number }>()
);

const fetchReportsT24SuccessAction = createAction(
  '[Reports] Fetch reports t24 success',
  props<{ reportsList: ReportsListModel[] }>()
);

const fetchReportsT24FailedAction = createAction('[Reports] Fetch reports t24 failed');

const createReportT24StartAction = createAction(
  '[Reports] Create report t24',
  props<{ id: number }>()
);

const createReportT24SuccessAction = createAction(
  '[Reports] Create report t24 success',
  props<{ reportsList: ReportsListModel[] }>()
);

const createReportT24FailedAction = createAction('[Reports] Create report t24 failed');

const fetchReportsSmudStartAction = createAction(
  '[Reports] Fetch reports smud',
  props<{ id: number }>()
);

const fetchReportsSmudSuccessAction = createAction(
  '[Reports] Fetch reports smud success',
  props<{ reportsList: ReportsListModel[] }>()
);

const fetchReportsSmudFailedAction = createAction('[Reports] Fetch reports smud failed');

const createReportSmudStartAction = createAction(
  '[Reports] Create report smud',
  props<{ id: number }>()
);

const createReportSmudSuccessAction = createAction(
  '[Reports] Create report smud success',
  props<{ reportsList: ReportsListModel[] }>()
);

const createReportSmudFailedAction = createAction('[Reports] Create report smud failed');

const fetchReportsInspectionAgreementStartAction = createAction(
  '[Reports] Fetch inspection agreement reports',
  props<{ id: number }>()
);

const fetchReportsInspectionAgreementSuccessAction = createAction(
  '[Reports] Fetch inspection agreement reports success',
  props<{ reportsList: ReportsListModel[] }>()
);

const fetchReportsInspectionAgreementFailedAction = createAction(
  '[Reports] Fetch inspection agreement reports failed'
);

const createReportInspectionAgreementStartAction = createAction(
  '[Reports] Create inspection agreement report',
  props<{ id: number }>()
);

const createReportInspectionAgreementSuccessAction = createAction(
  '[Reports] Create inspection agreement report success',
  props<{ reportsList: ReportsListModel[] }>()
);

const createReportInspectionAgreementFailedAction = createAction(
  '[Reports] Create inspection agreement report failed'
);

const createReportHomeScoreRebateStartAction = createAction(
  '[Reports] Create home score rebate report',
  props<{ id: number }>()
);

const createReportHomeScoreRebateSuccessAction = createAction(
  '[Reports] Create home score rebate report success',
  props<{ reportsList: ReportsListModel[] }>()
);

const createReportHomeScoreRebateFailedAction = createAction(
  '[Reports] Create home score rebate report failed'
);

const fetchReportsBayrenStartAction = createAction(
  '[Reports] Fetch bayren reports',
  props<{ id: number }>()
);

const fetchReportsBayrenSuccessAction = createAction(
  '[Reports] Fetch bayren reports success',
  props<{ reportsList: ReportsListModel[] }>()
);

const fetchReportsBayrenFailedAction = createAction('[Reports] Fetch bayren reports failed');

const createReportBayrenStartAction = createAction(
  '[Reports] Create bayren report',
  props<{ id: number; create: ReportsBayernFormModel }>()
);

const createReportBayrenSuccessAction = createAction(
  '[Reports] Create bayren report success',
  props<{ reportsList: ReportsListModel[] }>()
);

const createReportBayrenFailedAction = createAction('[Reports] Create bayren report failed');

export const ReportsActions = {
  fetchReportsCountStartAction,
  fetchReportsCountSuccessAction,
  fetchReportsCountFailedAction,
  fetchReportsHomeScoreStartAction,
  fetchReportsHomeScoreSuccessAction,
  fetchReportsHomeScoreFailedAction,
  createReportHomeScoreStartAction,
  createReportHomeScoreSuccessAction,
  createReportHomeScoreFailedAction,
  removeReportStartAction,
  removeReportSuccessAction,
  fetchReportsT24StartAction,
  fetchReportsT24SuccessAction,
  fetchReportsT24FailedAction,
  createReportT24StartAction,
  createReportT24SuccessAction,
  createReportT24FailedAction,
  fetchReportsSmudStartAction,
  fetchReportsSmudSuccessAction,
  fetchReportsSmudFailedAction,
  createReportSmudStartAction,
  createReportSmudSuccessAction,
  createReportSmudFailedAction,
  fetchReportsInspectionAgreementStartAction,
  fetchReportsInspectionAgreementSuccessAction,
  fetchReportsInspectionAgreementFailedAction,
  createReportInspectionAgreementStartAction,
  createReportInspectionAgreementSuccessAction,
  createReportInspectionAgreementFailedAction,
  createReportHomeScoreRebateStartAction,
  createReportHomeScoreRebateSuccessAction,
  createReportHomeScoreRebateFailedAction,
  fetchReportsBayrenStartAction,
  fetchReportsBayrenSuccessAction,
  fetchReportsBayrenFailedAction,
  createReportBayrenStartAction,
  createReportBayrenSuccessAction,
  createReportBayrenFailedAction
};

const reportsActionsUnion = union(ReportsActions);

export type ReportsActionsType = typeof reportsActionsUnion;
