import { createReducer, on } from '@ngrx/store';

import { CheersActions, CheersActionsType } from './cheers.actions';
import { cheersInitialState, CheersState } from './cheers.state';

const reducer = createReducer(
  cheersInitialState,
  on(
    CheersActions.fetchCheersListStartAction,
    CheersActions.downloadDocumentUrStartAction,
    CheersActions.createCf1rCertificateStartAction,
    CheersActions.createCf2rCertificateStartAction,
    CheersActions.createCf3rCertificateStartAction,
    CheersActions.createAllCertificateStartAction,
    CheersActions.deleteDocumentStartAction,
    (state: CheersState): CheersState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    CheersActions.fetchCheersListSuccessAction,
    CheersActions.createCf1rCertificateSuccessAction,
    CheersActions.createCf2rCertificateSuccessAction,
    CheersActions.createCf3rCertificateSuccessAction,
    CheersActions.deleteDocumentSuccessAction,
    (state: CheersState, { cheersList }): CheersState => ({
      ...state,
      cheersList,
      isLoading: false
    })
  ),
  on(
    CheersActions.downloadDocumentUrlSuccessAction,
    CheersActions.createAllCertificateSuccessAction,
    (state: CheersState, { url }): CheersState => ({
      ...state,
      url,
      isLoading: false
    })
  ),
  on(
    CheersActions.fetchCheersListFailedAction,
    CheersActions.downloadDocumentUrlFailedAction,
    CheersActions.createCf1rCertificateFailedAction,
    CheersActions.createCf2rCertificateFailedAction,
    CheersActions.createCf3rCertificateFailedAction,
    CheersActions.createAllCertificateFailedAction,
    CheersActions.deleteDocumentFailedAction,
    (state: CheersState): CheersState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    CheersActions.resetUrlAction,
    (state: CheersState): CheersState => ({
      ...state,
      url: null
    })
  )
);

export function cheersReducer(state: CheersState, action: CheersActionsType): CheersState {
  return reducer(state, action);
}
