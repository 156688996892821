import { FormControl, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';
import Rpick from 'ramda/es/pick';
import Rprop from 'ramda/es/prop';

import { InputTypeEnum } from '@shared/enums';
import { UserModel } from '@shared/models';
// import {PermissionModel, UserModel} from '@shared/models';
import { WrapperEnum } from '@shared/modules/+formly-controls';
import { FormlyFieldsModel, UserResponseModel } from '../models';

const getEmailField = (translate: TranslateService, type: InputTypeEnum): FormlyFieldConfig => ({
  key: 'email',
  type: 'input',
  templateOptions: {
    placeholder: translate.instant('field.email.placeholder'),
    type,
    required: true,
    attributes: {
      class: 'form-control auth__input'
    }
  },
  validators: {
    validation: [Validators.required, Validators.email]
  },
  validation: {
    messages: {
      required: translate.instant('field.validation.required'),
      email: translate.instant('field.validation.email')
    }
  }
});

const getPasswordField = (
  translate: TranslateService,
  type: InputTypeEnum,
  placeholder?: string
): FormlyFieldConfig => ({
  key: 'password',
  type: 'input',
  templateOptions: {
    placeholder: translate.instant(placeholder || 'field.password.placeholder'),
    type,
    required: true,
    minLength: 8,
    attributes: {
      class: 'form-control auth__input'
    }
  },
  validators: {
    validation: [Validators.required, Validators.minLength]
  },
  validation: {
    messages: {
      required: translate.instant('field.validation.required'),
      minlength: translate.instant('field.validation.min-length', {
        minLength: 8
      })
    }
  }
});

const getPasswordConfirmationField = (
  translate: TranslateService,
  type: InputTypeEnum,
  placeholder?: string
): FormlyFieldConfig => ({
  key: 'password_confirmation',
  type: 'input',
  templateOptions: {
    placeholder: translate.instant(placeholder || 'field.password-confirmation.placeholder'),
    type,
    required: true,
    minLength: 8,
    attributes: {
      class: 'form-control auth__input'
    }
  },
  validators: {
    validation: [Validators.required, Validators.minLength],
    fieldMatch: {
      expression: (control: FormControl): boolean => control.value === control.root.value.password,
      message: translate.instant('field.validation.matching', {
        field: translate.instant('field.password.placeholder')
      })
    }
  },
  validation: {
    messages: {
      required: translate.instant('field.validation.required'),
      minlength: translate.instant('field.validation.min-length', {
        minLength: 8
      })
    }
  }
});

export const getLoginFormData = (translate: TranslateService): FormlyFieldsModel => ({
  fields: [
    getEmailField(translate, InputTypeEnum.Email),
    getPasswordField(translate, InputTypeEnum.Password)
  ],
  model: {
    email: '',
    password: ''
  }
});

export const getRegisterFormData = (translate: TranslateService): FormlyFieldsModel => ({
  fields: [
    {
      templateOptions: {
        label: 'field.section.company-information'
      },
      wrappers: [WrapperEnum.GroupTitle],
      fieldGroupClassName: 'formly-field__row',
      fieldGroup: [
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'company_name',
          templateOptions: {
            placeholder: translate.instant('field.company-name.placeholder'),
            required: true,
            minLength: 2,
            maxLength: 100,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required, Validators.minLength, Validators.maxLength]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required'),
              minlength: translate.instant('field.validation.min-length', {
                minLength: 2
              }),
              maxlength: translate.instant('field.validation.max-length', {
                maxLength: 100
              })
            }
          }
        },
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'contact_person',
          templateOptions: {
            placeholder: translate.instant('field.contact-person.placeholder'),
            required: true,
            minLength: 4,
            maxLength: 150,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required, Validators.minLength, Validators.maxLength]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required'),
              minlength: translate.instant('field.validation.min-length', {
                minLength: 4
              }),
              maxlength: translate.instant('field.validation.max-length', {
                maxLength: 150
              })
            }
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'formly-field__row',
      fieldGroup: [
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'address',
          templateOptions: {
            placeholder: translate.instant('field.address.placeholder'),
            required: true,
            minLength: 2,
            maxLength: 100,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required, Validators.minLength, Validators.maxLength]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required'),
              minlength: translate.instant('field.validation.min-length', {
                minLength: 2
              }),
              maxlength: translate.instant('field.validation.max-length', {
                maxLength: 100
              })
            }
          }
        },
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'city',
          templateOptions: {
            placeholder: translate.instant('field.city.placeholder'),
            required: true,
            minLength: 2,
            maxLength: 45,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required, Validators.minLength, Validators.maxLength]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required'),
              minlength: translate.instant('field.validation.min-length', {
                minLength: 2
              }),
              maxlength: translate.instant('field.validation.max-length', {
                maxLength: 45
              })
            }
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'formly-field__row',
      fieldGroup: [
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'zip',
          templateOptions: {
            placeholder: translate.instant('field.zip.placeholder'),
            type: InputTypeEnum.Number,
            required: true,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required')
            }
          }
        },
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'state',
          templateOptions: {
            placeholder: translate.instant('field.state.placeholder'),
            required: true,
            disabled: true,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required')
            }
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'formly-field__row',
      fieldGroup: [
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'cslb',
          templateOptions: {
            placeholder: translate.instant('field.cslb.placeholder'),
            required: true,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required')
            }
          }
        },
        {
          className: 'formly-field__two-column-field',
          type: 'phone-input',
          key: 'phone',
          templateOptions: {
            placeholder: translate.instant('field.phone.placeholder'),
            type: InputTypeEnum.Tel,
            required: true,
            minLength: 6,
            maxLength: 45,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required, Validators.minLength, Validators.maxLength]
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required'),
              minlength: translate.instant('field.validation.min-length', {
                minLength: 6
              }),
              maxlength: translate.instant('field.validation.max-length', {
                maxLength: 45
              })
            }
          }
        }
      ]
    },
    {
      templateOptions: {
        label: 'field.section.login-information'
      },
      wrappers: [WrapperEnum.GroupTitle],
      fieldGroupClassName: 'formly-field__row',
      fieldGroup: [
        {
          className: 'formly-field__two-column-field',
          ...getEmailField(translate, InputTypeEnum.Email)
        },
        {
          className: 'formly-field__two-column-field',
          type: 'input',
          key: 'email_confirmation',
          templateOptions: {
            placeholder: translate.instant('field.email-confirmation.placeholder'),
            type: InputTypeEnum.Email,
            required: true,
            attributes: {
              class: 'form-control auth__input'
            }
          },
          validators: {
            validation: [Validators.required, Validators.email],
            fieldMatch: {
              expression: (control: FormControl): boolean =>
                control.value === control.root.value.email,
              message: translate.instant('field.validation.matching', {
                field: translate.instant('field.email.placeholder')
              })
            }
          },
          validation: {
            messages: {
              required: translate.instant('field.validation.required'),
              email: translate.instant('field.validation.email')
            }
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'formly-field__row',
      fieldGroup: [
        {
          className: 'formly-field__two-column-field',
          ...getPasswordField(translate, InputTypeEnum.Password)
        },
        {
          className: 'formly-field__two-column-field',
          ...getPasswordConfirmationField(translate, InputTypeEnum.Password)
        }
      ]
    }
  ],
  model: {
    email: '',
    email_confirmation: '',
    password: '',
    password_confirmation: '',
    contact_person: '',
    company_name: '',
    address: '',
    city: '',
    state: 'California',
    zip: null,
    phone: '',
    cslb: '',
    terms_accepted: true
  }
});

export const getPasswordResetFormData = (translate: TranslateService): FormlyFieldsModel => ({
  fields: [getEmailField(translate, InputTypeEnum.Email)],
  model: {
    email: ''
  }
});

export const getResendVerificationEmailFormData = (
  translate: TranslateService
): FormlyFieldsModel => ({
  fields: [getEmailField(translate, InputTypeEnum.Email)],
  model: {
    email: ''
  }
});

export const getPasswordChangeFormData = (
  translate: TranslateService,
  token: string,
  email: string
): FormlyFieldsModel => ({
  fields: [
    getPasswordField(translate, InputTypeEnum.Password, 'field.new-password.placeholder'),
    getPasswordConfirmationField(
      translate,
      InputTypeEnum.Password,
      'field.new-password-confirmation.placeholder'
    ),
    getEmailField(translate, InputTypeEnum.Hidden),
    {
      key: 'token',
      type: 'input',
      templateOptions: {
        type: InputTypeEnum.Hidden,
        required: true,
        minLength: 8
      },
      validators: {
        validation: [Validators.required, Validators.minLength]
      },
      validation: {
        messages: {
          required: translate.instant('field.validation.required'),
          minlength: translate.instant('field.validation.min-length', {
            minLength: 8
          })
        }
      }
    }
  ],
  model: {
    email,
    token,
    password: '',
    password_confirmation: ''
  }
});

// export const getPermissions = (user: UserResponseModel): PermissionModel[] => {
//   const permissions = [];
//   user.roles.forEach(role => {
//     if (role.permissions.length > 0) {
//       permissions.push(...role.permissions);
//     }
//   });
//
//   return permissions;
// };

export const flattenUser = (user: UserResponseModel): UserModel => ({
  ...Rpick(['id', 'email', 'terms_accepted', 'terms_accepted_at', 'validations'], user),
  emailConfirmed: true,
  user_profile: {
    ...Rprop('user_profile', user),
    show_in_directory: Boolean(RpathOr(false, ['user_profile', 'show_in_directory'], user)),
    company_status: user.company_status,
    show_cheers: user.show_cheers
  },
  permissions: RpathOr([], ['role', 'permissions'], user),
  role: Romit(['permissions'], Rprop('role', user))
  // permissions: getPermissions(user),
  // role: Romit(['permissions'], Rprop('role', user)),
  // roles: user.roles
});
