import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import RpathOr from 'ramda/es/pathOr';
import { Observable } from 'rxjs';

import { MainMenuEnum, PermissionEnum, SessionStorageEnum, UserProfileTypeEnum } from '@shared/enums';
import {
  CommonRouteDataModel,
  CompanyModel,
  EquipmentInfoModel,
  GeneralModel,
  MainMenuModel,
  ProjectInspectionStepsModel,
  RouteParamsModel,
  SidebarCategoryModel,
  T24DataCollectionModel,
  UserProfileModel,
  UserValidationsModel
} from '@shared/models';
import { NotificationGroupModel } from '@shared/models/notification.model';
import { NotificationDataService } from '@shared/services/notification-data.service';
import { AppState } from '@store/app.state';
import { AuthActions } from '@store/auth/auth.actions';
import {
  getSelectedEmployeeFullNameState,
  getUserProfileState,
  getUserProfileTypeState,
  getUserValidationsState, hasPermissionState
} from '@store/auth/auth.selectors';
import { getGeneralState } from '@store/home-score';
import { LayoutActions } from '@store/layout/layout.actions';
import {
  getCompanyListState,
  getEmployeeListState,
  getMainMenuState,
  getSelectedCompanyIdState,
  getSelectedEmployeeIdState,
  getShowSidebarState,
  getShowSideMenuState,
  getShowUserDropdownState,
  getSidebarState
} from '@store/layout/layout.selectors';
import { getProjectInspectionStepsState } from '@store/projects';
import { getT24DataState } from '@store/t24-data-collection';

@Component({
  selector: 'common-container',
  templateUrl: './common-container.component.html',
  styleUrls: ['./common-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonContainerComponent implements OnInit {
  public userProfile$: Observable<UserProfileModel>;
  public showUserDropdown$: Observable<boolean>;
  public showSideMenu$: Observable<boolean>;
  public userValidations$: Observable<UserValidationsModel>;
  public selectedCompanyId: number;
  public selectedEmployeeId: number;
  public companyList$: Observable<CompanyModel[]>;
  public employeeList$: Observable<UserProfileModel[]>;
  public t24Data$: Observable<T24DataCollectionModel | EquipmentInfoModel>;
  public employeeFullName: string;
  public projectInspectionSteps$: Observable<ProjectInspectionStepsModel>;
  public general$: Observable<GeneralModel>;
  public notifications: NotificationGroupModel[] = [];

  public title: string;
  public subTitle: string;
  public menuId: MainMenuEnum;
  public showSidebar: boolean;
  public mainMenu: MainMenuModel[];
  public sidebar: SidebarCategoryModel;
  public showCompanySearch: boolean;
  public showEmployeeSearch: boolean;
  public isArchonEmployee: boolean;
  public params: RouteParamsModel;
  public showAppLoader: boolean;
  public showBackToProject: boolean;
  public canSeeBranches: boolean;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private notificationDataService: NotificationDataService
  ) {}

  public ngOnInit(): void {
    this.route.url.subscribe(() => {
      const { title, subTitle, menuId } = RpathOr(
        RpathOr(this.route.snapshot.data, ['firstChild', 'data'], this.route.snapshot),
        ['firstChild', 'firstChild', 'data'],
        this.route.snapshot
      ) as CommonRouteDataModel;
      this.title = title;
      this.subTitle = subTitle;
      this.menuId = menuId;
      this.showCompanySearch = [MainMenuEnum.CompanyProfile, MainMenuEnum.ProjectOption].includes(
        this.menuId
      );
      this.showEmployeeSearch =
        [MainMenuEnum.TimeClock].includes(this.menuId) &&
        this.subTitle !== 'common.time-clock.employee-list.sub-title';
      this.showAppLoader = !!this.menuId;

      this.updateMainMenu();

      const { path } = this.route.snapshot.routeConfig as CommonRouteDataModel;
      this.showBackToProject =
        [MainMenuEnum.TechniciansApp, MainMenuEnum.Cheers].includes(path) &&
        ![MainMenuEnum.ProjectInspections, MainMenuEnum.ManageProjects].includes(this.menuId);

      const { id, type } = this.route.snapshot.firstChild.params;
      this.params = {
        id,
        type
      };
    });

    this.userProfile$ = this.store.pipe(select(getUserProfileState));
    this.userValidations$ = this.store.pipe(select(getUserValidationsState));
    this.showUserDropdown$ = this.store.pipe(select(getShowUserDropdownState));
    this.showSideMenu$ = this.store.pipe(select(getShowSideMenuState));
    this.store.pipe(select(getSelectedEmployeeFullNameState)).subscribe((fullName: string) => {
      this.employeeFullName = this.showEmployeeSearch ? fullName : undefined;
      this.cd.markForCheck();
    });
    this.store.pipe(select(getShowSidebarState)).subscribe((showSidebar: boolean) => {
      this.showSidebar = showSidebar;
      this.cd.markForCheck();
    });
    this.store.pipe(select(getMainMenuState)).subscribe((menu: MainMenuModel[]) => {
      this.mainMenu = menu;
      this.cd.markForCheck();
    });
    this.store.pipe(select(getSidebarState)).subscribe((sidebar: SidebarCategoryModel) => {
      this.sidebar = sidebar;
      this.cd.markForCheck();
    });
    this.store.pipe(select(getSelectedCompanyIdState)).subscribe((id: number) => {
      this.selectedCompanyId = id;
      this.cd.markForCheck();
    });
    this.store.pipe(select(getSelectedEmployeeIdState)).subscribe((id: number) => {
      this.selectedEmployeeId = id;
      this.cd.markForCheck();
    });
    this.store
      .pipe(select(getUserProfileTypeState))
      .subscribe((userProfileType: UserProfileTypeEnum) => {
        this.isArchonEmployee = userProfileType === UserProfileTypeEnum.ArchonEmployee;
        this.cd.markForCheck();
      });
    this.store
      .pipe(select(hasPermissionState, PermissionEnum.CanSeeBranches))
      .subscribe((canSeeBranches: boolean) => {
        this.canSeeBranches = canSeeBranches;
        this.cd.markForCheck();
      });
    this.companyList$ = this.store.pipe(select(getCompanyListState));
    this.employeeList$ = this.store.pipe(select(getEmployeeListState));
    this.t24Data$ = this.store.pipe(select(getT24DataState));
    this.projectInspectionSteps$ = this.store.pipe(select(getProjectInspectionStepsState));
    this.general$ = this.store.pipe(select(getGeneralState));

    this.notificationDataService.getNotificationData().subscribe(notifications => {
      this.notifications = notifications;
      this.cd.markForCheck();
    });
  }

  public updateMainMenu(): void {
    /**
     * @route /
     * @dispatch [Layout] Init main menu
     */
    this.store.dispatch(LayoutActions.initMainMenuAction({ id: this.menuId }));

    const sessionCompanyId = +localStorage.getItem(SessionStorageEnum.SelectedCompany);

    if (this.showCompanySearch && !sessionCompanyId !== !this.selectedCompanyId) {
      this.onCompanySelect(sessionCompanyId);
    }

    if (!this.showCompanySearch && this.selectedCompanyId) {
      this.onCompanySelect(null);
    }

    const sessionEmployeeId = +localStorage.getItem(SessionStorageEnum.SelectedEmployee);

    if (this.showEmployeeSearch && !sessionEmployeeId !== !this.selectedEmployeeId) {
      this.onEmployeeSelect(sessionEmployeeId);
    }

    if (!this.showEmployeeSearch && this.selectedEmployeeId) {
      this.onEmployeeSelect(null);
    }
  }

  public openSubmenu(menuId: MainMenuEnum): void {
    /**
     * @route /
     * @dispatch [Layout] Open submenu
     */
    this.store.dispatch(LayoutActions.openSubmenuAction({ id: menuId }));
  }

  public toggleUserDropdown(): void {
    /**
     * @route /
     * @dispatch [Layout] Toggle user dropdown
     */
    this.store.dispatch(LayoutActions.toggleUserDropdownAction());
  }

  public logout(): void {
    /**
     * @route /
     * @dispatch [Auth] Logout
     */
    this.store.dispatch(AuthActions.logoutAction());
  }

  public toggleSideMenu(): void {
    /**
     * @route /
     * @dispatch [Layout] Toggle side menu
     */
    this.store.dispatch(LayoutActions.toggleSideMenuAction());
  }

  public toggleSidebar(): void {
    /**
     * @route /
     * @dispatch [Layout] Toggle sidebar
     */
    this.store.dispatch(LayoutActions.toggleSidebarAction());
  }

  public onCompanySelect(id: number): void {
    /**
     * @route /
     * @dispatch [Layout] Set selected company id
     */
    this.store.dispatch(LayoutActions.setSelectedCompanyIdAction({ id }));
  }

  public onEmployeeSelect(id: number): void {
    /**
     * @route /
     * @dispatch [Layout] Set selected employee id
     */
    this.store.dispatch(LayoutActions.setSelectedEmployeeIdAction({ id }));
  }
}
