import { OrderByEnum } from '@shared/enums';
import { OrderByModel } from '@shared/models';

export const ORDER_BY_LIST: OrderByModel[] = [
  {
    value: OrderByEnum.Asc,
    name: 'A-Z'
  },
  {
    value: OrderByEnum.Desc,
    name: 'Z-A'
  }
];
