import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthHeaderComponent {
  @Input() public title: string;
  @Input() public subTitle: string;
}
