import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResourceEnum } from '@shared/enums';
import {
  CustomBonusFormModel,
  EmployeeEarningModel,
  TaskFormModel,
  TaskModel,
  TaskSummaryModel,
  TaskUpdateFormModel,
  TimeClockWeekModel
} from '@shared/models';
import { getHttpHeaders, objToHttpParams } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class TimeClockHttpService {
  constructor(private http: HttpClient) {}

  public getTasksForToday(
    id: number
  ): Observable<{ tasks: TaskModel[]; summary: TaskSummaryModel }> {
    return this.http.get<{ tasks: TaskModel[]; summary: TaskSummaryModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_TIMECLOCK_URL,
        ApiResourceEnum.TimeClockTasksToday,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getTasksForDate(
    id: number,
    date: string
  ): Observable<{ tasks: TaskModel[]; summary: TaskSummaryModel }> {
    return this.http.get<{ tasks: TaskModel[]; summary: TaskSummaryModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_TIMECLOCK_URL,
        ApiResourceEnum.TimeClockTasksDate,
        id,
        date
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createTask(
    task: TaskFormModel
  ): Observable<{ tasks: TaskModel[]; summary: TaskSummaryModel }> {
    return this.http.post<{ tasks: TaskModel[]; summary: TaskSummaryModel }>(
      this.getEndpoint(environment.API_TIMECLOCK_URL, ApiResourceEnum.TimeClockTask),
      task,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getUserHistory(id: number, year: number): Observable<{ history: TimeClockWeekModel[] }> {
    return this.http.get<{ history: TimeClockWeekModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_TIMECLOCK_URL,
        ApiResourceEnum.TimeClockUserHistory,
        id
      ),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ year })
      }
    );
  }

  public removeTask(id: number): Observable<{ tasks: TaskModel[]; summary: TaskSummaryModel }> {
    return this.http.delete<{ tasks: TaskModel[]; summary: TaskSummaryModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_TIMECLOCK_URL,
        ApiResourceEnum.TimeClockRemoveTask,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createPastTask(
    task: TaskFormModel
  ): Observable<{ tasks: TaskModel[]; summary: TaskSummaryModel }> {
    return this.http.post<{ tasks: TaskModel[]; summary: TaskSummaryModel }>(
      this.getEndpoint(environment.API_TIMECLOCK_URL, ApiResourceEnum.TimeClockPastTask),
      task,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateTasks(
    tasks: TaskUpdateFormModel[]
  ): Observable<{ tasks: TaskModel[]; summary: TaskSummaryModel }> {
    return this.http.put<{ tasks: TaskModel[]; summary: TaskSummaryModel }>(
      this.getEndpoint(environment.API_TIMECLOCK_URL, ApiResourceEnum.TimeClockTasksUpdate),
      { tasks },
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getEmployeesEarning(date: string): Observable<EmployeeEarningModel[]> {
    return this.http.get<EmployeeEarningModel[]>(
      this.getEndpointWithRouteBinding(
        environment.API_TIMECLOCK_URL,
        ApiResourceEnum.TimeClockEarningDate,
        undefined,
        date
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public addCustomBonus(bonus: CustomBonusFormModel): Observable<EmployeeEarningModel> {
    return this.http.post<EmployeeEarningModel>(
      this.getEndpoint(environment.API_TIMECLOCK_URL, ApiResourceEnum.AddCustomBonus),
      bonus,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateCustomBonus(
    id: number,
    bonus: CustomBonusFormModel
  ): Observable<EmployeeEarningModel> {
    return this.http.put<EmployeeEarningModel>(
      this.getEndpointWithRouteBinding(
        environment.API_TIMECLOCK_URL,
        ApiResourceEnum.UpdateCustomBonus,
        id
      ),
      bonus,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public async generateReport(date: string): Promise<void> {
    this.http
      .get<BlobPart>(
        this.getEndpointWithRouteBinding(
          environment.API_TIMECLOCK_URL,
          ApiResourceEnum.TimeClockEarningReport,
          undefined,
          date
        ),
        {
          responseType: 'blob' as 'json',
          headers: getHttpHeaders()
        }
      )
      .pipe(map(response => new Blob([response], { type: 'application/pdf' })))
      .subscribe((pdf: Blob) => {
        const downloadURL = URL.createObjectURL(pdf);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.target = '_blank';
        link.click();
      });
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id?: number,
    date?: string
  ): string {
    let path: string = resource;
    if (id) {
      path = path.replace(':id', id.toString());
    }
    if (date) {
      path = path.replace(':date', date);
    }
    return `${baseUrl}/${path}`;
  }
}
