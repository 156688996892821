import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SessionExpirationService } from '@shared/modules/+session-expiration';
import { NotificationHttpService } from '@shared/services/notification-http.service';
import { AppState } from '@store/app.state';
import { AuthActions } from '@store/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit {
  public shouldShowSessionExpirationPopup$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private sessionExpirationService: SessionExpirationService,
    private notificationRequestService: NotificationHttpService
  ) {}

  public ngOnInit(): void {
    /**
     * @route /
     * @dispatch [Auth] Get user
     */
    this.store.dispatch(AuthActions.getUserAction());

    this.notificationRequestService.getNotifications();

    this.sessionExpirationService.refreshTimer();
    this.shouldShowSessionExpirationPopup$ = this.sessionExpirationService.getShouldShowPopup();
  }
}
