import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, interval, Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { gracePeriod, SESSION_EXPIRATION_CONFIG } from '../constants';
import { SessionExpirationPopupStatus } from '../enums';
import {
  defaultInitialSessionPopupStatus,
  SessionExpirationConfigModel,
  SessionExpirationStatus
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SessionExpirationService {
  private popupStatus$: BehaviorSubject<SessionExpirationStatus>;
  private sessionDuration: number;
  private sessionDurationWithGrace: number;
  private gracePeriodInMs: number;
  private timeoutHandler: Subscription;

  constructor(
    private http: HttpClient,
    private jwtService: JwtHelperService,
    private ngZone: NgZone,
    @Inject(SESSION_EXPIRATION_CONFIG)
    private config: SessionExpirationConfigModel
  ) {
    this.gracePeriodInMs = (this.config.gracePeriod || gracePeriod) * 60 * 1000;
    this.popupStatus$ = new BehaviorSubject(defaultInitialSessionPopupStatus);
    this.setSessionDuration();
  }

  public getShouldShowPopup(): Observable<boolean> {
    return this.popupStatus$.pipe(
      map(
        (popupType: SessionExpirationStatus): boolean =>
          popupType.popupStatus !== SessionExpirationPopupStatus.Hidden
      )
    );
  }

  public getPopupStatus(): Observable<SessionExpirationStatus> {
    return this.popupStatus$.asObservable();
  }

  public refreshSession(): Observable<boolean> {
    return this.http.get<{ token: string }>(this.config.refreshUrl).pipe(
      map(({ token }) => {
        localStorage.setItem(this.config.localStorageName, token);
        this.hidePopup();
        return true;
      }),
      catchError((error: HttpErrorResponse) => {
        if (navigator.onLine && error.status >= 400 && error.status <= 599) {
          this.showPopup(true);
        }
        return of(false);
      })
    );
  }

  public refreshTimer(): void {
    this.setSessionDuration();
    if (!this.sessionDuration) {
      return;
    }
    this.clearTimer();
    this.ngZone.runOutsideAngular(() => {
      this.timeoutHandler = interval(1000)
        .pipe(
          tap(() => {
            const currentTime = Date.now();
            if (currentTime >= this.sessionDurationWithGrace) {
              if (currentTime >= this.sessionDuration) {
                this.ngZone.run(() => this.showPopup(true));
              } else {
                this.ngZone.run(() => this.showPopup(false, this.sessionDuration));
              }
            }
          })
        )
        .subscribe();
    });
  }

  public hidePopup(): void {
    this.popupStatus$.next(defaultInitialSessionPopupStatus);
  }

  public showPopup(forceExpiration: boolean = false, expirationTime: number = 0): void {
    this.clearTimer();
    this.popupStatus$.next(
      forceExpiration
        ? { popupStatus: SessionExpirationPopupStatus.Expired, expirationTime }
        : { popupStatus: SessionExpirationPopupStatus.Default, expirationTime }
    );
  }

  private clearTimer(): void {
    if (this.timeoutHandler) {
      this.timeoutHandler.unsubscribe();
    }
  }

  private setSessionDuration(): void {
    const expirationDate =
      !this.jwtService.isTokenExpired() && this.jwtService.getTokenExpirationDate();
    if (expirationDate) {
      this.sessionDuration = expirationDate.getTime();
      this.sessionDurationWithGrace = this.sessionDuration - this.gracePeriodInMs;
    }
  }
}
