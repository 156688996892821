import RaddIndex from 'ramda/es/addIndex';
import Rmap from 'ramda/es/map';
import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';
import Rreject from 'ramda/es/reject';
import RwhereEq from 'ramda/es/whereEq';

import { ALPHABET } from '@shared/constants';
import { StatusTypeEnum } from '@shared/enums';
import { CompanyListResponseModel, CompanyModel, PaginationModel } from '@shared/models';

export const flattenCompaniesWithPagination = (
  data: CompanyListResponseModel,
  status: StatusTypeEnum | string
): { companies: CompanyModel[]; pagination: PaginationModel } => {
  let childNumber: number;
  let parentIndex: number = 0;
  return {
    companies: Rmap((company: CompanyModel) => {
      if (!company.parent_id || Boolean(status)) {
        childNumber = null;
        parentIndex += 1;
      } else {
        childNumber = childNumber ? childNumber + 1 : 1;
      }
      const displayIndex: string = `${parentIndex}${
        childNumber ? `${ALPHABET[childNumber - 1]}` : ''
      }`;
      return {
        ...company,
        displayIndex
      };
    }, RpathOr([], ['data'], data)),
    pagination: Romit(['data'], data)
  };
};

export const flattenCompanies = ({
  companies
}: {
  companies: CompanyModel[];
}): { companies: CompanyModel[] } => ({
  companies: RaddIndex(Rmap)(
    (company: CompanyModel, index: number) => ({
      ...company,
      displayIndex: (index + 1).toString()
    }),
    companies
  )
});

export const getCompaniesWithUpdatedStatus = (
  id: number,
  status: StatusTypeEnum,
  companies: CompanyModel[]
): CompanyModel[] =>
  Rmap(
    (company: CompanyModel) => ({
      ...company,
      status: company.id === id ? status : company.status
    }),
    companies
  );

export const getCompaniesWithoutRemoved = (id: number, companies: CompanyModel[]): CompanyModel[] =>
  Rreject(RwhereEq({ id }), companies);
