import { createReducer, on } from '@ngrx/store';

import { ReportsActions, ReportsActionsType } from './reports.actions';
import { reportsInitialState, ReportsState } from './reports.state';

const reducer = createReducer(
  reportsInitialState,
  on(
    ReportsActions.fetchReportsCountStartAction,
    ReportsActions.fetchReportsHomeScoreStartAction,
    ReportsActions.createReportHomeScoreStartAction,
    ReportsActions.fetchReportsT24StartAction,
    ReportsActions.createReportT24StartAction,
    ReportsActions.fetchReportsSmudStartAction,
    ReportsActions.createReportSmudStartAction,
    ReportsActions.fetchReportsInspectionAgreementStartAction,
    ReportsActions.createReportInspectionAgreementStartAction,
    ReportsActions.fetchReportsBayrenStartAction,
    (state: ReportsState): ReportsState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    ReportsActions.createReportBayrenStartAction,
    ReportsActions.createReportHomeScoreRebateStartAction,
    (state: ReportsState): ReportsState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    ReportsActions.fetchReportsCountSuccessAction,
    (state: ReportsState, { reportsCount }): ReportsState => ({
      ...state,
      reportsCount,
      isLoading: false
    })
  ),
  on(
    ReportsActions.fetchReportsHomeScoreSuccessAction,
    ReportsActions.createReportHomeScoreSuccessAction,
    ReportsActions.fetchReportsT24SuccessAction,
    ReportsActions.createReportT24SuccessAction,
    ReportsActions.fetchReportsSmudSuccessAction,
    ReportsActions.createReportSmudSuccessAction,
    ReportsActions.fetchReportsInspectionAgreementSuccessAction,
    ReportsActions.createReportInspectionAgreementSuccessAction,
    ReportsActions.fetchReportsBayrenSuccessAction,
    ReportsActions.createReportBayrenSuccessAction,
    (state: ReportsState, { reportsList }): ReportsState => ({
      ...state,
      reportsList,
      isLoading: false
    })
  ),
  on(
    ReportsActions.createReportHomeScoreRebateSuccessAction,
    (state: ReportsState, { reportsList }): ReportsState => ({
      ...state,
      reportsList,
      isLoading: false
    })
  ),
  on(
    ReportsActions.fetchReportsCountFailedAction,
    ReportsActions.fetchReportsHomeScoreFailedAction,
    ReportsActions.createReportHomeScoreFailedAction,
    ReportsActions.fetchReportsT24FailedAction,
    ReportsActions.createReportT24FailedAction,
    ReportsActions.fetchReportsSmudFailedAction,
    ReportsActions.createReportSmudFailedAction,
    ReportsActions.fetchReportsInspectionAgreementFailedAction,
    ReportsActions.createReportInspectionAgreementFailedAction,
    ReportsActions.fetchReportsBayrenFailedAction,
    (state: ReportsState): ReportsState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    ReportsActions.createReportBayrenFailedAction,
    ReportsActions.createReportHomeScoreRebateFailedAction,
    (state: ReportsState): ReportsState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    ReportsActions.removeReportSuccessAction,
    (state: ReportsState, { reportsList }): ReportsState => ({
      ...state,
      reportsList
    })
  )
);

export function reportsReducer(state: ReportsState, action: ReportsActionsType): ReportsState {
  return reducer(state, action);
}
