import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { StatusSectionEnum, StepsFormType } from '@app/modules/ProjectRequest/models/Enums';
import { HesDataModel } from '@app/modules/ProjectRequest/models/hes-data.model';
import { InspectionStatusModel } from '@app/modules/ProjectRequest/models/inspection-status.model';
import { InspectionModel } from '@app/modules/ProjectRequest/models/inspection.model';
import { NoteDataModel } from '@app/modules/ProjectRequest/models/note-data.model';
import { RebateDataModel } from '@app/modules/ProjectRequest/models/rebate-data.model';
import { StatusModel } from '@app/modules/ProjectRequest/models/status.model';

@Injectable()
export class DataService {
  public changeStatus = new Subject<any>();
  public chooseCity = new Subject<any>();
  public chooseZipcode = new Subject<any>();
  public chooseHomeId = new Subject<any>();
  public chooseCompanyId = new Subject<any>();
  public openRequestId = new Subject<any>();
  public formType = new Subject<any>();
  public T24services = new Subject<any>();
  public statusRequestId = new Subject<any>();
  public openTab = new Subject<any>();
  public dataValue = new Subject<any>();
  public openHvacTab = new Subject<any>();
  public invoiceItems = new Subject<any>();
  public searchData = new Subject<any>();
  public projectData = new Subject<any>();
  public permitDetailsData = new Subject<any>();
  public permitClosureDetailsData = new Subject<any>();
  public t24SystemsData = new Subject<any>();
  public changePage = new BehaviorSubject<boolean>(false);
  public paymentMethods = new BehaviorSubject<any>([]);
  public inspections = new BehaviorSubject<InspectionModel[]>([]);
  public t24Statuses = new BehaviorSubject<InspectionStatusModel[]>([]);
  public rebateData = new BehaviorSubject<RebateDataModel[]>([]);
  public hesData = new BehaviorSubject<HesDataModel[]>([]);
  public regeneratedCertificateData = new BehaviorSubject<boolean>(false);
  public projectStatus = new BehaviorSubject<StatusModel>({
    id: 1,
    name: '',
    description: '',
    code: 1,
    ordinance: 1,
    type: '',
    section: StatusSectionEnum.Project,
    created_at: '',
    updated_at: ''
  });
  public noteData = new BehaviorSubject<NoteDataModel[]>([]);

  public changeStatusTo(item: number): void {
    this.changeStatus.next(item);
  }

  public saveCity(id: number): void {
    this.chooseCity.next(id);
  }

  public saveZipCode(id: number): void {
    this.chooseZipcode.next(id);
  }

  public saveHomeId(id: number): void {
    this.chooseHomeId.next(id);
  }

  public saveCompanyId(id: number): void {
    this.chooseCompanyId.next(id);
  }

  public saveRequestId(id: number): void {
    this.openRequestId.next(id);
  }

  public stepFormType(type: StepsFormType): void {
    this.formType.next(type);
  }

  public saveT24services(id: number): void {
    this.T24services.next(id);
  }

  public saveRequestStatus(value: any): void {
    this.statusRequestId.next(value);
  }
  public activatedTab(value: any): void {
    this.openTab.next(value);
  }

  public activatedHvacTab(value: boolean): void {
    this.openHvacTab.next(value);
  }

  public sendData(value: any): void {
    this.dataValue.next(value);
  }

  public setInvoiceItems(projectRequests: any): void {
    let requestNumber = 1;
    const invoices = [];
    projectRequests.forEach(projectRequest => {
      projectRequest.invoice_items.forEach(invoice => {
        const oneInvoice = invoice;
        oneInvoice.request_number = requestNumber;

        invoices.push(oneInvoice);
      });
      requestNumber++;
    });

    this.invoiceItems.next(invoices);
  }

  public setInvoices(invoiceItems: any): void {
    this.invoiceItems.next(invoiceItems);
  }

  public getInvoiceItems(): Observable<any> {
    return this.invoiceItems.asObservable();
  }

  public setSearchData(searchData: any): void {
    this.searchData = searchData;
  }

  public getSearchData(): Observable<any> {
    return this.searchData.asObservable();
  }

  public setProjectData(projectData: any): void {
    this.projectData.next(projectData);
  }

  public getProjectData(): Observable<any> {
    return this.projectData.asObservable();
  }

  public setPermitDetailsData(permitDetailsData: any): void {
    this.permitDetailsData.next(permitDetailsData);
  }

  public getPermitDetailsData(): Observable<any> {
    return this.permitDetailsData.asObservable();
  }

  public setPermitClosureDetailsData(permitClosureDetailsData: any): void {
    this.permitClosureDetailsData.next(permitClosureDetailsData);
  }

  public getPermitClosureDetailsData(): Observable<any> {
    return this.permitClosureDetailsData.asObservable();
  }

  public setT24SystemsData(t24SystemsData: any): void {
    this.t24SystemsData.next(t24SystemsData);
  }

  public getT24SystemsData(): Observable<any> {
    return this.t24SystemsData.asObservable();
  }

  public setInspections(inspections: InspectionModel[]): void {
    this.inspections.next(inspections);
  }

  public getInspections(): Observable<InspectionModel[]> {
    return this.inspections.asObservable();
  }

  public setT24Statuses(t24Statuses: InspectionStatusModel[]): void {
    this.t24Statuses.next(t24Statuses);
  }

  public getT24Statuses(): Observable<InspectionStatusModel[]> {
    return this.t24Statuses.asObservable();
  }

  public setRebateData(rebateData: RebateDataModel[]): void {
    this.rebateData.next(rebateData);
  }

  public getRebateData(): Observable<RebateDataModel[]> {
    return this.rebateData.asObservable();
  }

  public setProjectStatus(projectStatus: StatusModel): void {
    this.projectStatus.next(projectStatus);
  }

  public getProjectStatus(): Observable<StatusModel> {
    return this.projectStatus.asObservable();
  }

  public setHesData(hesData: HesDataModel[]): void {
    this.hesData.next(hesData);
  }

  public getHesData(): Observable<HesDataModel[]> {
    return this.hesData.asObservable();
  }

  public setPaymentMethods(paymentMethods: any[]): void {
    this.paymentMethods.next(paymentMethods);
  }

  public getPaymentMethods(): Observable<any> {
    return this.paymentMethods.asObservable();
  }

  public setChangePage(status: boolean): void {
    this.changePage.next(status);
  }

  public getChangePage(): Observable<boolean> {
    return this.changePage.asObservable();
  }

  public setNoteData(notes: NoteDataModel[]): void {
    this.noteData.next(notes);
  }

  public getNoteData(): Observable<NoteDataModel[]> {
    return this.noteData.asObservable();
  }

  public addNoteData(note: NoteDataModel): void {
    const notes = this.noteData.getValue();
    notes.unshift(note);

    this.setNoteData(notes);
  }

  public deleteNoteData(noteId: number): void {
    const notes = this.noteData.getValue();
    const noteIndex = notes.findIndex(e => e.id === noteId);
    notes.splice(noteIndex, 1);

    this.setNoteData(notes);
  }

  public updateNoteData(note: NoteDataModel): void {
    const notes = this.noteData.getValue();
    const noteIndex = notes.findIndex(e => e.id === note.id);
    notes[noteIndex] = note;

    this.setNoteData(notes);
  }

  public setRegeneratedCertificateData(regenerated: boolean): void {
    this.regeneratedCertificateData.next(regenerated);
  }

  public getRegeneratedCertificateData(): Observable<boolean> {
    return this.regeneratedCertificateData.asObservable();
  }
}
