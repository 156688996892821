import {
  CompanyModel,
  MainMenuModel,
  SidebarCategoryModel,
  UserProfileModel
} from '@shared/models';

export interface LayoutState {
  main_menu: MainMenuModel[];
  sidebar: SidebarCategoryModel;
  showSideMenu: boolean;
  showSidebar: boolean;
  showUserDropdown: boolean;
  selectedCompanyId: number;
  companyList: CompanyModel[];
  selectedEmployeeId: number;
  employeeList: UserProfileModel[];
}

export const layoutInitialState: LayoutState = {
  main_menu: [],
  sidebar: null,
  showSideMenu: true,
  showSidebar: false,
  showUserDropdown: false,
  selectedCompanyId: null,
  companyList: [],
  selectedEmployeeId: null,
  employeeList: []
};

export const LAYOUT_FEATURE = 'layout';
