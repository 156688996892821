export const environment = {
    API_DOMAIN: 'office-api.test.archon.energy',
    API_TECHNICIANS_DOMAIN: 'technician-api.test.archon.energy',
    API_TIMECLOCK_DOMAIN: 'timeclock-api.test.archon.energy',
    API_URL: 'https://office-api.test.archon.energy/api',
    API_TECHNICIANS_URL: 'https://technician-api.test.archon.energy/api',
    API_TIMECLOCK_URL: 'https://timeclock-api.test.archon.energy/api',
    PUSHER_HOST: 'socket.test.archon.energy',
    PUSHER_PORT: 6001,
    PUSHER_AUTH_ENDPOINT: 'https://socket.test.archon.energy/api/broadcasting/auth',
    PUSHER_FORCE_TLS: true,
    PUSHER_APP_KEY: 'f3e9de194c7d6e6c9bc676b80ed88c72',
    production: true
};
