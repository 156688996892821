import Rmap from 'ramda/es/map';
import Rreject from 'ramda/es/reject';
import RwhereEq from 'ramda/es/whereEq';

import { FileModel } from '@shared/models';

export const getDocumentsWithoutRemoved = (id: number, documents: FileModel[]): FileModel[] =>
  Rreject(RwhereEq({ id }), documents);

export const getDocumentsWithNameUpdates = (
  id: number,
  fileName: string,
  documents: FileModel[]
): FileModel[] =>
  Rmap(
    (document: FileModel) => ({
      ...document,
      file_name: document.id === id ? fileName : document.file_name
    }),
    documents
  );
