import { PermissionTypeEnum } from '@shared/enums';

export const PERMISSION_TYPE_LIST: PermissionTypeEnum[] = [
  PermissionTypeEnum.BuildingDepartmentNotes,
  PermissionTypeEnum.DocumentStorage,
  PermissionTypeEnum.Finance,
  PermissionTypeEnum.Inspections,
  PermissionTypeEnum.Permits,
  PermissionTypeEnum.ProjectCreation,
  PermissionTypeEnum.ReportsAnalytics,
  PermissionTypeEnum.ScheduleSection,
  PermissionTypeEnum.ServicePrices,
  PermissionTypeEnum.TaskManagement,
  PermissionTypeEnum.TechnicalSupport,
  PermissionTypeEnum.TimeClock,
  PermissionTypeEnum.UserManagement,
  PermissionTypeEnum.VehicleManagement
];
