import Rmap from 'ramda/es/map';

import {
  CompanyStatusEnum,
  MainMenuEnum,
  MainMenuRouteEnum,
  PermissionEnum,
  PositionTypeEnum,
  StatusTypeEnum,
  UserProfileTypeEnum
} from '@shared/enums';
// import {SidebareMap} from '@shared/mappings';
import {
  MainMenuModel,
  PermissionModel,
  SidebarCategoryModel,
  UserProfileModel,
  UserValidationsModel
} from '@shared/models';
import { getAdminPanelMenu } from '@shared/utils/admin-panel-menu.utils';
import { getCompanyProfileMenu } from '@shared/utils/company-profile-menu.utils';
import { getProjectOptionMenu } from '@shared/utils/project-option.menu.utils';
import { getT24DataCollectionMenu } from '@shared/utils/t24-data-collection-menu.utils';
import { getTechniciansAppMenu } from '@shared/utils/technicians-app-menu.utils';
import { getTimeClockMenu } from '@shared/utils/time-clock-menu.utils';

// const filterSidebarMenu = (id: MainMenuEnum): SidebarCategoryModel => ({
//   ...SidebareMap[id],
//   group: [SidebareMap[id].group[0]]
// });

const projectOptionsPermissions = [
  PermissionEnum.CanSeeHomeOwners,
  PermissionEnum.CanSeeSalesPerson,
  PermissionEnum.CanSeeInstallers,
  PermissionEnum.CanSeeTitle24Options,
  PermissionEnum.CanSeeRebateCompanies,
  PermissionEnum.CanSeeHomeEnergyScore,
  PermissionEnum.CanSeeAuthorizationLetters,
  PermissionEnum.CanSeePermitContractors,
  PermissionEnum.CanSeeBusinessLicenses,
  PermissionEnum.CanSeeBuildingDepartmentNote
];

export const getMainMenu = (
  userType: UserProfileTypeEnum,
  permissions: PermissionModel[],
  userProfile: UserProfileModel,
  userValidations: UserValidationsModel
): MainMenuModel[] => [
  {
    id: MainMenuEnum.Dashboard,
    name: 'navigation.main-menu.dashboard',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Dashboard,
    show:
      userType === UserProfileTypeEnum.CompanyEmployee &&
      userProfile.status === StatusTypeEnum.Active &&
      userProfile.company_status !== CompanyStatusEnum.Restricted &&
      userValidations.company_info &&
      userValidations.accounts &&
      userValidations.company_employees &&
      userValidations.general_contacts &&
      userValidations.payment_info
  },
  {
    id: MainMenuEnum.Project,
    name: 'navigation.main-menu.projects',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Project,
    show:
      userProfile.status === StatusTypeEnum.Active &&
      userValidations.company_info &&
      userValidations.accounts &&
      userValidations.company_employees &&
      userValidations.general_contacts &&
      userValidations.payment_info,
    subMenu: [
      {
        id: MainMenuEnum.GeneralProject,
        name: 'navigation.sub-menu.general-projects',
        hasSidebar: false,
        route: MainMenuRouteEnum.GeneralProject,
        show: userProfile.position !== PositionTypeEnum.Inspector
      },
      {
        id: MainMenuEnum.PermiProject,
        name: 'navigation.sub-menu.permit-projects',
        hasSidebar: false,
        route: MainMenuRouteEnum.PermiProject,
        show:
          userProfile.position !== PositionTypeEnum.Inspector &&
          userProfile.company_status !== CompanyStatusEnum.Restricted
      },
      {
        id: MainMenuEnum.InspectionList,
        name: 'navigation.sub-menu.inspection-list',
        hasSidebar: false,
        route: MainMenuRouteEnum.InspectionList,
        show: userProfile.company_status !== CompanyStatusEnum.Restricted
      },
      {
        id: MainMenuEnum.PermitClosureList,
        name: 'navigation.sub-menu.permit-closure-list',
        hasSidebar: false,
        route: MainMenuRouteEnum.PermitClosureList,
        show:
          userProfile.position !== PositionTypeEnum.Inspector &&
          userProfile.company_status !== CompanyStatusEnum.Restricted
      }
    ]
  },
  {
    id: MainMenuEnum.Schedule,
    name: 'navigation.main-menu.schedule',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: true,
    route: MainMenuRouteEnum.Schedule,
    show:
      (userType === UserProfileTypeEnum.ArchonEmployee ||
        userType === UserProfileTypeEnum.CompanyEmployee) &&
      userProfile.company_status !== CompanyStatusEnum.Restricted &&
      userValidations.company_info &&
      userValidations.accounts &&
      userValidations.company_employees &&
      userValidations.general_contacts &&
      userValidations.payment_info
  },
  {
    id: MainMenuEnum.Finance,
    name: 'navigation.main-menu.finance',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Finance,
    show:
      permissions.some(({ name }) => name === PermissionEnum.CanSeeFinance) &&
      userProfile.status === StatusTypeEnum.Active &&
      userValidations.company_info &&
      userValidations.accounts &&
      userValidations.company_employees &&
      userValidations.general_contacts &&
      userValidations.payment_info
  },
  {
    id: MainMenuEnum.Reports,
    name: 'navigation.main-menu.reports',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Reports,
    show: permissions.some(({ name }) => name === PermissionEnum.CanSeeReports),
    subMenu: [
      {
        id: MainMenuEnum.ReportInspections,
        name: 'navigation.sub-menu.inspection-report',
        hasSidebar: false,
        route: MainMenuRouteEnum.ReportInspections,
        show: true
      },
      {
        id: MainMenuEnum.ReportPermit,
        name: 'navigation.sub-menu.permit-report',
        hasSidebar: false,
        route: MainMenuRouteEnum.ReportPermit,
        show: true
      }
    ]
  },
  {
    id: MainMenuEnum.Inventory,
    name: 'navigation.main-menu.inventory',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Inventory,
    show: userType === UserProfileTypeEnum.ArchonEmployee,
    subMenu: [
      {
        id: MainMenuEnum.Equipments,
        name: 'navigation.sub-menu.equipments',
        hasSidebar: false,
        route: MainMenuRouteEnum.Equipments,
        show: true
      },
      {
        id: MainMenuEnum.MiscParts,
        name: 'navigation.sub-menu.misc-parts',
        hasSidebar: false,
        route: MainMenuRouteEnum.MiscParts,
        show: true
      },
      {
        id: MainMenuEnum.VehicleFleet,
        name: 'navigation.sub-menu.vehicle-fleet',
        hasSidebar: false,
        route: MainMenuRouteEnum.VehicleFleet,
        show: true
      }
    ]
  },
  {
    id: MainMenuEnum.TimeClock,
    name: 'navigation.main-menu.time-clock',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.TimeClock,
    show: userType === UserProfileTypeEnum.ArchonEmployee
  },
  {
    id: MainMenuEnum.CommSchedule,
    name: 'navigation.main-menu.comm-schedule',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.CommSchedule,
    show: userType === UserProfileTypeEnum.ArchonEmployee,
    subMenu: [
      {
        id: MainMenuEnum.CommScheduleInspection,
        name: 'navigation.sub-menu.comm-schedule',
        hasSidebar: false,
        route: MainMenuRouteEnum.CommScheduleInspection,
        show: true
      },
      {
        id: MainMenuEnum.CommScheduleClosure,
        name: 'navigation.sub-menu.comm-schedule-closure',
        hasSidebar: false,
        route: MainMenuRouteEnum.CommScheduleClosure,
        show: true
      },
      {
        id: MainMenuEnum.CommSchedulePermit,
        name: 'navigation.sub-menu.comm-schedule-permit',
        hasSidebar: false,
        route: MainMenuRouteEnum.CommSchedulePermit,
        show: true
      },
      {
        id: MainMenuEnum.CommScheduleRebate,
        name: 'navigation.sub-menu.comm-schedule-rebate',
        hasSidebar: false,
        route: MainMenuRouteEnum.CommScheduleRebate,
        show: true
      }
    ]
  },
  {
    id: MainMenuEnum.CompanyProfile,
    name: 'navigation.sub-menu.company-profile',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.CompanyProfile,
    show:
      userType !== UserProfileTypeEnum.ArchonEmployee &&
      permissions.some(({ name }) => name === PermissionEnum.CanSeeCompanyProfile)
  },
  {
    id: MainMenuEnum.ProjectOption,
    name: 'navigation.sub-menu.project-options',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.ProjectOption,
    show:
      userType !== UserProfileTypeEnum.ArchonEmployee &&
      userProfile.company_status !== CompanyStatusEnum.Restricted &&
      permissions.some(({ name }) => projectOptionsPermissions.includes(name))
  },
  {
    id: MainMenuEnum.Document,
    name: 'navigation.main-menu.documents',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Document,
    show:
      userProfile.status === StatusTypeEnum.Active &&
      userProfile.company_status !== CompanyStatusEnum.Restricted &&
      userValidations.company_info &&
      userValidations.accounts &&
      userValidations.company_employees &&
      userValidations.general_contacts &&
      userValidations.payment_info
  },
  {
    id: MainMenuEnum.Setting,
    name: 'navigation.main-menu.settings',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Setting,
    show:
      userType === UserProfileTypeEnum.ArchonEmployee &&
      (permissions.some(({ name }) => name === PermissionEnum.CanSeeAdmin) ||
        permissions.some(({ name }) => name === PermissionEnum.CanSeeCompanyProfile) ||
        permissions.some(({ name }) => name === PermissionEnum.CanSeeFinance) ||
        permissions.some(({ name }) => projectOptionsPermissions.includes(name))),
    subMenu: [
      {
        id: MainMenuEnum.AdminPanel,
        name: 'navigation.sub-menu.admin-panel',
        hasSidebar: true,
        route: MainMenuRouteEnum.AdminPanel,
        show:
          userType === UserProfileTypeEnum.ArchonEmployee &&
          (permissions.some(({ name }) => name === PermissionEnum.CanSeeAdmin) ||
            permissions.some(({ name }) => name === PermissionEnum.CanSeeFinance))
      },
      {
        id: MainMenuEnum.CompanyProfile,
        name: 'navigation.sub-menu.company-profile',
        hasSidebar: true,
        route: MainMenuRouteEnum.CompanyProfile,
        show: permissions.some(({ name }) => name === PermissionEnum.CanSeeCompanyProfile)
      },
      {
        id: MainMenuEnum.ProjectOption,
        name: 'navigation.sub-menu.project-options',
        hasSidebar: true,
        route: MainMenuRouteEnum.ProjectOption,
        show: permissions.some(({ name }) => projectOptionsPermissions.includes(name))
      }
    ]
  }
];

export const toggleSubmenu = (menu: MainMenuModel[], id: MainMenuEnum): MainMenuModel[] =>
  Rmap((item: MainMenuModel) => ({ ...item, isOpen: item.id === id ? !item.isOpen : false }), menu);

export const getSidebarMenu = (
  id: MainMenuEnum,
  permissions: PermissionModel[],
  userProfile: UserProfileModel
): SidebarCategoryModel => {
  let returnValue: SidebarCategoryModel = null;

  switch (id) {
    case MainMenuEnum.CompanyProfile:
      returnValue = getCompanyProfileMenu(permissions, userProfile);
      break;
    case MainMenuEnum.TimeClock:
      returnValue = getTimeClockMenu();
      break;
    case MainMenuEnum.AdminPanel:
      returnValue = getAdminPanelMenu(permissions);
      break;
    case MainMenuEnum.ProjectOption:
      returnValue = getProjectOptionMenu(permissions);
      break;
    case MainMenuEnum.TechniciansApp:
      returnValue = getTechniciansAppMenu();
      break;
    case MainMenuEnum.T24DataCollection:
      returnValue = getT24DataCollectionMenu();
      break;
  }

  return returnValue;
};
