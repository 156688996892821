import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthRouterPathEnum, MainMenuEnum, PermissionEnum, UserProfileTypeEnum } from '@shared/enums';
import { AuthGuard } from '@shared/guards';
import { ActiveUserGuard } from '@shared/guards/active-user.guard';
import { AuthContainerComponent } from './layouts/+auth/';
import { CommonContainerComponent } from './layouts/+common/';
import { DataService } from './modules/ProjectRequest/services/data.service';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'auth',
        component: AuthContainerComponent,
        loadChildren: () => import('@app/+auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard, ActiveUserGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'project-request',
        canActivate: [AuthGuard, ActiveUserGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./modules/ProjectRequest/project-request.module').then(
            module => module.ProjectRequestModule
          )
      },
      {
        path: 'scheduling',
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./modules/scheduling/scheduling.module').then(module => module.SchedulingModule)
      },
      {
        path: 'scheduler',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/scheduler/scheduler.module').then(module => module.SchedulerModule)
      },
      {
        path: 'analytics/installer',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/installer-analytics/installer-analytics.module').
            then(module => module.InstallerAnalyticsModule)
      },
      {
        path: 'invoices',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        data: {
          permissions: [PermissionEnum.CanSeeFinance]
        },
        loadChildren: () =>
          import('./modules/invoices/invoices.module').then(module => module.InvoicesModule)
      },
      {
        path: 'communication-scheduling',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        data: {
          permissions: [PermissionEnum.CanSeeCommSchedule]
        },
        loadChildren: () =>
          import('./modules/communicationScheduling/communication-scheduling.module')
            .then(module => module.CommunicationSchedulingModule)
      },
      {
        path: 'notifications',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(module => module.NotificationsModule)
      },
      {
        path: 'inventory',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        data: {
          permissions: [PermissionEnum.CanSeeInventory]
        },
        loadChildren: () =>
          import('./modules/inventory/inventory.module').then(module => module.InventoryModule)
      },
      {
        path: 'documents',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/documents/documents.module').then(module => module.DocumentsModule)
      },
      {
        path: 'report',
        component: CommonContainerComponent,
        canActivate: [AuthGuard],
        data: {
          permissions: [PermissionEnum.CanSeeReports]
        },
        loadChildren: () =>
          import('./modules/reports/reports.module').then(module => module.ReportsModule)
      },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          menuId: MainMenuEnum.Project,
          userTypes: [UserProfileTypeEnum.ArchonEmployee, UserProfileTypeEnum.CompanyEmployee]
        },
        children: [
          {
            path: 'general',
            data: {
              menuId: MainMenuEnum.GeneralProject
            },
            redirectTo: '/projects'
          },
          {
            path: 'permit',
            data: {
              menuId: MainMenuEnum.PermiProject
            },
            redirectTo: '/projects'
          }
        ]
      },
      {
        path: 'schedule',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          menuId: MainMenuEnum.Schedule,
          userTypes: [UserProfileTypeEnum.ArchonEmployee, UserProfileTypeEnum.CompanyEmployee]
        }
      },
      {
        path: 'finance',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          menuId: MainMenuEnum.Finance,
          userTypes: [UserProfileTypeEnum.ArchonEmployee, UserProfileTypeEnum.CompanyEmployee]
        }
      },
      {
        path: 'documents',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          menuId: MainMenuEnum.Document,
          userTypes: [UserProfileTypeEnum.ArchonEmployee, UserProfileTypeEnum.CompanyEmployee]
        }
      },
      {
        path: 'time-clock',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          userTypes: [UserProfileTypeEnum.ArchonEmployee]
        },
        loadChildren: () =>
          import('@app/+time-clock/time-clock.module').then(m => m.TimeClockModule)
      },
      {
        path: 'comm-schedule/inspection',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          permissions: [PermissionEnum.CanSeeCommSchedule]
        },
        loadChildren: () =>
          import('@app/+comm-schedule/comm-schedule.module').then(m => m.CommScheduleModule)
      },
      {
        path: 'comm-schedule/closure',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          permissions: [PermissionEnum.CanSeeCommSchedule]
        },
        loadChildren: () =>
          import('@app/+comm-schedule-closure/comm-schedule-closure.module').then(
            m => m.CommScheduleClosureModule
          )
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          menuId: MainMenuEnum.Setting,
          userTypes: [UserProfileTypeEnum.ArchonEmployee, UserProfileTypeEnum.CompanyEmployee]
        },
        children: [
          {
            path: 'admin',
            canActivate: [AuthGuard],
            data: {
              userTypes: [UserProfileTypeEnum.ArchonEmployee],
              permissions: [PermissionEnum.CanSeeAdmin, PermissionEnum.CanSeeFinance]
            },
            loadChildren: () => import('@app/+admin/admin.module').then(m => m.AdminModule)
          },
          {
            path: 'contractor',
            loadChildren: () =>
              import('@app/+contractor/contractor.module').then(m => m.ContractorModule)
          },
          {
            path: 'project-options',
            loadChildren: () =>
              import('@app/+project-options/project-options.module').then(
                m => m.ProjectOptionsModule
              )
          }
        ]
      },
      {
        path: 'technicians-app',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          userTypes: [UserProfileTypeEnum.ArchonEmployee]
        },
        loadChildren: () =>
          import('@app/+technicians-app/technicians-app.module').then(m => m.TechniciansAppModule)
      },
      {
        path: 'cheers',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        data: {
          userTypes: [UserProfileTypeEnum.ArchonEmployee]
        },
        loadChildren: () => import('@app/+cheers/cheers.module').then(m => m.CheersModule)
      },
      { path: '', redirectTo: AuthRouterPathEnum.Login, pathMatch: 'full' },
      { path: '**', redirectTo: AuthRouterPathEnum.Login }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [DataService]
})
export class AppRoutingModule {}
