import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-phone-input',
  template: `
    <input type="tel" appPhoneMask [formControl]="formControl" [formlyAttributes]="field" />
  `
})
export class FormlyFieldPhoneInput extends FieldType {}
