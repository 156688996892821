import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResourceEnum, CompanyProfileRouterPathEnum, StatusTypeEnum } from '@shared/enums';
import {
  CompanyFormModel,
  CompanyListResponseModel,
  CompanyModel,
  GeneralLicenseModel,
  GeneralSettingModel,
  MessageResponseModel,
  PaginationModel,
  PaginationQueryModel,
  UserModel
} from '@shared/models';
import {
  flattenCompanies,
  flattenCompaniesWithPagination,
  getHttpHeaders,
  objToHttpParams
} from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class CompanyHttpService {
  constructor(private http: HttpClient, private router: Router) {}

  public getCompanies(
    queryParams: PaginationQueryModel
  ): Observable<{ companies: CompanyModel[]; pagination: PaginationModel }> {
    return this.http
      .get<CompanyListResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.CompaniesPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(data => flattenCompaniesWithPagination(data, queryParams.status)));
  }

  public changeCompanyStatus(id: number, status: StatusTypeEnum): Observable<MessageResponseModel> {
    return this.http.patch<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyChangeStatus,
        id
      ),
      { status },
      {
        headers: getHttpHeaders()
      }
    );
  }

  public searchCompanyByName(name: string): Observable<{ companies: CompanyModel[] }> {
    return this.http
      .get<{ companies: CompanyModel[] }>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.CompanyByName),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams({ name })
        }
      )
      .pipe(map(flattenCompanies));
  }

  public getCompany(id: number): Observable<{ company: CompanyModel }> {
    return this.http.get<{ company: CompanyModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CompanyById, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateCompany(id: number, company: CompanyFormModel): Observable<UserModel> {
    return this.http.put<UserModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CompanyById, id),
      company,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public deleteCompany(id: number): Observable<MessageResponseModel> {
    return this.http.delete<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.RemoveCompanyBranch,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createBranch(company: CompanyFormModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CreateBranch),
      company,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigateToBranches(): void {
    this.router.navigate([CompanyProfileRouterPathEnum.Branches]);
  }

  public getCompanyDropdownList(): Observable<{ companies: CompanyModel[] }> {
    return this.http.get<{ companies: CompanyModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.CompaniesDropdown),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getCompanyWithBranchesDropdownList(id: number): Observable<{ companies: CompanyModel[] }> {
    return this.http.get<{ companies: CompanyModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyBranchesDropdown,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateCompanyGeneralLicense(
    id: number,
    generalLicense: GeneralLicenseModel
  ): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyGeneralLicense,
        id
      ),
      generalLicense,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public saveCompanyGeneralSetting(
    id: number,
    settings: GeneralSettingModel
  ): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyGeneralSettings,
        id
      ),
      settings,
      {
        headers: getHttpHeaders()
      }
    );
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    routeBinding: number
  ): string {
    return `${baseUrl}/${resource.replace(':id', routeBinding.toString())}`;
  }
}
