import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import {
  CompanyModel,
  MainMenuModel,
  SidebarCategoryModel,
  UserProfileModel
} from '@shared/models';
import { LAYOUT_FEATURE, LayoutState } from './layout.state';

export const getLayoutState = createFeatureSelector<LayoutState>(LAYOUT_FEATURE);

export const getMainMenuState: MemoizedSelector<object, MainMenuModel[]> = createSelector(
  getLayoutState,
  ({ main_menu }: LayoutState): MainMenuModel[] => main_menu
);

export const getSidebarState: MemoizedSelector<object, SidebarCategoryModel> = createSelector(
  getLayoutState,
  ({ sidebar }: LayoutState): SidebarCategoryModel => sidebar
);

export const getShowUserDropdownState: MemoizedSelector<object, boolean> = createSelector(
  getLayoutState,
  ({ showUserDropdown }: LayoutState): boolean => showUserDropdown
);

export const getShowSideMenuState: MemoizedSelector<object, boolean> = createSelector(
  getLayoutState,
  ({ showSideMenu }: LayoutState): boolean => showSideMenu
);

export const getShowSidebarState: MemoizedSelector<object, boolean> = createSelector(
  getLayoutState,
  ({ showSidebar }: LayoutState): boolean => showSidebar
);

export const getSelectedCompanyIdState: MemoizedSelector<object, number> = createSelector(
  getLayoutState,
  ({ selectedCompanyId }: LayoutState): number => selectedCompanyId
);

export const getCompanyListState: MemoizedSelector<object, CompanyModel[]> = createSelector(
  getLayoutState,
  ({ companyList }: LayoutState): CompanyModel[] => companyList
);

export const getSelectedEmployeeIdState: MemoizedSelector<object, number> = createSelector(
  getLayoutState,
  ({ selectedEmployeeId }: LayoutState): number => selectedEmployeeId
);

export const getEmployeeListState: MemoizedSelector<object, UserProfileModel[]> = createSelector(
  getLayoutState,
  ({ employeeList }: LayoutState): UserProfileModel[] => employeeList
);
