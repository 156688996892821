import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() public loaderText: string = 'common.loader.text';
  @Input() public showAppLoader: boolean;

  public isLoading$: Subject<boolean> = this.loaderService.isLoading$;

  constructor(private loaderService: LoaderService) {}
}
