import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
  MemoizedSelectorWithProps
} from '@ngrx/store';

import { PermissionEnum, RoleEnum, UserProfileTypeEnum } from '@shared/enums';
import { PermissionModel, RoleModel, UserProfileModel, UserValidationsModel } from '@shared/models';
import {
  getEmployeeListState,
  getSelectedCompanyIdState,
  getSelectedEmployeeIdState
} from '@store/layout/layout.selectors';
import { AUTH_FEATURE, AuthState } from './auth.state';

export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE);

export const getIsLoadingState: MemoizedSelector<object, boolean> = createSelector(
  getAuthState,
  ({ isLoading }: AuthState): boolean => isLoading
);

export const getIsRoleSuperAdminState: MemoizedSelector<object, boolean> = createSelector(
  getAuthState,
  ({ role }: AuthState): boolean => role && role.name === RoleEnum.SuperUser
);

export const getUserPermissionsState: MemoizedSelector<object, PermissionModel[]> = createSelector(
  getAuthState,
  ({ permissions }: AuthState): PermissionModel[] => permissions
);

export const getRolesState: MemoizedSelector<object, RoleModel[]> = createSelector(
  getAuthState,
  ({ roles }: AuthState): RoleModel[] => roles
);

export const getUserProfileState: MemoizedSelector<object, UserProfileModel> = createSelector(
  getAuthState,
  ({ user_profile }: AuthState): UserProfileModel => user_profile
);

export const getUserValidationsState: MemoizedSelector<
  object,
  UserValidationsModel
> = createSelector(getAuthState, ({ validations }: AuthState): UserValidationsModel => validations);

export const getUserProfileTypeState: MemoizedSelector<
  object,
  UserProfileTypeEnum
> = createSelector(
  getAuthState,
  ({ user_profile }: AuthState): UserProfileTypeEnum =>
    user_profile && user_profile.user_profile_type
);

export const getCompanyIdState: MemoizedSelector<object, number> = createSelector(
  getUserProfileState,
  getSelectedCompanyIdState,
  (userProfile: UserProfileModel, id: number): number =>
    id || (userProfile && userProfile.company_id)
);

export const getIsEmailConfirmedState: MemoizedSelector<object, boolean> = createSelector(
  getAuthState,
  ({ emailConfirmed }: AuthState): boolean => emailConfirmed
);

export const getUserIdState: MemoizedSelector<object, number> = createSelector(
  getAuthState,
  getSelectedEmployeeIdState,
  ({ id }: AuthState, selectedEmployeeId: number): number => selectedEmployeeId || id
);

export const getSelectedEmployeeFullNameState: MemoizedSelector<object, string> = createSelector(
  getUserIdState,
  getEmployeeListState,
  (selectedEmployeeId: number, employeeList: UserProfileModel[]): string => {
    const employee: UserProfileModel | undefined = (employeeList || []).find(
      ({ user_account_id }: UserProfileModel) => user_account_id === selectedEmployeeId
    );

    return employee && employee.full_name;
  }
);

export const getTermsAndConditionAcceptedState: MemoizedSelector<object, boolean> = createSelector(
  getAuthState,
  ({ terms_accepted }: AuthState): boolean => terms_accepted
);

export const getTermsAndConditionAcceptedAtState: MemoizedSelector<object, string> = createSelector(
  getAuthState,
  ({ terms_accepted_at }: AuthState): string => terms_accepted_at
);

export const hasPermissionState: MemoizedSelectorWithProps<
  object,
  PermissionEnum,
  boolean
> = createSelector(
  getAuthState,
  ({ permissions }: AuthState, permission: PermissionEnum): boolean =>
    permissions.some((p: PermissionModel) => p.name === permission)
);
