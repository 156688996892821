import Rflatten from 'ramda/es/flatten';
import Rmap from 'ramda/es/map';
import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';
import Rpipe from 'ramda/es/pipe';

import { CommScheduleListResponseModel, CommScheduleModel, PaginationModel } from '@shared/models';

export const flattenCommSchedulesWithPagination = (
  data: CommScheduleListResponseModel
): { comm_schedules: CommScheduleModel[]; pagination: PaginationModel } => ({
  comm_schedules: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});

export const updateCommcheduleListWithUpdatedSchedule = (
  id: number,
  commSchedule: CommScheduleModel,
  commSchedules: CommScheduleModel[]
): CommScheduleModel[] =>
  Rpipe(
    Rmap((schedule: CommScheduleModel) => (schedule.id === id ? commSchedule : schedule)),
    Rflatten
  )(commSchedules);
