export enum ApiResourceEnum {
  Login = 'login',
  GetUser = 'account',
  GetNotifications = 'account/notifications',
  Register = 'register',
  Refresh = 'refresh',
  ConfirmEmail = 'register/confirm',
  PasswordReset = 'password/email',
  PasswordChange = 'password/reset',
  CompaniesPaginate = 'companies/paginate',
  CompaniesDropdown = 'companies/dropdown-list',
  CompanyChangeStatus = 'company/:id/status',
  CompanyByName = 'companies/findByName',
  CompanyById = 'company/:id',
  CompanyBranchesDropdown = 'company/:id/dropdown-list',
  CompanyGeneralLicense = 'company/:id/general-license',
  CompanyGeneralSettings = 'company/:id/general-settings',
  GetArchonInspectors = 'archon/inspectors',
  GetArchonTechnicians = 'archon/technicians',
  UploadLogo = 'company/:id/logo',
  UploadCompanyDocument = 'company/:id/document',
  RemoveCompanyDocument = 'company/:id/document/:fileId',
  CreateBranch = 'branch',
  RemoveCompanyBranch = 'branch/:id',
  PriceLists = 'price-lists',
  PriceListsPaginate = 'price-lists/paginate',
  CreateCompanyPriceList = 'company-price-list/:id',
  AssignCompanyPriceList = 'company-price-list/:id/attach/:priceListId',
  PriceList = 'price-list/:id',
  CreatePriceList = 'price-list',
  CompanyUserAccounts = 'company/:id/accounts',
  UserAccountChangeStatus = 'account/:id/status',
  Permissions = 'permissions',
  PermissionsPaginate = 'permissions/paginate',
  PermissionByName = 'permissions/findByName',
  Permission = 'permission/:id',
  RolesPaginate = 'roles/paginate',
  RoleByName = 'roles/findByName',
  Role = 'role/:id',
  Roles = 'roles',
  CreateRole = 'role',
  ZonesPaginate = 'operational-zones/paginate',
  Zones = 'operational-zones',
  Zone = 'operational-zone/:id',
  AccountsPaginate = 'accounts/paginate',
  AccountByName = 'accounts/findByName',
  UserProfile = 'user-profile/:id',
  UserAccount = 'account/:id',
  AcceptTermsAndConditions = 'account/:id/accept',
  ResetPassword = 'account/:id/reset',
  CompanyProfilesWithouAccount = 'user-profiles/:id/without-account',
  CompanyProfilesPaginate = 'user-profiles/:id/paginate',
  CompanyProfilesPerTypePaginate = 'user-profiles/:id/type-paginate',
  CompanyProfilesPerTypeSearchByName = '/user-profiles/:id/find-by-type-name',
  CompanyProfiles = 'user-profiles/:id',
  CompanyDirectoryProfiles = 'user-profiles/:id/directory',
  ArchonEmployees = 'archon/employees',
  ArchonEmployeesDropdown = 'archon/employees/dropdown-list',
  ArchonEmployeesPaginate = 'archon/employees/paginate',
  UploadAvatar = 'user-profile/:id/avatar',
  UserProfileChangeStatus = 'user-profile/:id/status',
  UploadUserProfileDocument = 'user-profile/:id/document',
  RemoveUserProfileDocument = 'user-profile/:id/document/:fileId',
  UpdateEmployee = 'user-profile/:companyId/:id',
  TimeClockTask = 'tasks',
  TimeClockTasksToday = 'admin/tasks/today/:id',
  TimeClockUserHistory = 'admin/tasks/history/:id',
  TimeClockTasksDate = 'admin/tasks/date/:id/:date',
  TimeClockRemoveTask = 'admin/tasks/delete/:id',
  TimeClockPastTask = 'admin/tasks',
  TimeClockTasksUpdate = 'admin/tasks/update',
  TimeClockEarningDate = 'admin/tasks/users/:date',
  TimeClockEarningReport = 'admin/payroll/:date/pdf',
  CompanyGeneralContacts = 'general-contacts/:id',
  BuildingDepartments = 'building-departments',
  BuildingDepartment = 'building-department/:id',
  BuildingDepartmentNote = 'building-department-note/:id',
  UploadBuildingDepartmentDocument = 'building-department/:id/document',
  RemoveBuildingDepartmentDocument = 'building-department/:id/document/:fileId',
  BusinessLicensesPaginate = 'business-licenses/:id/paginate',
  BusinessLicenseCreate = 'business-license',
  BusinessLicense = 'business-license/:id',
  PaymentTypes = 'company/:id/payment-methods',
  CreditCard = 'company/:id/credit-card',
  SetDefaultCreditCard = 'company/:id/credit-card/set-default',
  BankAccount = 'company/:id/bank-account',
  CommSchedulePaginate = 'communication-schedules/paginate',
  CommScheduleClosurePaginate = 'communication-schedules/closure/paginate',
  CommScheduleByName = 'communication-schedules/findByName',
  CommScheduleByNameClosure = 'communication-schedules/closure/findByName',
  CommScheduleHistoryLog = 'communication-schedules/:id',
  CommScheduleSMS = 'communication-schedules/sms/:id',
  CommSchedulePDF = 'communication-schedules/:id/pdf',
  PriceCategories = 'price-categories',
  PriceGroupsPaginate = 'price-groups/paginate',
  CreatePriceGroup = 'price-group',
  PriceGroup = 'price-group/:id',
  PriceGroups = 'price-groups',
  PriceServicesPaginate = 'price-services/paginate',
  CreatePriceService = 'price-service',
  PriceService = 'price-service/:id',
  ScoresInspectionById = 'scores/inspection/:id',
  CreateScoresInspection = 'scores',
  ScoresInspection = 'scores/update/:id',
  AgreementsInspectionById = 'agreements/inspection/:id',
  AgreementsInspection = 'agreements/update/:id',
  HotWaterByInspectionId = 'inspections/water_heaters/:id',
  HotWater = 'water_heaters/update/:id',
  CreateHotWater = 'water_heaters',
  PvSystemByInspectionId = 'inspections/pv_systems/:id',
  PvSystem = 'pv_systems/update/:id',
  CreatePvSystem = 'pv_systems',
  OutdoorFeaturesByInspectionId = 'inspections/outdoor_features/:id',
  OutdoorFeatures = 'outdoors/update/:id',
  CreateOutdoorFeatures = 'outdoors',
  AssessorElectrificationByInspectionId = 'assessor_electrification/inspection/:id',
  AssessorElectrification = 'assessor_electrification/update/:id',
  CreateAssessorElectrification = 'assessor_electrification',
  RoofAtticByInspectionId = 'inspections/insulation_zones/:id',
  RoofAttic = 'insulation_zones',
  RemoveRoofAttic = 'insulation_zones/delete/:id',
  ResendVerificationEmail = 'register/resend-verification-email',
  CheersListById = 'cheers/documentInformation/:id',
  CheersDownloadDocument = 'cheers/documentUrl/:id',
  CreateCf1rCertificate = 'cheers/cf1r/2019',
  CreateCf2rCertificate = 'cheers/cf2rInstallation',
  CreateCf3rCertificate = 'cheers/cf3rVerification',
  CreateAllCertificate = 'cheers/allDocumentsUrl/:id',
  CheersDeleteDocument = 'cheers/deleteDocument/:id',
  FoundationByInspectionId = 'inspections/foundations/:id',
  Foundations = 'foundations',
  RemoveFoundation = 'foundations/delete/:id',
  WallsByInspectionId = 'inspections/walls/:id',
  Walls = 'walls',
  HeatingsByInspectionId = 'inspections/heatings/:id',
  Heatings = 'heatings',
  RemoveHeating = 'heatings/delete/:id',
  CoolingByInspectionId = 'inspections/coolings/:id',
  Coolings = 'coolings',
  RemoveCooling = 'coolings/delete/:id',
  DuctByInspectionId = 'inspections/ducts/:id',
  Ducts = 'ducts',
  RemoveDuct = 'ducts/delete/:id',
  AppliancesByInspectionId = 'inspections/appliances/:id',
  Appliances = 'appliances',
  RemoveAppliances = 'appliances/delete/:id',
  WindowsByInspectionId = 'inspections/windows/:id',
  Windows = 'windows/updateForWeb',
  CazTestingByInspectionId = 'caz_testings/inspection/:id',
  CazTesting = 'caz_testings',
  RemoveCazZone = 'caz_zones/delete/:id',
  InfiltrationResultsByInspectionId = 'blower_doors/inspection/:id',
  InfiltrationResults = 'blower_doors/update/:id',
  CreateInfiltrationResults = 'blower_doors',
  SystemsByInspectionId = 'inspections/systems/:id',
  RemoveSplitUnitSystem = 'split_units/delete/:id',
  RemovePackageUnitSystem = 'package_units/delete/:id',
  RemoveMiniSplitSystem = 'mini_split_heatpumps/delete/:id',
  SplitUnitsBySystemId = 'split_units/:id',
  PackageUnitsBySystemId = 'package_units/:id',
  MiniSplitHeatPumpBySystemId = 'mini_split_heatpumps/:id',
  SplitUnits = 'split_units/update/:id',
  PackageUnits = 'package_units/update/:id',
  MiniSplitHeatPump = 'mini_split_heatpumps/update/:id',
  CreateMiniSplitUnits = 'mini_split_units',
  MiniSplitUnits = 'mini_split_units/update/:id',
  CreateUpdateMiniSplitUnits = 'mini_split_units/create/update/for/web',
  CreateHeatPumpPackageUnits = 'heatpump_package_units',
  HeatPumpPackageUnits = 'heatpump_package_units/update/:id',
  CreateGasPackageUnits = 'gas_package_units',
  GasPackageUnits = 'gas_package_units/update/:id',
  CreateFurnaces = 'furnaces',
  Furnaces = 'furnaces/update/:id',
  CreateAcEvaporators = 'ac_evaporators',
  AcEvaporators = 'ac_evaporators/update/:id',
  CreateHeatPumpCondensers = 'heatpump_condensers',
  HeatPumpCondensers = 'heatpump_condensers/update/:id',
  CreateAirHandlers = 'air_handlers',
  AirHandlers = 'air_handlers/update/:id',
  CreateAcCondensers = 'ac_condensers',
  AcCondensers = 'ac_condensers/update/:id',
  CreateAirflowVerifications = 'airflow_verifications/create/for/web',
  AirflowVerifications = 'airflow_verifications/update/for/web/:id',
  CreateFanWattDraw = 'fan_watt_draws/create/for/web',
  FanWattDraw = 'fan_watt_draws/update/for/web/:id',
  CreateRefrigerantVerifications = 'refrigerant_verifications/create/for/web',
  RefrigerantVerifications = 'refrigerant_verifications/update/for/web/:id',
  CreateDuctLeakageVerifications = 'duct_leakages/create/for/web',
  DuctLeakageVerifications = 'duct_leakages/update/for/web/:id',
  UpdateInstalledDuctSystemInfo = 'duct_system_informations/update/:id',
  ProjectsGet = 'projects/get/by/user/for/web?paginate=1000',
  ProjectInspectionsGet = 'inspections/get/by/project/for/web/:id?paginate=1000',
  ProjectInspectionStepsGet = 'inspections/get/steps/:id',
  ProjectInspectionChangeStatus = 'inspections/complete/:id',
  NoShowChangeStatus = 'inspections/status/noshow/:id',
  ImportOldInspection = 'inspections/import/data/:id',
  ReportsCountById = 'pdf/get/documents/count/:id',
  ReportsHomeScore = 'pdf/:id/HomeScore',
  CreateReportHomeScore = 'pdf/home_energy_score/:id',
  RemoveReport = 'pdf/delete/:id',
  ReportsT24 = 'pdf/:id/T24',
  CreateReportT24 = 'pdf/systems/:id',
  ReportsSmud = 'pdf/:id/smud',
  CreateReportSmud = 'pdf/smud/:id',
  ReportsInspectionAgreement = 'pdf/:id/InspectionAgreement',
  CreateReportInspectionAgreement = 'pdf/inspection_agreement/:id',
  CreateReportHomeScoreRebate = 'pdf/home_energy_score_rebate/:id',
  ReportsBayren = 'pdf/:id/BPI',
  CreateReportBayren = 'pdf/bpi/:id',
  BonusPricing = 'bonus-pricing',
  UpdateBonusPricing = 'bonus-pricing/:id',
  AddCustomBonus = 'bonuses',
  UpdateCustomBonus = 'bonuses/update/:id',
  OtpAuth = 'otp-auth',
  OtpResend = 'otp-resend'
}
