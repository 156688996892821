import { PermissionModel, RoleModel, UserProfileModel } from '@shared/models';

export interface AuthState {
  id: number;
  email: string;
  emailConfirmed: boolean;
  terms_accepted: boolean;
  terms_accepted_at: string;
  user_profile: UserProfileModel;
  role: RoleModel;
  permissions: PermissionModel[];
  isLoading: boolean;
  validations: any;
  roles: RoleModel[];
}

export const authInitialState: AuthState = {
  id: null,
  email: '',
  emailConfirmed: false,
  terms_accepted: false,
  terms_accepted_at: null,
  user_profile: null,
  role: null,
  permissions: [],
  isLoading: false,
  validations: null,
  roles: []
};

export const AUTH_FEATURE = 'authentication';
