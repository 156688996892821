import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';

import {
  BusinessLicenseListResponseModel,
  BusinessLicenseModel,
  PaginationModel
} from '@shared/models';

export const flattenBusinessLicensesWithPagination = (
  data: BusinessLicenseListResponseModel
): { business_licenses: BusinessLicenseModel[]; pagination: PaginationModel } => ({
  business_licenses: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});
