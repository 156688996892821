import { PositionTypeEnum, ProfessionLicenseTypeEnum, UserProfileTypeEnum } from '@shared/enums';

export const PROFESSIONAL_LICENSE_TYPE_LIST: ProfessionLicenseTypeEnum[] = [
  ProfessionLicenseTypeEnum.BPILicense,
  ProfessionLicenseTypeEnum.HomeScoreLicense,
  ProfessionLicenseTypeEnum.T24License
];

export const SHOW_IN_DIRECTORY: string[] = [
  'common.list.hide-from-directory',
  'common.list.show-in-directory'
];

export const POSITION_TYPE_LIST: { [key in UserProfileTypeEnum]: PositionTypeEnum[] } = {
  [UserProfileTypeEnum.ArchonEmployee]: [
    PositionTypeEnum.Accounting,
    PositionTypeEnum.CEO,
    PositionTypeEnum.Inspector,
    PositionTypeEnum.OfficeManager,
    PositionTypeEnum.OfficeStaff,
    PositionTypeEnum.PermitTechnician
  ],
  [UserProfileTypeEnum.CompanyEmployee]: [
    PositionTypeEnum.Accounting,
    PositionTypeEnum.OfficeManager,
    PositionTypeEnum.OfficeStaff,
    PositionTypeEnum.Owner,
    PositionTypeEnum.ProjectManager,
    PositionTypeEnum.Technician
  ],
  [UserProfileTypeEnum.Installer]: [PositionTypeEnum.Apprentice, PositionTypeEnum.LeadInstaller],
  [UserProfileTypeEnum.SalesPerson]: [PositionTypeEnum.SalesManager, PositionTypeEnum.SalesPerson],
  [UserProfileTypeEnum.Client]: []
};
