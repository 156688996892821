import { MainMenuRouteEnum, PermissionEnum } from '@shared/enums';
import { PermissionModel, SidebarCategoryModel } from '@shared/models';

const CompanyAssociatesPermissions = [
  PermissionEnum.CanSeeHomeOwners,
  PermissionEnum.CanSeeSalesPerson,
  PermissionEnum.CanSeeInstallers
];

const CompanyInspectionOptionsPermissions = [
  PermissionEnum.CanSeeTitle24Options,
  PermissionEnum.CanSeeRebateCompanies,
  PermissionEnum.CanSeeHomeEnergyScore
];

const CompanyPermitOptionsPermissions = [
  PermissionEnum.CanSeeBusinessLicenses,
  PermissionEnum.CanSeeBuildingDepartmentNote
];

export const getProjectOptionMenu = (permissions: PermissionModel[]): SidebarCategoryModel => ({
  category: 'navigation.sidebar.category.project-options',
  icon: 'icon-project-request.svg',
  group: [
    {
      name: 'navigation.sidebar.group.company-associates',
      icon: 'icon-general-info.svg',
      show: permissions.some(({ name }) => CompanyAssociatesPermissions.includes(name)),
      items: [
        {
          name: 'navigation.sidebar.item.home-owners',
          route: `${MainMenuRouteEnum.ProjectOption}/home-owners`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeHomeOwners)
        },
        {
          name: 'navigation.sidebar.item.sales-persons',
          route: `${MainMenuRouteEnum.ProjectOption}/sales-persons`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeSalesPerson)
        },
        {
          name: 'navigation.sidebar.item.installers',
          route: `${MainMenuRouteEnum.ProjectOption}/installers`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeInstallers)
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.inspection-options',
      icon: 'icon-permits.svg',
      show: permissions.some(({ name }) => CompanyInspectionOptionsPermissions.includes(name)),
      items: [
        {
          name: 'navigation.sidebar.item.title-24-options',
          route: `${MainMenuRouteEnum.ProjectOption}/title-24-options`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeTitle24Options)
        },
        {
          name: 'navigation.sidebar.item.rebate-companies',
          route: `${MainMenuRouteEnum.ProjectOption}/rebate-companies`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeRebateCompanies)
        },
        {
          name: 'navigation.sidebar.item.home-energy-score',
          route: `${MainMenuRouteEnum.ProjectOption}/home-energy-score`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeHomeEnergyScore)
        }
      ]
    },
    {
      name: 'navigation.sidebar.group.permit-options',
      icon: 'icon-inspections.svg',
      show: permissions.some(({ name }) => CompanyPermitOptionsPermissions.includes(name)),
      items: [
        {
          name: 'navigation.sidebar.item.authorization-letters',
          route: `${MainMenuRouteEnum.ProjectOption}/authorization-letters`,
          show: false
        },
        {
          name: 'navigation.sidebar.item.permit-contracts',
          route: `${MainMenuRouteEnum.ProjectOption}/permit-contracts`,
          show: false
        },
        {
          name: 'navigation.sidebar.item.business-licenses',
          route: `${MainMenuRouteEnum.ProjectOption}/business-licenses`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeBusinessLicenses)
        },
        {
          name: 'navigation.sidebar.item.building-dep-note',
          route: `${MainMenuRouteEnum.ProjectOption}/building-department-notes`,
          show: permissions.some(({ name }) => name === PermissionEnum.CanSeeBuildingDepartmentNote)
        }
      ]
    }
  ]
});
