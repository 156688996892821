import Rappend from 'ramda/es/append';
import Rreject from 'ramda/es/reject';
import RwhereEq from 'ramda/es/whereEq';

import { ReportsListModel } from '@shared/models';

export const getReportsListWithoutRemoved = (
  id: number,
  reportsList: ReportsListModel[]
): ReportsListModel[] => Rreject(RwhereEq({ id }), reportsList);

export const updateReportsListWithUpdateReport = (
  report: ReportsListModel,
  reportsList: ReportsListModel[]
): ReportsListModel[] => Rappend(report, reportsList);
