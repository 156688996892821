import Romit from 'ramda/es/omit';
import RpathOr from 'ramda/es/pathOr';

import {
  PaginationModel,
  PermissionModel,
  PermissionResponseModel,
  RoleModel,
  RoleResponseModel
} from '@shared/models';

export const flattenRolesWithPagination = (
  data: RoleResponseModel
): { roles: RoleModel[]; pagination: PaginationModel } => ({
  roles: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});

export const flattenPermissionsWithPagination = (
  data: PermissionResponseModel
): { permissions: PermissionModel[]; pagination: PaginationModel } => ({
  permissions: RpathOr([], ['data'], data),
  pagination: Romit(['data'], data)
});
