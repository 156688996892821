import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ErrorResponseModel } from '@shared/models';
import { MESSAGE_FEATURE, MessageState } from './message.state';

export const getMessageState = createFeatureSelector<MessageState>(MESSAGE_FEATURE);

export const getValidationErrorSumaryState: MemoizedSelector<
  object,
  ErrorResponseModel | null
> = createSelector(getMessageState, ({ error }: MessageState): ErrorResponseModel | null => error);
