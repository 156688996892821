import { SessionExpirationPopupStatus } from '../enums/session-expiration-popup-type.enum';

export interface SessionExpirationStatus {
  popupStatus: SessionExpirationPopupStatus;
  expirationTime: number;
}

export const defaultInitialSessionPopupStatus: SessionExpirationStatus = {
  popupStatus: SessionExpirationPopupStatus.Hidden,
  expirationTime: 0
};
