import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'arrow-tooltip',
  templateUrl: './arrow-tooltip.component.html',
  styleUrls: ['./arrow-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowTooltipComponent {
  @Input() public content: TemplateRef<any>;
}
