import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthRouteSuccessMessageMap } from '@app/+auth/mappings';
import { environment } from '@environments/environment';
import {
  AdminRouterPathEnum,
  ApiResourceEnum,
  CompanyProfileRouterPathEnum,
  ProjectOptionsRouterPathEnum,
  StatusTypeEnum,
  UserProfileTypeEnum
} from '@shared/enums';
import {
  AccountResponseModel,
  CreateAccountFormModel,
  EmployeeFormModel,
  GeneralContactModel,
  MessageResponseModel,
  NewGeneralContactModel,
  PaginationModel,
  PaginationQueryModel,
  ResetPasswordFormModel,
  UpdateAccountFormModel,
  UpdateGeneralContactModel,
  UserModel,
  UserProfileModel,
  UserProfileResponseModel
} from '@shared/models';
import {
  flattenAccountsWithPagination,
  flattenUserProfilesWithPagination,
  getHttpHeaders,
  objToHttpParams
} from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {
  constructor(private http: HttpClient, private router: Router) {}

  public getCompanyUserAccounts(id: number): Observable<{ accounts: UserModel[] }> {
    return this.http.get<{ accounts: UserModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyUserAccounts,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public changeUserAccountStatus(
    id: number,
    status: StatusTypeEnum
  ): Observable<{ user_profile: UserProfileModel }> {
    return this.http.patch<{ user_profile: UserProfileModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.UserAccountChangeStatus,
        id
      ),
      { status },
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPaginatedAccounts(
    queryParams: PaginationQueryModel
  ): Observable<{ accounts: UserModel[]; pagination: PaginationModel }> {
    return this.http
      .get<AccountResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.AccountsPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenAccountsWithPagination));
  }

  public searchAccountByName(name: string): Observable<{ accounts: UserModel[] }> {
    return this.http.get<{ accounts: UserModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.AccountByName),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ name })
      }
    );
  }

  public getArchonInspectors(): Observable<{ inspectors: UserProfileModel[] }> {
    return this.http.get<{ inspectors: UserProfileModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.GetArchonInspectors),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getArchonTechnicians(): Observable<{ technicians: UserProfileModel[] }> {
    return this.http.get<{ technicians: UserProfileModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.GetArchonTechnicians),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getUserProfile(id: number): Observable<{ user_profile: UserProfileModel }> {
    return this.http.get<{ user_profile: UserProfileModel }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.UserProfile, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getCompanyUserProfiles(id: number): Observable<{ user_profiles: UserProfileModel[] }> {
    return this.http.get<{ user_profiles: UserProfileModel[] }>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.CompanyProfiles, id),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateAccount(
    id: number,
    account: UpdateAccountFormModel
  ): Observable<MessageResponseModel> {
    return this.http.put<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.UserAccount, id),
      account,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public resetPassword(
    id: number,
    resetPassword: ResetPasswordFormModel
  ): Observable<MessageResponseModel> {
    return this.http.patch<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.ResetPassword, id),
      resetPassword,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getCompanyUserProfilesWithoutAccount(
    id: number
  ): Observable<{ user_profiles: UserProfileModel[] }> {
    return this.http.get<{ user_profiles: UserProfileModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyProfilesWithouAccount,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createAccount(
    id: number,
    account: CreateAccountFormModel
  ): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.UserAccount, id),
      account,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigateToAccounts(): void {
    const path = this.router.isActive(CompanyProfileRouterPathEnum.Accounts, false)
      ? CompanyProfileRouterPathEnum.Accounts
      : AdminRouterPathEnum.Accounts;

    this.router.navigate([path]);
  }

  public getArchonEmployees(): Observable<{ user_profiles: UserProfileModel[] }> {
    return this.http.get<{ user_profiles: UserProfileModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.ArchonEmployees),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPaginatedArchonEmployees(
    queryParams: PaginationQueryModel
  ): Observable<{ user_profiles: UserProfileModel[]; pagination: PaginationModel }> {
    return this.http
      .get<UserProfileResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.ArchonEmployeesPaginate),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenUserProfilesWithPagination));
  }

  public updateUserProfile(
    id: number,
    companyId: number,
    userProfile: EmployeeFormModel
  ): Observable<UserModel> {
    return this.http.put<UserModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.UpdateEmployee,
        id,
        companyId
      ),
      userProfile,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createUserProfile(
    id: number,
    userProfile: EmployeeFormModel
  ): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpointWithRouteBinding(environment.API_URL, ApiResourceEnum.UserProfile, id),
      userProfile,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPaginatedCompanyEmployees(
    id: number,
    queryParams: PaginationQueryModel
  ): Observable<{ user_profiles: UserProfileModel[]; pagination: PaginationModel }> {
    return this.http
      .get<UserProfileResponseModel>(
        this.getEndpointWithRouteBinding(
          environment.API_URL,
          ApiResourceEnum.CompanyProfilesPaginate,
          id
        ),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenUserProfilesWithPagination));
  }

  public navigateToUserProfiles(
    redirectUrl: AdminRouterPathEnum | CompanyProfileRouterPathEnum | ProjectOptionsRouterPathEnum
  ): void {
    this.router.navigate([redirectUrl]);
  }

  public getCompanyGeneralContacts(
    id: number
  ): Observable<{ general_contacts: GeneralContactModel[] }> {
    return this.http.get<{ general_contacts: GeneralContactModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyGeneralContacts,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public createGeneralContact(
    id: number,
    generalContact: NewGeneralContactModel
  ): Observable<{ general_contacts: GeneralContactModel[] }> {
    return this.http.post<{ general_contacts: GeneralContactModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyGeneralContacts,
        id
      ),
      generalContact,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public updateGeneralContact(
    id: number,
    generalContact: UpdateGeneralContactModel
  ): Observable<{ general_contacts: GeneralContactModel[], user: UserModel }> {
    return this.http.put<{ general_contacts: GeneralContactModel[], user: UserModel }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyGeneralContacts,
        id
      ),
      generalContact,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public removeGeneralContact(id: number): Observable<{ general_contacts: GeneralContactModel[] }> {
    return this.http.delete<{ general_contacts: GeneralContactModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyGeneralContacts,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getCompanyDirectoryProfiles(
    id: number
  ): Observable<{ user_profiles: UserProfileModel[] }> {
    return this.http.get<{ user_profiles: UserProfileModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyDirectoryProfiles,
        id
      ),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public getPaginatedCompanyEmployeesPerType(
    id: number,
    queryParams: PaginationQueryModel
  ): Observable<{ user_profiles: UserProfileModel[]; pagination: PaginationModel }> {
    return this.http
      .get<UserProfileResponseModel>(
        this.getEndpointWithRouteBinding(
          environment.API_URL,
          ApiResourceEnum.CompanyProfilesPerTypePaginate,
          id
        ),
        {
          headers: getHttpHeaders(),
          params: objToHttpParams(queryParams)
        }
      )
      .pipe(map(flattenUserProfilesWithPagination));
  }

  public changeUserProfileStatus(
    id: number,
    status: StatusTypeEnum
  ): Observable<MessageResponseModel> {
    return this.http.patch<MessageResponseModel>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.UserProfileChangeStatus,
        id
      ),
      { status },
      {
        headers: getHttpHeaders()
      }
    );
  }

  public searchProfileByTypeAndName(
    id: number,
    userType: UserProfileTypeEnum,
    name: string
  ): Observable<{ user_profiles: UserProfileModel[] }> {
    return this.http.get<{ user_profiles: UserProfileModel[] }>(
      this.getEndpointWithRouteBinding(
        environment.API_URL,
        ApiResourceEnum.CompanyProfilesPerTypeSearchByName,
        id
      ),
      {
        headers: getHttpHeaders(),
        params: objToHttpParams({ name, type: userType })
      }
    );
  }

  public getArchonEmployeeDropdownList(): Observable<{ user_profiles: UserProfileModel[] }> {
    return this.http.get<{ user_profiles: UserProfileModel[] }>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.ArchonEmployeesDropdown),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigateOnSuccess(path: CompanyProfileRouterPathEnum): void {
    const navigationExtras: NavigationExtras = {
      state: { message: AuthRouteSuccessMessageMap[path] }
    };
    this.router.navigate([path], navigationExtras);
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum): string {
    return `${baseUrl}/${resource}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number,
    companyId?: number
  ): string {
    let path: string = resource.replace(':id', id.toString());
    if (companyId) {
      path = path.replace(':companyId', companyId.toString());
    }
    return `${baseUrl}/${path}`;
  }
}
