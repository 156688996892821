import { createReducer, on } from '@ngrx/store';

import { BillingActions, BillingActionsType } from './billing.actions';
import { billingInitialState, BillingState } from './billing.state';

const reducer = createReducer(
  billingInitialState,
  on(
    BillingActions.fetchPriceListsSuccessAction,
    (state: BillingState, { prices, empty_price_list }): BillingState => ({
      ...state,
      prices,
      empty_price_list
    })
  ),
  on(
    BillingActions.createCompanyPriceListSuccessAction,
    BillingActions.updateCompanyPriceListSuccessAction,
    BillingActions.assignCompanyPriceListSuccessAction,
    (state: BillingState, { prices }): BillingState => ({
      ...state,
      prices
    })
  ),
  on(
    BillingActions.fetchPriceListsPaginationSuccessAction,
    (state: BillingState, { prices, pagination }): BillingState => ({
      ...state,
      prices,
      pagination,
      price: null
    })
  ),
  on(
    BillingActions.fetchPriceListStartAction,
    BillingActions.fetchBonusPricingStartAction,
    (state: BillingState): BillingState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    BillingActions.fetchPriceListSuccessAction,
    (state: BillingState, { price }): BillingState => ({
      ...state,
      price,
      isLoading: false
    })
  ),
  on(
    BillingActions.fetchPriceListFailedAction,
    BillingActions.fetchBonusPricingFailedAction,
    (state: BillingState): BillingState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    BillingActions.fetchEmptyPriceListSuccessAction,
    (state: BillingState, { empty_price_list }): BillingState => ({
      ...state,
      empty_price_list
    })
  ),
  on(
    BillingActions.fetchPaymentTypesSuccessAction,
    (state: BillingState, { bank_accounts, credit_cards }): BillingState => ({
      ...state,
      bank_accounts,
      credit_cards
    })
  ),
  on(
    BillingActions.removeCreditCardSuccessAction,
    BillingActions.createCreditCardSuccessAction,
    BillingActions.updateCreditCardSuccessAction,
    BillingActions.setDefaultCreditCardSuccessAction,
    (state: BillingState, { credit_cards }): BillingState => ({
      ...state,
      credit_cards
    })
  ),
  on(
    BillingActions.removeAchPaymentSuccessAction,
    BillingActions.createAchPaymentSuccessAction,
    BillingActions.updateAchPaymentSuccessAction,
    (state: BillingState, { bank_accounts }): BillingState => ({
      ...state,
      bank_accounts
    })
  ),
  on(
    BillingActions.fetchPriceCategoriesSuccessAction,
    (state: BillingState, { price_categories }): BillingState => ({
      ...state,
      price_categories
    })
  ),
  on(
    BillingActions.fetchPriceGroupsPaginationSuccessAction,
    (state: BillingState, { price_groups, pagination }): BillingState => ({
      ...state,
      price_groups,
      pagination
    })
  ),
  on(
    BillingActions.fetchPriceGroupStartAction,
    BillingActions.fetchPriceServiceStartAction,
    (state: BillingState): BillingState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    BillingActions.fetchPriceGroupSuccessAction,
    (state: BillingState, { price_group }): BillingState => ({
      ...state,
      price_group,
      isLoading: false
    })
  ),
  on(
    BillingActions.fetchPriceGroupFailedAction,
    BillingActions.fetchPriceServiceFailedAction,
    BillingActions.saveBonusPricingFailedAction,
    BillingActions.updateBonusPricingFailedAction,
    (state: BillingState): BillingState => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    BillingActions.fetchPriceGroupsSuccessAction,
    (state: BillingState, { price_groups }): BillingState => ({
      ...state,
      price_groups
    })
  ),
  on(
    BillingActions.fetchPriceServicesPaginationSuccessAction,
    (state: BillingState, { price_services, pagination }): BillingState => ({
      ...state,
      price_services,
      pagination
    })
  ),
  on(
    BillingActions.fetchPriceServiceSuccessAction,
    (state: BillingState, { price_service }): BillingState => ({
      ...state,
      price_service,
      isLoading: false
    })
  ),
  on(
    BillingActions.fetchBonusPricingSuccessAction,
    BillingActions.saveBonusPricingSuccessAction,
    BillingActions.updateBonusPricingSuccessAction,
    (state: BillingState, { bonus_pricing }): BillingState => ({
      ...state,
      bonus_pricing,
      isLoading: false
    })
  )
);

export function billingReducer(state: BillingState, action: BillingActionsType): BillingState {
  return reducer(state, action);
}
