import {
  CazTestingModel,
  InfiltrationResultsModel
} from '@shared/models/bpi-data-collection.model';

export interface BpiDataCollectionState {
  cazTesting: CazTestingModel;
  infiltrationResults: InfiltrationResultsModel;
  isLoading: boolean;
}

export const bpiDataCollectionInitialState: BpiDataCollectionState = {
  cazTesting: null,
  infiltrationResults: null,
  isLoading: false
};

export const BPI_DATA_COLLECTION_FEATURE = 'bpiDataCollection';
