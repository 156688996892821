import { GeneralContactModel, PaginationModel, UserModel, UserProfileModel } from '@shared/models';

export interface UserState {
  accounts: UserModel[];
  user_profiles: UserProfileModel[];
  user_profile: UserProfileModel;
  inspectors: UserProfileModel[];
  technicians: UserProfileModel[];
  isLoading: boolean;
  pagination: PaginationModel;
  general_contacts: GeneralContactModel[];
}

export const userInitialState: UserState = {
  accounts: [],
  user_profiles: [],
  user_profile: null,
  inspectors: [],
  technicians: [],
  isLoading: false,
  pagination: null,
  general_contacts: []
};

export const USER_FEATURE = 'user';
