import {CommScheduleTypeEnum, CommunicationTypeEnum} from '@shared/enums';

export const COMMUNICATION_STATUS_FILTER_LIST: Array<{ text: string; value: string }> = [
  {
    text: 'All',
    value: null
  },
  {
    text: 'Successful Complete (Green)',
    value: 'Completed'
  },
  {
    text: 'Unsuccessful (Red)',
    value: 'Unsuccessful'
  },
  {
    text: 'All inactive statuses',
    value: 'Inactive'
  },
  {
    text: 'All active statuses',
    value: 'Active'
  }
];

export const COMMUNICATION_TYPE_FILTER_LIST: Array<{ text: string; value: string }> = [
  {
    text: 'All',
    value: null
  },
  {
    text: 'Phone Call',
    value: 'PhoneCall'
  },
  {
    text: 'Text SMS',
    value: 'TextSMS'
  },
  {
    text: 'Mail',
    value: 'Mail'
  },
  {
    text: 'Unresponsive',
    value: 'Unresponsive'
  },
];

export const COMMUNICATION_TYPE_LIST: CommScheduleTypeEnum[] = [
  CommScheduleTypeEnum.Mail,
  CommScheduleTypeEnum.PhoneCall,
  CommScheduleTypeEnum.TextSMS,
  CommScheduleTypeEnum.Unresponsive,
  CommScheduleTypeEnum.Verify
];

export const COMMUNICATION_CLOSURE_TYPE_LIST: CommunicationTypeEnum[] = [
  CommunicationTypeEnum.PhoneCall,
  CommunicationTypeEnum.TextSMS,
  CommunicationTypeEnum.Mail,
  CommunicationTypeEnum.MarkUnresponsive
];

export const YES_NO_OPTION: string[] = ['common.option.yes', 'common.option.no'];
