import { ChartDataSets } from 'chart.js';
import Rkeys from 'ramda/es/keys';
import Rmap from 'ramda/es/map';

import { TaskSummaryEnum } from '@shared/enums';
import { TaskSummaryMap } from '@shared/mappings';
import { EmployeeEarningModel, TaskSummaryModel } from '@shared/models';

export const getChartDatasets = (summary: TaskSummaryModel): ChartDataSets[] =>
  Rmap(
    (key: TaskSummaryEnum) => ({
      ...TaskSummaryMap[key],
      data: [summary[key]]
    }),
    Rkeys(summary)
  );

export const getUpdatedEarnings = (
  earnings: EmployeeEarningModel[],
  earning: EmployeeEarningModel
): EmployeeEarningModel[] =>
  Rmap(
    (data: EmployeeEarningModel) => ({
      ...(data.employee_id === earning.employee_id ? earning : data)
    }),
    earnings
  );
